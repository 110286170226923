import React, { useState } from "react";
import Typography from "utils/typography";

const Customized = ({ data, questionOption, filterLength }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  console.log(data, "Customized");

  return (
    <div className="space-y-3">
      {questionOption !== undefined ? (
        <div className="flex items-center justify-between py-2">
          <Typography.SubText className="font-semibold">
            {questionOption?.min?.value} - {questionOption?.min?.label}
          </Typography.SubText>

          <Typography.SubText className="font-semibold">
            {questionOption?.max?.value} - {questionOption?.max?.label}
          </Typography.SubText>
        </div>
      ) : null}

      {data?.map((el, i) => {
        return (
          <div key={`customBar${i}`} className="w-[100%] h-[35px] rounded-[4px] bg-primary-50 relative">
            <div
              style={{ width: `${filterLength === 0 ? el.percentage : el.staticPercentage}%` }}
              className="bg-primary-300 bg-opacity-40 rounded-[4px] h-[100%] absolute left-0 z-[6]"
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
            ></div>

            <div className="flex items-center justify-between  absolute z-[7] w-[100%] top-[25%] px-4">
              <p className="text-xs">
                {el.name === "MooyiOption" ? (
                  "Others"
                ) : (
                  <>
                    {el.name.substring(0, 50)} {el.name.length > 50 ? "..." : ""}
                  </>
                )}
              </p>

              <div className="flex space-x-1 text-sm">
                <p className="text-xs">{filterLength === 0 ? el.percentage : el.staticPercentage}%</p>
                <p className="text-xs">({filterLength === 0 ? el.value : el?.staticValue})</p>
              </div>
            </div>

            {/* <div
              style={{ width: `${el.staticPercentage}%` }}
              className="bg-violet-500 bg-opacity-30 rounded-[4px] h-[100%] absolute left-0 cursor-pointer "
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {hoveredIndex === i && (
                <span className=" h-auto rounded-[4px]shadow-primary p-2 text-[11px] flex items-center space-x-2 justify-between absolute z-[20] bg-white left-4 rounded-md top-[2px]">
                  <div className="w-[20%] flex items-center space-x-2">
                    <p>{el.staticPercentage}%</p>
                    <p>({el?.staticValue})</p>
                  </div>
                </span>
              )}
            </div> */}
            {/* </div> */}
          </div>
        );
      })}
    </div>
  );
};

export default Customized;
