import React, { useState } from "react";
import Plans from "./Plans";
import { useQuery } from "@tanstack/react-query";
import { getRates } from "services/rates";
import ToggleButton from "components/Button/ToogleButton";

const EngagementHub = () => {
  const [toggleValue, setToggleValue] = useState(false);

  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  const rates = useQuery(["rates"], getRates);
  const conversionRate = rates?.data?.data[0].currencyRates[7].userRate;
  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <p>Drive your business success with our comprehensive customer engagement.</p>
        <div className="pt-4 flex justify-center items-center space-x-3">
          <p className={`${!toggleValue && "font-bold"}`}>USD</p>
          <ToggleButton toggleValue={toggleValue} onChange={handleToggleChange} />
          <p className={`${toggleValue && "font-bold"}`}>NGN</p>
        </div>
      </div>
      <div>
        <Plans conversionRate={conversionRate} toggleValue={toggleValue} />
      </div>
    </div>
  );
};

export default EngagementHub;
