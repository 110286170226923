import dayjs from "dayjs";
import React from "react";
// import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";

const WeeklyView = ({ currentDate }) => {
  const getWeekDates = (date) => {
    const startOfWeek = date.startOf("week");
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(startOfWeek.add(i, "day"));
    }
    return dates;
  };

  const isSameDay = (date1, date2) => {
    return date1.isSame(date2, "day");
  };

  const isCurrentHour = (hour, currentDate, day) => {
    return day.isSame(dayjs(), "day") && hour === dayjs().hour() && currentDate.isSame(dayjs(), "week");
  };

  const weekDates = getWeekDates(currentDate);

  const hours = Array.from({ length: 23 }, (_, i) => i + 1); // Array of hours from 1 to 23

  const formatHour = (hour) => {
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour} ${ampm}`;
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="grid grid-cols-8 w-[100%] border-t-[1px] border-l-[1px]">
        <div className=" border-r-[1px]"></div> {/* Empty top-left corner */}
        {weekDates.map((date, index) => (
          <div
            key={index}
            className="text-center font-bold h-[70px] flex flex-col border-r-[1px] items-center justify-center"
          >
            <p className="font-semibold">{date.format("dddd")}</p>

            <p className="text-lg">{date.format("D")}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-8  w-full border-[1px] h-[62vh] overflow-y-scroll [&::-webkit-scrollbar]:w-0">
        {hours.map((hour) => (
          <React.Fragment key={hour}>
            <div className=" border-t-[1px] border-r-[1px] text-sm pr-2 flex items-center justify-center">
              {formatHour(hour)}
            </div>

            {weekDates.map((date, index) => (
              <div
                key={index}
                className={`p-2 border-t-[1px] border-r-[1px] h-12 ${
                  isSameDay(date, dayjs()) && isCurrentHour(hour, currentDate, date) ? "bg-primary-100" : ""
                }`}
              >
                {isSameDay(date, dayjs()) && isCurrentHour(hour, currentDate, date) ? (
                  <span className="font-bold text-sm ">{formatHour(hour)}</span>
                ) : (
                  ""
                )}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default WeeklyView;
