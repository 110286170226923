import React from "react";

export const GenerateLinkIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.66699 15.9997C2.66699 12.3178 5.65177 9.33301 9.33366 9.33301H13.3337C14.0701 9.33301 14.667 9.92997 14.667 10.6663C14.667 11.4027 14.0701 11.9997 13.3337 11.9997H9.33366C7.12453 11.9997 5.33366 13.7905 5.33366 15.9997C5.33366 18.2089 7.12453 19.9997 9.33366 19.9997H13.3337C14.0701 19.9997 14.667 20.5966 14.667 21.333C14.667 22.0694 14.0701 22.6663 13.3337 22.6663H9.33366C5.65177 22.6663 2.66699 19.6815 2.66699 15.9997Z"
      fill="#2D28FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.333 10.6663C17.333 9.92997 17.9299 9.33301 18.6663 9.33301H22.6663C26.3482 9.33301 29.333 12.3178 29.333 15.9997C29.333 19.6815 26.3482 22.6663 22.6663 22.6663H18.6663C17.9299 22.6663 17.333 22.0694 17.333 21.333C17.333 20.5966 17.9299 19.9997 18.6663 19.9997H22.6663C24.8755 19.9997 26.6663 18.2089 26.6663 15.9997C26.6663 13.7905 24.8755 11.9997 22.6663 11.9997H18.6663C17.9299 11.9997 17.333 11.4027 17.333 10.6663Z"
      fill="#2D28FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.667 16.0003C10.667 15.2639 11.264 14.667 12.0003 14.667H20.0003C20.7367 14.667 21.3337 15.2639 21.3337 16.0003C21.3337 16.7367 20.7367 17.3337 20.0003 17.3337H12.0003C11.264 17.3337 10.667 16.7367 10.667 16.0003Z"
      fill="#1D19AF"
    />
  </svg>
);
