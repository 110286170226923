export const handleApiError = (error) => {
  if (process.env.NODE_ENV !== "production") {
    console.warn("[Axios Error....]", error);
  }

  const { response } = error;
  const { data } = response;

  if (data?.message) return data?.message;
  switch (response?.status) {
    case 400:
      return (data?.error && JSON.stringify(data?.error)) || data?.message || "Something went wrong";
    case 401:
      return "Unauthorized User!";
    case 404:
      return data?.message || "Resource not found";
    case 409:
      return "A duplicate already exists";
    default:
      return "Something went wrong";
  }
};
