import React, { useState } from "react";
import { FaAnglesRight } from "react-icons/fa6";
import { Link, NavLink, useLocation } from "react-router-dom";
import smallLogo from "assets/images/Sidebar/smallLogo.png";
import { CalenderIcon, ContactIcon, HomeIcon, MessagingIcon, SurveyIcon, SettingsIcon } from "assets/icons/sidebar";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { fetchWorkspace } from "services/workspaceService";
import OpenSidebar from "./OpenSidebar";
import BlueButton from "./Button/BlueButton";
import Skeleton from "react-loading-skeleton";

const tabs = [
  { id: 1, name: "Home", icons: HomeIcon, path: "/dashboard" },
  { id: 7, name: "Calendar", icons: CalenderIcon, path: "/calendar" },

  {
    id: 2,
    name: "Contacts",
    icons: ContactIcon,
    path: "/contacts",
    children: [
      { id: 21, name: "All contacts", path: "/contacts/all-contacts?tab=0" },
      { id: 22, name: "All leads", path: "/contacts/all-contacts?tab=1" },
      { id: 22, name: "Tags", path: "/contacts/all-contacts?tab=2" },
      { id: 23, name: "Segments", path: "/contacts/all-contacts?tab=3" },
      { id: 24, name: "Custom fields", path: "/contacts/all-contacts?tab=4" },
    ],
  },

  {
    id: 3,
    name: "Research",
    icons: SurveyIcon,
    path: "/research",
    children: [
      { id: 31, name: "Online surveys", path: "/research/online-survey" },
      { id: 32, name: "Field interviews", path: "/field-surveys" },
      { id: 33, name: "Brand tracking", path: "/branded-survey" },
    ],
  },

  // { id: 8, name: "Customer Insight", icons: ContactIcon, path: "/customer-insight" },
  {
    id: 4,
    name: "Messaging",
    icons: MessagingIcon,
    path: "/broadcasts/email",
    children: [
      { id: 41, name: "Emails", path: "/broadcasts/email" },
      { id: 42, name: "SMS", path: "/broadcasts/sms" },
      { id: 43, name: "Voice", path: "/broadcasts/voice" },
    ],
  },
];

const settingsTabs = [{ id: 5, name: "Settings", icons: SettingsIcon, path: "/settings" }];

const activeStyle = "bg-primary-50 flex items-center justify-center w-[100%]   rounded-[6px] cursor-pointer h-[40px]";
const nonActiveStyle = "h-[40px] w-[100%] flex items-center justify-center";

const sidebarVariants = {
  open: {
    width: "250px",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
  closed: {
    width: "80px",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
};

const Sidebar = ({ openSidebar, handleSidebarOpen }) => {
  const [noticed, setNoticed] = useState(false);
  const [clickNoticed, setClickedNotice] = useState(false);
  const { data } = useQuery(["defaultWorkspace"], fetchWorkspace);
  const location = useLocation();
  const handleHover = (id) => {
    setNoticed(noticed === id ? null : id);
  };
  const handleClicked = (id) => {
    setClickedNotice(clickNoticed === id ? null : id);
    console.log(id, "thay");
  };

  return (
    <motion.div
      className={`h-[100vh] relative p-3 pt-[1em] border-r-[2px]`}
      initial={false}
      animate={openSidebar ? "open" : "closed"}
      variants={sidebarVariants}
    >
      <div
        className="w-[25px] h-[25px] absolute -right-3 top-[1em] rounded bg-white flex items-center justify-center border-[1px] border-primary-200"
        onClick={handleSidebarOpen}
      >
        <FaAnglesRight className="text-gray-500   text-base " />
      </div>

      {!openSidebar ? (
        <>
          <Link to="/">
            <img src={smallLogo} alt="logo" width={40} className="mx-auto" />
          </Link>
          <div className="border-[1px]  rounded-md my-[1em]">
            <div
              className={`flex items-center justify-center py-2 h-[50px] cursor-pointer ${
                clickNoticed === "workspace" ? "border-primary-800 rounded-t-md border-[1px] bg-primary-50" : ""
              } `}
              onClick={() => handleClicked("workspace")}
            >
              {data?.wsProfile === null || data?.wsProfile === undefined ? (
                <p className="bg-black rounded-full min-w-[25px] h-[25px] text-white flex items-center justify-center text-ss font-semibold">
                  {data?.name?.slice(0, 2).toUpperCase()}
                </p>
              ) : !data?.wsProfile?.includes("https://") ? (
                <div className="h-[34px] w-[34px] overflow-hidden rounded-full shadow-md border-[1px]">
                  <img src={`https://${data?.wsProfile}`} alt="" className="w-[100%] h-[100%] object-cover " />
                </div>
              ) : (
                <p className="bg-black rounded-full min-w-[25px] h-[25px] text-white flex items-center justify-center text-ss font-[500]">
                  {data?.name?.slice(0, 2).toUpperCase()}
                </p>
              )}
            </div>

            <div className="border-t-[1px]"></div>

            <Link to="/settings?g=invite" className="flex items-center justify-center py-2 h-[50px]">
              <ContactIcon fill1="#D4D4D4" fill2="#404040" />
            </Link>
          </div>

          {clickNoticed === "workspace" ? (
            <div className="w-[200px] absolute left-[5em] top-[3.5em] rounded bg-white flex flex-col items-center justify-center shadow-xl z-[999] border-[1px]">
              <div className="py-4 space-y-2">
                <div className="flex items-center justify-center">
                  {data?.wsProfile === null || data?.wsProfile === undefined ? (
                    <p className="bg-black rounded-full min-w-[40px] h-[40px] text-white flex items-center justify-center text-ss font-semibold">
                      {data?.name?.slice(0, 2).toUpperCase()}
                    </p>
                  ) : !data?.wsProfile?.includes("https://") ? (
                    <div className="h-[40px] w-[40px] overflow-hidden rounded-full shadow-md border-[1px]">
                      <img src={`https://${data?.wsProfile}`} alt="" className="w-[100%] h-[100%] object-cover " />
                    </div>
                  ) : (
                    <p className="bg-black rounded-full min-w-[40px] h-[40px] text-white flex items-center justify-center text-ss font-[500]">
                      {data?.name?.slice(0, 2).toUpperCase()}
                    </p>
                  )}
                </div>

                <p className="font-semibold text-sm"> {data?.name || <Skeleton />} </p>
                <p className="text-xs text-center">
                  {data?.adminCount} Member{data?.adminCount > 1 ? "s" : ""}
                </p>
              </div>

              <hr className="border-t-[1px] w-[100%]" />
              <Link className="p-3" to="/organization">
                <BlueButton text={<p className="text-xs font-semibold">Switch Workspace</p>} />
              </Link>
            </div>
          ) : null}

          <div className="space-y-2">
            {tabs.map((el) => {
              const IconComponent = el.icons;

              return (
                <div className="relative">
                  {el.children ? (
                    <NavLink
                      key={`${el.id}--appRoute`}
                      className={`hover:text-secondary w-[100%] flex items-center justify-center ${
                        location.pathname.includes(el.path) ? "bg-primary-100 rounded-md" : ""
                      } `}
                      onClick={el.children ? () => handleClicked(el.id) : null}
                      onMouseEnter={() => handleHover(el.id)}
                      onMouseLeave={() => handleHover(el.id)}
                    >
                      {({ isActive }) => (
                        <div className="h-[40px] flex items-center justify-center">
                          {location.pathname.includes(el.path) ? (
                            <IconComponent fill1="#95ADFF" fill2="#1D19AF" />
                          ) : (
                            <IconComponent fill1={"#D4D4D4"} fill2={"#404040"} />
                          )}
                        </div>
                      )}
                    </NavLink>
                  ) : (
                    <NavLink
                      to={el.path}
                      key={`${el.id}--appRoute`}
                      className="hover:text-secondary w-[100%] flex items-center justify-center "
                      onMouseEnter={() => handleHover(el.id)}
                      onMouseLeave={() => handleHover(el.id)}
                      onClick={el.children ? () => handleClicked(el.id) : null}
                    >
                      {({ isActive }) => (
                        <div className={isActive ? activeStyle : nonActiveStyle}>
                          {noticed === el.id ? (
                            <IconComponent fill1="#95ADFF" fill2="#1D19AF" />
                          ) : (
                            <IconComponent
                              fill1={isActive ? "#95ADFF" : "#D4D4D4"}
                              fill2={isActive ? "#1D19AF" : "#404040"}
                            />
                          )}
                        </div>
                      )}
                    </NavLink>
                  )}

                  {noticed === el.id ? (
                    <div className="w-[140px] rounded-lg border-[1px] border-primary-200 bg-white text-primary-800 absolute h-[40px] -right-[11.5em] top-0 text-sm pl-4 flex items-center z-[99999]">
                      {el.name}
                    </div>
                  ) : null}
                  {clickNoticed === el.id ? (
                    <div
                      className="w-[140px] absolute border-[1px]  border-primary-100 bg-white rounded-lg top-0 -right-[9.5em] flex items-center flex-col overflow-hidden z-[99999]"
                      // onMouseEnter={() => handleHover(el.id)}
                      // onMouseLeave={() => handleHover(el.id)}
                    >
                      {el.children.map((el) => {
                        return (
                          <Link
                            to={el.path}
                            key={el.id}
                            className="h-[40px] border-b-[1px] w-[100%] flex items-center pl-4 text-xs hover:bg-primary-100 text-gray-800 hover:text-primary-800 "
                            onClick={() => handleHover(el.id)}
                          >
                            {el.name}
                          </Link>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}

            <div className="border-t-[1px]"></div>

            {settingsTabs.map((el) => {
              const IconComponent = el.icons;

              return (
                <NavLink
                  to={el.path}
                  key={`${el.id}--appRoute`}
                  className="hover:text-secondary w-[100%] flex items-center justify-center relative"
                  onMouseEnter={() => handleHover(el.id)}
                  onMouseLeave={() => handleHover(el.id)}
                  onClick={() => handleClicked("")}
                >
                  {({ isActive }) => (
                    <div className={isActive ? activeStyle : nonActiveStyle}>
                      {noticed === el.id ? (
                        <IconComponent fill1="#95ADFF" fill2="#1D19AF" />
                      ) : (
                        <IconComponent
                          fill1={isActive ? "#95ADFF" : "#D4D4D4"}
                          fill2={isActive ? "#1D19AF" : "#404040"}
                        />
                      )}

                      {noticed === el.id ? (
                        <div className="w-[140px] rounded-lg border-[1px] border-primary-200 bg-white text-primary-800 absolute h-[40px] -right-[11.5em] top-0 text-sm pl-4 flex items-center z-[99999]">
                          {el.name}
                        </div>
                      ) : null}
                    </div>
                  )}
                </NavLink>
              );
            })}
          </div>
        </>
      ) : (
        <OpenSidebar />
      )}
    </motion.div>
  );
};

export default Sidebar;
