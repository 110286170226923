import Toast from "config/toast";
import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import Api from "utils/axios";
import { handleApiError } from "utils/handleApiError";
import Typography from "utils/typography";

const FileUpload = ({ name, setData, data }) => {
  const [uploading, setUploading] = useState(false);
  const [percent, setPercent] = useState(0);

  console.log(name, data);

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      Toast.error("Please select only one image");
    } else {
      const imageFile = new FormData();
      imageFile.append("file", e.target.files[0]);
      setUploading(true);

      let progress = 0;

      try {
        const { data: newData } = await Api.post(`/workspace-microservice/media/public-upload`, imageFile, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total !== undefined) {
              progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setPercent(progress);
            } else {
              setUploading(false);
            }
          },
        });

        // setImage(data?.data.file);

        setData({
          ...data,
          [name]: newData?.data.file,
        });
      } catch (error) {
        throw Toast.error(handleApiError(error));
      }
    }
  };

  const clearInput = () => {
    // setImage("");
    setData({
      ...data,
      [name]: "",
    });
    setUploading(false);
  };

  return (
    <div>
      {data[name] !== "" ? (
        <>
          <div className="h-[140px] overflow-hidden rounded-lg flex items-center justify-center my-3">
            <img src={data[name]} alt={data[name]} className="w-[100%] h-[100%] object-center" />
          </div>

          <div
            className="border-[1px] border-gray-400 w-[160px] h-[40px] flex items-center justify-center space-x-2 rounded text-gray-600 cursor-pointer hover:bg-slate-100"
            onClick={clearInput}
          >
            <MdDelete className="text-grey-300 text-xl cursor-pointer" />

            <Typography.SubText>Remove cover</Typography.SubText>
          </div>
        </>
      ) : null}

      {uploading ? (
        <>
          <div className="flex space-x-2 items-center">
            <div className="bg-grey-100 h-[5px] rounded-lg w-[100%] overflow-hidden">
              <div className="bg-green-500 h-[100%]" style={{ width: `${percent}%` }}></div>
            </div>

            <p className="text-xs text-grey-200">{percent}%</p>

            {data[name] !== "" ? (
              <>
                <MdDelete className="text-grey-300 text-xl cursor-pointer" onClick={clearInput} />
              </>
            ) : null}
          </div>
        </>
      ) : (
        <>
          {data[name] === "" && !uploading ? (
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[95px] border border-dashed bg-gray-50  border-gray-200 rounded-lg cursor-pointer"
              >
                <div className="flex flex-col items-center justify-center">
                  <svg
                    className="mb-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                  >
                    <path
                      d="M11.4 12.8H14.6V6.40002H19.4L13 0L6.59997 6.40002H11.4V12.8ZM16.2 9.20003V11.6672L23.5264 14.4L13 18.3249L2.47356 14.4L9.79998 11.6672V9.20003L0.199951 12.8V19.2001L13 24.0001L25.8 19.2001V12.8L16.2 9.20003Z"
                      fill="#78797F"
                    />
                  </svg>
                  <p className="text-xs text-gray-500 text-center">
                    Drag and drop PNG or JPEG file or{" "}
                    <span className="text-primary-800 underline">browse computer</span> <br />
                    (Max. size 1536px by 672px)
                  </p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={onSelectFile} />
              </label>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default FileUpload;
