import dayjs from "dayjs";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { calenderSurveyStatus } from "../../../lib/survStatus";
import DetailsComponents from "./DetailsComponents";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typography from "utils/typography";
import { IoCloseCircle } from "react-icons/io5";

const AllSurveyData = ({ el, date, setSelectedDate }) => {
  const navigate = useNavigate();

  const openModal = (elm) => {
    navigate(`/calendar?id=${elm._id}`);
    setSelectedDate("");
  };
  return (
    <div className="bg-black bg-opacity-50 backdrop-blur-[2px] top-0 bottom-0 left-0 h-[100vh] w-full z-[99999] fixed flex items-center justify-center  ">
      <div className=" bg-white border-[1px] w-[190px] overflow-hidden  rounded-lg overscroll-contain">
        <div className="bg-gray-100 h-[60px] p-2 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Typography.SubText>{dayjs(date).format("ddd DD")}</Typography.SubText>

            <p className="bg-primary-800 rounded-full w-[25px] h-[25px] text-white flex items-center justify-center text-sm">
              {el.length}
            </p>
          </div>

          <IoCloseCircle className="text-gray-500 text-2xl cursor-pointer" onClick={() => setSelectedDate("")} />
        </div>
        <div className="p-4 h-[250px] overflow-y-scroll">
          {el.map((elm) => (
            <div key={elm._id} onClick={() => openModal(elm)}>
              {calenderSurveyStatus(elm)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CalenderMonth = ({ currentDate, holidayData, surveyData }) => {
  const [survData, setSurvData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [params] = useSearchParams();
  const navigate = useNavigate();

  console.log(selectedDate, "selectedDate");

  // const handleOpen = (id) => {
  //   setOpen(open === id ? null : id);
  // };

  const getMonthDates = (date) => {
    const startOfMonth = date.startOf("month");
    const endOfMonth = date.endOf("month");

    const dates = [];
    let day = startOfMonth;

    // Add days from the previous month to fill the first row
    const firstDayOfWeek = startOfMonth.day();
    for (let i = 0; i < firstDayOfWeek; i++) {
      dates.push(startOfMonth.subtract(firstDayOfWeek - i, "day"));
    }

    // Add all days of the current month
    while (day.isBefore(endOfMonth) || day.isSame(endOfMonth, "day")) {
      dates.push(day);
      day = day.add(1, "day");
    }

    // Add days from the next month to fill the last row
    const remainingDays = 35 - dates.length;
    for (let i = 1; i <= remainingDays; i++) {
      dates.push(endOfMonth.add(i, "day"));
    }

    return dates;
  };

  const isSameDay = (date1, date2) => {
    return date1.isSame(date2, "day");
  };

  const monthDates = getMonthDates(currentDate);

  const getWeeksInMonth = (dates) => {
    const weeks = [];
    let week = [];

    dates.forEach((date) => {
      week.push(date);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    });

    return weeks;
  };

  const weeksInMonth = getWeeksInMonth(monthDates);

  const getAllNewSurvey = (day) => {
    const evt1 = surveyData?.filter(
      (elm) => dayjs(elm.publishDate || elm.createdAt).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"),
    );

    return evt1;
  };

  return (
    <div className="flex flex-col items-center w-[100%] ">
      {params.get("id") ? <DetailsComponents data={survData} setSurvData={setSurvData} /> : null}

      <div className="grid grid-cols-7 w-full border">
        {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day) => (
          <div key={day} className="text-center text-sm h-[40px] font-bold flex items-center justify-center">
            {day}
          </div>
        ))}

        {weeksInMonth.map((week, weekIndex) => (
          <React.Fragment key={weekIndex}>
            {week.map((date, dateIndex) => (
              <div key={dateIndex} className={`border h-[110px] relative p-2 ${isSameDay(date, dayjs()) ? "" : ""}`}>
                <p
                  className={`${
                    isSameDay(date, dayjs())
                      ? "w-[30px] h-[30px] rounded-full bg-primary-800 text-white flex items-center justify-center mx-auto"
                      : ""
                  } text-sm text-center`}
                >
                  {date.date()}
                </p>

                {holidayData?.map((el) => {
                  return dayjs(el.date).format("MM-DD") === dayjs(date).format("MM-DD") ? (
                    <p
                      className={`text-[10px] p-[2px] px-2 bg-primary-100 rounded-[4px] cursor-pointer flex items-center  mt-2 `}
                    >
                      <BsDot className="mr-[2px] text-lg" /> {el.name}
                    </p>
                  ) : null;
                })}

                {getAllNewSurvey(date)
                  ?.slice(0, 1)
                  ?.map((el) => (
                    <>
                      <div onClick={() => navigate(`/calendar?id=${el._id}`)}>{calenderSurveyStatus(el)}</div>

                      {getAllNewSurvey(date).length > 1 ? (
                        <p
                          className={`text-[10px] p-[2px] px-2 bg-gray-100 rounded-[4px] cursor-pointer flex items-center  mt-2 `}
                          onClick={() => setSelectedDate(dayjs(date).format("DD/MM/YYYY"))}
                        >
                          {" "}
                          +{getAllNewSurvey(date).length - 1} more
                        </p>
                      ) : (
                        ""
                      )}

                      {selectedDate === dayjs(date).format("DD/MM/YYYY") ? (
                        <AllSurveyData el={getAllNewSurvey(date)} date={date} setSelectedDate={setSelectedDate} />
                      ) : null}
                    </>
                  ))}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CalenderMonth;
