import React, { useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Manual } from "assets/images/contacts/Manual";
import { Flutterwave } from "assets/images/contacts/Flutterwave";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import FlutterwaveSegmentModal from "./FlutterwaveSegmentModal";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const CreateSegment = () => {
  const [index, setIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [hover, setHover] = useState(false);
  const { sidebar } = useSideBarStore();

  const handleIndex = (id) => {
    setIndex(id === index ? null : id);
  };
  const handleHover = (id) => {
    setHover(id === index ? null : id);
  };

  const data = [
    {
      title: "Create a manual segment",
      text: "Manually create a segment group from your contact list.",
      img: Manual,
      fill1: "#95ADFF",
      fill2: "#6B7FFF",
    },
    {
      title: "Connect to Flutterwave",
      text: "Integrate and segment your customers from your store.",
      img: Flutterwave,
      fill1: "#FFCA7D",
      fill2: "#FF9B00",
    },
  ];

  //   navigate("/contacts/segment/create/flutterwave")

  const handleSubmit = () => {
    index === 0 ? navigate("/contacts/segment/create/manual") : setOpenModal(true);
  };

  const navigate = useNavigate();
  return (
    <div className="bg-white p-5 rounded-md h-[calc(100vh-100px)]">
      <div
        onClick={() => navigate(-1)}
        className="flex items-center space-x-1 my-2 text-primary-800 pl-2 cursor-pointer"
      >
        <FiChevronLeft />
        <p>Go back</p>
      </div>
      <div className="flex flex-col h-full">
        <div className="text-center font-[500] text-[24px] mt-[2rem]">Create your segment in two ways</div>
        <div className="flex justify-center mt-[2rem]">
          <div className="flex items-center space-x-[5rem]">
            {data.map((el, i) => {
              const Icons = el.img;
              return (
                <div
                  key={i}
                  className={`rounded-lg drop-shadow-md border overflow-hidden cursor-pointer ${
                    index === i || hover === i ? "border-primary-800" : ""
                  } w-[268px] relative`}
                  onClick={() => handleIndex(i)}
                  onMouseEnter={() => handleHover(i)}
                  onMouseLeave={() => handleHover(false)}
                >
                  <div className="absolute top-5 right-4">
                    <div
                      className={`rounded-full p-1 flex items-center justify-center border w-[20px] h-[20px] ${
                        index === i ? "border-blue-800" : ""
                      } `}
                    >
                      {index === i ? <div className={`rounded-full border p-1 bg-blue-800`}></div> : <div></div>}
                    </div>
                  </div>

                  <div>
                    <Icons fill1={index === i || hover === i ? el.fill2 : el.fill1} />
                  </div>
                  <div className="p-5 text-center">
                    <p className={`font-[500] text-[16px] ${index === i ? "text-primary-800" : ""}`}>{el.title}</p>
                    <p className="text-[16px] text-gray-500">{el.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-[2rem]">
          <p className="font-[500] text-[16px] text-center">Need help getting started?</p>
          <div className="flex divide-x space-x-5 items-center text-[12px] justify-center text-primary-800">
            <div>
              <Link to="https://usemooyi.com/faq" target="_blank" rel="noopener noreferrer">
                Visit our FAQ
              </Link>
            </div>
            <div className="pl-5">
              <Link
                to="https://www.youtube.com/channel/UC-KbRjjhExwbHkOurEtdNFQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                Watch tutorial video
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <div>
          {" "}
          <GreyButton text="Cancel" onClick={() => navigate(-1)} />
        </div>

        <div className="flex justify-between space-x-4">
          <BlueButton text={`Next`} disabled={index === null} onClick={handleSubmit} />
        </div>
      </div>
      {openModal ? <FlutterwaveSegmentModal onClose={() => setOpenModal(!openModal)} /> : null}
    </div>
  );
};

export default CreateSegment;
