import React from "react";

export const BrandTracking = ({ fill1, fill2 }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 4.25015C8.25 3.83593 7.91317 3.49583 7.5015 3.54176C6.43131 3.66117 5.40131 4.03543 4.4999 4.63772C3.38987 5.37942 2.52471 6.43363 2.01382 7.66703C1.50292 8.90045 1.36925 10.2577 1.6297 11.567C1.89015 12.8764 2.53303 14.0791 3.47703 15.0231C4.42104 15.9671 5.62377 16.61 6.93314 16.8705C8.2425 17.1309 9.5997 16.9972 10.8331 16.4863C12.0665 15.9754 13.1207 15.1103 13.8624 14.0002C14.4647 13.0988 14.839 12.0688 14.9584 10.9986C15.0043 10.587 14.6642 10.2502 14.25 10.2502H11.2559C10.8417 10.2502 10.5175 10.5949 10.3824 10.9864C10.3197 11.1681 10.2337 11.3419 10.1257 11.5035C9.87787 11.8744 9.52552 12.1636 9.11332 12.3343C8.70112 12.5051 8.24752 12.5497 7.8099 12.4627C7.37229 12.3757 6.97032 12.1609 6.65482 11.8453C6.33933 11.5298 6.12448 11.1279 6.03743 10.6903C5.95039 10.2526 5.99506 9.79903 6.1658 9.38683C6.33655 8.97463 6.62569 8.62228 6.99668 8.3744C7.15819 8.26648 7.33206 8.18045 7.51372 8.11775C7.9053 7.98257 8.25 7.65844 8.25 7.24422V4.25015Z"
      fill={fill1}
    />
    <path
      d="M15.75 8.75015C16.1642 8.75015 16.5043 8.41333 16.4584 8.00165C16.3882 7.37221 16.2295 6.75449 15.9862 6.16703C15.6469 5.34808 15.1498 4.60397 14.523 3.97717C13.8962 3.35038 13.1521 2.85317 12.3332 2.51396C11.7457 2.27062 11.1279 2.11199 10.4984 2.04176C10.0868 1.99583 9.75 2.33593 9.75 2.75014V5.74422C9.75 6.15844 10.0947 6.48257 10.4863 6.61775C10.529 6.63251 10.5714 6.64859 10.6133 6.66594C10.887 6.77931 11.1357 6.94548 11.3452 7.15496C11.5546 7.36445 11.7209 7.61314 11.8342 7.88684C11.8516 7.92875 11.8676 7.97111 11.8824 8.01388C12.0175 8.40538 12.3417 8.75015 12.7559 8.75015H15.75Z"
      fill={fill2}
    />
  </svg>
);
