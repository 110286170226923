export const voiceTemplates = [
  {
    id: 0,
    campaign_name: "",
    content: "",
  },
  {
    id: 1,
    content:
      "Hello [Partner Name],  I have an exciting opportunity to share with you — it’s the MPower Financing partnership program. You can earn commissions referring  US and Canada bound postgraduate students loans of up to $100,000. This is a massive opportunity for you and your clients. Let's work together to empower dreams.",
    campaign_name: "Calling on Partners",
    category: "Promotional",
  },

  {
    id: 2,
    content:
      "Hello, can you hear me? Hey [Student's Name], how's it going?  Congratulations on getting accepted to study abroad. I’m sending a link to schedule a call to walk you through how to get a loan of up to $100,000 towards your MSc abroad. No collateral or payments are required to get these loans. Please check your spam if you can’t find it in your inbox. We're here to support you every step of the way. Good luck! - [Your Name]",
    campaign_name: "Calling on students",
    category: "Educational",
  },

  {
    id: 3,
    content:
      "Hello, can you hear me? Hey [Student's Name], I'm just reminding you. Now is a great time to submit your loan application for your MSc studies abroad. Don't miss out on this incredible opportunity! If you need any assistance, feel free to reach out. Looking forward to your application! Take care! - [Your Name]",
    campaign_name: "Nudging submission",
    category: "Reminder",
  },

  {
    id: 4,
    content:
      "Hello, can you hear me? Hey [Student's Name], how's everything going? I just wanted to share some good news with you! We've received your loan application, and it's currently under review. Rest assured, we'll keep you updated on its status every step of the way. If you have any questions, don't hesitate to reach out. Talk soon! - [Your Name]",
    campaign_name: "Status updates",
    category: "Educational",
  },
];
