import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import { useFormik } from "formik";
import React from "react";
import Flag from "assets/images/flag.svg";
import BlueButton from "components/Button/BlueButton";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "config/gateway";
import { editContact } from "services/contactService";
import Toast from "config/toast";
import * as Yup from "yup";
import dayjs from "dayjs";
import Dropdown from "components/DropDown";

const validationSchema = Yup.object({
  email: Yup.string().email("Enter a valid email address").required("Please fill in this field"),
  firstname: Yup.string().required("Please fill in this field"),
  lastname: Yup.string().required("Please fill in this field"),
  birthday: Yup.date()
    .max(new Date(), "Selected date must be today or earlier")
    .test("age", "You must be at least 18 years old", (value) => {
      const currentDate = new Date();
      const minDate = new Date();
      minDate.setFullYear(currentDate.getFullYear() - 18);
      return new Date(value) <= minDate;
    }),
  phone: Yup.string().required("Please fill in this field"),
});

const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Prefer not to say", value: "prefer not to say" },
];

const EditContactModal = ({ data, contactId, toggleModal }) => {
  const { mutate } = useMutation(editContact, {
    onSuccess: () => {
      Toast.success("Contact Edited!");
      queryClient.invalidateQueries(["singleContact"]);
      toggleModal();
    },

    onError: (error) => {
      Toast.error(error);
      toggleModal();
    },
  });

  const checkPhone = (x) => {
    if (x === null || x === "") {
      return "";
    } else if (x.startsWith("234")) {
      return x.substring(3);
    } else if (x.startsWith("+234")) {
      return x.substring(4);
    }
  };

  const initialValues = {
    firstname: data?.firstname || "",
    lastname: data?.lastname || "",
    // address: data?.address || "",
    birthday: dayjs(data?.birthday).format("YYYY-MM-DD") || "",
    email: data?.email || "",
    phone: `${checkPhone(data?.phone)}` || "",
    gender: "",
  };

  const onSubmit = async (data) => {
    try {
      const userData = {
        ...data,
        phone: `${data.phone === "" ? "" : `+234${data.phone}`}`,
      };
      mutate({ id: contactId, payload: userData });
    } catch (err) {
      Toast.error(err);
    }
  };

  console.log(data, "fresh");

  const { handleChange, values, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  return (
    <Modal title="Edit contact" onClose={toggleModal}>
      <div className="w-[400px] px-5 ">
        <form className="w-[100%] space-y-4 mt-4" onSubmit={handleSubmit}>
          {/* <div className="grid grid-cols-1"> */}
          <SecondaryInput
            text="First name"
            placeholder="Enter First Name"
            onChange={handleChange}
            name="firstname"
            type="text"
            value={values.firstname}
            error={getError("firstname")}
          />

          <SecondaryInput
            text="Last name"
            placeholder="Enter Last Name"
            onChange={handleChange}
            name="lastname"
            type="text"
            value={values.lastname}
            error={getError("lastname")}
          />
          {/* </div> */}

          {/* <div className="grid grid-cols-1"> */}
          <div className="mt-1">
            <div className="flex space-x-2 items-center ">
              <p className="text-m font-[500]">Gender</p>
            </div>
            <div className="mt-2">
              <Dropdown
                tag="Select your gender"
                options={gender}
                onSelect={(el) => setFieldValue("gender", el.value)}
              />
            </div>
          </div>
          <SecondaryInput
            text="Email address"
            placeholder="Enter Email address"
            onChange={handleChange}
            name="email"
            type="text"
            value={values.email}
            error={getError("email")}
          />

          {/* <SecondaryInput
            text="Address"
            placeholder="Enter your address"
            onChange={handleChange}
            name="address"
            type="text"
            value={values.address}
            error={getError("address")}
          /> */}

          <SecondaryInput
            text="Birthday"
            placeholder="Enter Birthday"
            onChange={handleChange}
            name="birthday"
            type="date"
            value={values.birthday}
            error={getError("birthday")}
          />

          <div className="text-primary flex flex-col w-[100%]">
            <label htmlFor="phone" className="text-[#292D32] text-m font-[500]">
              Phone Number
            </label>

            <div className="border-[#D0D5DD] border-[1px] flex items-center rounded-[8px] my-2 ">
              <div className="flex items-center px-3">
                <img src={Flag} alt="Flag" />
                <p className="ml-2">+234</p>
              </div>
              <input
                type="tel"
                placeholder="Enter mobile number"
                onChange={handleChange}
                value={values.phone}
                name="phone"
                className=" border-l-[1px]  border-[#D0D5DD] h-[48px] pl-2 outline-none w-[70%]  bg-[#FAFAFA] text-m"
                maxLength="10"
                error={getError("phone")}
                // disabled={disabled}
              />
            </div>
          </div>

          <BlueButton text="Save" css="ml-2" />
        </form>
      </div>
    </Modal>
  );
};

export default EditContactModal;
