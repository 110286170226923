import React, { useState } from "react";
import CopyAction from "assets/svgs/actionsSvgs/CopyAction";
import DownloadAction from "assets/svgs/actionsSvgs/DownloadAction";
import PowerOffAction from "assets/svgs/actionsSvgs/PowerOffAction";
import PlayAction from "assets/svgs/actionsSvgs/PlayAction";
import ShareAction from "assets/svgs/actionsSvgs/ShareAction";
import PieCharts from "../components/Charts/PieChart";
import ChartCard from "../components/ChartCard";
import ProfilingStackedBarChart from "../components/Charts/ProfilingStackedBarChart";
import CustomBarChart from "../components/Charts/CustomBarChart";

const Overview = () => {
  const [active, setActive] = useState(null);

  const handleHover = (id) => {
    setActive(active === id ? null : id);
  };

  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
  ];

  const customData = [
    { name: "Page A", value: 2400 },
    { name: "Page B", value: 1398 },
    { name: "Page C", value: 9800 },
    { name: "Page D", value: 3908 },
  ];

  const stackeddata = [
    {
      name: "Cars",
      Toyota: 4000,
      Honda: 2400,
      Civic: 2400,
      BMW: 2400,
    },
  ];

  const actionButtons = [
    {
      id: 1,
      name: "Copy link",
      action: (
        <CopyAction
          fill1={active === 1 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 1 ? "#95ADFF" : "#404040"}
          fill3={active === 1 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 2,
      name: "Export",
      action: (
        <DownloadAction
          fill1={active === 2 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 2 ? "#95ADFF" : "#404040"}
          fill3={active === 2 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 3,
      name: "Close survey",
      action: (
        <PowerOffAction
          fill1={active === 3 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 3 ? "#95ADFF" : "#404040"}
          fill3={active === 3 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 4,
      name: "Pause survey",
      action: (
        <PlayAction
          fill1={active === 4 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 4 ? "#95ADFF" : "#404040"}
          fill3={active === 4 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 5,
      name: "Share",
      action: (
        <ShareAction
          fill1={active === 5 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 5 ? "#95ADFF" : "#404040"}
          fill3={active === 5 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="flex w-full items-center justify-between">
        <div>
          <p>Filter:</p>
        </div>
        <div className="flex space-x-2 items-center">
          <div>Actions:</div>
          <div className="flex items-center space-x-2 relative">
            {actionButtons.map((el, i) => (
              <div
                key={i}
                className="cursor-pointer"
                onMouseEnter={() => handleHover(el.id)}
                onMouseLeave={() => setActive(null)}
              >
                {el.action}
                {active === el.id ? (
                  <div className="absolute top-10">
                    <p className="px-3 py-1 w-fit rounded-md bg-white text-[10px]">{el.name}</p>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <ChartCard title={"Pie chart"} subtitle="Info on the pie chart">
              <PieCharts data={data} />
            </ChartCard>
          </div>
          <div>
            <ChartCard title={"Pie chart"} subtitle="Info on the pie chart">
              <ProfilingStackedBarChart data={stackeddata} />
            </ChartCard>
          </div>
          <div>
            <ChartCard title={"Pie chart"} subtitle="Info on the pie chart">
              <CustomBarChart data={customData} />
            </ChartCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
