import { useState } from "react";
import { RiArrowRightUpLine } from "react-icons/ri";
import TopUpModal from "./TopUpModal";
// import { useStore } from "store";
// import Tooltip from "components/Tooltip";
// import Dropdown from "components/DropDown";
// import PrimaryInput from "components/Inputs/PrimaryInput";
import Email from "./Email";
import Sms from "./Sms";
import Voice from "./Voice";
import ProgressBar from "components/ProgressBarNew";
import useCredit from "hooks/useCredit";
import useSubscription from "hooks/usesubscription";
import { formatDateAlone, getDaysLeft } from "lib/formatDate";
import { toTitleCase } from "lib/titleCase";

const tabs = [
  { name: "Email", value: 0 },
  { name: "SMS", value: 1 },
  { name: "Voice message", value: 2 },
];

const Campaign = ({ wsUsage }) => {
  const { wsCredit } = useCredit();
  const { subscription } = useSubscription();
  const [open, setOpen] = useState(false);

  const plan = subscription?.plan;

  console.log(subscription?.plan);

  const [step, setStep] = useState(0);

  const displayStep = () => {
    switch (step) {
      case 0:
        return <Email />;
      case 1:
        return <Sms />;
      case 2:
        return <Voice />;
      default:
    }
  };

  const balances = [
    { name: "Online surveys responses", values: wsCredit?.bundle?.survey?.online, total: 1000, percent: 10 },
    { name: "Field interview responses", values: wsCredit?.bundle?.survey?.field?.media, total: 20000, percent: 2 },
    { name: "Emails", values: wsCredit?.bundle?.broadcast?.email, total: 1000, percent: 50 },
    { name: "SMS", values: wsCredit?.bundle?.broadcast?.sms, total: 1000, percent: 20 },
    { name: "Robocalls", values: wsCredit?.bundle?.broadcast?.voice, total: 100000, percent: 70 },
    { name: "Rewards", values: wsCredit?.bundle?.rewards, total: 1000, percent: 5 },
  ];

  const handleTabChange = (e) => {
    setStep(e);
    // setSearchBarParams("");
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  // const totalUsage = wsUsage?.email + wsUsage?.sms + wsUsage?.robocall;

  // const calculateUsage = (x) => {
  //   return `w-[${Math.floor((x / totalUsage) * 100)}%]`;
  // };

  return (
    <div>
      <div className="flex gap-[1em] my-1">
        <div className="h-[190px] bg-primary-50 rounded-[8px] w-[50%]">
          <div className="bg-creditBg bg-cover bg-center bg-no-repeat h-[100%] w-[100%]  p-4 px-8 flex justify-between">
            <div className="space-y-2">
              {plan !== undefined ? (
                plan?.map((el, i) => (
                  <div key={i}>
                    <p className="text-xl font-semibold text-primary-800">
                      {el.category === "researchHub" ? "Research hub" : "Engagement hub"}
                    </p>
                    <p className="text-[12px]">{toTitleCase(el.plan)}-plan</p>
                  </div>
                ))
              ) : (
                <p className="text-xl font-semibold text-primary-800">SME plan</p>
              )}
            </div>

            <div className="flex flex-col justify-between">
              <div>
                <div className="flex items-center text-sm text-neutral-600 space-x-2">
                  <p>Next subscription date</p>:
                  <p className="text-primary-800 font-semibold text-lg">
                    {plan !== undefined ? formatDateAlone(subscription?.ends_on) : "24th March 2023"}
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <p></p>

                  <p className="text-sm mt-2 text-neutral-600">
                    {plan !== undefined ? getDaysLeft(subscription?.ends_on) : "13"} days left
                  </p>
                </div>
              </div>

              <div className="flex space-x-4 mt-[2em] justify-between">
                <div></div>
                <div
                  onClick={() => handleModal(1)}
                  className="flex justify-center items-center bg-primary-800  text-white hover:bg-primary-600  w-[160px] rounded-[6px] h-[40px] px-4 space-x-1 cursor-pointer"
                >
                  <p className="text-s font-semibold ">Top Up Credit</p>
                  <RiArrowRightUpLine />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 w-[50%] h-[190px] rounded-[12px] border-[1px] bg-white">
          <p>Balances</p>
          <div className="grid grid-cols-2 gap-4 mt-3">
            {balances.map((el, i) => (
              <div className="" key={i}>
                <div className="flex items-center justify-between">
                  <p className="text-xs font-light">{el.name}</p>
                  <p className="text-sm font-semibold">{el.values.toLocaleString()}</p>
                </div>
                <div>
                  <hr />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div> */}

      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[4px] px-2 cursor-pointer ${
                step === el.value ? "border-b-[2px] text-[#0F172A] border-purple font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.value !== 4 ? () => handleTabChange(el.value) : null}
              key={el.value.toLocaleString()}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>

      {open === 1 ? <TopUpModal handleModal={handleModal} /> : null}
    </div>
  );
};

export default Campaign;
