import { create } from "zustand";

const useSurveyStore = create((set) => ({
  screenConfig: {
    welcome: {
      pryText: "Please spare a few moments to share your experience.",
      buttonText: "welcome bro",
      description: "Let’s make something amazing together",
      image: "",
    },
    thankYou: {
      pryText: "Thank you screen",
      buttonText: "welcome bro",
      description: "Let’s make something amazing together",
      image: "",
    },
  },

  insightCategory: "automotive_and_accessories",
  setInsightCategory: (data) =>
    set(() => ({
      insightCategory: data,
    })),

  setScreenConfig: (nameKey, childKey, value) =>
    set((state) => {
      const newObject = { ...state.screenConfig };
      newObject[nameKey][childKey] = value;
      return { screenConfig: newObject };
    }),

  // setSurveyQuestion: (index, name, value) =>
  //   set((state) => {
  //     const updatedArray = [...state.surveyQuestionsArray];
  //     updatedArray[index] = {
  //       ...updatedArray[index],
  //       [name]: value,
  //     };
  //     return { surveyQuestionsArray: updatedArray };
  //   }),

  surveyQuestionsArray: [],

  setSurveyQuestionsArray: (questions) => set({ surveyQuestionsArray: questions }),

  setSurveyQuestion: (index, name, value) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      updatedArray[index] = {
        ...updatedArray[index],
        [name]: value,
      };
      return { surveyQuestionsArray: updatedArray };
    }),

  // Add an option to a specific question
  addOptionToQuestion: (index) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      const options = updatedArray[index]?.options || [];
      updatedArray[index] = {
        ...updatedArray[index],
        options: [...options, ""],
      };
      return { surveyQuestionsArray: updatedArray };
    }),

  // Handle changes to option values
  handleOptionValues: (index, newIndex, value) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      const updatedQuestion = { ...updatedArray[index] };

      updatedQuestion.options[newIndex] = value;
      updatedArray[index] = updatedQuestion;

      return { surveyQuestionsArray: updatedArray };
    }),

  // Delete a specific option
  deleteValueOptions: (index, optionIndex) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      updatedArray[index] = {
        ...updatedArray[index],
        options: updatedArray[index]?.options?.filter((_, i) => i !== optionIndex),
      };
      return { surveyQuestionsArray: updatedArray };
    }),

  // Handle Likert type options (min and max values)
  handleLikertType: (index, parent, name, value) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      const valueType = parent === "likertMax" ? "max" : "min";
      updatedArray[index] = {
        ...updatedArray[index],
        options: {
          ...updatedArray[index].options,
          [valueType]: {
            ...updatedArray[index].options[valueType],
            [name]: name === "value" ? +value : value,
          },
        },
      };
      return { surveyQuestionsArray: updatedArray };
    }),

  handleRatingTextType: (name, value, index) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      updatedArray[index] = {
        ...updatedArray[index],
        options: {
          ...updatedArray[index].options,
          label: {
            ...updatedArray[index].options.label,
            [name]: value,
          },
        },
      };
      return { surveyQuestionsArray: updatedArray };
    }),

  handleRatingSelectType: (name, value, index) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      updatedArray[index] = {
        ...updatedArray[index],
        options: {
          ...updatedArray[index].options,
          [name]: name === "shape" ? value : +value,
        },
      };
      return { surveyQuestionsArray: updatedArray };
    }),

  removeSurveyQuestion: (index) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      updatedArray.splice(index, 1);
      return { surveyQuestionsArray: updatedArray };
    }),
}));

export default useSurveyStore;
