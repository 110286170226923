import BlueButton from "components/Button/BlueButton";
import React from "react";
import { Link } from "react-router-dom";

const EmptyState = ({ icon, title, content, link, button, type, handleClick }) => {
  return (
    <div className="flex flex-col items-center space-y-3 w-full">
      <img src={icon} alt="" className="w-[82px] h-[53px]" />
      <p className="text-[16px] font-[500] text-center">{title}</p>
      <p className="w-[50%] text-gray-400 text-[14px] text-center">{content}</p>
      {type === "custom" ? (
        <BlueButton text={button} type="button" onClick={() => (type === "custom" ? handleClick() : "")} />
      ) : button === "" ? null : (
        <Link to={link}>
          <BlueButton text={button} type="button" />
        </Link>
      )}
    </div>
  );
};

export default EmptyState;
