import React from "react";
import Modal from "components/Modal";
import { useStore } from "store";
import BlueButton from "components/Button/BlueButton";
// import Tooltip from "components/Tooltip";
import WordTag from "components/common/WordTag";
import { CONTACT_TYPE } from "data/constants/surveyConstants";
import { GenerateLinkIcon } from "assets/icons/survey/GenerateLinkIcon";
import { UseSavedContactIcon } from "assets/icons/survey/UseSavedContactIcon";
import { TargetResponseIcon } from "assets/icons/survey/TargetResponseIcon";
import GreyButton from "components/Button/GreyButton";
import { trackEvent } from "config/mixpanel";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const campaigns = [
  {
    id: 2,
    name: "Use our audience",
    description: "Send your survey to our available audience.",
    value: CONTACT_TYPE.MOOYI_AUDIENCE,
    img: <TargetResponseIcon />,
    tool: "Send important announcements to our contacts e.g. emergency notices or organisation updates. ",
  },
  {
    id: 0,
    name: "Share link or QR Code",
    description: "Generate a shareable link for your preferred audience.",
    value: CONTACT_TYPE.GENERATE_LINK,
    img: <GenerateLinkIcon />,
    tool: "Send important announcements to your contacts e.g. emergency notices or organisation updates. ",
  },

  {
    id: 1,
    name: "Use your audience",
    description: "Send to your saved contacts.",
    value: CONTACT_TYPE.PERSONAL_CONTACT,
    img: <UseSavedContactIcon />,
    tool: "Send out offers and promote your products and services to gain new customers.",
  },

  // {
  //   id: 3,
  //   name: "Connect with API",
  //   description: "Send surveys to your contacts using API",
  //   value: CONTACT_TYPE.CONNECT_API,
  //   img: ConnectApi,
  //   tool: "Send important announcements to your contacts from your api.",
  // },
];
const AudienceModal = ({ handleModal, setNewStep, from }) => {
  const { wsPlan, user } = useStore();
  const [params, setParams] = useSearchParams();

  const audienceType = params.get("audienceType");

  const updateType = (x) => {
    setParams(`audienceType=${x}`);

    trackEvent("select_audience", {
      email: user?.email,
      date: dayjs().format(),
    });
    // handleModal();
  };

  const handleSave = () => {
    handleModal();
    setNewStep(2);
  };

  return (
    <Modal title="Choose your Audience type" onClose={handleModal}>
      <div className="w-[500px] px-5">
        <div className="space-y-4 mt-[1.5em]">
          {campaigns.map((el) => {
            return (
              <div
                className={`flex space-x-4 rounded-[8px] border-[1px] p-4 h-[80px] cursor-pointer justify-between items-center ${
                  el.value === audienceType ? "bg-[#EAF1FF]" : null
                } ${wsPlan === "starter" && el.id === 2 ? "grayscale border-[0px] bg-[#FAFAFA]" : ""}`}
                key={el.id}
                onClick={wsPlan === "starter" && el.id === 2 ? undefined : () => updateType(el.value)}
              >
                <div className="flex space-x-4 ">
                  <div
                    className={`h-[20px] rounded-full min-w-[20px]  border-[1px] ${
                      el.value === audienceType ? "border-primary-800" : "border-[#D4D4D4]"
                    } flex items-center justify-center`}
                  >
                    <div
                      className={`${el.value === audienceType && "bg-primary-800"} h-[10px] w-[10px] rounded-[50%]`}
                    ></div>
                  </div>
                  <div>
                    <div className="flex items-center space-x-2">
                      <p
                        className={` text-m tracking-[-0.02em] font-semibold font-secondary flex items-center ${
                          wsPlan === "starter" && el.id === 2 ? "text-[#A3A3A3]" : "text-[#0F172A]"
                        }`}
                      >
                        {el.name}
                      </p>

                      {/* <Tooltip text={el.tool} /> */}
                      {el.id === 2 ? <WordTag text="RECOMMENDED" /> : ""}
                    </div>

                    <p className="text-[#737373] text-ss tracking-[-0.01em]  leading-[16px] mt-2 ">{el.description}</p>
                  </div>
                </div>

                <div className="bg-primary-50 border-[1px] rounded-full border-primary-800 h-[50px] w-[50px] flex items-center justify-center">
                  {el.img}
                </div>
              </div>
            );
          })}

          <div className="flex items-center justify-between pt-[2em]">
            <div></div>
            <div className="flex space-x-4">
              <GreyButton text="Cancel" css="bg-[#F5F5F5] text-s font-[500] h-[45px]" onClick={handleModal} />

              <BlueButton
                text="Continue"
                onClick={from === "step2" ? handleModal : handleSave}
                // disabled={data.audience === "" || isLoadingDraft}
                // onClick={data.audience !== "" ? nextStep : undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AudienceModal;
