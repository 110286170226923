import { useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import React, { useEffect, useRef, useState } from "react";
import { BsDot, BsShareFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import Overview from "./Overview";
import Details from "./Details";
import { getCampaignReport, getACampaignReport } from "services/campaignService";
import { getCampaignGraph } from "services/campaignService";
import Toast from "config/toast";
import PrimaryButton from "components/Button/PrimaryButton";
import ShareModal from "./ShareModal";
import Typography from "utils/typography";
import moment from "moment";
import { reportStatus } from "lib/survStatus";
import { toTitleCase } from "lib/titleCase";
import { FaChevronLeft } from "react-icons/fa";

const tabs = [
  { name: "Summary", value: 0 },
  { name: "Details", value: 1 },
];

const EmailReport = () => {
  const [step, setStep] = useState(0);
  const { id } = useParams();
  const [openModal, setOpen] = useState(null);
  const [select, setSelect] = useState("");

  const componentRef = useRef();

  const { data, isLoading: reportLoading } = useQuery(["oneCampaign", id], () => getCampaignReport(id), {
    onError: (error) => {
      Toast.error(error);
    },
  });
  const { data: campDetails, isLoading: detailsLoading } = useQuery(
    ["oneCampaignReport", id],
    () => getACampaignReport(id),
    {
      onError: (error) => {
        Toast.error(error);
      },
    },
  );

  const { data: campGraph, refetch } = useQuery(
    ["campaignGraph", id, select],
    () => getCampaignGraph({ id: id, select: select }),
    {
      onError: (error) => {
        Toast.error(error);
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [refetch, select]);

  // let graphData = [];

  // for (const x in campGraph) {
  //   for (const y in campGraph[x]) {
  //     const keys = Object.keys(campGraph[x]);
  //     console.log(keys)
  //     graphData.push({
  //       name: keys[0],
  //       sent: campGraph[x][y].sent,
  //       dnd: campGraph[x][y].dnd,
  //       delivered: campGraph[x][y].delivered,
  //     });
  //   }
  // }

  const transformGraphData = (data) => {
    const hours = 24;
    const graphData = [];

    for (let hour = 1; hour <= hours; hour++) {
      const dayIndex = hour - 1;
      graphData.push({
        name: hour,
        clicked: data?.clicked[dayIndex] ?? 0,
        opened: data?.opened ? data.opened[dayIndex] ?? 0 : 0,
      });
    }

    return graphData;
  };

  console.log(campGraph);

  const graphData = transformGraphData(campGraph);
  console.log(graphData);

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <Overview
              data={data}
              campDetails={campDetails}
              graphData={graphData}
              select={select}
              setSelect={setSelect}
            />
          </div>
        );
      case 1:
        return <Details data={campDetails} />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  if (reportLoading || detailsLoading) {
    return <PreLoader text="Loading Report" />;
  }

  return (
    <div ref={componentRef}>
      <div className="flex items-center justify-between mt-3">
        <div className="flex items-start space-x-3">
          <Link to="/broadcasts/email" className="text-[#737373] flex items-center space-x-2">
            <div className="bg-white border-[1px] rounded h-[25px] w-[25px] flex items-center justify-center mt-1">
              <FaChevronLeft className="text-xs text-gray-600" />
            </div>
          </Link>

          <div>
            <Typography.H3 className="font-[600] flex items-center space-x-2 ">
              <span>{campDetails.campaignSubject}</span>
              <span>{reportStatus(toTitleCase(campDetails.campaignStatus))}</span>
            </Typography.H3>
            <Typography.SubText className="flex items-center space-x-1">
              <span>{campDetails.campaignObjective}</span>
              <BsDot />
              <span>{moment(campDetails?.campaignDate).format("MMMM Do YYYY | h:mm:ss a")}</span>
            </Typography.SubText>
          </div>
        </div>

        <div className="flex justify-between items-center">
          {/* <p className="text-[25px] font-[800]">{campDetails?.campaignName}</p> */}

          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <div
                onClick={() => setOpen(1)}
                className="flex space-x-2 items-center bg-primary-100 px-6 py-2 rounded-[6px] text-primary-800 cursor-pointer hover:bg-primary-600 text-xs hover:text-white"
              >
                <BsShareFill />
                <p>Share</p>
              </div>

              <Link to={`/broadcasts/email/retarget/${id}`}>
                <PrimaryButton
                  text="Retarget"
                  css="bg-primary-100 text-primary-800 text-xs px-6 py-2   hover:text-white"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-4 mt-5">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[4px] px-2 cursor-pointer ${
                step === el.value ? "border-b-[2px] text-[#0F172A] border-purple font-semibold " : "text-[#6C6685]"
              } `}
              onClick={() => handleTabChange(el.value)}
              key={el.value}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
      {openModal && <ShareModal handleModal={() => setOpen(null)} />}
    </div>
  );
};

export default EmailReport;
