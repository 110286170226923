import React from "react";
export const Manual = ({ fill1 }) => (
  <svg width="268" height="127" viewBox="0 0 268 127" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6595_445687)">
      <path d="M0 4C0 1.79086 1.79086 0 4 0H264C266.209 0 268 1.79086 268 4V127H0V4Z" fill={fill1} />
      <g opacity="0.3">
        <path
          d="M8.33509 -10.1478C15.5155 -8.28917 19.9562 -1.1774 24.8887 4.36636C29.6744 9.74527 35.1338 15.0352 36.9434 22.0063C38.7263 28.8743 36.2184 35.9891 34.9081 42.9629C33.7069 49.3563 31.5952 55.3322 29.5234 61.4983C27.1679 68.5087 27.0244 76.819 21.7811 82.0307C16.5489 87.2312 8.3851 87.7374 1.25014 89.6006C-5.90054 91.4679 -13.2196 94.7729 -20.4057 93.0473C-27.5669 91.3276 -32.2769 84.722 -38.0618 80.1605C-43.475 75.892 -49.8888 72.6725 -53.6086 66.8661C-57.3322 61.0535 -57.6494 53.8856 -59.2036 47.1583C-60.8597 39.99 -64.7516 32.857 -63.123 25.6823C-61.4921 18.4971 -55.4784 13.2222 -50.3095 7.97557C-45.3912 2.98317 -40.1298 -1.92038 -33.5527 -4.33325C-27.1746 -6.67314 -20.1617 -4.61896 -13.433 -5.55298C-6.00653 -6.58387 1.07635 -12.0267 8.33509 -10.1478Z"
          fill="#7392FA"
          fill-opacity="0.37"
        />
        <path
          d="M0.848274 -32.6087C8.02872 -30.7501 12.4694 -23.6383 17.4019 -18.0946C22.1876 -12.7157 27.647 -7.4257 29.4566 -0.454594C31.2395 6.41333 28.7316 13.5282 27.4213 20.5019C26.2201 26.8953 24.1084 32.8713 22.0366 39.0374C19.681 46.0477 19.5376 54.3581 14.2943 59.5698C9.0621 64.7703 0.898285 65.2765 -6.23667 67.1397C-13.3874 69.007 -20.7064 72.312 -27.8925 70.5863C-35.0538 68.8667 -39.7637 62.2611 -45.5486 57.6996C-50.9619 53.4311 -57.3757 50.2116 -61.0954 44.4051C-64.819 38.5926 -65.1362 31.4246 -66.6904 24.6974C-68.3465 17.529 -72.2384 10.3961 -70.6098 3.22139C-68.9789 -3.96382 -62.9652 -9.23872 -57.7964 -14.4854C-52.878 -19.4778 -47.6166 -24.3813 -41.0395 -26.7942C-34.6614 -29.1341 -27.6485 -27.0799 -20.9198 -28.0139C-13.4933 -29.0448 -6.41047 -34.4876 0.848274 -32.6087Z"
          fill="#446BF1"
          fill-opacity="0.27"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M281.335 93.8522C288.516 95.7108 292.956 102.823 297.889 108.366C302.674 113.745 308.134 119.035 309.943 126.006C311.726 132.874 309.218 139.989 307.908 146.963C306.707 153.356 304.595 159.332 302.523 165.498C300.168 172.509 300.024 180.819 294.781 186.031C289.549 191.231 281.385 191.737 274.25 193.601C267.099 195.468 259.78 198.773 252.594 197.047C245.433 195.328 240.723 188.722 234.938 184.161C229.525 179.892 223.111 176.673 219.391 170.866C215.668 165.054 215.351 157.886 213.796 151.158C212.14 143.99 208.248 136.857 209.877 129.682C211.508 122.497 217.522 117.222 222.69 111.976C227.609 106.983 232.87 102.08 239.447 99.6668C245.825 97.3269 252.838 99.381 259.567 98.447C266.993 97.4161 274.076 91.9733 281.335 93.8522Z"
          fill="#7392FA"
          fill-opacity="0.37"
        />
        <path
          d="M273.848 71.3913C281.029 73.2499 285.469 80.3617 290.402 85.9054C295.188 91.2843 300.647 96.5743 302.457 103.545C304.24 110.413 301.732 117.528 300.421 124.502C299.22 130.895 297.108 136.871 295.037 143.037C292.681 150.048 292.538 158.358 287.294 163.57C282.062 168.77 273.898 169.276 266.763 171.14C259.613 173.007 252.294 176.312 245.107 174.586C237.946 172.867 233.236 166.261 227.451 161.7C222.038 157.431 215.624 154.212 211.905 148.405C208.181 142.593 207.864 135.425 206.31 128.697C204.654 121.529 200.762 114.396 202.39 107.221C204.021 100.036 210.035 94.7613 215.204 89.5146C220.122 84.5222 225.383 79.6187 231.96 77.2058C238.339 74.8659 245.352 76.9201 252.08 75.9861C259.507 74.9552 266.59 69.5124 273.848 71.3913Z"
          fill="#446BF1"
          fill-opacity="0.27"
        />
      </g>
      <path
        d="M190 20H78C71.3726 20 66 25.3726 66 32V95C66 101.627 71.3726 107 78 107H190C196.627 107 202 101.627 202 95V32C202 25.3726 196.627 20 190 20Z"
        fill="white"
      />
      <path
        d="M133.72 88.4498C147.378 88.4498 158.451 77.3775 158.451 63.719C158.451 50.0606 147.378 38.9883 133.72 38.9883C120.062 38.9883 108.989 50.0606 108.989 63.719C108.989 77.3775 120.062 88.4498 133.72 88.4498Z"
        fill="#DAE5FF"
      />
      <path
        d="M155.077 54.1534C156.776 54.1534 158.153 52.7763 158.153 51.0767C158.153 49.3771 156.776 48 155.077 48C153.377 48 152 49.3771 152 51.0767C152 52.7763 153.377 54.1534 155.077 54.1534Z"
        fill="#6B7FFF"
      />
      <path
        d="M155.387 49.8452C155.387 49.6755 155.248 49.5371 155.079 49.5371C154.909 49.5371 154.77 49.6755 154.77 49.8452V50.7681H153.848C153.678 50.7681 153.54 50.9064 153.54 51.0761C153.54 51.2458 153.678 51.3842 153.848 51.3842H154.77V52.3071C154.77 52.4768 154.909 52.6151 155.079 52.6151C155.248 52.6151 155.387 52.4768 155.387 52.3071V51.3842H156.309C156.479 51.3842 156.618 51.2458 156.618 51.0761C156.618 50.9064 156.479 50.7681 156.309 50.7681H155.387V49.8452Z"
        fill="white"
      />
      <path
        d="M133.72 66.1939C137.818 66.1939 141.139 62.8722 141.139 58.7747C141.139 54.6772 137.818 51.3555 133.72 51.3555C129.622 51.3555 126.301 54.6772 126.301 58.7747C126.301 62.8722 129.622 66.1939 133.72 66.1939Z"
        fill="#1D19AF"
      />
      <path
        d="M117.5 82.3882C119.956 75.8172 126.292 71.1387 133.72 71.1387C141.148 71.1387 147.484 75.8172 149.94 82.3882C145.598 86.1641 139.926 88.4502 133.72 88.4502C127.514 88.4502 121.842 86.1641 117.5 82.3882Z"
        fill="#1D19AF"
      />
      <path
        d="M202 95C202 101.627 196.627 107 190 107H78C71.3726 107 66 101.627 66 95V32C66 25.3726 71.3726 20 78 20H190C196.627 20 202 25.3726 202 32V95ZM66.2494 94.7787C66.2494 101.406 71.622 106.779 78.2494 106.779H189.751C196.378 106.779 201.751 101.406 201.751 94.7787V32.208C201.751 25.5806 196.378 20.208 189.751 20.208H78.2494C71.622 20.208 66.2494 25.5806 66.2494 32.208V94.7787Z"
        fill="#C7C7C7"
      />
      <circle cx="240" cy="30" r="9.5" fill="white" stroke="#D4D4D4" />
    </g>
    <defs>
      <clipPath id="clip0_6595_445687">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H264C266.209 0 268 1.79086 268 4V127H0V4Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
