import ProgressBar from "components/ProgressBarNew";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import TopUpModal from "pages/Settings/Campaigns/TopUpModal";
import useSubscription from "hooks/usesubscription";
import useCredit from "hooks/useCredit";
import { formatDateAlone } from "lib/formatDate";
import { getDaysLeft } from "lib/formatDate";
import { toTitleCase } from "lib/titleCase";

const BalanceCard = () => {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const { wsCredit } = useCredit();
  const { subscription } = useSubscription();

  const plan = subscription?.plan;

  const balances = [
    { name: "Online surveys responses", values: wsCredit?.bundle?.survey?.online, total: 1000, percent: 10 },
    { name: "Field interview responses", values: wsCredit?.bundle?.survey?.field?.media, total: 20000, percent: 2 },
    { name: "Emails", values: wsCredit?.bundle?.broadcast?.email, total: 1000, percent: 50 },
    { name: "SMS", values: wsCredit?.bundle?.broadcast?.sms, total: 1000, percent: 20 },
    { name: "Robocalls", values: wsCredit?.bundle?.broadcast?.voice, total: 100000, percent: 70 },
    { name: "Rewards", values: wsCredit?.bundle?.rewards, total: 1000, percent: 5 },
  ];

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };
  return (
    <div>
      <div className="flex gap-[1em] my-1">
        <div className="h-[190px] bg-primary-50 rounded-[8px] w-[50%]">
          <div className="bg-creditBg bg-cover bg-center bg-no-repeat h-[100%] w-[100%]  p-4 px-8 flex justify-between">
            <div className="space-y-2">
              {plan !== undefined ? (
                plan?.map((el, i) => (
                  <div key={i}>
                    <p className="text-xl font-semibold text-primary-800">
                      {el.category === "researchHub" ? "Research hub" : "Engagement hub"}
                    </p>
                    <p className="text-[12px]">{toTitleCase(el.plan)}-plan</p>
                  </div>
                ))
              ) : (
                <p className="text-xl font-semibold text-primary-800">SME plan</p>
              )}
            </div>

            <div className="flex flex-col justify-between">
              <div>
                <div className="flex items-center justify-end text-sm text-neutral-600 space-x-2">
                  <p>Next subscription date</p>:
                  <p className="text-primary-800 font-semibold text-lg">
                    {plan !== undefined ? formatDateAlone(subscription?.ends_on) : "24th March 2023"}
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <p></p>

                  <p className="text-sm mt-2 text-neutral-600">
                    {plan !== undefined ? getDaysLeft(subscription?.ends_on) : "13"} days left
                  </p>
                </div>
              </div>

              <div className="flex space-x-4 mt-[2em] justify-between">
                <div
                  className="flex justify-center items-center bg-primary-600  text-white hover:bg-primary-800  w-[160px] rounded-[6px] h-[40px] px-4 space-x-1 cursor-pointer"
                  onClick={() => navigate("/pricing")}
                >
                  <p className="text-s font-semibold ">Upgrade plan</p>
                </div>
                <div
                  onClick={() => handleModal(1)}
                  className="flex justify-center items-center bg-primary-800  text-white hover:bg-primary-600  w-[160px] rounded-[6px] h-[40px] px-4 space-x-1 cursor-pointer"
                >
                  <p className="text-s font-semibold ">Top Up Bundle</p>
                  <FaArrowUp />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 w-[50%] h-[190px] rounded-[12px] border-[1px] bg-white">
          <p>Balances</p>
          <div className="grid grid-cols-2 gap-4 mt-3">
            {balances.map((el, i) => (
              <div className="" key={i}>
                <div className="flex items-center justify-between">
                  <p className="text-xs font-light">{el.name}</p>
                  <p className="text-sm font-semibold">{el.values.toLocaleString()}</p>
                </div>
                <div>
                  <hr />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {open === 1 ? <TopUpModal handleModal={handleModal} /> : null}
    </div>
  );
};

export default BalanceCard;
