import React from "react";

const SideModal = ({ children }) => {
  return (
    <div className="bg-grey fixed top-0 bottom-0 left-0 h-[100vh] w-full   items-center z-[999]">
      <div className="h-[100vh] w-[600px] bg-white float-right p-[2em]">{children}</div>
    </div>
  );
};

export default SideModal;
