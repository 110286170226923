import React, { useMemo, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Pagination = ({ data, itemsPerPage, currentPage, setCurrentPage }) => {
  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = data?.length;

    let startValue = (currentPage - 1) * itemsPerPage;
    startValue = startValue + 1;

    let endValue = Math.min(currentPage * itemsPerPage, totalValue);
    return [startValue, endValue];
  }, [currentPage, data?.length, itemsPerPage]);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (pageCountEnd < data?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={`flex items-center space-x-4 justify-end mt-5 ${data?.length < itemsPerPage && "hidden"}`}>
      <div
        onClick={pageCountStart === 1 ? null : handlePrev}
        className={`hover:border py-1 px-2 ${
          pageCountStart === 1 ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <MdKeyboardArrowLeft />
      </div>
      <div className="flex items-center space-x-1 text-s">
        <span className="border py-1 px-3 flex items-center justify-center">
          {pageCountStart} - {pageCountEnd}
        </span>{" "}
        <span>of</span> <span className="border py-1 px-3 flex items-center justify-center">{data?.length}</span>
      </div>
      <div
        onClick={pageCountEnd === data?.length ? null : handleNext}
        className={`hover:border py-1 px-2 ${
          pageCountEnd === data?.length ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <MdKeyboardArrowRight />
      </div>
    </div>
  );
};

export default Pagination;
