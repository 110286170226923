export function formatCamelCaseToWords(input) {
  const withSpaces = input.replace(/([A-Z])/g, " $1").trim(); // Insert space before uppercase letters
  const parts = withSpaces.split(" ");

  // Capitalize the first character of each part
  const formattedText = parts
    .map((part, index) => {
      if (index === 0) {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      }
      return part;
    })
    .join(" ");

  return formattedText;
}
