import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import { useMutation } from "@tanstack/react-query";
import { createTag } from "services/contactService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { editTag } from "services/contactService";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name too short")
    .max(25, "Name too long (25 characters max)")
    .matches(/^\S*$/, "Name should not contain any whitespace")
    .required("Please fill in this field"),
});

const initialValues = {
  name: "",
  description: "",
};

const CreateTagModal = ({ toggleModal, id, closeModal }) => {
  const navigate = useNavigate();
  const { mutate: create, isLoading: createLoading } = useMutation(createTag, {
    onSuccess: () => {
      Toast.success("Tag created");
      closeModal();
      queryClient.invalidateQueries(["allTags"]);
    },
    onError: (error) => {
      Toast.error(`${error}`);
    },
  });

  const { mutate: tagEdit, isLoading: loadingEdit } = useMutation(editTag, {
    onSuccess: () => {
      Toast.success("tag name edited");
      queryClient.invalidateQueries(["allTags"]);
      navigate("/contacts/all-contacts?tab=1");
      closeModal();
    },
    onError: (error) => {
      Toast.error(`${error}`);
    },
  });

  const onSubmit = (data) => {
    const payload = { name: data?.name };
    id === undefined ? create(data) : tagEdit({ payload, id });
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  return (
    <Modal title={id === undefined ? "Name your tag" : "Edit tag details"} onClose={closeModal}>
      <div className="w-[516px] px-5">
        <p className="text-m font-semibold my-2">Enter tag details below.</p>
        <form action="">
          <div className="mt-5 space-y-5">
            <div className="w-full">
              <SecondaryInput
                text="Tag Name"
                placeholder="Enter tag name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                name="name"
                error={getError("name")}
                type="text"
              />
              <p className="text-ss text-gray-400">E.g Creators, Influencers, leaders.</p>
            </div>
            <div className="w-full">
              <SecondaryInput
                text="Description (optional)"
                placeholder="Describe your tag"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                name="description"
                error={getError("description")}
                type="text"
              />
              <p className="text-ss text-gray-400">Describe your tag in a few words to make it easy to identify.</p>
            </div>
          </div>
          <div className="w-full my-5">
            <div className="flex justify-end space-x-5 mt-[3em]">
              <div>
                <GreyButton text="Cancel" onClick={closeModal} />
              </div>
              <div className="flex space-x-2">
                <BlueButton
                  text={id === undefined ? "Create" : "Save"}
                  css=""
                  onClick={handleSubmit}
                  disabled={values.name === ""}
                  loading={createLoading || loadingEdit}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateTagModal;
