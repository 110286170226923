import GreyButton from "components/Button/GreyButton";
import DeleteButton from "../../components/Button/DeleteButton";
import Modal from "../../components/Modal";
import deleteOwl from "assets/images/deleteOwl.png";
import React from "react";

const DeleteModal = ({ toggleModal, deleteCampaigns }) => {
  return (
    <Modal title="Delete Campaigns" onClose={toggleModal}>
      <div className="w-[400px]">
        <div className="flex justify-center py-2 bg-red-100 mt-3">
          <div className="w-[208px]">
            <img src={deleteOwl} alt="" />
          </div>
        </div>
        <p className="px-5 py-5 text-center text-s">
          Are you sure you want to delete your campaign(s)?.This action cannot be undone.
        </p>
        <div className="flex justify-between px-5 pt-2 border-t">
          <div></div>
          <div className="flex gap-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={toggleModal} />
            <DeleteButton text="Yes, delete" onClick={deleteCampaigns} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
