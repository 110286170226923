export const nps = {
  automotive_and_accessories: [
    {
      question:
        "How likely are you to recommend our automotive products and services to friends or family (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What's the main factor that influenced your rating?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "What specific aspect of our automotive products or services could be improved?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How well do our products meet your automotive needs?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "Are there any additional services or products you’d like us to offer in the automotive category?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  baby_and_kids_products: [
    {
      question: "How likely would you recommend our baby and kids' products to other parents (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What drove your decision to give us this score?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "In what areas could we enhance our baby and kids' products?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How would you rate the suitability of our products for your child’s age and needs?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "What additional products or features would you like to see in our baby and kids range?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  banking_and_financial_services: [
    {
      question: "How likely are you to recommend our banking and financial services to others (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What influenced your rating the most?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "How effectively do our services meet your requirements?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "What can we do to improve our banking or financial services?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "Are there any specific financial products or tools you would like us to introduce?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  beauty_and_personal_care: [
    {
      question: "On a scale from 0-10, how likely are you to recommend our beauty and personal care products?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "Can you share the main reason for your score?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "What specific improvements would you like to see in our beauty products?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How well do our products cater to your personal care needs?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "Do you want us to include product types or ingredients in our range?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  business: [
    {
      question:
        "How likely are you to recommend our business services or products to colleagues and peers (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What is the primary reason behind your score?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "What aspects of our business services need enhancement?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How relevant are our services to your current business needs?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "What additional business solutions or support would you find valuable?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  education: [
    {
      question: "On a scale of 0-10, how likely are you to recommend our educational products or services?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What's the key factor influencing your rating?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "In what ways can we improve our educational content or delivery?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How well do our educational services align with your learning or teaching goals?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "Are there specific educational topics or tools you want us to focus on?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  electronics_and_gadgets: [
    {
      question: "How likely are you to suggest our repair service to friends or family (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What’s the most significant factor in your decision to give this rating?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "What specific areas of our repair services could use improvement?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How effectively do our repair services meet your technology needs?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "What repair services are you looking for that we could add to our selection?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  food_and_beverages: [
    {
      question: "On a scale from 0-10, how likely are you to recommend our food and beverages?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "Can you specify the main reason for your score?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "In what areas could we improve our food and beverage offerings?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How well do our products meet your dietary preferences and tastes?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "Are there specific types of food or beverage products you wish we offered?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  health_and_wellness_products: [
    {
      question: "How likely are you to recommend our health and wellness products to others (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What influenced your rating the most?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "What can we improve in our health and wellness product line?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How closely do our products align with your health goals?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "Are there any specific health products or features you’d like to see us introduce?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  home_decor_and_furnishings: [
    {
      question: "On a scale of 0-10, how likely are you to recommend our home decor and furnishings?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What's the primary factor behind your score?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "How can we enhance our home decor and furnishings offerings?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How well do our products match your style and home needs?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "What types of home decor items or styles are you looking for that we could provide?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  home_and_kitchen_appliances: [
    {
      question: "How likely are you to suggest our home and kitchen appliances to someone you know (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What's the biggest reason for the rating you gave?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    {
      question: "What improvements can we make in our home and kitchen appliance range?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    {
      question: "How well do our appliances meet your home needs?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    {
      question: "Are there any appliance types you wish we had?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
  ],
  jobs_and_career: [
    {
      question: "On a scale from 0-10, how likely are you to recommend our job and career services?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "What motivated your decision for this score?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "In what ways could we improve our job and career services?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How effectively have our services supported your career goals?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "Are there specific career resources or tools you think we should offer?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
  mobile_phones_and_accessories: [
    {
      question: "How likely are you to recommend our mobile phones and accessories to others (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 10,
        label: {
          first: "Good",
          last: "Bad",
        },
      },
    },
    {
      question: "Can you tell us the main reason behind your score?",
      description: "Add some text here (Optional)",
      questionType: "text_response",
    },
    // {
    //   question: "What could we do to make our mobile phones and accessories better?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "How do you rate the quality and features of our mobile products?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
    // {
    //   question: "What types of phones or accessories are you looking for that we currently don’t offer?",
    //   description: "Add some text here (Optional)",
    //   questionType: "text_response",
    // },
  ],
};
