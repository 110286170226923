import React from "react";
import { FaTimes } from "react-icons/fa";
import BlueButton from "components/Button/BlueButton";
import owl from "assets/images/owl.png";
import GreyButton from "components/Button/GreyButton";
import logo from "assets/images/newLogo.png";

export const SuccessModal = ({ handleModal, handleSubmit, handleDone }) => {
  const closeModal = () => {
    handleModal();
  };

  return (
    <div className="w-[100%] h-[100vh] bg-white absolute top-0 left-0 z-[3000] flex flex-col justify-center">
      <div className="flex justify-center">
        <img src={logo} alt="" className="w-[184px]" />
      </div>
      <div className="flex justify-end mx-auto w-[80%] pt-[4em]">
        <FaTimes onClick={closeModal} className="text-[24px]  cursor-pointer" />
      </div>
      <div className="flex justify-center">
        <img src={owl} alt="" className="w-[243px]" />
      </div>
      <p className="text-[24px] font-[500] text-center my-3">Congratulations!</p>
      <div className="flex justify-center mt-5">
        <p className="text-center w-[38%]">
          Your message is on its way. To ensure that we are compliant and considerate of people’s time, broadcasts will
          be published between 8 am and 7 pm.
        </p>
      </div>
      <div className="flex justify-center space-x-5 mt-5">
        <BlueButton text="Send another" type="submit" onClick={handleSubmit} />
        <GreyButton text="Done" className="w-[163px]" onClick={handleDone} />
      </div>
    </div>
  );
};
