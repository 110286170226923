import React from "react";

const ShareAction = ({ fill1, fill2, fill3 }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" fill="white" />
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" stroke={fill1} stroke-width="0.8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.136 16.1427L16.636 20.6427L15.8643 19.3564L23.3643 14.8564L24.136 16.1427Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.3643 25.1427L15.8643 20.6427L16.636 19.3564L24.136 23.8564L23.3643 25.1427Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.75 17.75C24.9927 17.75 26 16.7426 26 15.5C26 14.2574 24.9927 13.25 23.75 13.25C22.5073 13.25 21.5 14.2574 21.5 15.5C21.5 16.7426 22.5073 17.75 23.75 17.75Z"
      fill={fill3}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.75 26.75C24.9927 26.75 26 25.7427 26 24.5C26 23.2573 24.9927 22.25 23.75 22.25C22.5073 22.25 21.5 23.2573 21.5 24.5C21.5 25.7427 22.5073 26.75 23.75 26.75Z"
      fill={fill3}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.25 22.25C17.4926 22.25 18.5 21.2427 18.5 20C18.5 18.7573 17.4926 17.75 16.25 17.75C15.0074 17.75 14 18.7573 14 20C14 21.2427 15.0074 22.25 16.25 22.25Z"
      fill={fill3}
    />
  </svg>
);

export default ShareAction;
