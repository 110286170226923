import { useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import { useRef, useState } from "react";
import { BsDot, BsShareFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import Overview from "./Overview";
import Details from "./Details";
import { getCampaignReport, getACampaignReport } from "services/campaignService";
import { getCampaignGraph } from "services/campaignService";
import Toast from "config/toast";

import PrimaryButton from "components/Button/PrimaryButton";
import ShareModal from "./ShareModal";
import Typography from "utils/typography";
import { reportStatus } from "lib/survStatus";
import { toTitleCase } from "lib/titleCase";
import moment from "moment";
import { FaChevronLeft } from "react-icons/fa";

const tabs = [
  { name: "Summary", value: 0 },
  { name: "Details", value: 1 },
];

const VoiceReport = () => {
  const [step, setStep] = useState(0);
  const { id } = useParams();
  const componentRef = useRef();
  const [openModal, setOpen] = useState(null);

  const { data, isLoading: reportLoading } = useQuery(["oneCampaign", id], () => getCampaignReport(id), {
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { data: campDetails, isLoading: detailsLoading } = useQuery(
    ["oneCampaignReport", id],
    () => getACampaignReport(id),
    {
      onError: (error) => {
        Toast.error(error);
      },
    },
  );
  const { data: campGraph, isLoading: graphLoading } = useQuery(
    ["campaignGraph", id],
    () => getCampaignGraph({ id: id }),
    {
      onError: (error) => {
        Toast.error(error);
      },
    },
  );

  console.log(campDetails);
  console.log(campGraph);
  // console.log(campDetails);

  const transformGraphData = (data) => {
    const hours = 24;
    const graphData = [];

    for (let hour = 1; hour <= hours; hour++) {
      const dayIndex = hour - 1;
      graphData.push({
        name: hour,
        accepted: data?.accepted !== undefined ? data?.accepted[dayIndex] : 0,
        delivered: data?.delivered ? data.delivered[dayIndex] ?? 0 : 0,
      });
    }

    return graphData;
  };

  const graphData = transformGraphData(campGraph);

  const displayStep = () => {
    switch (step) {
      case 0:
        return <Overview data={data} graphData={graphData} campDetails={campDetails} />;
      case 1:
        return <Details data={campDetails} />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  if (reportLoading || detailsLoading || graphLoading) {
    return <PreLoader text="Loading Report" />;
  }
  return (
    <div>
      <div className="flex justify-between items-center mt-3">
        <div className="flex items-start space-x-3">
          <Link to="/broadcasts/voice" className="text-[#737373] flex items-center space-x-2">
            <div className="bg-white border-[1px] rounded h-[25px] w-[25px] flex items-center justify-center mt-1">
              <FaChevronLeft className="text-xs text-gray-600" />
            </div>
          </Link>

          <div>
            <Typography.H3 className="font-[600] flex items-center space-x-2 ">
              <span>{campDetails.campaignSubject}</span>
              <span>{reportStatus(toTitleCase(campDetails.campaignStatus))}</span>
            </Typography.H3>
            <Typography.SubText className="flex items-center space-x-1">
              <span>{campDetails.campaignObjective}</span>
              <BsDot />
              <span>{moment(campDetails?.campaignDate).format("MMMM Do YYYY | h:mm:ss a")}</span>
            </Typography.SubText>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div
              onClick={() => setOpen(1)}
              className="flex space-x-2 items-center bg-primary-100 px-6 py-2 rounded-[6px] text-primary-800 cursor-pointer hover:bg-primary-600 text-xs hover:text-white"
            >
              <BsShareFill />
              <p>Share</p>
            </div>

            {/* <ReactToPrint
            trigger={() => (
              <div className="text-primary-800 cursor-pointer flex space-x-2 items-center hover:text-[#4908AA]">
                <HiDownload />
                <p>Download</p>
              </div>
            )}
            content={() => componentRef.current}
          /> */}

            <Link to={`/broadcasts/voice/retarget/${id}`}>
              <PrimaryButton text="Retarget" css="bg-primary-100 text-primary-800 px-6 py-2 text-xs hover:text-white" />
            </Link>
          </div>
        </div>
      </div>

      <div className="flex items-end mt-5">
        <div className="flex space-x-4  border-b border-b-1 w-full">
          {tabs.map((el) => {
            return (
              <p
                className={` pb-[4px] px-2 cursor-pointer ${
                  step === el.value ? "border-b-[2px] text-[#0F172A] border-purple font-semibold " : "text-[#957eed]"
                } `}
                onClick={() => handleTabChange(el.value)}
                key={el.value}
              >
                {el.name}
              </p>
            );
          })}
        </div>
        {/* <div>
          <PrimaryButton text="Copy link" css="bg-primary-100 text-primary-800 h-[40px] hover:text-white" />
        </div> */}
      </div>

      <div>{displayStep()}</div>
      {openModal && <ShareModal handleModal={() => setOpen(null)} />}
    </div>
  );
};

export default VoiceReport;
