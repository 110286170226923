import React from "react";
import BlueButton from "components/Button/BlueButton";

const Data = () => {
  return (
    <div>
      <p className="text-[#292D32] font-semibold text-[20px]">Export Workspace Activity Log</p>
      <p className="text-[#737373] text-s font-[450] my-4">
        Download and review Workspace activity within a date range. By default, show last 30 days of activity.
      </p>

      <BlueButton disabled={true} text="Download Activity Log" />
    </div>
  );
};

export default Data;
