import { useMutation } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createSurveyTarget } from "services/newSurveyService";
import { queryClient } from "config/gateway";
import PrimaryInput from "components/Inputs/PrimaryInput";
import Typography from "utils/typography";
import BottomBar from "./BottomBar";
import SaveAndExitModal from "./SaveAndExitModal";

const GenerateLink = ({ audienceType, setStep, step }) => {
  const [disableButton, setDisabledButton] = useState(true);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [surveyData, setSurveyData] = useState({
    audienceType: audienceType,
    expectedResponse: 0,
    reward: 100,
  });

  const locationArray = location.pathname.split("/");

  const { mutate, isLoading } = useMutation(createSurveyTarget, {
    onSuccess: () => {
      setStep(step + 1);

      Toast.success("Saved");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(createSurveyTarget, {
    onSuccess: () => {
      Toast.success("Saved");

      queryClient.invalidateQueries(["onlineSurveyDraft", "brandTrackingDraft", "offline_surveyDraft"]);
      if (locationArray.includes("brand-tracking")) {
        navigate(`/research/brand-tracking`);
      } else if (locationArray.includes("online-survey")) {
        navigate(`/research/online-survey`);
      } else {
        navigate(`/research/field-interview`);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleSave = () => {
    const newSurveyData = {
      ...surveyData,
      expectedResponse: +surveyData.expectedResponse,
      reward: +surveyData.reward,
    };
    mutate({
      surveyId: id,
      payload: newSurveyData,
    });
  };

  const handleSaveAndExit = () => {
    const newSurveyData = {
      ...surveyData,
      expectedResponse: +surveyData.expectedResponse,
      reward: +surveyData.reward,
    };

    saveMutate({
      surveyId: id,
      payload: newSurveyData,
    });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSurveyData((prevData) => ({ ...prevData, [name]: value }));
  };

  const checkState = useCallback(() => {
    if (surveyData.expectedResponse > 0 && (surveyData.reward === 0 || surveyData.reward % 100 === 0)) {
      setDisabledButton(false);
    }
  }, [surveyData.expectedResponse, surveyData.reward]);

  useEffect(() => {
    checkState();
  }, [checkState]);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  if (isLoading || saveLoading) {
    return <PreLoader />;
  }

  return (
    <div className="">
      {open === 4 ? <SaveAndExitModal handleModal={handleModal} callBackFn={handleSaveAndExit} /> : null}

      <div className="border mt-6 p-6 max-w-[70%] rounded-md flex flex-col  items-start md:flex-row md:justify-between">
        <div className="w-[47%] space-y-1">
          <div className="flex items-center">
            <Typography.SubText className="font-semibold mr-1">Responses needed</Typography.SubText>
          </div>
          <Typography.MicroText>The number of responses needed for this survey</Typography.MicroText>

          <PrimaryInput
            type={"tel"}
            value={surveyData.expectedResponse}
            placeholder={"0"}
            onChange={handleOnChange}
            name={"expectedResponse"}
          />
        </div>

        <div className="w-[47%] space-y-1">
          <div className="flex items-center">
            <Typography.SubText className="font-semibold mr-1">Reward per response (Optional)</Typography.SubText>
          </div>
          <Typography.MicroText>Reward your respondents for their response</Typography.MicroText>

          <PrimaryInput
            type={"tel"}
            value={surveyData.reward}
            placeholder={"200 credits"}
            onChange={handleOnChange}
            name={"reward"}
          />
        </div>
      </div>

      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={handleSave}
        handleModal={() => handleModal(4)}
        disabled={disableButton}
      />
    </div>
  );
};

export default GenerateLink;
