import SideModal from "components/SideModal";
import React from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";

const ViewModal = ({ onClose, title, url }) => {
  console.log(url, "ecink");
  return (
    <SideModal>
      <HiOutlineArrowLeft className="text-[28px] cursor-pointer" onClick={onClose} />

      <p className="text-[20px] mt-[1em]  font-semibold">{title || null}</p>

      <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[0.5em]"></div>

      <div className="border-[1px] border-[#BFCCDA] mt-[3em] h-[600px] w-[450px] mx-auto ">
        <img src={url} alt="" className="w-[100%] h-[100%] object-contain" />
        {/* <embed src={url} type="application/pdf" width="100%" height="100%"/> */}

        {/* <object data={url} type="application/pdf" className="w-[100%] h-[100%]">
          <iframe
            src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
            title="testPdf"
            height="100%"
            width="100%"
            className="w-[500px]"
          ></iframe>
        </object> */}
      </div>
    </SideModal>
  );
};

export default ViewModal;
