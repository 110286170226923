import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const isNotBetween7amAnd8pm = (dateString) => {
  const date = dayjs.utc(dateString, "YYYY-MM-DD HH:mm:ss").tz("UTC");
  const hour = date.hour();
  return hour < 7 || hour >= 19;
};

export const getCurrentDate = () => {
  const today = new Date();
  let month = String(today.getMonth() + 1).padStart(2, "0");
  let day = String(today.getDate()).padStart(2, "0");
  const year = today.getFullYear();

  return `${year}-${month}-${day}`;
};

export const getCurrentTime = () => {
  const today = new Date();
  let hours = String(today.getHours()).padStart(2, "0");
  let minutes = String(today.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const convertToUTCAndGMTPlus1 = (dateString) => {
  const date = dayjs(dateString).utc();
  console.log(date, "date");
  const dateInGMTPlus1 = date.tz("Africa/Lagos"); // Change the timezone to 'Africa/Lagos' for GMT+1
  console.log(dateInGMTPlus1, "dateee");

  return dateInGMTPlus1.format("YYYY-MM-DD HH:mm:ss"); // Format the date as needed
};

export const convertToUtc = (localTime) => {
  // Combine the current date with the local time
  const currentDate = new Date();
  const [currentYear, currentMonth, currentDay] = currentDate.toLocaleDateString().split("/");
  const localDateTime = `${currentYear}-${currentMonth}-${currentDay}T${localTime}`;

  // Perform UTC time conversion using dayjs
  const convertedTimeUtc = dayjs.utc(localDateTime).format("HH:mm:ss");

  return convertedTimeUtc;
};

export const convertTimeStampToSecs = (stamp) => {
  const { hour, minutes, seconds } = stamp;
  const t = hour * 60 * 60 + minutes * 60 + seconds;

  return t;
};

export const formatTime = (input) => {
  if (!input?.includes(":")) {
    return "";
  }

  const [hour, minute] = input?.split(":");
  const formattedHour = hour.padStart(2, "0");
  return `${formattedHour}:${minute}`;
};
