// import moment from "moment";
import { useState } from "react";
import TopUpModal from "../TopUpModal";
import TransferModal from "../TransferModal";
import BalanceCard from "./BalanceCard";
import AccountHistory from "./AccountHistory";
import WorkspaceHistory from "./WorkspaceHistory";

const tabs = [
  { name: "Account Usage History", value: 0 },
  { name: "Workspace Billing History", value: 1 },
];

const PlanAndBilling = ({ plan, ledger, accLedger, setAccLedgerBatch, accLedgerBatch, accLoading }) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  const handleTabChange = (e) => {
    setStep(e);
  };

  const displayStep = () => {
    switch (step) {
      case 0:
        return <AccountHistory ledger={ledger} />;
      case 1:
        return (
          <WorkspaceHistory
            accLedger={accLedger}
            setAccLedgerBatch={setAccLedgerBatch}
            accLedgerBatch={accLedgerBatch}
            accLoading={accLoading}
          />
        );
      default:
    }
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  return (
    <div>
      {/* <div className="flex justify-between gap-[2em]">
        <div className="border-[1px] rounded-[8px] p-4 w-[55%]">
          <div className="flex justify-between">
            <div className="text-primary-800">
              <p className="text-[18px] font-semibold">{toTitleCase(plan?.data?.plan)} Plan</p>
            </div>

            <div>
              {plan?.data?.plan === "starter" ? null : (
                <>
                  <p className="font-[450]">
                    Subscription end date:{" "}
                    <span className="text-[#29085C] font-semibold">
                      {moment(plan?.data?.ends_on).format("MMMM Do YYYY")}
                    </span>
                  </p>
                  <p className="text-right text-[#737373] text-m tracking-[-0.02em] ">{check} days left</p>
                </>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between mt-[3.8em]">
            <p></p>

            <Link
              to="/account/plans"
              className="flex justify-center items-center bg-[#FBBF24] hover:bg-[#E8C209]  text-primary-800  w-[150px] rounded-[6px] h-[40px] px-4 space-x-1"
            >
              <p className="text-s font-semibold ">Upgrade Plan</p>
              <RiArrowRightUpLine />
            </Link>
          </div>
        </div>
      </div> */}
      <div>
        <BalanceCard />
      </div>

      <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[2em]"></div>

      <div className="bg-white p-5">
        <div className="flex space-x-4 mt-4">
          {tabs.map((el) => {
            return (
              <p
                className={` pb-[4px] px-2 cursor-pointer ${
                  step === el.value
                    ? "border-b-[2px] text-[#0F172A] border-primary-800 font-semibold "
                    : "text-[#6C6685]"
                } `}
                onClick={el.value !== 2 ? () => handleTabChange(el.value) : null}
                key={el.value}
              >
                {el.name}
              </p>
            );
          })}
        </div>
        <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>
        <div>{displayStep()}</div>
      </div>
      {open === 1 ? <TopUpModal handleModal={handleModal} /> : null}
      {open === 2 ? <TransferModal handleModal={handleModal} /> : null}
    </div>
  );
};

export default PlanAndBilling;
