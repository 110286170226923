import React from "react";
import SidePanel from "./SidePanel";
import SectionQuestionsPanel from "./SectionQuestionsPanel";
import { UIConstants } from "data/constants/uiConstants";

const BranchingBuilder = ({ newStep, setNewStep }) => {
  return (
    <div className="flex h-[72vh]">
      <div
        className={`w-[30%]  border-l-[1px] border-t-[1px] border-b-[1px]   h-[100%] overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <SidePanel />
      </div>

      <SectionQuestionsPanel setNewStep={setNewStep} newStep={newStep} />
    </div>
  );
};

export default BranchingBuilder;
