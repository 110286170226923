export const getHeader = (value) => {
  const x = value.split("/")[1];

  switch (x) {
    case "contacts":
      return "Contacts";
    case "settings":
      return "Settings";

    case "broadcasts":
      const x = value.split("/")[2];
      switch (x) {
        case "sms":
          return "SMS";
        case "voice":
          return "Voice";
        case "email":
          return "Email";
        default:
      }

      break;
    case "surveys":
      return "Surveys";

    case "field-surveys":
      return "Field Interviews";

    case "branded-survey":
      return "Brand Tracking";

    case "dashboard":
      return "Dashboard";

    case "research":
      return "Research";

    case "pricing":
      return "Pricing";

    case "calendar":
      return "Calendar";
    default:
  }
};
