import React, { useEffect, useState } from "react";
import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { useSearchParams } from "react-router-dom";
import { isNotBetween7amAnd8pm } from "lib/checkTime";
import publishOwl from "assets/images/publishOwl.png";
import { getCurrentDate } from "lib/checkTime";

import dayjs from "dayjs";

const tabs = [
  {
    id: 1,
    name: "Send immediately",
    value: "send",
  },
  {
    id: 2,
    name: "Schedule for later",
    value: "schedule",
  },
];
const PublishModal = ({ handleSubmit, data, setData, handleModal }) => {
  const [type, setType] = useState("");
  const [fromCal] = useSearchParams();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const fromCalendar = fromCal.get("fromCalendar");

  const parsedDateTime = dayjs(`${date}T${time}`);
  const formattedDate = time === undefined ? "" : setData;

  useEffect(() => {
    const scheduleTime = () =>
      time === undefined
        ? ""
        : setData({
            ...data,
            campaign_date: parsedDateTime.toISOString(),
          });

    scheduleTime();
  }, [time]);

  console.log(formattedDate);

  const updateType = (x) => {
    setType(x);
    if (x === "schedule") {
      setData({
        ...data,
        campaign_status: "scheduled",
      });
    } else {
      setData({
        ...data,
      });
    }
  };

  console.log(data);

  useEffect(() => {
    if (fromCalendar) {
      setType("schedule");
    }
  }, [fromCalendar, time]);

  const handleDate = (e) => {
    // setData({
    //   ...data,
    //   [e.target.name]: e.target.value,
    // });
  };
  return (
    <Modal title="Publish campaign" onClose={handleModal}>
      <div className="max-[350px] w-[400px]">
        <div className="flex justify-center py-2 bg-primary-100">
          <div className="w-[210px]">
            <img src={publishOwl} alt="" />
          </div>
        </div>
        <div className="px-5">
          {tabs.map((el) => {
            return (
              <div
                className="flex items-center space-x-2 my-3"
                key={el.id}
                onClick={isNotBetween7amAnd8pm() && el.id === 1 ? null : () => updateType(el.value)}
              >
                <div
                  className={`h-[20px] w-[20px] rounded-full border-[1px] cursor-pointer flex items-center justify-center ${
                    el.value === type ? "border-purple" : ""
                  }`}
                >
                  <div className={`${el.value === type ? "bg-primary-800 rounded-full h-[12px] w-[12px]" : ""}`}></div>
                </div>

                <p className={isNotBetween7amAnd8pm() && el.id === 1 ? "text-grey-1" : ""}>{el.name}</p>
              </div>
            );
          })}
          {type === "schedule" && (
            <div className="flex space-x-4 justify-between w-[100%] my-[1em]">
              <div className="w-[50%]">
                <SecondaryInput
                  text="Date"
                  placeholder="Enter campaign name"
                  onChange={(e) => setDate(e.target.value)}
                  name="campaignDate"
                  type="date"
                  value={date}
                  step="2"
                  min={getCurrentDate()}
                />
              </div>
              <div className="w-[50%]">
                <SecondaryInput
                  text="Time"
                  placeholder="Enter campaign name"
                  onChange={(e) => setTime(e.target.value)}
                  name="campaignTime"
                  type="time"
                  value={time}
                  min="08:00"
                  max="19:00"
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between px-5 pt-2 border-t">
          <div></div>
          <div className="flex space-x-2">
            <GreyButton text="Cancel" onClick={handleModal} type="submit" />
            {!data.scheduled ? (
              <BlueButton text="Confirm" onClick={handleSubmit} disabled={isNotBetween7amAnd8pm() || type === ""} />
            ) : null}

            {data.scheduled ? (
              <BlueButton
                text="Confirm"
                onClick={handleSubmit}
                disabled={
                  data.campaignDate !== "" && data.campaignTime !== ""
                    ? isNotBetween7amAnd8pm(`${data.campaignDate}T${data.campaignTime}:00Z`)
                    : true
                }
              />
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PublishModal;
