import React from "react";
import OverviewCard from "./OverviewCard";
import StackedBarCharts from "./Charts/StackedBarCharts";
import HalfPieChart from "./Charts/HalfPieChart";
import Typography from "utils/typography";

const OverviewTab = ({ nameKey, overViewData }) => {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <div className="space-y-5">
      <div className="flex items-center justify-between space-x-4">
        {overViewData.map((elm, i) => (
          <OverviewCard
            key={`${nameKey}-${i}`}
            elm={elm}
            loading={false}
            img={elm.image}
            title={elm.name}
            value={elm.value}
          />
        ))}
      </div>

      <div className="flex items-center gap-[1.5em]">
        <div className="bg-white p-6 w-[50%] rounded-lg border-[1px] ">
          <div className="flex items-center justify-between">
            <Typography.Text>NPS Score</Typography.Text>

            <Typography.SubText>See all</Typography.SubText>
          </div>
          <HalfPieChart />
        </div>

        <div className="bg-white p-6 w-[50%] rounded-lg border-[1px] ">
          <StackedBarCharts data={data} />
        </div>
      </div>

      <p>{nameKey}</p>
    </div>
  );
};

export default OverviewTab;
