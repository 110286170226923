export const roleData = [
  {
    value: "",
    label: "Select role",
  },
  {
    value: "Data Analyst",
    label: "Data Analyst",
  },
  {
    value: "Consultant",
    label: "Consultant",
  },
  {
    value: "Digital Marketer",
    label: "Digital Marketer",
  },
  {
    value: "Freelancer",
    label: "Freelancer",
  },
  {
    value: "Product Designer",
    label: "Product Designer",
  },
  {
    value: "UX Researcher",
    label: "UX Researcher",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const options1 = [
  { value: "art/design", label: "Art/Design" },
  { value: "agriculture", label: "Agriculture" },
  { value: "architecture", label: "Architecture" },
  { value: "aviation", label: "Aviation" },
  { value: "banking/finance", label: "Banking/Finance" },
  { value: "construction", label: "Construction" },
  { value: "ecommerce", label: "Ecommerce" },
  { value: "education", label: "Education" },
  { value: "engineering", label: "Engineering" },
  { value: "entertainment", label: "Entertainment" },
  { value: "FMCG", label: "FMCG" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hospitality", label: "Hospitality" },
  { value: "housing/real estate", label: "Housing/Real estate" },
  { value: "management", label: "Management" },
  { value: "marketing/advertising", label: "Marketing/Advertising" },
  { value: "information technology", label: "Information Technology" },
  { value: "logistics", label: "Logistics" },
  { value: "science", label: "Science" },
];

export const sizeOptions = [
  { value: "0-9 employees", label: "0-9 Employees" },
  { value: "11-50 employees", label: "11-50 Employees" },
  { value: "50-200 employees", label: "50-200 Employees" },
  { value: "251-1000 employees", label: "251-1000 Employees" },
];

export const proofData = [
  { value: "Utility Bill", label: "Utility Bill" },
  { value: "Any government issued ID", label: "Any government issued ID" },
  { value: "Affidavit", label: "Affidavit" },
];

export const category = [
  { value: "automotive_and_accessories", label: "Automotive and Accessories" },
  { value: "baby_and_kids_products", label: "Baby and Kids Products" },
  { value: "banking_and_financial_services", label: "Banking and Financial Services" },
  { value: "beauty_and_personal_care", label: "Beauty and Personal Care" },
  { value: "education", label: "Education" },
  { value: "electronics_and_gadgets", label: "Electronics and Gadgets" },
  { value: "food_and_beverages", label: "Food and Beverages" },
  { value: "health_and_wellness_products", label: "Health and Wellness Products" },
  { value: "home_decor_and_furnishings", label: "Home Decor and Furnishings" },
  { value: "home_and_kitchen_appliances", label: "Home and Kitchen Appliances" },
  { value: "jobs_and_career", label: "Jobs and Career" },
  { value: "mobile_phones_and_accessories", label: "Mobile Phones and Accessories" },
  { value: "other", label: "Other" },
];
