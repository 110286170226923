import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import { baseAppURL } from "helpers/getBaseUrl";
import { copyFunc } from "lib/copyFunc";
import React from "react";
import { FaFacebook, FaTelegram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";

const ShareModal = ({ data, handleModal }) => {
  const { pubId, surveyId } = useParams();
  const { user } = useLoggedInUser();

  const handleLinkCopy = () => {
    trackEvent("share_report", {
      email: user?.data?.email,
      surveyName: data?.surveyName,
      surveyStatus: data?.surveyStatus,
      date: dayjs().format(),
    });

    copyFunc(surveyLink, "Link Copied");
  };

  const surveyLink = `${baseAppURL}public/summary/${pubId}/${surveyId}`;

  return (
    <div>
      <Modal title="Share survey report" onClose={handleModal}>
        <div className="w-[500px] px-5">
          <p className="text-s">Copy your report link to share your survey report.</p>

          <div className="mt-[2em]">
            <div className="flex justify-between">
              <div className="h-[40px] rounded-md border-[1px] flex items-center justify-center w-[75%]">
                <p className="text-ss">
                  {surveyLink?.substring(0, 55)} {surveyLink?.length > 55 ? "..." : ""}
                </p>
              </div>
              <GreyButton text="Copy Link" onClick={handleLinkCopy} />
            </div>

            {/* <div className="flex items-center space-x-2 mt-[1em]">
              <p className="text-s">Disable link sharing</p>
              <Toggle checked={data?.shareLink} onChange={handleSend} icons={false} disabled={isLoading} />
            </div> */}
          </div>

          <div className="flex flex-col justify-center space-y-1 items-center mt-[2em]">
            <p className="text-s">Share via:</p>

            <div className="flex items-center space-x-2">
              <FaWhatsapp />
              <FaTwitter />
              <FaFacebook />
              <FaTelegram />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShareModal;
