import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import React from "react";
// import CreateSurveyModal from "./CreateSurveyModal";
import dayjs from "dayjs";
import Logo from "assets/images/newLogo.png";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";

const SmsPreview = ({ handleModal, justPreview }) => {
  const { smsTemplate: el } = useStore();

  const navigate = useNavigate();

  const routeToCreation = () => {
    navigate("/broadcasts/sms/create");
    // handleModal();
  };

  return (
    <Modal title="Simple SMS template" onClose={handleModal}>
      <div className="w-[750px] px-5">
        <div
          className={`w-full p-4 px-[6em] flex justify-center bg-cover bg-no-repeat overflow-hidden rounded-md bg-gray-100 mt-5 `}
        >
          <div className="border-t-[5px] border-l-[5px] border-r-[5px]  border-black  border-b-none w-[50%] h-full items-end rounded-t-[20px] p-2 pb-0">
            <div className="border-t-[2px] border-l-[2px] border-r-[2px] border-[#D0D5DD] border-b-none w-full h-full items-end rounded-t-[18px] p-2 overflow-hidden">
              <div className="bg-white rounded-md p-2">
                <div className="bg-white drop-shadow-sm">
                  <div className="w-[50px] mx-auto mb-[7px]">
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className="">
                    <p className="text-[7px] text-center">Sender ID</p>
                    <p className="text-[6px] text-center pt-2">Text message</p>
                    <p className="text-[6px] text-center ">
                      <p>{dayjs().format("h:mm a")}</p>
                    </p>
                  </div>
                </div>
                <p className="text-ss mt-10">{el.content}</p>
              </div>
            </div>
          </div>
        </div>

        {justPreview ? null : (
          <div className="flex justify-between mt-[1em]">
            <GreyButton text="Back" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <BlueButton text="Use Templates" onClick={routeToCreation} />
          </div>
        )}
      </div>
      {/* {open && <CreateSurveyModal handleModal={handleModal(2)} />} */}
    </Modal>
  );
};

export default SmsPreview;
