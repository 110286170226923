import Loading from "assets/images/loading.gif";

const PreLoader = ({ gif }) => {
  return (
    <div className="absolute bg-white flex flex-col justify-center items-center top-0 left-0 z-30 w-full h-[100vh]">
      <img src={gif ? gif : Loading} alt="Loading..." className="w-[200px]" />
    </div>
  );
};

export default PreLoader;
