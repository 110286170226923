export const setAutoSaveQuestions = (questions) => {
  localStorage.setItem("surveyQuestions", JSON.stringify(questions));
};

export const getAutoSavedQuestions = () => {
  const savedQuestions = localStorage.getItem("surveyQuestions");
  return savedQuestions ? JSON.parse(savedQuestions) : [];
};

export const removeAutoSavedQuestions = () => {
  localStorage.removeItem("surveyQuestions");
};
