import Tabs from "components/Tab";
import React, { useCallback, useEffect, useState } from "react";
import Active from "../components/HomeTabs/Active";
import BlueButton from "components/Button/BlueButton";
import { motion } from "framer-motion";
import Typography from "utils/typography";
// import { FaTrashAlt } from "react-icons/fa";
import classNames from "classnames";
import NewPagination from "components/NewPagination";
import useOnlineSurveys from "hooks/useOnlineSurveys";
import { SurveyIcon } from "assets/icons/sidebar";
import { deleteSurvey } from "services/newSurveyService";
import { useMutation } from "@tanstack/react-query";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import DeleteSurveyModal from "../components/HomeTabs/components/DeleteSurveyModal";
import SurveyDetailsModal from "../components/SurveyDetailsModal";
import CreateSurveyModal from "../components/CreateSurveyModal";
import HomeCardSkeleton from "../components/HomeTabs/components/HomeCardSkeleton";
import { cardVariants } from "animation";
import { Delete } from "assets/icons/contacts/Delete";
import { useStore } from "store";
import { RiSearch2Line } from "react-icons/ri";
import PreviewTemplateModal from "../components/PreviewTemplateModal";
import { surveyTemplates } from "data/surveyTemplates";
import { removeAutoSavedQuestions } from "store/surveyStore/autoSaveSurveyQuestions";

const OnlineSurveyHome = () => {
  const [step, setStep] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("active");
  const [selectArray, setSelectArray] = useState([]);
  const [open, setOpen] = useState(null);
  const [active, setActive] = useState(false);
  const { setSurveyTemplate } = useStore();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [previewTemplateInfo, setPreviewTemplateInfo] = useState();

  const {
    newSurveyData,
    newDraftData,
    newCloseData,
    newScheduledData,
    fetchActive,
    loadingActive,
    fetchDraft,
    loadingDraft,
    fetchClosed,
    loadingClosed,
    fetchScheduled,
    loadingScheduled,
    totalActiveContact,
    totalDraftContact,
    totalClosedContact,
    totalScheduledContact,
  } = useOnlineSurveys({
    page: currentPage,
    surveyType: "onlineSurvey",
  });

  const arrayToSearch =
    status === "active"
      ? newSurveyData
      : status === "draft"
      ? newDraftData
      : status === "scheduled"
      ? newScheduledData
      : newCloseData;

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleTemplatePreview = (id) => {
    const foundTemplate = surveyTemplates.find((el) => el.id === id);
    handleModal(id === foundTemplate.id ? 4 : null);
    setPreviewTemplateInfo(foundTemplate);
  };

  useEffect(() => {
    setFilteredItems(arrayToSearch);
    removeAutoSavedQuestions();
  }, [arrayToSearch]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    if (value !== "") {
      const filtered = arrayToSearch.filter((item) => item.surveyName.toLowerCase().includes(value));

      setFilteredItems(filtered);
    } else {
      setFilteredItems(arrayToSearch);
    }
  };

  const Tab = [
    { id: 1, name: "Active", key: "active", icons: SurveyIcon, number: totalActiveContact },
    { id: 2, name: "Drafts", key: "draft", icons: SurveyIcon, number: totalDraftContact },
    { id: 3, name: "Closed", key: "closed", icons: SurveyIcon, number: totalClosedContact },
    { id: 4, name: "Scheduled", key: "scheduled", icons: SurveyIcon, number: totalScheduledContact },
  ];

  const { mutate: mutateDeleteSurvey, isLoading: isDeletingSurvey } = useMutation(deleteSurvey, {
    onSuccess: () => {
      Toast.success("Survey deleted.");
      handleModal();
      queryClient.invalidateQueries([
        "onlineSurveyActive",
        "onlineSurveyDraft",
        "onlineSurveyClosed",
        "onlineSurveyScheduled",
      ]);
      queryClient.invalidateQueries(["onBoarding"]);
      setSelectArray([]);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectArray([...selectArray, x]);
    } else {
      const filterArray = selectArray?.filter((el) => el !== x);
      setSelectArray(filterArray);
    }
  };

  const checkArray = useCallback(() => {
    if (selectArray.length > 0) {
      setActive(true);
    } else if (selectArray.length <= 0) {
      setActive(false);
    }
  }, [selectArray.length]);

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  const handleTabChange = (e) => {
    setStep(e.id);
    setStatus(e.key);
    setCurrentPage(1);
    setSearchTerm("");
    setSelectArray([]);
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return loadingActive || fetchActive ? (
          <div className="grid grid-cols-3 gap-6 ">
            {Array.from({ length: 8 }).map((el, i) => (
              <motion.div
                key={i}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                className="relative"
              >
                <HomeCardSkeleton key={el} />
              </motion.div>
            ))}
          </div>
        ) : (
          <Active
            surveyData={filteredItems}
            status={status}
            selectArray={selectArray}
            handleCheck={handleCheck}
            drafts={newDraftData}
          />
        );

      case 2:
        return fetchDraft || loadingDraft ? (
          <div className="grid grid-cols-3 gap-6 ">
            {Array.from({ length: 8 }).map((el, i) => (
              <motion.div
                key={i}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                className="relative"
              >
                <HomeCardSkeleton key={el} />
              </motion.div>
            ))}
          </div>
        ) : (
          <Active surveyData={filteredItems} status={status} selectArray={selectArray} handleCheck={handleCheck} />
        );

      case 3:
        return fetchClosed || loadingClosed ? (
          <div className="grid grid-cols-3 gap-6 ">
            {Array.from({ length: 8 }).map((el, i) => (
              <motion.div
                key={i}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                className="relative"
              >
                <HomeCardSkeleton key={el} />
              </motion.div>
            ))}
          </div>
        ) : (
          <Active surveyData={filteredItems} status={status} selectArray={selectArray} handleCheck={handleCheck} />
        );

      case 4:
        return fetchScheduled || loadingScheduled ? (
          <div className="grid grid-cols-3 gap-6 ">
            {Array.from({ length: 8 }).map((el, i) => (
              <motion.div
                key={i}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                className="relative"
              >
                <HomeCardSkeleton key={el} />
              </motion.div>
            ))}
          </div>
        ) : (
          <Active surveyData={filteredItems} status={status} selectArray={selectArray} handleCheck={handleCheck} />
        );

      default:
    }
  };

  const handleDeleteSurvey = () => {
    mutateDeleteSurvey({
      surveyIds: selectArray,
    });
  };

  const handleCreateNewSurvey = () => {
    setSurveyTemplate({
      id: 0,
      surveyName: "",
      surveyCategory: "",
      surveyDescription: "",
      coverImage: "",
      reward: 0,
      campaignType: "",
      bg: "bg-bgOne",
      questions: [],
    });
    handleModal(2);
  };

  return (
    <div className="bg-white  rounded-lg p-4 min-h-[calc(100vh-110px)]">
      <BlueButton text="Create new survey" onClick={handleCreateNewSurvey} />

      <div className="pt-4">
        <Tabs tabs={Tab} step={step} clickFn={handleTabChange} lookup="id" />
      </div>

      <div className="border-[1px] border-gray-300 rounded-b-md">
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center space-x-4 h-[70px] px-4">
            <Typography.SubText>Actions:</Typography.SubText>

            <div
              className={classNames(
                "w-[40px] h-[40px] flex items-center justify-center border-[1px] rounded  hover:bg-primary-100 cursor-pointer ",
                active ? "bg-primary-200 text-white hover:bg-primary-500" : "bg-slate-100 text-gray-500",
              )}
              onClick={active ? () => handleModal(1) : null}
              data-title="Delete"
            >
              <Delete fill1="#A3A3A3" fill2="#404040" />
            </div>
          </div>

          {selectArray?.length > 0 ? (
            <div className="w-[100px] bg-gray-100 h-[40px] flex items-center justify-center border-gray-200 rounded-md cursor-pointer space-x-2">
              <Typography.SubText>{selectArray.length}</Typography.SubText>
              <Typography.SubText>selected</Typography.SubText>
            </div>
          ) : null}

          <div className="border-gray-400 rounded flex items-center space-x-2 border-[1px] h-[40px] w-[300px] px-2 bg-slate-50">
            <RiSearch2Line className="text-gray-400" />
            <input
              type="text"
              className="outline-none text-xs placeholder:text-xs bg-transparent"
              placeholder="Search surveys..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div></div>

        <motion.div
          key={step} // Using key ensures the component re-renders when `step` changes
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="bg-gray-100 p-4"
        >
          {displayStep()}
        </motion.div>
      </div>

      <NewPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={10}
        isFetching={fetchActive}
        total={
          status === "draft"
            ? totalDraftContact
            : status === "closed"
            ? totalClosedContact
            : status === "scheduled"
            ? totalScheduledContact
            : totalActiveContact
        }
      />

      {open === 1 ? <DeleteSurveyModal handleModal={handleModal} handleDeleteSurvey={handleDeleteSurvey} /> : null}

      {/*replace true with  open === 2*/}
      {open === 2 ? (
        <CreateSurveyModal handleModal={handleModal} handleTemplatePreview={handleTemplatePreview} />
      ) : null}
      {open === 4 ? (
        <PreviewTemplateModal
          handleClose={handleModal}
          data={previewTemplateInfo}
          handleTemplateUse={() => handleModal(3)}
        />
      ) : null}

      {open === 3 ? <SurveyDetailsModal handleOpen={handleModal} from="onlineSurvey" /> : null}
    </div>
  );
};

export default OnlineSurveyHome;
