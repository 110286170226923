import React from "react";
import Skeleton from "react-loading-skeleton";

const HomeCardSkeleton = () => {
  return (
    <div className="border-[1px] h-[250px] w-[100%] border-gray-300 rounded-lg overflow-hidden flex flex-col justify-between">
      <div className="h-[40%] ">
        <Skeleton className="w-[100%] h-[100%] " />
      </div>

      <div className="p-2 space-y-4 bg-white h-[45%]">
        <Skeleton className="w-[100%]" />
        <Skeleton className="w-[50%]" />
        <Skeleton className="w-[70%]" />
        <Skeleton className="w-[80%]" />
      </div>

      <div className="h-[15%] border-t-[1px] border-gray-300 px-4 bg-white">
        <Skeleton className="w-[100%] mt-3" />
      </div>
    </div>
  );
};

export default HomeCardSkeleton;
