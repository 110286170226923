import { IoPersonCircleSharp } from "react-icons/io5";
import Toggle from "react-toggle";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { trackEvent } from "config/mixpanel";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "config/gateway";
import { createTestCampaign } from "services/campaignService";

import dayjs from "dayjs";
import Toast from "config/toast";
import AudioPlayer from "components/AudioPlayer";
import SavedContacts from "pages/Broadcasts/Contacts/SavedContacts";

const Audience = ({ setData, data }) => {
  const [display, setDisplay] = useState(false);
  const [active, setActive] = useState(false);
  const { user } = useLoggedInUser();

  const audioData = data?.content;

  const handleChange = () => {
    setDisplay(!display);
  };

  const handleTelChange = (e) => {
    if (e.target.value.length === 10) {
      setActive(true);
    } else {
      setActive(false);
    }
    setData({
      ...data,
      testContact: e.target.value,
    });
  };

  const { mutate: testMutate } = useMutation(createTestCampaign, {
    onSuccess: () => {
      trackEvent("Test campaign success", {
        email: user?.data?.email,
        sender: data?.sender_name,
        campaignName: data?.campaign_name,
        date: dayjs().format(),
        campaignType: "robocall",
      });
      Toast.success("Test created");
      queryClient.invalidateQueries(["wsCredit"]);
      setData({
        ...data,
        testContact: "",
      });
    },
    onMutate: () => {
      Toast.success("Sending Test Campaign");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleTest = async () => {
    const test = {
      campaign_channel: "robocall",
      content: data.content,
      audioLength: 90,
      tester: { phone: "+234" + data.testContact },
    };
    trackEvent("Test campaign success", {
      email: user?.data?.email,
      campaignType: "voice",
      date: dayjs().format(),
    });

    testMutate(test);
  };

  return (
    <div className="w-[100%]">
      <div className="flex items-start justify-between">
        <div className="w-[50%]">
          <div className="flex items-center justify-between mb-5">
            <div>
              <p className="font-bold">Add your audience</p>
              <p className="text-s">Share your email to your contacts</p>
            </div>
          </div>
          <div>
            <SavedContacts data={data} setData={setData} />
          </div>
          <div className="flex space-x-4 items-center mt-10">
            <p className="text-s font-semibold">Send Test</p>
            <Toggle checked={display} onChange={handleChange} icons={false} />
          </div>
          {display && (
            <div>
              <div className="flex space-x-2 items-center my-[1em]">
                <div className="flex items-center px-4 rounded-[8px] border-[#DFE5EC] border-[1px] space-x-2 w-[80%] h-[45px]">
                  <p className="border-r pr-2">+234</p>
                  <input
                    type="tel"
                    className="outline-none h-[100%] w-full"
                    placeholder="8123456789"
                    value={data.testContact}
                    onChange={handleTelChange}
                    maxlength="10"
                  />
                </div>
                <p
                  className={`text-ss cursor-pointer rounded-lg px-3 h-[45px] flex items-center justify-center ${
                    active ? "text-primary-800 bg-primary-100 font-bold " : "text-[#737373] bg-gray-50 "
                  }`}
                  onClick={active ? handleTest : null}
                >
                  Send Test
                </p>
              </div>
              <p className="text-ss">
                You have 10 free voice message. Additional usage will be deducted from your voice balance.
              </p>
            </div>
          )}
        </div>
        <div className=" w-[45%] bg-gray-50">
          <div className="bg-white drop-shadow-md py-5 px-2 w-full flex justify-center">
            <p>Preview</p>
          </div>
          <div>
            <div className="mt-2 p-4">
              <AudioPlayer url={data?.content} className="h-[300px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Audience;
