export const serializeMessage = (message, data) => {
  const regex = /{{(.*?)}}/g;
  return message.replace(regex, (_, variable) => {
    const trimmedVariable = variable.trim();
    return data[trimmedVariable] ?? "";
  });
};

export const replacePlaceholders = (array) => {
  const replacements = {
    "{{firstname}}": "First Name",
    "{{lastname}}": "Last Name",
    "{{fullname}}": "Full Name",
    "{{email}}": "Email",
    "{{phone}}": "Phone",
  };

  return array === null ? [] : array?.map((elm) => replacements[elm] || elm);
};
