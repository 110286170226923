import React from "react";
import Questions from "./Questions";
import Style from "./Style";

const tabs = [
  { name: "Questions", id: 1 },
  { name: "Style", id: 2 },
];
const QuestionTab = ({ setStep, step, surveyQuestionsArray, setSurveyQuestionsArray, surveyQuestion, newCardRef }) => {
  const handleStep = (value) => {
    setStep(value);
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return (
          <Questions
            surveyQuestionsArray={surveyQuestionsArray}
            setSurveyQuestionsArray={setSurveyQuestionsArray}
            surveyQuestion={surveyQuestion}
            newStep={step}
            setNewStep={setStep}
            newCardRef={newCardRef}
          />
        );
      case 2:
        return <Style />;
      default:
    }
  };
  return (
    <div className="p-4">
      <div className="flex text-sm space-x-4 border-b-[1px]">
        {tabs.map((el) => {
          return (
            <div
              className={`${
                step === el.id ? "border-b-[2px] border-primary-800 text-primary-800" : ""
              }  pb-[0.5em] cursor-pointer`}
              onClick={() => handleStep(el.id)}
            >
              {el.name}
            </div>
          );
        })}
      </div>

      <div className="my-2">{displayStep()}</div>
    </div>
  );
};

export default QuestionTab;
