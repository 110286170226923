import axios from "axios";
import { getToken, getWorkspaceToken, removeToken } from "helpers/authHelper";
import { handleApiError } from "./handleApiError";

const Api = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV !== "production"
      ? `${process.env.REACT_APP_STAGING_URL}/v2/api`
      : `${process.env.REACT_APP_BACKEND_LIVE_URL}/v2/api`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

Api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const wpToken = getWorkspaceToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (wpToken) {
      config.headers["workspace"] = `${wpToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error.response) {
      if ([401].includes(error.response.status)) {
        window.location.replace("/auth/login");
        removeToken();
      }
    }
    return Promise.reject(error);
  },
);

export const uploadFile = async (value) => {
  try {
    const { data } = await Api.post("/contact/bulk-upload", value);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const uploadDocument = async (value) => {
  try {
    const { data } = await Api.post("/auth-microservice/user/document-upload", value);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const uploadImage = async (value) => {
  try {
    const { data } = await Api.post("/auth-microservice/user/profile-img", value);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const uploadWorkspaceImage = async (value) => {
  try {
    const { data } = await Api.post("/workspace-microservice/workspace/profile", value);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const uploadMedia = async (value) => {
  try {
    const { data } = await Api.post("/workspace-microservice/media/upload", value);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
