import { useMutation, useQuery } from "@tanstack/react-query";
import { BranchingIcon } from "assets/icons/survey/BranchingIcon";
import ScreeningQuesIcon from "assets/icons/survey/ScreeningQuesIcon";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Dropdown from "components/DropDown";
import FileUpload from "components/Inputs/FileUpload";
import SecondaryInput from "components/Inputs/SecondaryInput";
import PreLoader from "components/Loader/PreLoader";
import Modal from "components/Modal";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
import { LogicTypes } from "data/constants/surveyConstants";
import { surveyCategory } from "data/surveyCategory";
import dayjs from "dayjs";
import { cn } from "helpers/classHelpers";
import useLoggedInUser from "hooks/useLoggedInUser";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { MdDelete, MdOutlineCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Toggle from "react-toggle";
import { fetchBySurveyId } from "services/newSurveyService";
import { createSurvey } from "services/newSurveyService";
import { useStore } from "store";
import useBranchingStore from "store/branchingStore";
import Typography from "utils/typography";

const SurveyDetailsModal = ({ from, handleOpen, fromStep }) => {
  const { user } = useLoggedInUser();
  const [showDropNDrag, setShowDropNDrag] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { surveyTemplate, setSurveyTemplate } = useStore();
  const [logicType, setLogicType] = useState("");
  const {
    handleBranchingOptionValues,
    deleteBranchingOptions,
    handleBranchingText,
    createBranchingOptionValues,
    branchingQuestion,
    setBranchingQuestion,
  } = useBranchingStore();

  const { data } = useQuery(["fetchBySurveyId-start", id], () => fetchBySurveyId(id), {
    enabled: !!id,
  });

  const [screeningQuestionArray, setScreeningQuestionArray] = useState([
    {
      question: "",
      options: [],
    },
  ]);

  useEffect(() => {
    if (
      data?.data?.surveyDetails?.branchingQuestion !== undefined &&
      data?.data?.surveyDetails?.branchingQuestion?.questionName !== ""
    ) {
      setBranchingQuestion(data?.data?.surveyDetails?.branchingQuestion);
      setLogicType(LogicTypes.BRANCHING_QUESTION);
    } else if (
      screeningQuestionArray &&
      screeningQuestionArray.length > 1 &&
      screeningQuestionArray[0].question !== ""
    ) {
      setLogicType(LogicTypes.SCREENING_QUESTION);
    }
  }, [data?.data?.surveyDetails?.branchingQuestion, screeningQuestionArray, setBranchingQuestion]);

  const [showScreeningQuestion, setShowScreeningQuestion] = useState(false);

  const [surveyData, setSurveyData] = useState({
    surveyName: "",
    surveyCategory: from === "onlineSurvey" || from === "fieldInterview" ? "" : "Brand tracking",
    recurringInterval: "",
    description: "",
    timeOfTheDay: "",
    surveyDescription: "",
    allowScreeningQuestion: false,
    surveyInstruction: "",
    allowImage: false,
    isBranched: false,
    coverImage: "",
    ...surveyTemplate,
  });

  useEffect(() => {
    if (data?.data?.surveyDetails) {
      setSurveyData((prev) => ({
        ...prev,
        ...data?.data?.surveyDetails,
      }));

      if (data?.data?.surveyDetails?.screenQue && data?.data?.surveyDetails?.screenQue[0] !== "") {
        setShowScreeningQuestion(true);
      }

      setScreeningQuestionArray(data?.data?.surveyDetails.screenQue);
    }
  }, [data?.data?.surveyDetails, id]);

  useEffect(() => {
    if (surveyData.coverImage !== "" && !showDropNDrag) {
      setShowDropNDrag(true);
      setSurveyData({
        ...surveyData,
        allowImage: !surveyData.allowImage,
      });
    }
  }, [showDropNDrag, surveyData]);

  useEffect(() => {
    if (data?.data?.surveyDetails?.branchingQuestion?.questionName !== "") {
      // setLogicType(LogicTypes.BRANCHING_QUESTION);
    } else if (
      screeningQuestionArray &&
      screeningQuestionArray.length > 1 &&
      screeningQuestionArray[0].question !== ""
    ) {
      // setLogicType(LogicTypes.SCREENING_QUESTION);
    }
  }, [data?.data?.surveyDetails?.branchingQuestion?.questionName, screeningQuestionArray]);

  const handleDropNDrag = () => {
    setShowDropNDrag(!showDropNDrag);

    setSurveyData({
      ...surveyData,
      coverImage: "",
      allowImage: !surveyData.allowImage,
    });
  };

  /****************** START Screening questions functions *************************/

  const createQuestion = () => {
    trackEvent("add_screening", {
      email: user?.data?.email,
      date: dayjs().format(),
      numberOfScreeningQuestions: screeningQuestionArray.length,
      surveyType: "Field survey",
    });
    setScreeningQuestionArray([
      ...screeningQuestionArray,
      {
        question: "",
        options: [],
      },
    ]);
  };

  // const handleScreeningQuestion = () => {
  //   setShowScreeningQuestion(!showScreeningQuestion);
  //   setScreeningQuestionArray([
  //     {
  //       question: "",
  //       options: [],
  //     },
  //   ]);

  //   setSurveyData({
  //     ...surveyData,
  //     allowScreeningQuestion: !surveyData.allowScreeningQuestion,
  //   });
  // };

  const createValueOptions = (index) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: [
          ...updatedValues[index]?.options,
          {
            value: "",
            answer: false,
          },
        ],
      };

      return updatedValues;
    });
  };

  // const deleteBranchingOptions = (optionIndex) => {
  //   setBranchingQuestion((prevValues) => {
  //     const updatedValues = { ...prevValues };

  //     // Filter out the option at optionIndex from the options array
  //     const updatedOptions = updatedValues.options.filter((_, index) => index !== optionIndex);

  //     // Assign the filtered array back to options
  //     updatedValues.options = updatedOptions;

  //     return updatedValues;
  //   });
  // };

  const handleOptional = (index, buttonId, presentValue) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];

      updatedValues[index] = {
        ...updatedValues[index],
        options: updatedValues[index]?.options.map((option, i) =>
          i === buttonId
            ? {
                ...option,
                answer: !presentValue,
              }
            : option,
        ),
      };

      return updatedValues;
    });
  };

  const handleText = (e, index) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],

        [e.target.name]: e.target.value,
      };
      return updatedValues;
    });
  };

  const deleteValueOptions = (i, optionIndex) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];

      updatedValues[i] = {
        ...updatedValues[i],
        options: updatedValues[i]?.options?.filter((_, index) => index !== optionIndex),
      };

      return updatedValues;
    });
  };

  const handleOptionValues = (e, index, newIndex) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];
      const updatedQuestion = { ...updatedValues[index] };

      updatedQuestion.options = [
        ...updatedQuestion.options,
        (updatedQuestion.options[newIndex].value = e.target.value),
      ];

      return updatedValues;
    });
  };

  /****************** End Screening questions functions *************************/

  /*************************** *****************************/
  const handleInput = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (e, name) => {
    setSurveyData({
      ...surveyData,
      [name]: e,
    });
  };

  const deleteQuestion = (id) => {
    setScreeningQuestionArray((prevArray) => {
      const updatedArray = prevArray.filter((_, index) => index !== id);
      return updatedArray;
    });
  };

  /*************************** *****************************/

  const { mutate, isLoading: mutatingSurvey } = useMutation(createSurvey, {
    onSuccess: (data) => {
      const id = data?.data?._id;

      if (fromStep) {
        handleOpen();
        queryClient.invalidateQueries(["fetchBySurveyId"]);
      } else {
        if (from === "onlineSurvey") {
          navigate(`/research/online-survey/${id}`);
        } else if (from === "brandTracking") {
          navigate(`/research/brand-tracking/${id}`);
        } else if (from === "fieldInterview") {
          navigate(`/research/field-interview/${id}`);
        }

        queryClient.invalidateQueries(["allSurvey"]);
      }

      Toast.success("Saved!");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleSave = () => {
    const userSurveyDetails = {
      surveyName: surveyData.surveyName,
      surveyCategory:
        from === "onlineSurvey" || from === "fieldInterview" ? surveyData.surveyCategory : "Brand tracking",
      recurringProp: {
        recurringInterval: surveyData.recurringInterval,
        timeOfTheDay: surveyData.timeOfTheDay,
      },
      ...(from === "fieldInterview" ? { description: surveyData.description } : null),
      surveyDescription: surveyData.surveyDescription,
      ...(from === "fieldInterview" ? { surveyInstruction: surveyData.surveyInstruction } : null),
      allowScreeningQuestion: false,
      coverImage: surveyData.coverImage,
      screenQue: screeningQuestionArray,
      branchingQuestion,
    };

    // console.log(screeningQuestionArray, "screeningQuestionArray")

    mutate({
      surveyId: id ? id : null,
      payload: userSurveyDetails,
      surveyType: from === "fieldInterview" ? "fieldTextSurvey" : from,
    });
  };

  if (mutatingSurvey) {
    return <PreLoader />;
  }

  const disabled = surveyData.surveyName === "" || surveyData.surveyCategory === "";

  const surveyLogic = [
    {
      name: "Screening question",
      description: "Add screening questions to your survey to filter out people who do not meet your target criteria.",
      icon: ScreeningQuesIcon,
      id: LogicTypes.SCREENING_QUESTION,
    },
    {
      name: "Branching question",
      description: "Route respondents to various sections within your survey based on their responses. ",
      icon: BranchingIcon,
      id: LogicTypes.BRANCHING_QUESTION,
    },
  ];

  const handleLogicType = (value) => {
    if (logicType === value) {
      setLogicType("");
    } else {
      if (value === LogicTypes.BRANCHING_QUESTION) {
        setLogicType({
          ...logicType,
          isBranched: true,
        });
      } else {
        setLogicType({
          ...logicType,
          isBranched: false,
        });
      }
      setLogicType(value);
    }
  };

  return (
    <Modal title={<p className="text-lg font-semibold">Survey details</p>} onClose={handleOpen}>
      <div className="w-[60vw] h-[80vh] px-5 overflow-y-scroll pr-4">
        <p className="text-sm text-neutral-500">Provide your survey details and add a screening question.</p>

        <div className="flex flex-col justify-between h-[92%] mt-[1em]">
          <div className="space-y-4">
            <div className="flex justify-between items-center ">
              <div className="w-[48%]">
                <SecondaryInput
                  text="Survey Name"
                  placeholder="Use a title that captures the essence of your survey"
                  onChange={handleInput}
                  name="surveyName"
                  type="text"
                  value={surveyData.surveyName}
                />
              </div>

              {from === "onlineSurvey" || from === "fieldInterview" ? (
                <div className="w-[48%] -mt-2">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-m font-[500]">Category</p>
                  </div>
                  <div className="mt-2">
                    <Dropdown
                      tag={surveyData.surveyCategory !== "" ? surveyData.surveyCategory : "Select a category"}
                      options={surveyCategory}
                      onSelect={(el) => handleSelect(el.value, "surveyCategory")}
                      defaultValue={surveyData.surveyCategory}
                    />
                  </div>
                </div>
              ) : (
                <div className="w-[48%] ">
                  <SecondaryInput
                    text="Category"
                    placeholder="Summer Findings."
                    onChange={handleInput}
                    name="surveyCategory"
                    type="text"
                    disabled={true}
                    defaultValue="Brand tracking"
                    value="Brand tracking"
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col space-y-2">
              <Typography.SubText className="font-semibold">Description (Optional)</Typography.SubText>

              <div className="border-[1px] border-[#D0D5DD]  rounded-[5px] flex flex-col p-4 bg-[#FAFAFA] h-[116px]">
                <textarea
                  name="surveyDescription"
                  id="surveyDescription"
                  cols="30"
                  rows="3"
                  placeholder="Describe your survey"
                  className="w-[97%] mr-4 bg-transparent outline-none text-s placeholder:text-s resize-none"
                  onChange={handleInput}
                  value={surveyData.surveyDescription}
                ></textarea>
              </div>
            </div>

            {from === "fieldInterview" ? (
              <>
                <hr />

                <div className="flex flex-col space-y-2">
                  <Typography.SubText className="font-semibold">Instruction (Optional)</Typography.SubText>

                  <div className="border-[1px] border-[#D0D5DD]  rounded-[5px] flex flex-col p-4 bg-[#FAFAFA] h-[116px]">
                    <textarea
                      name="surveyInstruction"
                      id="surveyInstruction"
                      cols="30"
                      rows="3"
                      placeholder="Add instruction"
                      className="w-[97%] mr-4 bg-transparent outline-none text-s placeholder:text-s resize-none"
                      onChange={handleInput}
                      value={surveyData.surveyInstruction}
                    ></textarea>
                  </div>
                </div>
              </>
            ) : null}
            <div className="my-10">
              <div className="w-full h-[1px] bg-gray-200 rounded-md pt-1"></div>
            </div>

            <div className="">
              <div className="flex space-x-2 items-center cursor-pointer">
                <Typography.SubText className="font-semibold">Add cover image</Typography.SubText>

                <Toggle checked={showDropNDrag} onChange={handleDropNDrag} icons={false} />
              </div>

              {showDropNDrag || surveyData.coverImage !== "" ? (
                <div className="mt-2">
                  <FileUpload name="coverImage" setData={setSurveyData} data={surveyData} />
                </div>
              ) : null}
            </div>

            <div>
              <Typography.SubText className="font-semibold">Set survey logic (Optional)</Typography.SubText>

              <Typography.MicroText className="mb-3">
                Select either a screening or branching question to send respondents to based on their response. Learn
                more about logic features
              </Typography.MicroText>

              <div className="flex items-center gap-[1em]">
                {surveyLogic.map((el) => {
                  return (
                    <div
                      className={cn(
                        "border-[1px] rounded-md p-4 w-[50%] space-y-2 cursor-pointer",
                        el.id === logicType ? "bg-primary-50 border-primary-800" : "bg-gray-100 border-gray-200",
                      )}
                      onClick={() => handleLogicType(el.id)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <el.icon
                            fill1={el.id === logicType ? "#95ADFF" : "#A3A3A3"}
                            fill2={el.id === logicType ? "#1D19AF" : "#404040"}
                          />

                          <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>
                        </div>

                        <div
                          className={cn(
                            "w-[15px] h-[15px] rounded-full border-[1px] flex items-center justify-center",
                            el.id === logicType ? "border-primary-800" : "border-gray-400",
                          )}
                        >
                          {el.id === logicType && (
                            <div className={cn("w-[10px] h-[10px] rounded-full border-[1px] bg-primary-800")}></div>
                          )}
                        </div>
                      </div>

                      <Typography.MicroText className="mb-3">{el.description}</Typography.MicroText>
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              {logicType === LogicTypes.SCREENING_QUESTION ||
              (screeningQuestionArray && screeningQuestionArray.length > 1) ? (
                <div className="w-[100%]">
                  <div className="flex w-[100%] gap-6 items-start">
                    {screeningQuestionArray?.map((_, index) => {
                      return (
                        <div className="border-[1px] p-3 rounded-md  w-[50%]" key={`screeningQuestionArray-${index}`}>
                          <SecondaryInput
                            placeholder="Type your screener question here"
                            value={screeningQuestionArray[index].question}
                            onChange={(e) => handleText(e, index)}
                            name="question"
                          />

                          <p className="text-xs text-gray-500">You can add a maximum of 5 options</p>

                          <div className="w-[100%]">
                            {screeningQuestionArray[index]?.options.map((_, i) => {
                              return (
                                <div className="flex items-center justify-between">
                                  <div className="flex space-x-3 my-3 w-[50%]">
                                    <p className="text-s">{i + 1}</p>
                                    <input
                                      type="text"
                                      placeholder="Add label Optional"
                                      className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                                      value={screeningQuestionArray[index]?.options[i]?.value}
                                      name="value"
                                      onChange={(e) => handleOptionValues(e, index, i)}
                                    />
                                    <MdOutlineCancel
                                      className="text-[24px] text-grey-1 cursor-pointer"
                                      onClick={() => deleteValueOptions(index, i)}
                                    />
                                  </div>

                                  <div className="flex items-center space-x-3 ">
                                    <p className="text-xs">Set correct answer</p>
                                    <Toggle
                                      checked={screeningQuestionArray[index]?.options[i]?.answer}
                                      icons={false}
                                      onChange={() =>
                                        handleOptional(index, i, screeningQuestionArray[index]?.options[i]?.answer)
                                      }
                                      className="toggle"
                                    />
                                  </div>
                                </div>
                              );
                            })}

                            <div className="flex justify-between items-center">
                              {screeningQuestionArray[index]?.options.length >= 5 ? (
                                <p></p>
                              ) : (
                                <div
                                  className="flex space-x-2 items-center cursor-pointer my-[1em]"
                                  onClick={() => createValueOptions(index)}
                                >
                                  <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
                                  <p className="text-ss text-primary-800">Add Option</p>
                                </div>
                              )}
                              {screeningQuestionArray.length === 1 ? null : (
                                <MdDelete
                                  className="text-[24px] text-grey-1 cursor-pointer"
                                  onClick={() => deleteQuestion(index)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {screeningQuestionArray.length >= 2 ? null : (
                      <div className="space-y-2">
                        <div
                          className="flex space-x-2 text-gray-900 bg-gray-100 items-center cursor-pointer h-[35px] border-[1px] p-4 mt-4 rounded-md w-[150px] font-bold justify-center"
                          onClick={createQuestion}
                        >
                          <AiOutlinePlus />
                          <p className="text-ss">Add question</p>
                        </div>
                        <p className="text-xs text-gray-500">You can add only 2 screening questions.</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            {logicType === LogicTypes.BRANCHING_QUESTION && (
              <div>
                <div className="border-[1px] p-3 rounded-md  w-[100%]">
                  <SecondaryInput
                    placeholder="Type your screener question here"
                    value={branchingQuestion.questionName}
                    onChange={(e) => handleBranchingText(e.target.value)}
                    name="question"
                  />

                  <p className="text-xs text-gray-500">
                    You can add a maximum of 3 options, your options determine the number of branches within your
                    survey.
                  </p>

                  <div className="w-[100%]">
                    {branchingQuestion?.options.map((_, i) => {
                      return (
                        <div className="flex items-center justify-between">
                          <div className="flex space-x-3 my-3 w-[80%]">
                            <p className="text-s">{i + 1}</p>
                            <input
                              type="text"
                              placeholder="Add label Optional"
                              className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                              value={branchingQuestion.options[i].option}
                              name="value"
                              onChange={(e) => handleBranchingOptionValues(i, e.target.value)}
                            />
                            <MdOutlineCancel
                              className="text-[24px] text-grey-1 cursor-pointer"
                              onClick={() => deleteBranchingOptions(i)}
                            />
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex justify-between items-center">
                      {branchingQuestion.options.length >= 3 ? (
                        <p></p>
                      ) : (
                        <div
                          className="flex space-x-2 items-center cursor-pointer my-[1em]"
                          onClick={createBranchingOptionValues}
                        >
                          <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
                          <p className="text-ss text-primary-800">Add Option</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="my-10">
              <div className="w-full h-[1px] bg-gray-200 rounded-md pt-1"></div>
            </div> */}
            {/* {from === "fieldInterview" ? null : (
              <div className="">
                <Typography.SubText className="font-semibold">Frequency</Typography.SubText>

                <Typography.SubText className="">
                  Share your survey once or over a period of time to gather more insights from your audience.
                </Typography.SubText>

                <div className="space-x-4 flex items-center my-2">
                  {frequencyData.map((el) => (
                    <div
                      className="flex items-center space-x-2 cursor-pointer"
                      key={el}
                      onClick={() => setFrequecy(el)}
                    >
                      <div
                        className={`w-[15px] h-[15px] rounded-full border-[1px] flex items-center justify-center ${
                          frequency === el ? "border-primary-800" : "border-gray-600 "
                        }  `}
                      >
                        {frequency === el ? <div className="w-[8px] h-[8px] rounded-full bg-primary-800"></div> : null}
                      </div>
                      <Typography.SubText>{el}</Typography.SubText>
                    </div>
                  ))}
                </div>
                {frequency === "Recurring survey" ? (
                  <div className="flex justify-between items-center ">
                    <div className="w-[48%] -mt-2">
                      <div className="flex space-x-2 items-center ">
                        <Typography.SubText className="font-semibold">Repeat survey every</Typography.SubText>
                      </div>
                      <div className="mt-2">
                        <Dropdown
                          tag={surveyData.recurringInterval !== "" ? surveyData.recurringInterval : "Choose an option"}
                          options={surveyRecurrence}
                          onSelect={(el) => handleSelect(el.value, "recurringInterval")}
                          defaultValue={surveyData.recurringInterval}
                        />
                      </div>
                    </div>

                    <div className="w-[48%]">
                      <SecondaryInput
                        text="Time"
                        placeholder="Set time to send"
                        onChange={handleInput}
                        type="time"
                        name="timeOfTheDay"
                        value={surveyData.timeOfTheDay}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            )} */}
          </div>

          <div className="flex items-center justify-between border-t-[1px] h-[50px] mt-4 pt-4">
            <GreyButton text="Cancel" className="w-[120px]" onClick={handleOpen} />

            <BlueButton text="Save" className="w-[120px]" onClick={handleSave} disabled={disabled} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SurveyDetailsModal;
