import React from "react";
import { BsSearch } from "react-icons/bs";

const SearchInput = ({ placeholder }) => {
  return (
    <div className="bg-[#F2F1F3] w-[366px] h-[40px] rounded-md flex items-center px-4 space-x-2  border-[1px] border-[#DFE5EC]">
      <BsSearch className="text-[#9CA3AF]" />
      <input
        type="text"
        className="placeholder:text-[#9CA3AF] placeholder:text-s placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
        placeholder={placeholder}
        // onChange={handleChange}
      />
    </div>
  );
};

export default SearchInput;
