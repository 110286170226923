import React from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import Typography from "utils/typography";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import detractors from "assets/images/detractors.png";

const SingleOverviewCard = ({ value, title, loading, img, type }) => {
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const isNPS = locationArray.includes("nps");

  console.log(isNPS);
  const case1 = isNPS ? "Promoters" : "Satisfied";
  const case2 = isNPS ? "Passives" : "Neutral";
  const case3 = isNPS ? "Detractors" : "Unsatisfied";
  const iconType = (type) => {
    switch (type) {
      case case1:
        return promoters;
      case case2:
        return passives;
      case case3:
        return detractors;
      default:
        return totalScore;
    }
  };
  return (
    <div className="border-[1px] w-full h-[110px] p-4 rounded-lg bg-white hover:border-primary-800 hover:shadow-md cursor-pointer transition-all ease-in-out duration-300 flex items-center">
      <div className="flex items-start space-x-4">
        <div>
          <img src={isNPS === true ? iconType(type) : img} alt="icon" className="w-[30px]" />
        </div>

        <div className="w-[80%] space-y-2">
          {loading ? <Skeleton count={1} className="w-[40%]" /> : <Typography.H3>{value}</Typography.H3>}

          {loading ? <Skeleton className="w-[60%]" /> : <Typography.SubText>{title}</Typography.SubText>}
        </div>
      </div>
    </div>
  );
};

export default SingleOverviewCard;
