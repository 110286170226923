import CalenderMonth from "./components/CalenderMonth";
import dayjs from "dayjs";
import React, { useState } from "react";
import WeeklyView from "./components/WeeklyView";
import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";
import { useQuery } from "@tanstack/react-query";
import { getAllCampaignsCalendar, getAllHolidayCalendar } from "services/campaignService";
import { getCalenderSurvey } from "services/surveyService";
import { getDateIndex } from "lib/getDateIndex";

const CalenderIndex = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [view, setView] = useState("month");
  // const { monthIndex } = useStoreCalender();
  // const { setCurrentYear, setCurrentMonth } = useStore();

  // // const currentYear = useStore((state) => state.currentYear);
  // // const currentMonth = useStore((state) => state.currentMonth);

  const currentMonth = currentDate.format("MMMM");

  const currentYear = currentDate.format("YYYY");

  console.log(currentMonth, currentYear, "currentYear");

  const { data: campData } = useQuery(["allCampaignsCalendar", currentYear, currentMonth], () =>
    getAllCampaignsCalendar(currentYear, getDateIndex(currentMonth)),
  );

  const { data: surveyData } = useQuery(["allSurveyCalendar", currentYear, currentMonth], () =>
    getCalenderSurvey(currentYear, getDateIndex(currentMonth)),
  );
  const { data: holidayData } = useQuery(["allHolidayCalendar"], () => getAllHolidayCalendar());

  console.log(campData, surveyData, holidayData);

  const toggleView = (value) => {
    setView(value);
  };

  const goToPrevious = () => {
    if (view === "month") {
      setCurrentDate(currentDate.subtract(1, "month"));
    } else {
      setCurrentDate(currentDate.subtract(1, "week"));
    }
  };

  const goToNext = () => {
    if (view === "month") {
      setCurrentDate(currentDate.add(1, "month"));
    } else {
      setCurrentDate(currentDate.add(1, "week"));
    }
  };

  const getMonthAndYear = (date) => {
    return date.format("MMMM YYYY");
  };

  const getWeekRange = (date) => {
    const startOfWeek = date.startOf("week").format("D MMMM");
    const endOfWeek = date.endOf("week").format("D MMMM, YYYY");
    return `${startOfWeek} - ${endOfWeek}`;
  };

  // const sortHoliday = useCallback(() => {
  //   const evt = holidayData?.filter((elm) => dayjs(elm.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"));
  //   setEvent(evt);
  // }, [day, holidayData]);

  return (
    <div className="flex flex-col items-center w-[100%] bg-white p-4 rounded-md border">
      <div className="flex items-center justify-between w-[100%] my-6">
        <div className="flex items-center justify-between space-x-5">
          <div className="flex items-center space-x-4">
            <div
              onClick={goToPrevious}
              className="border-[1px] rounded hover:bg-primary-50 h-[25px] w-[25px] flex items-center justify-center text-lg cursor-pointer"
            >
              <HiMiniChevronLeft />
            </div>
            <div
              onClick={goToNext}
              className="border-[1px] rounded hover:bg-primary-50 h-[25px] w-[25px] flex items-center justify-center text-lg cursor-pointer"
            >
              <HiMiniChevronRight />
            </div>
          </div>

          <div className="text-lg font-semibold ">
            {view === "month" ? (
              <h2 className="">{getMonthAndYear(currentDate)}</h2>
            ) : (
              <h2 className="">{getWeekRange(currentDate)}</h2>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between border-[1px] rounded-md text-sm border-gray-300 text-gray-600 overflow-hidden">
          {/*<p*/}
          {/*  className={`border-r-[1px] p-2 w-[70px] flex items-center justify-center border-gray-300 cursor-pointer ${*/}
          {/*    view === "day" ? "bg-primary-200 text-primary-800 border-[1px] border-primary-800" : ""*/}
          {/*  }`}*/}
          {/*  onClick={() => toggleView("day")}*/}
          {/*>*/}
          {/*  Day*/}
          {/*</p>*/}
          <p
            className={`border-r-[1px] p-2 w-[70px] flex items-center justify-center border-gray-300 cursor-pointer ${
              view === "week" ? "bg-primary-100 text-primary-800 border-[1px] border-primary-800" : ""
            }`}
            onClick={() => toggleView("week")}
          >
            Week
          </p>
          <p
            className={`p-2 w-[70px] flex items-center justify-center cursor-pointer ${
              view === "month" ? "bg-primary-100 text-primary-800 border-[1px] border-primary-800" : ""
            }`}
            onClick={() => toggleView("month")}
          >
            Month
          </p>
        </div>
      </div>

      <div className="w-[100%]">
        {view === "month" ? (
          <CalenderMonth currentDate={currentDate} holidayData={holidayData} surveyData={surveyData} />
        ) : (
          <WeeklyView currentDate={currentDate} />
        )}
      </div>

      {/* <Calendar/> */}
    </div>
  );
};

export default CalenderIndex;
