import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import React from "react";

const CSVModal = ({ handleModal, fileUrl, data }) => {
  const downloadFile = async () => {
    const link = document.createElement("a");
    link.href = fileUrl;

    // Extract the filename from the URL

    link.download = fileUrl.split("/").pop();

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  };

  return (
    <div>
      <Modal title={<p className="text-lg font-semibold">Download CSV</p>} onClose={handleModal}>
        <div className="w-[400px] px-5">
          <p className="w-[80%]  mx-auto text-center py-[2em]">
            Your Report is ready , click the button below to download.
          </p>

          <div className="w-[100%] flex items-center justify-center">
            {/* <a href="#link" download={link}> */}
            {fileUrl === undefined ? <p>Loading</p> : <BlueButton text="Download csv" onClick={downloadFile} />}

            {/* </a> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CSVModal;
