import React from "react";

export const Tag = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5127 9.93227C15.9772 9.4678 16.2155 8.82362 16.1651 8.16872L15.8621 4.22912C15.7769 3.12261 14.8977 2.24343 13.7912 2.15831L9.85164 1.85527C9.19674 1.80489 8.55257 2.0432 8.08809 2.50765L2.45588 8.13985C1.57721 9.01855 1.57721 10.4432 2.45588 11.3219L6.69852 15.5645C7.57719 16.4432 9.00182 16.4432 9.88052 15.5645L15.5127 9.93227Z"
      fill={fill1}
    />
    <path
      d="M10.9389 5.48868C10.4995 5.92802 10.4995 6.64034 10.9389 7.07967C11.3782 7.51901 12.0905 7.51901 12.5299 7.07967C12.9692 6.64034 12.9692 5.92802 12.5299 5.48868C12.0905 5.04935 11.3782 5.04935 10.9389 5.48868Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.63373 10.2618L7.75504 12.3831C8.04791 12.676 8.52281 12.676 8.81569 12.3831C9.10864 12.0903 9.10864 11.6154 8.81569 11.3225L6.69439 9.2011C6.4015 8.90823 5.92663 8.90823 5.63374 9.2011C5.34084 9.49405 5.34084 9.96888 5.63373 10.2618Z"
      fill={fill2}
    />
  </svg>
);
