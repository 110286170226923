export function removeEmptyKeysAndValues(obj) {
  const newObj = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const nestedObj = removeEmptyKeysAndValues(obj[key]);
        if (Object.keys(nestedObj).length !== 0) {
          newObj[key] = nestedObj;
        }
      } else if (Array.isArray(obj[key])) {
        const filteredArray = obj[key].map((item) => {
          if (typeof item === "object" && !Array.isArray(item)) {
            return removeEmptyKeysAndValues(item);
          }
          return item;
        });
        if (filteredArray.length !== 0) {
          newObj[key] = filteredArray;
        }
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  return newObj;
}
