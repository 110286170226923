import React, { useState } from "react";
import PremiumPlan from "./Premium";
import PayAsYouGo from "./Pay-as-you-go/PayAsYouGo";
import ToggleButton from "components/Button/ToogleButton";
import { useQuery } from "@tanstack/react-query";
import { getRates } from "services/rates";

const tabs = [
  { name: "Premium plan", value: 0 },
  { name: "Pay as you go", value: 1 },
];

const ResearchHub = () => {
  const [step, setStep] = useState(0);
  const [toggleValue, setToggleValue] = useState(false);

  const rates = useQuery(["rates"], getRates);
  const conversionRate = rates?.data?.data[0].currencyRates[7].userRate;

  const displayStep = () => {
    switch (step) {
      case 0:
        return <PremiumPlan conversionRate={conversionRate} toggleValue={toggleValue} />;
      case 1:
        return <PayAsYouGo conversionRate={conversionRate} toggleValue={toggleValue} setStep={setStep} />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <p>Gather valuable insights and reach your target audience with ease.</p>
        <div className="pt-4 flex justify-center items-center space-x-3">
          <p className={`${!toggleValue && "font-bold"}`}>USD</p>
          <ToggleButton toggleValue={toggleValue} onChange={handleToggleChange} />
          <p className={`${toggleValue && "font-bold"}`}>NGN</p>
        </div>
      </div>
      <div className="flex justify-center border-b mt-10">
        <div className="flex items-center space-x-5">
          {tabs.map((el, i) => (
            <div
              className={`flex item-center border rounded-t-md border-b-0 cursor-pointer px-5 py-2 ${
                step === el.value ? "border-blue-800 text-blue-800 bg-white" : ""
              }`}
              onClick={() => handleTabChange(el.value)}
              key={i}
            >
              {el.name}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5">{displayStep()}</div>
    </div>
  );
};

export default ResearchHub;
