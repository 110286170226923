import Modal from "components/Modal";
import { useState } from "react";
import Email from "./EmailIDModal";
import SMS from "./SmsIDModal";
import { useStore } from "store";

const tabs = [
  { name: "Email", value: 0 },
  { name: "SMS", value: 1 },
];

const SenderIDModal = ({ toggleModal }) => {
  const [step, setStep] = useState(0);
  const { onBoarding } = useStore();

  const displayStep = () => {
    switch (step) {
      case 0:
        return <Email toggleModal={toggleModal} />;
      case 1:
        return <SMS toggleModal={toggleModal} />;
      default:
    }
  };

  const handleTabChange = (e) => {
    if (e === 1 && !onBoarding?.compliance) {
      toggleModal(2);
    } else {
      setStep(e);
    }
  };

  return (
    <Modal title="New sender ID " onClose={toggleModal}>
      <div className="w-[450px] h-[330px] px-5">
        <div className="flex space-x-4 mt-2">
          {tabs.map((el) => {
            return (
              <p
                className={` px-2 cursor-pointer tracking-[-0.01em] h-[34px] w-[70px] flex items-center justify-center ${
                  step === el.value ? " text-primary-800 font-[500] bg-primary-50 rounded-[6px] " : "text-[#6C6685]"
                } `}
                onClick={() => handleTabChange(el.value)}
                key={el.value}
              >
                {el.name}
              </p>
            );
          })}
        </div>

        <div>{displayStep()}</div>
      </div>
    </Modal>
  );
};

export default SenderIDModal;
