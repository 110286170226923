import React from "react";

export const Upload = ({ fill }) => (
  <svg width="268" height="127" viewBox="0 0 268 127" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6595_446375)">
      <path d="M0 4C0 1.79086 1.79086 0 4 0H264C266.209 0 268 1.79086 268 4V127H0V4Z" fill={fill} />
      <g opacity="0.15">
        <path
          d="M8.33509 -10.1478C15.5155 -8.28917 19.9562 -1.1774 24.8887 4.36636C29.6744 9.74527 35.1338 15.0352 36.9434 22.0063C38.7263 28.8743 36.2184 35.9891 34.9081 42.9629C33.7069 49.3563 31.5952 55.3322 29.5234 61.4983C27.1679 68.5087 27.0244 76.819 21.7811 82.0307C16.5489 87.2312 8.3851 87.7374 1.25014 89.6006C-5.90054 91.4679 -13.2196 94.7729 -20.4057 93.0473C-27.5669 91.3276 -32.2769 84.722 -38.0618 80.1605C-43.475 75.892 -49.8888 72.6725 -53.6086 66.8661C-57.3322 61.0535 -57.6494 53.8856 -59.2036 47.1583C-60.8597 39.99 -64.7516 32.857 -63.123 25.6823C-61.4921 18.4971 -55.4784 13.2222 -50.3095 7.97557C-45.3912 2.98317 -40.1298 -1.92038 -33.5527 -4.33325C-27.1746 -6.67314 -20.1617 -4.61896 -13.433 -5.55298C-6.00653 -6.58387 1.07635 -12.0267 8.33509 -10.1478Z"
          fill="#7392FA"
          fill-opacity="0.27"
        />
        <path
          d="M0.848274 -32.6087C8.02872 -30.7501 12.4694 -23.6383 17.4019 -18.0946C22.1876 -12.7157 27.647 -7.4257 29.4566 -0.454594C31.2395 6.41333 28.7316 13.5282 27.4213 20.5019C26.2201 26.8953 24.1084 32.8713 22.0366 39.0374C19.681 46.0477 19.5376 54.3581 14.2943 59.5698C9.0621 64.7703 0.898285 65.2765 -6.23667 67.1397C-13.3874 69.007 -20.7064 72.312 -27.8925 70.5863C-35.0538 68.8667 -39.7637 62.2611 -45.5486 57.6996C-50.9619 53.4311 -57.3757 50.2116 -61.0954 44.4051C-64.819 38.5926 -65.1362 31.4246 -66.6904 24.6974C-68.3465 17.529 -72.2384 10.3961 -70.6098 3.22139C-68.9789 -3.96382 -62.9652 -9.23872 -57.7964 -14.4854C-52.878 -19.4778 -47.6166 -24.3813 -41.0395 -26.7942C-34.6614 -29.1341 -27.6485 -27.0799 -20.9198 -28.0139C-13.4933 -29.0448 -6.41047 -34.4876 0.848274 -32.6087Z"
          fill="#88AAFC"
          fill-opacity="0.2"
        />
      </g>
      <g opacity="0.15">
        <path
          d="M281.335 93.8522C288.516 95.7108 292.956 102.823 297.889 108.366C302.674 113.745 308.134 119.035 309.943 126.006C311.726 132.874 309.218 139.989 307.908 146.963C306.707 153.356 304.595 159.332 302.523 165.498C300.168 172.509 300.024 180.819 294.781 186.031C289.549 191.231 281.385 191.737 274.25 193.601C267.099 195.468 259.78 198.773 252.594 197.047C245.433 195.328 240.723 188.722 234.938 184.161C229.525 179.892 223.111 176.673 219.391 170.866C215.668 165.054 215.351 157.886 213.796 151.158C212.14 143.99 208.248 136.857 209.877 129.682C211.508 122.497 217.522 117.222 222.69 111.976C227.609 106.983 232.87 102.08 239.447 99.6668C245.825 97.3269 252.838 99.381 259.567 98.447C266.993 97.4161 274.076 91.9733 281.335 93.8522Z"
          fill="#7392FA"
          fill-opacity="0.27"
        />
        <path
          d="M273.848 71.3913C281.029 73.2499 285.469 80.3617 290.402 85.9054C295.188 91.2843 300.647 96.5743 302.457 103.545C304.24 110.413 301.732 117.528 300.421 124.502C299.22 130.895 297.108 136.871 295.037 143.037C292.681 150.048 292.538 158.358 287.294 163.57C282.062 168.77 273.898 169.276 266.763 171.14C259.613 173.007 252.294 176.312 245.107 174.586C237.946 172.867 233.236 166.261 227.451 161.7C222.038 157.431 215.624 154.212 211.905 148.405C208.181 142.593 207.864 135.425 206.31 128.697C204.654 121.529 200.762 114.396 202.39 107.221C204.021 100.036 210.035 94.7613 215.204 89.5146C220.122 84.5222 225.383 79.6187 231.96 77.2058C238.339 74.8659 245.352 76.9201 252.08 75.9861C259.507 74.9552 266.59 69.5124 273.848 71.3913Z"
          fill="#88AAFC"
          fill-opacity="0.2"
        />
      </g>
      <path
        d="M190 20H78C71.3726 20 66 25.3726 66 32V95C66 101.627 71.3726 107 78 107H190C196.627 107 202 101.627 202 95V32C202 25.3726 196.627 20 190 20Z"
        fill="white"
      />
      <path
        d="M103 64.7439C103 66.7352 103.752 68.653 105.107 70.1124C106.462 71.5719 108.318 72.465 110.304 72.6125H154.988C157.331 72.5514 159.563 71.6062 161.238 69.9666C162.912 68.327 163.904 66.1147 164.014 63.774C164.124 61.4332 163.344 59.1376 161.831 57.3481C160.318 55.5587 158.184 54.408 155.858 54.1274C155.742 50.5028 154.369 47.0315 151.974 44.3081C149.579 41.5848 146.312 39.7791 142.732 39.2003C139.152 38.6216 135.482 39.3059 132.352 41.136C129.221 42.966 126.824 45.828 125.572 49.2313C124.289 48.5769 122.87 48.2345 121.43 48.2321C119.07 48.2314 116.798 49.1308 115.078 50.7469C113.358 52.3631 112.319 54.5744 112.173 56.9301C111.748 56.8595 111.319 56.8244 110.889 56.8252C109.85 56.8252 108.822 57.0302 107.863 57.4285C106.904 57.8269 106.033 58.4106 105.3 59.1463C104.567 59.882 103.987 60.7552 103.592 61.7158C103.197 62.6763 102.996 63.7054 103 64.7439Z"
        fill="#6B7FFF"
      />
      <path d="M138.427 61.1517H128.834V72.6325H138.427V61.1517Z" fill="white" />
      <path d="M125.832 62.8354H141.429L133.631 53.6777L125.832 62.8354Z" fill="white" />
      <path d="M159.685 84.3881H107.577V88.225H159.685V84.3881Z" fill="#EBEBEB" />
      <path d="M109.495 84.8777H108.061V87.7354H109.495V84.8777Z" fill="#6B7FFF" />
      <path d="M111.324 84.8777H109.89V87.7354H111.324V84.8777Z" fill="#6B7FFF" />
      <path d="M113.152 84.8777H111.718V87.7354H113.152V84.8777Z" fill="#6B7FFF" />
      <path d="M114.98 84.8777H113.547V87.7354H114.98V84.8777Z" fill="#6B7FFF" />
      <path d="M116.804 84.8777H115.37V87.7354H116.804V84.8777Z" fill="#6B7FFF" />
      <path d="M118.633 84.8777H117.199V87.7354H118.633V84.8777Z" fill="#6B7FFF" />
      <path d="M120.461 84.8777H119.027V87.7354H120.461V84.8777Z" fill="#6B7FFF" />
      <path d="M122.29 84.8777H120.856V87.7354H122.29V84.8777Z" fill="#6B7FFF" />
      <path d="M124.118 84.8777H122.684V87.7354H124.118V84.8777Z" fill="#6B7FFF" />
      <path d="M125.942 84.8777H124.508V87.7354H125.942V84.8777Z" fill="#6B7FFF" />
      <path d="M127.77 84.8777H126.336V87.7354H127.77V84.8777Z" fill="#6B7FFF" />
      <path d="M129.599 84.8777H128.165V87.7354H129.599V84.8777Z" fill="#6B7FFF" />
      <path d="M131.427 84.8777H129.993V87.7354H131.427V84.8777Z" fill="#6B7FFF" />
      <path d="M133.256 84.8777H131.822V87.7354H133.256V84.8777Z" fill="#6B7FFF" />
      <path d="M135.079 84.8777H133.646V87.7354H135.079V84.8777Z" fill="#6B7FFF" />
      <path
        d="M127.261 81.7401C127.142 81.7408 127.024 81.7178 126.914 81.6726C126.804 81.6274 126.704 81.5608 126.62 81.4767C126.536 81.3926 126.469 81.2927 126.424 81.1827C126.379 81.0727 126.356 80.9548 126.357 80.8358V79.7068C126.356 79.684 126.36 79.6614 126.368 79.6402C126.376 79.619 126.389 79.5997 126.404 79.5834C126.42 79.5671 126.439 79.5541 126.46 79.5453C126.481 79.5364 126.504 79.5319 126.526 79.5319C126.573 79.5319 126.618 79.5501 126.652 79.5828C126.685 79.6154 126.705 79.6599 126.706 79.7068V80.8209C126.699 80.8984 126.708 80.9767 126.732 81.0506C126.757 81.1246 126.797 81.1925 126.849 81.2502C126.902 81.3078 126.966 81.3539 127.037 81.3854C127.108 81.4169 127.185 81.4332 127.263 81.4332C127.341 81.4332 127.418 81.4169 127.49 81.3854C127.561 81.3539 127.625 81.3078 127.677 81.2502C127.73 81.1925 127.769 81.1246 127.794 81.0506C127.819 80.9767 127.828 80.8984 127.82 80.8209V79.7068C127.816 79.6817 127.817 79.6559 127.824 79.6314C127.831 79.6068 127.843 79.584 127.859 79.5645C127.875 79.5451 127.896 79.5295 127.919 79.5187C127.942 79.508 127.967 79.5024 127.993 79.5024C128.018 79.5024 128.043 79.508 128.066 79.5187C128.089 79.5295 128.11 79.5451 128.126 79.5645C128.143 79.584 128.155 79.6068 128.161 79.6314C128.168 79.6559 128.169 79.6817 128.165 79.7068V80.8209C128.168 80.941 128.146 81.0606 128.102 81.1723C128.058 81.2841 127.991 81.3858 127.907 81.4715C127.823 81.5572 127.722 81.6251 127.611 81.6713C127.5 81.7174 127.381 81.7408 127.261 81.7401Z"
        fill="#263238"
      />
      <path
        d="M129.634 80.8908H129.249V81.5703C129.249 81.6167 129.231 81.6611 129.198 81.6939C129.165 81.7267 129.121 81.7451 129.074 81.7451C129.028 81.7451 128.983 81.7267 128.951 81.6939C128.918 81.6611 128.899 81.6167 128.899 81.5703V79.7068C128.899 79.6604 128.918 79.6159 128.951 79.5831C128.983 79.5503 129.028 79.5319 129.074 79.5319H129.634C129.727 79.5239 129.821 79.5353 129.909 79.5654C129.998 79.5955 130.079 79.6437 130.148 79.7069C130.217 79.7701 130.272 79.8469 130.309 79.9324C130.347 80.018 130.366 80.1104 130.366 80.2039C130.366 80.2973 130.347 80.3898 130.309 80.4753C130.272 80.5609 130.217 80.6377 130.148 80.7009C130.079 80.7641 129.998 80.8122 129.909 80.8424C129.821 80.8725 129.727 80.8839 129.634 80.8758V80.8908ZM129.249 80.5411H129.634C129.68 80.5468 129.726 80.5427 129.77 80.5291C129.814 80.5156 129.854 80.4928 129.889 80.4623C129.923 80.4318 129.951 80.3944 129.97 80.3524C129.989 80.3104 129.999 80.2649 129.999 80.2189C129.999 80.1728 129.989 80.1273 129.97 80.0854C129.951 80.0434 129.923 80.0059 129.889 79.9754C129.854 79.945 129.814 79.9222 129.77 79.9086C129.726 79.895 129.68 79.8909 129.634 79.8966H129.249V80.5411Z"
        fill="#263238"
      />
      <path
        d="M130.733 81.5703V79.7068C130.729 79.6817 130.73 79.6559 130.737 79.6314C130.743 79.6068 130.755 79.584 130.772 79.5645C130.788 79.5451 130.809 79.5295 130.832 79.5187C130.855 79.508 130.88 79.5024 130.905 79.5024C130.931 79.5024 130.956 79.508 130.979 79.5187C131.002 79.5295 131.023 79.5451 131.039 79.5645C131.055 79.584 131.067 79.6068 131.074 79.6314C131.081 79.6559 131.082 79.6817 131.078 79.7068V81.3804H131.927C131.973 81.3804 132.018 81.3988 132.051 81.4316C132.083 81.4644 132.102 81.5089 132.102 81.5553C132.101 81.6008 132.082 81.644 132.049 81.6757C132.016 81.7074 131.973 81.7251 131.927 81.7251H130.903C130.86 81.7253 130.819 81.7095 130.788 81.6808C130.756 81.6521 130.737 81.6127 130.733 81.5703Z"
        fill="#263238"
      />
      <path
        d="M134.1 79.8217C134.305 80.0336 134.419 80.3165 134.42 80.6111C134.419 80.9071 134.305 81.1914 134.1 81.4054C134.006 81.5111 133.891 81.5956 133.761 81.6533C133.632 81.711 133.492 81.7406 133.351 81.7402C133.207 81.7399 133.065 81.7101 132.934 81.6525C132.802 81.5949 132.684 81.5108 132.586 81.4054C132.385 81.1904 132.274 80.9059 132.277 80.6111C132.273 80.3177 132.385 80.0346 132.586 79.8217C132.683 79.7154 132.801 79.6306 132.933 79.573C133.065 79.5153 133.207 79.486 133.351 79.487C133.492 79.4865 133.632 79.5161 133.761 79.5738C133.891 79.6315 134.006 79.716 134.1 79.8217ZM134.06 80.6111C134.06 80.4099 133.985 80.2161 133.85 80.0665C133.788 79.9962 133.711 79.9398 133.624 79.9011C133.538 79.8623 133.445 79.8421 133.351 79.8417C133.256 79.8415 133.163 79.8614 133.077 79.9002C132.991 79.939 132.914 79.9957 132.851 80.0665C132.711 80.2129 132.634 80.4084 132.636 80.6111C132.634 80.7115 132.652 80.8114 132.689 80.9049C132.726 80.9984 132.781 81.0836 132.851 81.1556C132.913 81.2271 132.99 81.2846 133.076 81.3242C133.162 81.3638 133.256 81.3847 133.351 81.3854C133.446 81.3841 133.539 81.3629 133.625 81.3233C133.711 81.2838 133.788 81.2266 133.85 81.1556C133.986 81.007 134.061 80.8125 134.06 80.6111Z"
        fill="#263238"
      />
      <path
        d="M136.029 81.2056H135.269L135.115 81.6102C135.102 81.6423 135.08 81.6698 135.051 81.6894C135.023 81.709 134.989 81.7197 134.955 81.7202H134.895C134.873 81.7124 134.853 81.7003 134.836 81.6848C134.819 81.6692 134.805 81.6505 134.795 81.6296C134.786 81.6087 134.78 81.5861 134.779 81.563C134.778 81.54 134.782 81.517 134.79 81.4953L135.479 79.6518C135.493 79.6182 135.516 79.5895 135.547 79.5698C135.577 79.5501 135.613 79.5404 135.649 79.5419C135.685 79.5407 135.72 79.5506 135.749 79.5703C135.779 79.5901 135.801 79.6186 135.814 79.6518L136.508 81.4953C136.523 81.5391 136.52 81.5869 136.501 81.6287C136.481 81.6706 136.446 81.7033 136.403 81.7202H136.344C136.309 81.7211 136.274 81.711 136.246 81.6912C136.217 81.6715 136.195 81.6431 136.184 81.6102L136.029 81.2056ZM135.904 80.8608L135.629 80.2064L135.374 80.8808L135.904 80.8608Z"
        fill="#263238"
      />
      <path
        d="M136.938 81.5703V79.7068C136.937 79.6841 136.941 79.6615 136.949 79.6403C136.958 79.6191 136.97 79.5998 136.986 79.5835C137.002 79.5672 137.021 79.5542 137.042 79.5454C137.062 79.5365 137.085 79.532 137.108 79.532H137.477C137.755 79.5508 138.016 79.6745 138.206 79.8781C138.397 80.0816 138.502 80.3499 138.502 80.6286C138.502 80.9073 138.397 81.1756 138.206 81.3792C138.016 81.5827 137.755 81.7064 137.477 81.7252H137.128C137.105 81.728 137.082 81.7261 137.06 81.7198C137.038 81.7134 137.017 81.7027 137 81.6882C136.982 81.6738 136.967 81.6559 136.957 81.6356C136.946 81.6154 136.94 81.5932 136.938 81.5703ZM137.283 81.3955H137.477C137.576 81.3948 137.673 81.3748 137.764 81.3365C137.854 81.2983 137.937 81.2425 138.006 81.1725C138.075 81.1024 138.13 81.0194 138.167 80.9283C138.204 80.8371 138.222 80.7395 138.222 80.6411C138.222 80.4437 138.143 80.2543 138.004 80.1147C137.864 79.9751 137.675 79.8967 137.477 79.8967H137.283V81.3955Z"
        fill="#263238"
      />
      <path
        d="M139.226 81.2955C139.273 81.2945 139.319 81.3074 139.358 81.3326C139.397 81.3578 139.428 81.3941 139.447 81.4369C139.465 81.4797 139.471 81.5271 139.462 81.573C139.454 81.6188 139.432 81.6611 139.399 81.6945C139.366 81.7278 139.325 81.7507 139.279 81.7601C139.233 81.7696 139.186 81.7653 139.142 81.7477C139.099 81.73 139.062 81.7 139.036 81.6612C139.01 81.6225 138.996 81.5769 138.996 81.5303C138.996 81.4689 139.02 81.4099 139.063 81.366C139.106 81.3221 139.165 81.2968 139.226 81.2955Z"
        fill="#263238"
      />
      <path
        d="M139.951 81.2955C140.013 81.2955 140.073 81.3203 140.117 81.3643C140.161 81.4083 140.185 81.4681 140.185 81.5303C140.185 81.5926 140.161 81.6524 140.117 81.6964C140.073 81.7404 140.013 81.7652 139.951 81.7652C139.888 81.7652 139.829 81.7404 139.785 81.6964C139.741 81.6524 139.716 81.5926 139.716 81.5303C139.716 81.4681 139.741 81.4083 139.785 81.3643C139.829 81.3203 139.888 81.2955 139.951 81.2955Z"
        fill="#263238"
      />
      <path
        d="M140.67 81.2955C140.732 81.2955 140.792 81.3203 140.836 81.3643C140.88 81.4083 140.905 81.4681 140.905 81.5303C140.905 81.5926 140.88 81.6524 140.836 81.6964C140.792 81.7404 140.732 81.7652 140.67 81.7652C140.608 81.7652 140.548 81.7404 140.504 81.6964C140.46 81.6524 140.435 81.5926 140.435 81.5303C140.435 81.4681 140.46 81.4083 140.504 81.3643C140.548 81.3203 140.608 81.2955 140.67 81.2955Z"
        fill="#263238"
      />
      <path
        d="M202 95C202 101.627 196.627 107 190 107H78C71.3726 107 66 101.627 66 95V32C66 25.3726 71.3726 20 78 20H190C196.627 20 202 25.3726 202 32V95ZM66.2494 94.7787C66.2494 101.406 71.622 106.779 78.2494 106.779H189.751C196.378 106.779 201.751 101.406 201.751 94.7787V32.208C201.751 25.5806 196.378 20.208 189.751 20.208H78.2494C71.622 20.208 66.2494 25.5806 66.2494 32.208V94.7787Z"
        fill="#C7C7C7"
      />
      <circle cx="240" cy="30" r="9.5" fill="white" stroke="#D4D4D4" />
    </g>
    <defs>
      <clipPath id="clip0_6595_446375">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H264C266.209 0 268 1.79086 268 4V127H0V4Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
