import React from "react";
import VideoRecorder from "assets/images/survey/video-recorder.png";
import docHolder from "assets/images/survey/docHolder.png";
import audioHolder from "assets/images/survey/audioHolder.png";
import AudioBackground from "assets/images/survey/AudioBackground.png";
import VideoPlaceHolder from "assets/images/survey/videoPlaceholder.png";
import { formatDateWithDay } from "lib/formatDate";
import { getExtension } from "lib/respType";
import { timeFormat } from "lib/formatDate";

const ResponseCard = ({ name, placeholder, date, location, mediaUrl, responseType, mediaType, mediaTime }) => {
  const duration = timeFormat(mediaTime);
  // const fileType = getExtension(mediaUrl);

  // const videoType = ["mpeg", "avi", "mp4"];
  // const audioType = ["mp3", "m4a", "aac"];

  const mediaTypeext =
    mediaType !== "Audio_Response"
      ? VideoPlaceHolder
      : // : audioType.includes(fileType)
        AudioBackground;
  // : docHolder;

  const iconType =
    mediaType !== "Audio_Response"
      ? VideoRecorder
      : // : audioType.includes(fileType)
        audioHolder;
  // : docHolder;

  console.log({ duration });
  return (
    <div className="w-[253px] h-[251px] rounded-[6px] border overflow-hidden">
      <div className="w-full h-[131px] relative">
        <img src={mediaTypeext} alt="" className="object-cover" />
        {responseType !== "text" ? (
          <div className="bg-black rounded-full py-1 px-3 w-fit flex items-center justify-center text-white absolute top-[5.5rem] right-2 text-[12px]">
            <p>{duration}</p>
          </div>
        ) : null}
      </div>
      <div className="p-5">
        <div className="flex items-center space-x-2">
          <img src={iconType} alt="" className="w-[18px]" />
          <p className="text=[16px]">{name}</p>
        </div>
        <div className="space-y-2 text-[14px] pt-3">
          <p>{formatDateWithDay(date)}</p>
          <p>{location}</p>
        </div>
      </div>
    </div>
  );
};

export default ResponseCard;
