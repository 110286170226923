import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import CreationSuccess from "assets/images/creationSuccess.svg";
import NewLogo from "assets/images/newLogo.png";

const SavedContact = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationArray = location.pathname.split("/");

  // const sendAnotherFn = () => {
  //   navigate("/surveys/create");
  // };

  const routeFn = () => {
    if (locationArray.includes("brand-tracking")) {
      navigate(`/research/brand-tracking`);
      // queryClient.invalidateQueries(["brandTracking"]);
    } else if (locationArray.includes("online-survey")) {
      navigate(`/research/online-survey`);
    } else {
      navigate(`/research/field-interview`);
    }
  };
  return (
    <div className="absolute h-[100vh] w-[100vw] left-0 right-0 bottom-0  z-50 bg-white flex justify-center items-center flex-col space-y-6">
      <img src={NewLogo} alt="NewLogo" className="w-[140px] my-6" />
      <div className="flex justify-between w-[50%]">
        <div></div>

        <FaTimes className="text-2xl text-gray-600 cursor-pointer" onClick={routeFn} />
      </div>

      <img src={CreationSuccess} alt="CreationSuccess" className="w-[250px]" />

      <p className="text-xl font-bold">Congratulations!</p>
      <p className="text-sm text-gray-600 w-[30%] mx-auto text-center">
        Survey has been sent. Monitor progress on the dashboard and expect an email when response target number has been
        met.
      </p>

      <div className="flex items-center space-x-4">
        <BlueButton text="Send another" onClick={routeFn} />

        <GreyButton text="Done" onClick={routeFn} className="w-[160px]" />
      </div>
    </div>
  );
};

export default SavedContact;
