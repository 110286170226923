import React from "react";

const SecondarySelectInput = ({ options, name, value, onChange, text, css, error, ...rest }) => {
  return (
    <div>
      <div className="text-primary flex flex-col w-[100%] relative">
        <label htmlFor={name} className="text-[#292D32] text-m font-[500]">
          {text}
        </label>
        <select
          name={name}
          id=""
          value={value}
          required={rest.required}
          onChange={onChange}
          className="rounded-[5px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px]  pl-2 outline-none my-2 text-sm cursor-pointer appearance-none bg-transparent pr-8"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} className="bg-white  text-[#292D32] text-sm">
              {option.label}
            </option>
          ))}
        </select>

        <div className="pointer-events-none absolute inset-y-0 top-5 right-0 flex items-center pr-2">
          <svg className="fill-current h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 12l-6-6 1.5-1.5L10 9l4.5-4.5L16 6l-6 6z" clipRule="evenodd" />
          </svg>
        </div>
      </div>

      <p className="text-ss text-red-500">{error}</p>
    </div>
  );
};

export default SecondarySelectInput;
