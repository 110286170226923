import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import audienceIcon from "assets/svgs/audienceIcon.svg";
import trashCanIcon from "assets/svgs/trashCanIcon.svg";
import Typography from "utils/typography";
import { IoMdArrowDropdown } from "react-icons/io";
import EmptyState from "./EmptyState";
import emptySurvey from "assets/images/emptySurveyIcon.png";
import contactEmpty from "assets/icons/contacts/ContactEmpty.png";
import { useState } from "react";

const SavedAudienceSettings = ({ audienceData, onClose, setSelectedAudience, setCoreData }) => {
  const [openDropdown, setOpenDropDown] = useState();
  const [selected, setSelected] = useState();

  const handleDropDown = (id) => {
    setOpenDropDown(id === openDropdown ? null : id);
  };
  const handleSelect = (id) => {
    setSelected(id === selected ? null : id);
  };

  const areAllObjectsEmpty = (coreData) => {
    if (coreData === null || coreData === undefined) {
      return true;
    } else return Object.values(coreData).every((obj) => Object.keys(obj).length === 0);
  };
  const isObjectEmpty = (obj) => {
    if (obj === null || obj === undefined) {
      return true;
    } else return Object.keys(obj).length === 0;
  };

  const transformToTitleCase = (str) => {
    let formattedStr = str.replace(/_/g, " ");
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1).toLowerCase();
    return formattedStr;
  };

  const TagComponent = ({ text }) => {
    return (
      <div className="bg-gray-200 rounded-md py-2 px-4 flex items-center justify-center space-x-2">
        <Typography.MicroText>{text}</Typography.MicroText>
      </div>
    );
  };

  const handleSelectedAudience = () => {
    setSelectedAudience((prevState) => ({
      ...prevState,
      ...audienceData[selected],
    }));
    setCoreData((prevState) => ({
      ...prevState,
      ...audienceData[selected]?.targetingOption?.demographics[0]?.detailedTargeting,
    }));
    onClose();
  };
  return (
    <Modal title="Saved audience(s)" onClose={onClose}>
      <div className="w-[516px] px-5">
        <p className="text-m font-semibold my-2">Choose from your saved audience(s) to proceed</p>
        <hr />
        <div className="mt-2 w-full h-[500px] overflow-y-auto space-y-2">
          {audienceData && audienceData?.length !== 0 ? (
            audienceData?.map((el, i) => (
              <div
                key={i}
                className={`${
                  selected === i ? "bg-primary-50 border-primary-800" : ""
                } p-3 rounded-[8px] border hover:border-primary-800  hover:bg-primary-50`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-5 w-full cursor-pointer" onClick={() => handleSelect(i)}>
                    <div>
                      <img src={audienceIcon} alt="audienceIcon" />
                    </div>
                    <div>
                      <Typography.Text>{el?.targetingOption?.name}</Typography.Text>
                      <Typography.SubText className="text-[12px]">{el.targetingOption?.description}</Typography.SubText>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center space-y-2">
                    <div className="cursor-pointer" onClick={() => handleDropDown(i)}>
                      <IoMdArrowDropdown />
                    </div>
                    <div className="cursor-pointer">
                      <img src={trashCanIcon} alt="audienceIcon" />
                    </div>
                  </div>
                </div>
                <div>
                  {openDropdown === i ? (
                    <div>
                      <div className="border-slate-200 border-[1px] bg-white mt-2 rounded-md h-fit">
                        {el?.targetingOption?.demographics[0]?.age?.max !== "" ||
                        el?.targetingOption?.demographics[0]?.state?.length !== 0 ||
                        areAllObjectsEmpty(el?.targetingOption?.demographics[0]?.detailedTargeting) !== true ? (
                          <div className="p-4 h-[250px] overflow-y-scroll">
                            {el?.targetingOption?.demographics[0]?.age?.max !== "" &&
                            el?.targetingOption?.demographics[0]?.age?.min !== "" ? (
                              <>
                                <div className="space-y-2">
                                  <Typography.SubText className="font-semibold">Age</Typography.SubText>

                                  <Typography.SubText>
                                    {el?.targetingOption?.demographics[0].age?.min} to{" "}
                                    {el?.targetingOption?.demographics[0].age?.max}
                                  </Typography.SubText>
                                </div>

                                <hr className="w-[100%] my-3" />
                              </>
                            ) : null}

                            {el?.targetingOption?.demographics[0]?.state?.length !== 0 ? (
                              <>
                                <div className="space-y-2">
                                  <Typography.SubText className="font-semibold">State</Typography.SubText>
                                  {el?.targetingOption?.demographics[0]?.state?.map((el) => (
                                    <div className="flex items-center flex-wrap">
                                      <TagComponent text={el} />
                                    </div>
                                  ))}
                                </div>
                                <hr className="w-[100%] my-3" />
                              </>
                            ) : null}

                            <>
                              {/* Render Cores */}
                              {!isObjectEmpty(el?.targetingOption?.demographics[0]?.detailedTargeting?.cores) && (
                                <div className="space-y-2">
                                  <div className="flex items-center w-full space-x-1 mt-3">
                                    <Typography.SubText className="font-bold">Cores</Typography.SubText>
                                    <hr className="w-[100%]" />
                                  </div>
                                  {Object.keys(el?.targetingOption?.demographics[0]?.detailedTargeting?.cores).map(
                                    (key) => (
                                      <div key={key}>
                                        <Typography.SubText className="font-semibold">
                                          {transformToTitleCase(key)}:
                                        </Typography.SubText>
                                        <div className="flex items-center flex-wrap gap-2 mt-2">
                                          {el?.targetingOption?.demographics[0]?.detailedTargeting?.cores[key].map(
                                            (item) => (
                                              <TagComponent text={item} key={item} />
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              )}

                              {/* Render Interests */}
                              {!isObjectEmpty(el?.targetingOption?.demographics[0]?.detailedTargeting?.interest) && (
                                <div className="space-y-2">
                                  <div className="flex items-center w-full space-x-1 mt-3">
                                    <Typography.SubText className="font-bold">Interests</Typography.SubText>
                                    <hr className="w-[100%]" />
                                  </div>

                                  {Object.keys(el?.targetingOption?.demographics[0]?.detailedTargeting?.interest).map(
                                    (key) => (
                                      <div key={key}>
                                        <Typography.SubText className="font-semibold">
                                          {transformToTitleCase(key)}
                                        </Typography.SubText>
                                        <div className="flex items-center flex-wrap gap-2 mt-2">
                                          {el?.targetingOption?.demographics[0]?.detailedTargeting?.interest[key].map(
                                            (item) => (
                                              <TagComponent text={item} key={item} />
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              )}

                              {/* Render Behaviour */}
                              {!isObjectEmpty(el?.targetingOption?.demographics[0]?.detailedTargeting?.behaviour) && (
                                <div className="space-y-2">
                                  <div className="flex items-center w-full space-x-1 mt-3">
                                    <Typography.SubText className="font-bold">Behavioral</Typography.SubText>
                                    <hr className="w-[100%]" />
                                  </div>

                                  {Object.keys(el?.targetingOption?.demographics[0]?.detailedTargeting?.behaviour).map(
                                    (key) => (
                                      <div key={key}>
                                        <Typography.SubText className="font-semibold">
                                          {transformToTitleCase(key)}
                                        </Typography.SubText>
                                        <div className="flex items-center flex-wrap gap-2 mt-2">
                                          {el?.targetingOption?.demographics[0]?.detailedTargeting?.behaviour[key].map(
                                            (item) => (
                                              <TagComponent text={item} key={item} />
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              )}
                            </>
                          </div>
                        ) : (
                          <div className="h-[100%] w-[70%] mx-auto ">
                            <EmptyState
                              text="Your target audience selection will be displayed here."
                              icon={emptySurvey}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <EmptyState
              text="Nothing to show"
              icon={contactEmpty}
              content={"Your saved audience(s) will be displayed here."}
            />
          )}
        </div>

        <div className="w-full">
          <div className="flex justify-end space-x-5 mt-[1em]">
            <div>
              <GreyButton text="Cancel" onClick={() => onClose} />
            </div>
            <div className="flex space-x-2">
              <BlueButton
                text={"Next"}
                css=""
                onClick={handleSelectedAudience}
                // disabled={audience.name === ""}
                // loading={createLoading || loadingEdit}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SavedAudienceSettings;
