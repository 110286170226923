import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const formatLabel = (value) => `${value}%`;

const LineCharts = ({ data }) => {
  const allKeys = new Set(
    data
      .map((item) => Object.keys(item))
      .flat()
      .filter((key) => key !== "date"),
  );

  const CustomTooltip = ({ payload, label }) => {
    return (
      <div className="custom-tooltip bg-white rounded border-[1px] p-2">
        <p className="text-xs font-semibold">{label}</p>
        <ul className="space-y-2 mt-1">
          {payload?.map((item) => (
            <li className="text-xs text-primary-800" key={item.dataKey}>
              {item.dataKey} : {item.value}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const getColor = () => {
    // Generate random color in hexadecimal format
    const color =
      "#" +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0");
    return color;
  };

  return (
    <div className="w-[100%] h-[400px]">
      <ResponsiveContainer width="100%" height="100%" className="mx-auto">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: -25,
            bottom: 5,
          }}
          chart={{
            fontSize: 12, // Set a smaller font size for the entire chart
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: 12 }} />
          <YAxis tick={{ fontSize: 12 }} />
          <Tooltip labelStyle={{ fontSize: 12 }} label={{ formatter: formatLabel }} content={CustomTooltip} />
          <Legend fontSize={10} iconSize={10} />

          {Array.from(allKeys).map((key) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              activeDot={{ r: 8 }}
              label={{
                fontSize: 12,
              }}
              stroke={getColor()}
            /> // Dynamic dataKey and color
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineCharts;
