import { useMutation, useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import useLoggedInUser from "hooks/useLoggedInUser";
// import usePlan from "hooks/usePlan";
import { formatAmount } from "lib/formatAmount";
import { ImCheckmark } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { approvePlan, fetchAllPlans, upgradePlan } from "services/userService";
import { useStore } from "store";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useState } from "react";
import { getWorkspacePlan } from "services/workspaceService";
import { bundleArray, creditArray, priceArray } from "data";
import { toTitleCase } from "lib/titleCase";
import { sortByValue } from "lib/campaignFunction";

const publicKey =
  process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_FLW_KEY : process.env.REACT_APP_FLW_LIVE_KEY;

const planPackage = [
  [
    "500 *mooyi credits to get started",
    "Up to 500 contacts in your address book",
    "Easily create simple broadcast SMS and email campaigns ",
    "Send quick surveys to your customers and reward them with airtime",
  ],
  [
    "2,000 free *mooyi credits",
    "Up to 20,000 contacts in your address book",
    "Send quick surveys to your customers and reward them with airtime",
    "Track your SMS and email campaigns on Google Analytics",
    "Easily create custom-branded and personalised SMS and email campaigns",
    "Enjoy one-click retargeting and send follow-up campaigns easily",
    "Get updates about holidays around the world and plan campaigns smarter",
  ],
  [
    "10,000 free *mooyi credits",
    "Up to 100,000 contacts in your address book",
    "Enjoy detailed targeting options to reach your customers or our +12 million audiences who enjoy flexible rewards",
    "Send convenient voice campaigns or robocalls to your customers",
    "Create multiple workspaces and sender IDs for email and SMS",
    "All other features of the business plan",
  ],
];

const Choose = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(["allPlans"], fetchAllPlans);
  const { data: wsData } = useQuery(["wsData"], getWorkspacePlan);
  const { user } = useLoggedInUser();
  // const { wsPlan } = usePlan();
  const { user: storeUser } = useStore();
  const [flwAmount, setFLWAmount] = useState(0);

  const configs = {
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: flwAmount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: storeUser?.email,
      phone_number: `+234${storeUser?.phone}`,
      name: storeUser?.firstname + " " + storeUser?.lastname,
    },
    customizations: {
      title: "MOOYI",
      description: "Plan Upgrade",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const initializePayment = useFlutterwave(configs);

  const { mutate: mutatePlan } = useMutation(approvePlan, {
    onSuccess: () => {
      closePaymentModal();
      Toast.success("Plan changed");
      navigate("/settings/account/");
      queryClient.invalidateQueries(["allPlans"]);
    },
    onError: () => {
      Toast.error("Try again.");
    },
  });

  const { mutate } = useMutation(upgradePlan, {
    onSuccess: (data) => {
      initializePayment({
        callback: (response) => {
          const userData = {
            tx_ref: data?.data?.tx_ref,
            transactionId: response?.transaction_id,
          };
          mutatePlan(userData);

          // routeFn(); // this will close the modal programmatically
        },
        onClose: () => {},
      });
    },

    onError: () => {
      Toast.error("Try again.");
    },
  });

  const planData = data?.map((el, i) => ({
    id: el._id,
    name: el?.plan,
    amount: el?.price,
    value: el?.plan,

    packages:
      el?.plan === "starter"
        ? planPackage[0]
        : el?.plan === "business"
        ? planPackage[1]
        : el?.plan === "pro"
        ? planPackage[2]
        : null,
  }));

  // console.log(planData);
  // console.log(wsPlan);

  const handlePayment = (el) => {
    try {
      setFLWAmount(el.amount);

      const userData = {
        plan_id: el.id,
        fullname: `${[user?.data?.firstname + user?.data?.lastname]}`,
        email: user?.data?.email,
      };

      configs.amount = +el.amount;
      mutate(userData);
      configs.amount = +el.amount;
    } catch (error) {}
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="w-[75%] mx-auto my-[3em]">
      <div className="flex justify-between items-center">
        <p className="font-[800] text-[28px]">Select a plan</p>

        <Link
          to="/settings/account"
          className="bg-[#29085C17] h-[40px] w-[40px] flex items-center justify-center rounded-full text-[24px]"
        >
          <IoClose />
        </Link>
      </div>

      <p className="tracking-[-0.02em] my-[1em]"> All plans are billed monthly</p>

      <div
        className="flex justify-center w-[100%] gap-y-[56px] flex-col md:gap-x-[5px] 
        md:flex-row md:justify-between items-start"
      >
        {planData.sort(sortByValue)?.map((el) => {
          return (
            <div
              key={el.id}
              className=" flex flex-col gap-y-[32px]
            justify-between items-between
            mx-auto md:mx-0 border-[1px]
          rounded-[12px] p-4 w-[330px]"
            >
              <div className="mx-auto md:mx-0 rounded-[12px]">
                <p className="font-[800] text-[20px]">{toTitleCase(el.name)} Plan</p>
                <p className="text-[#737373] font-[400] text-[14px] pb-[5px]">
                  {el.name === "Starter Plan"
                    ? "Free forever"
                    : el.name === "Business Plan"
                    ? "Mooyi Recommends"
                    : el.name === "Pro Plan"
                    ? "Priority Support"
                    : null}
                </p>
                <p className="text-[14px] text-[#737373]">{el.description}</p>
                <div className="my-[1em] ">
                  {el.amount === 0 ? (
                    <p className="text-[32px] font-[800] text-primary-800">Free</p>
                  ) : (
                    <div className="text-[32px] font-[800]">
                      <span className="font-semibold text-primary-800 tracking-[-1px] text-[18px]">
                        NGN{" "}
                        <span className="text-[32px] text-primary-800 font-semibold">{formatAmount(el.amount)}</span>
                      </span>
                      <span className="text-[14px] text-[#737373] font-[450] ml-2">per month</span>{" "}
                    </div>
                  )}
                </div>

                <div className="space-y-2 mt-4 h-[35%]">
                  {el?.packages?.map((elm, i) => {
                    return (
                      <div key={i}>
                        <div className="flex my-4" key={`elm${i}`}>
                          <ImCheckmark className="min-w-[20px] h-[20px] text-[14px] rounded-[50%] text-primary-800 p-[4px] font-[900]" />
                          <p className="ml-4 text-[#525252] font-[450] text-[14px]">{elm}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                className={`text-white cursor-pointer ${
                  el.value === wsData?.data?.plan || el.amount === 0 ? "hidden" : "bg-primary-800 hover:bg-[#4908AA]"
                }  h-[40px] w-[100%] rounded-[36px] flex items-center justify-center my-6 `}
                onClick={() => handlePayment(el)}
              >
                <p className="font-semibold text-m">
                  {el.value === wsData?.data?.plan ? "Current plan" : "Select plan"}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex justify-between items-center my-6">
        <div>
          <p className="text-[] font-semibold">Enterprise</p>
          <p className="text-[#737373] text-s font-[450]">
            For large organisations with special requirements we offer custom pricing.
          </p>
        </div>

        <a href="https://www.mooyi.africa/contact-sales">
          <div className="bg-[#FAFAFA]  border-[#D4D4D4] border-[1px] rounded-[6px] flex items-center justify-center w-[130px] h-[45px]">
            <p className="font-semibold text-s">Talk to sales</p>
          </div>
        </a>
      </div>

      <div className="w-[100%] mx-auto my-[2em] mt-[6em]">
        <p className="text-[24px] font-semibold">
          *What are <span className="text-primary-800">mooyi</span> credits?
        </p>
        <p className="text-[#737373] text-[14px] w-[85%]">
          You use these special coins to send SMS, email, robocalls, or surveys. They&apos;re your ticket to reaching
          your audience and getting their attention. Think of them as your currency for communication and engagement on{" "}
          <span className="text-primary-800">mooyi.</span>
        </p>
      </div>

      <div className="w-[100%] mx-auto flex justify-between items-start mb-[6em]">
        <div className="w-[30%] border-[1px] rounded-[8px]">
          <div className="flex justify-between border-b-[1px] h-[55px] items-center p-4 font-[500]">
            <p>Price per</p>
            <p>mooyi Credits</p>
          </div>

          <div className="px-4">
            {priceArray.map((el) => {
              return (
                <div key={el.id} className="flex justify-between h-[50px] items-center">
                  <p className="text-[#344054] text-[16px] font-[400]">{el.name}</p>
                  <p className="font-[500]">{el.amount}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-[30%]">
          <div className="border-[1px] rounded-[8px]">
            <div className="flex justify-between border-b-[1px] h-[55px] items-center p-4 font-[500]">
              <p>Credits</p>
              <p>Cost</p>
            </div>

            <div className="px-4">
              {creditArray.map((el) => {
                return (
                  <div key={el.id} className="flex justify-between h-[50px] items-center">
                    <p className="text-[#344054] text-[16px] font-[400]">{el.name}</p>
                    <p className="font-[500]">&#x20A6;{formatAmount(el.amount)}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <p className="text-[12px] text-[#667085] mt-4 ml-2">*credits can rollover</p>
        </div>

        <div className="w-[30%] border-[1px] rounded-[8px] h-auto">
          <div className="flex justify-between border-b-[1px] h-[55px] items-center p-4 font-[500]">
            <p>Bundles</p>
            <p>Price (NGN)</p>
          </div>

          <div className="px-4">
            {bundleArray.map((el) => {
              return (
                <div key={el.id} className="flex justify-between h-[50px] items-center">
                  <p className="text-[#344054] text-[16px] font-[400]">{el.name}</p>
                  <p className="font-[500]">&#x20A6;{formatAmount(el.amount)}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
