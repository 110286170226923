import React from "react";

const EmptyState = ({ img, title, subTitle, className }) => {
  return (
    <figure className={`flex flex-col h-[calc(100%-15px)] justify-center items-center ${className}`}>
      <div className="w-[70px] h-[70px]">
        <img src={img} alt="empty_state.svg" />
      </div>
      <h5 className="font-bold mt-2">{title}</h5>
      <p className="text-s">{subTitle}</p>
    </figure>
  );
};

export default EmptyState;
