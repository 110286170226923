import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import SetupInsight from "./SetupInsight";
import CreationFlow from "./components/CreationFlow";
import NPSHome from "./NPS";
import CSatHome from "./CSAT";
import CustomerProfilingHome from "./CustomerProfiling";
import QrCodeFlow from "./components/SuccessScreens/QrCodeFlow";

const Insight = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/setup" element={<SetupInsight />} />
      <Route path="/nps" element={<NPSHome />} />
      <Route path="/csat" element={<CSatHome />} />
      <Route path="/customer-profile" element={<CustomerProfilingHome />} />
      <Route path="/setup/customer-profile" element={<CreationFlow />} />
      <Route path="/setup/nps" element={<CreationFlow />} />
      <Route path="/setup/csat" element={<CreationFlow />} />

      <Route path="/success" element={<QrCodeFlow />} />
    </Routes>
  );
};

export default Insight;
