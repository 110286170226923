import React from "react";
import mediaResponse from "assets/images/survey/mediaResponse.png";

const questionData = [
  {
    question: "How well do you understand the concept? (Scale 1-5)",
    response: "Not at all 😞",
    responseType: "text",
  },
  {
    question: "Is the concept relevant to your needs or interests? (Scale 1-5)",
    response: "Not at all 😞",
    responseType: "text",
  },
  {
    question: "What are the main benefits of the concept? (Share your thoughts)",
    response:
      "Tailor the specific questions and options to the concept you are testing and the information you want to gather from the survey participants. Including a mix of rating scales, open-ended questions, and other questions that suit your needs is good practice.",
    responseType: "text",
  },
  {
    question: "Rank these features in order of importance:",
    response: "Not at all 😞",
    responseType: "text",
  },
  {
    question: "How likely are you to use or adopt this concept? Choose one:",
    response: "Likely 🙂",
    responseType: "text",
  },
  {
    question: "Do you have any suggestions to improve the concept? (Share your ideas)",
    response:
      "Tailor the specific questions and options to the concept you are testing and the information you want to gather from the survey participants. Including a mix of rating scales, open-ended questions, and other questions that suit your needs is good practice.",
    responseType: "text",
  },
  {
    question: "Post an image of the meal",
    response: mediaResponse,
    responseType: "media",
  },
];

const TextResponse = () => {
  return (
    <div>
      <div className="mt-5 space-y-5 w-[50%]">
        <div className=" border-b pb-1 text-[14px]">
          <div className="flex items-center w-full justify-between">
            <p className="font-[500] w-[60%]">Completed</p>
            <p className="text-left w-[40%]">October 1st, 2022 | 5:00pm</p>
          </div>
        </div>
        <div className=" border-b pb-1 text-[14px]">
          <div className="flex items-center w-full justify-between">
            <p className="font-[500] w-[60%]">Completion time</p>
            <p className="text-left w-[40%]">2 minutes, 5 seconds</p>
          </div>
        </div>
        <div className="pb-1 text-[14px]">
          <div className="flex items-center w-full">
            <p className="font-[500] w-[60%]">Location</p>
            <p className="text-left w-[40%]">Lagos, Nigeria</p>
          </div>
        </div>
      </div>
      <div className="mt-10 w-full rounded-xl border p-5 space-y-7">
        {questionData.map((el, i) => (
          <div className="grid grid-cols-2 gap-4 text-[14px]" key={i}>
            <p>
              {i + 1}. {el.question}
            </p>
            {el.responseType === "media" ? <img src={el.response} alt="" /> : <p>{el.response}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextResponse;
