import { handleApiError } from "utils/handleApiError";
import Api from "utils/axios";

export const getWalletBalance = async () => {
  try {
    const { data } = await Api.get("/wallet/balance");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const transferToWorkspace = async (payload) => {
  try {
    const { data } = await Api.post("/wallet/transfer", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const topUpWallet = async (id) => {
  try {
    const { data } = await Api.get(`/wallet/topup/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const approveWalletTopUp = async (id, ref) => {
  try {
    const { data } = await Api.get(`/wallet/verify/${id}?tx_ref=${ref}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

// export const viewWalletLedger = async () => {
//   try {
//     const { data } = await Api.get("/wallet/ledger?batch=1");
//     return data?.data;
//   } catch (error) {
//     throw handleApiError(error);
//   }
// };

export const viewWalletLedger = async () => {
  try {
    const { data } = await Api.post("/workspace-microservice/ws-ledger");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
