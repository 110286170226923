import { useMutation, useQuery } from "@tanstack/react-query";
import Checkbox from "components/Inputs/CheckBox";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getACampaign } from "services/campaignService";
import Toggle from "react-toggle";
import { queryClient } from "config/gateway";
import { createTestCampaign } from "services/campaignService";
import message from "assets/images/broadcast/message.svg";
import alertCircled from "assets/images/broadcast/alert-circled.svg";
import handCursor from "assets/images/broadcast/Vector.svg";
import send from "assets/images/broadcast/Group.svg";

const Content = ({ setData, data }) => {
  const { smsId } = useParams();
  const [audience, setAudience] = useState([]);
  const [active, setActive] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [display, setDisplay] = useState(false);
  const [value, setValue] = useState(0);

  const handleCardClick = ({ name, initvalue }) => {
    const isSelected = selectedCards.includes(name);
    if (isSelected) {
      setSelectedCards(selectedCards.filter((cardName) => cardName !== name));
      setValue(value - initvalue);
    } else {
      setSelectedCards([...selectedCards, name]);
      setValue(value + initvalue);
    }
  };

  const handleChange = () => {
    setDisplay(!display);
  };

  const icons = [message, send, handCursor, alertCircled];

  const handleTelChange = (e) => {
    if (e.target.value.length === 10) {
      setActive(true);
    } else {
      setActive(false);
    }
    setData({
      ...data,
      testContact: e.target.value,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      audience: selectedCards,
      totalReceiver: value,
    });
  }, [data, selectedCards, setData, value]);

  const { mutate: testMutate } = useMutation(createTestCampaign, {
    onSuccess: () => {
      Toast.success("Test created");
      queryClient.invalidateQueries(["wsCredit"]);
      setData({
        ...data,
        testContact: "",
      });
    },
    onMutate: () => {
      Toast.success("Sending Test Campaign");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleTest = async () => {
    const test = {
      campaign_channel: "sms",
      sender_name: data.sender_name,
      campaign_subject: data.campaign_subject,
      content: data.content,
      tester: { phone: "+234" + data.testContact },
    };
    testMutate(test);
  };

  const {
    data: campData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["oneCampaign", smsId],
    () => getACampaign(smsId),
    {
      enabled: !!smsId,
      onError: (error) => {
        Toast.error(error);
      },
      onSuccess: (data) => {
        const keys = Object.keys(data);
        for (const x of keys) {
          if (!(data[x] instanceof Array) && data[x] instanceof Object) {
            setAudience((prevAudience) => [...prevAudience, { id: x, name: x, value: data[x].value }]);
          }
        }
      },
    },
    {
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    refetch();
  }, [smsId, refetch]);

  const handleCheck = (e, x, y) => {
    if (e.target.checked) {
      setData({
        ...data,
        audience: x,
        personal: y,
      });
    } else {
      setData({
        ...data,
        audience: "",
        personal: 0,
      });
    }
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in data.recipientArray) {
      if (data.recipientArray[x].group_name === y) {
        checked = true;
      }
    }
    return checked;
  };

  if (isLoading || isFetching) {
    return <PreLoader />;
  }

  return (
    <div>
      <p className="text-lg font-semibold">Retargeting options</p>
      <p className="text-sm">Choose the section of your audience you want to follow up with</p>
      <div className="mt-4">
        <p className="text-sm">Resend to</p>
        <p className="text-sm text-gray-500">Select multiple sections to retarget</p>
      </div>
      <div className="grid grid-cols-2 gap-[1em] mt-4">
        {audience?.slice(0, 4)?.map((el, i) => {
          return (
            <div
              className={`p-4 text-ss cursor-pointer font-extrabold text-[#404040] border-[1px] rounded border-[#D0D5DD]  flex items-center justify-between hover:shadow-lg hover:rounded-[8px] group ${
                selectedCards.includes(el?.name) ? "bg-[#E5E5E5] hover:bg-[#E5E5E5] hover:text-primary-800" : ""
              }`}
              key={el.id}
              onClick={() => handleCardClick({ name: el?.name, initvalue: el?.value })}
            >
              <div className="flex items-center space-x-4 relative w-[100%] cursor-pointer ">
                <div className="flex items-start space-x-3">
                  <div
                    className={`w-[30px] h-[30px] p-2 flex items-center justify-center rounded-full ${
                      selectedCards.includes(el?.name) ? "bg-white" : "bg-primary-100"
                    }`}
                  >
                    <img src={icons[i]} alt="" className="object-cover" />
                  </div>
                  <span>
                    <p className="text-m"> Total {el?.name}</p>
                    <p className="text-ss">{el?.value} contacts</p>
                  </span>
                </div>
              </div>

              <Checkbox onChange={(e) => handleCheck(e, el?.name, el?.value)} checked={checkInArray(el?.name)} />
            </div>
          );
        })}
      </div>
      <p
        className="underline text-primary-400 cursor-pointer text-xs underline-offset-2 mt-4"
        onClick={() => setSelectedCards([])}
      >
        Clear Selection
      </p>
      <div>
        <div className="flex space-x-4 items-center mt-5">
          <p className="text-s font-semibold">Send Test</p>
          <Toggle checked={display} onChange={handleChange} icons={false} />
        </div>

        {display && (
          <div>
            <div className="flex space-x-4 items-center my-[1em]">
              <div className="flex items-center px-4 rounded-[8px] border-[#DFE5EC] border-[1px] space-x-2 w-[] h-[45px]">
                <p>+234</p>
                <input
                  type="tel"
                  className="outline-none h-[100%]"
                  placeholder="8123456789"
                  value={data.testContact}
                  onChange={handleTelChange}
                  maxlength="10"
                />
              </div>
              <p
                className={`text-ss h-[45px] px-2 cursor-pointer rounded-md flex items-center justify-center ${
                  active ? "text-white font-bold bg-primary-800 " : "text-primary-800 bg-primary-100 "
                }`}
                onClick={active ? handleTest : null}
              >
                Send Test
              </p>
            </div>
            <p className="text-ss w-[50%]">
              You have 10 free test SMS. Additional usage will be deducted from your email balance.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
