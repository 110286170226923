import IconButton from "components/Button/IconButton";
import Modal from "components/Modal";
import Tooltip from "components/Tooltip";
import { formatAmount } from "lib/formatAmount";
import { toTitleCase } from "lib/titleCase";
import React from "react";
import { BsArrowUp } from "react-icons/bs";

const BalanceModal = ({ wsData, wsCredit, toggleDropdown }) => {
  return (
    <Modal
      title={
        <div className="flex items-center space-x-2">
          <p className="text-sm font-semibold">Balances</p>
          <p className="text-xs p-[4px] px-2 rounded-md font-semibold bg-primary-50 text-primary-800">
            {toTitleCase(wsData?.data?.plan)} Plan
          </p>
          <Tooltip
            text={
              <p className="text-xs">
                Bundle balance provides a summary of your survey responses, messaging and rewards bundles from your last
                purchase or plan.
              </p>
            }
          />
        </div>
      }
      onClose={() => toggleDropdown(6)}
    >
      <div className="w-[500px] px-5">
        <p className="text-sm text-neutral-500">View your available balance.</p>

        <div className="space-y-6 py-[2em]">
          <div className="space-y-2">
            <div className="flex text-sm justify-between">
              <p className="font-semibold">Online survey responses</p>

              <p className="font-[600] text-[16px]">{formatAmount(wsCredit?.bundle?.survey?.online)} </p>
            </div>

            <hr />
          </div>

          <div className="space-y-2">
            <div className="flex text-sm justify-between">
              <p className="font-semibold">Rewards</p>

              <p className="font-[600] text-[16px]">{formatAmount(wsCredit?.bundle?.rewards)}</p>
            </div>

            <hr />
          </div>

          <div className="space-y-2">
            <div className="flex text-sm justify-between">
              <p className="font-semibold">Field interview</p>

              <p className="font-[600] text-[16px]">{formatAmount(wsCredit?.bundle?.survey?.field?.media)} </p>
            </div>

            <hr />
          </div>

          {/* <div className="space-y-2">
            <div className="flex text-sm justify-between">
              <p className="font-semibold">Field interview responses (text)</p>

              <p className="font-[600] text-[16px]">{formatAmount(wsCredit?.bundle?.survey?.field?.text)}</p>
            </div>

            <hr />
          </div> */}

          <div className="space-y-2">
            <div className="flex text-sm justify-between">
              <p className="font-semibold">Emails</p>

              <p className="font-[600] text-[16px]">{formatAmount(wsCredit?.bundle?.broadcast?.email)}</p>
            </div>

            <hr />
          </div>

          <div className="space-y-2">
            <div className="flex text-sm justify-between">
              <p className="font-semibold">SMS</p>

              <p className="font-[600] text-[16px]">{formatAmount(wsCredit?.bundle?.broadcast?.sms)}</p>
            </div>

            <hr />
          </div>

          <div className="space-y-2">
            <div className="flex text-sm justify-between">
              <p className="font-semibold">Robocalls</p>

              <p className="font-[600] text-[16px]">{formatAmount(wsCredit?.bundle?.broadcast?.voice)}</p>
            </div>

            <hr />
          </div>
          <div className="flex justify-end">
            <IconButton icon={<BsArrowUp />} text="Top up" onClick={() => toggleDropdown(7)} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BalanceModal;
