import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import Mascot from "assets/images/saveProgressMascot.png";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "store";

const SaveAndExitModal = ({ handleModal, callBackFn }) => {
  const { setSurveyTemplate } = useStore();
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const navigate = useNavigate();

  const fallBackFn = () => {
    callBackFn();
    setSurveyTemplate({
      id: 0,
      surveyName: "",
      surveyCategory: "",
      surveyDescription: "",
      coverImage: "",
      reward: 0,
      campaignType: "",
      bg: "bg-bgOne",
      questions: [],
    });
  };

  const handleDontSave = () => {
    handleModal();
    setSurveyTemplate({
      id: 0,
      surveyName: "",
      surveyCategory: "",
      surveyDescription: "",
      coverImage: "",
      reward: 0,
      campaignType: "",
      bg: "bg-bgOne",
      questions: [],
    });

    if (pathArray.includes("brand-tracking")) {
      navigate(`/research/brand-tracking`);
    } else if (pathArray.includes("online-survey")) {
      navigate(`/research/online-survey`);
    } else {
      navigate(`/research/field-interview`);
    }
  };
  return (
    <Modal title="Save your progress" onClose={handleModal}>
      <div className="w-[470px] ">
        <div className="bg-primary-50 h-[200px] flex items-center justify-center mt-6">
          <img src={Mascot} alt="Mascot" className="w-[220px] mx-auto my-6" />
        </div>

        <p className="text-sm text-center py-5">If you leave without saving, your current progress will be lost.</p>
        <div className="flex items-center justify-between pt-4 px-4 border-t-[1px]">
          <div></div>
          <div className="flex items-center justify-center space-x-4">
            <div>
              <GreyButton text="Don't save" onClick={handleDontSave} />
            </div>

            <BlueButton text="Yes, save" type="button" onClick={fallBackFn} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SaveAndExitModal;
