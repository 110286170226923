import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Overview from "./Overview";
import Details from "../components/analyticsTab/Details";
import Typography from "utils/typography";
import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import SecondaryInput from "components/Inputs/SecondaryInput";
import GreyButton from "components/Button/GreyButton";
import researchGif from "assets/images/insights/customerProfile.png";

const tabs = [
  { id: 1, name: "Overview" },
  { id: 3, name: "Survey details" },
];

const CustomerProfilingHome = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [isEmpty, setIsEmpty] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [name, setName] = useState("");

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <Overview />;
      case 3:
        return <Details />;

      default:
    }
  };

  return (
    <div>
      {isEmpty ? (
        <>
          <div className="flex space-x-4 mt-4">
            {tabs.map((el) => {
              return (
                <Typography.SubText
                  className={` pb-[4px] cursor-pointer ${
                    step === el.id
                      ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold "
                      : "text-[#6C6685]"
                  } `}
                  onClick={el.id !== 5 ? () => handleTabChange(el.id) : null}
                  key={el.id}
                >
                  {el.name}
                </Typography.SubText>
              );
            })}
          </div>
          <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

          <div>{displayStep()}</div>
        </>
      ) : (
        <div>
          <div className="border-[1px] rounded-lg h-[240px] w-[60%] bg-white ">
            <div className=" bg-cover h-[100%] flex items-center justify-center ">
              <div className="w-[60%] space-y-4">
                <div className="w-fit px-3 py-1 bg-primary-50">
                  <Typography.SubText className="w-[100%] text-primary-800">Insights</Typography.SubText>
                </div>
                <Typography.H3 className="font-semibold text-primary-800">Get to know your customers</Typography.H3>

                <Typography.SubText className="w-[70%]">
                  Gather more information about the preferences and behaviour of your customers.
                </Typography.SubText>

                <BlueButton text="Get started" onClick={() => handleModal(1)} />
              </div>

              <div className="w-[23%] ">
                <img src={researchGif} alt="researchGif" />
              </div>
            </div>
          </div>
          {openModal === 1 ? (
            <Modal title="Customer Profiling" onClose={handleModal}>
              <div className="w-[440px]">
                <Typography.SubText className="px-5">Give your survey a title</Typography.SubText>
                <div className="mt-2 px-5">
                  <p className="font-[600] text-[14px] pb-2">Title</p>
                  <div className=" h-[40px] rounded-[8px] flex justify-between w-full items-center">
                    <SecondaryInput
                      placeholder="Enter title"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-[1em] px-5">
                  <div></div>
                  <div className="flex gap-[1em] mt-[1em]">
                    <GreyButton
                      text="Cancel"
                      css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s"
                      onClick={handleModal}
                    />
                    <BlueButton text="Save" onClick={() => ""} />
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CustomerProfilingHome;
