import React from "react";

export const SettingsIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99473 3.59032C6.37236 3.38005 6.68434 3.0577 6.82103 2.64766L7.11169 1.77565C7.31586 1.16314 7.88907 0.75 8.53475 0.75H9.46618C10.1118 0.75 10.6851 1.16314 10.8892 1.77565L11.1798 2.64766C11.3166 3.0577 11.6286 3.38005 12.0062 3.59032C12.0649 3.62302 12.123 3.65665 12.1806 3.69119C12.5516 3.91393 12.9873 4.02329 13.4113 3.93653L14.3125 3.75209C14.9451 3.62265 15.5895 3.9125 15.9123 4.47164L16.378 5.27831C16.7008 5.83746 16.6296 6.54044 16.2012 7.02352L15.5901 7.71285C15.3036 8.0358 15.1798 8.4666 15.1866 8.8983C15.1877 8.9661 15.1877 9.0339 15.1866 9.1017C15.1798 9.53333 15.3036 9.96412 15.5901 10.2871L16.2012 10.9764C16.6296 11.4595 16.7008 12.1625 16.378 12.7217L15.9123 13.5283C15.5895 14.0875 14.9451 14.3773 14.3125 14.2478L13.4113 14.0634C12.9873 13.9767 12.5517 14.0861 12.1806 14.3088C12.1231 14.3433 12.0649 14.377 12.0062 14.4097C11.6286 14.62 11.3166 14.9423 11.1798 15.3524L10.8892 16.2244C10.6851 16.8368 10.1118 17.25 9.46618 17.25H8.53475C7.88907 17.25 7.31586 16.8368 7.11169 16.2244L6.82103 15.3524C6.68434 14.9423 6.37236 14.62 5.99473 14.4097C5.93598 14.377 5.87781 14.3433 5.82025 14.3088C5.44923 14.0861 5.01358 13.9767 4.58962 14.0634L3.68835 14.2478C3.05581 14.3773 2.41141 14.0875 2.08859 13.5283L1.62285 12.7217C1.30003 12.1625 1.37122 11.4595 1.79959 10.9764L2.41086 10.2871C2.69729 9.96405 2.82108 9.53333 2.81424 9.10163C2.81317 9.0339 2.81317 8.9661 2.81424 8.8983C2.82108 8.46667 2.6973 8.03588 2.41086 7.71285L1.79959 7.02352C1.37122 6.54044 1.30003 5.83746 1.62285 5.27831L2.08859 4.47164C2.41141 3.91249 3.05581 3.62265 3.68835 3.75208L4.58968 3.93653C5.01364 4.02329 5.44928 3.91393 5.8203 3.6912C5.87784 3.65665 5.93599 3.62302 5.99473 3.59032Z"
      fill={fill1}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 11.25C10.2427 11.25 11.25 10.2427 11.25 9C11.25 7.75732 10.2427 6.75 9 6.75C7.75732 6.75 6.75 7.75732 6.75 9C6.75 10.2427 7.75732 11.25 9 11.25Z"
      fill={fill2}
    />
  </svg>
);
