import React, { useEffect, useState } from "react";
import BuilderTab from "./builder/BuilderTab";
import { UIConstants } from "data/constants/uiConstants";
import Typography from "utils/typography";
import { FaPencilAlt } from "react-icons/fa";
import BottomBar from "./BottomBar";
import PreviewCard from "./PreviewCard";
import { useMutation, useQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import { createInsightQuestion } from "services/insightService";
import { useSearchParams } from "react-router-dom";
import useSurveyStore from "store/insightStore";
import PreLoader from "components/Loader/PreLoader";
import { getInsight } from "services/insightService";

const CustomiseSurvey = ({ step, setStep }) => {
  const [open, setOpen] = useState(null);
  const [params] = useSearchParams();
  const insightId = params.get("insightId");
  const { screenConfig, surveyQuestionsArray, setSurveyQuestionsArray } = useSurveyStore();
  // const location = useLocation();

  // const locationArray = location.pathname.split("/")

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const { mutate, isLoading } = useMutation(createInsightQuestion, {
    onSuccess: () => {
      Toast.success("Progress Saved!");
      setStep(step + 1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  // const {data: insightData} = useQuery(["getInsight-Question"] , () => getInsight("nps"))

  // const fetchedQuestion = insightData?.insights[0]?.insightQuestionConfig?.question

  // useEffect(() => {
  //   if (fetchedQuestion?.length > 0) {
  //     setSurveyQuestionsArray([...fetchedQuestion]);
  //   }
  // }, [fetchedQuestion, setSurveyQuestionsArray]);

  // console.log(fetchedQuestion, "fetchedQuestion")

  const handleSubmit = () => {
    const questionData = {
      insightId,
      screenConfig,
      question: surveyQuestionsArray,
    };

    mutate(questionData);
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="h-[70vh] flex items-start gap-[2em]">
      <div
        className={`w-[30%] border-[1px] shadow-md h-[100%] overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <BuilderTab />
      </div>

      <div
        className={`w-[70%] border-[1px] shadow-md h-[100%] bg-neutral-100  overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <div className="h-[50px] border-b-[1px] flex items-center justify-between px-4 bg-white">
          <div className="text-sm flex items-center">
            <Typography.SubText className="font-semibold">freshData?.surveyDetails?.surveyName</Typography.SubText>

            <div
              className="hover:bg-gray-200 p-2 cursor-pointer rounded"
              onClick={() => handleModal("openSurveyModal")}
            >
              <FaPencilAlt className="text-gray-600" />
            </div>
          </div>
        </div>

        <div className="h-[calc(100%-50px)] flex items-center justify-center">
          <PreviewCard />
        </div>
      </div>

      <BottomBar step={step} setStep={setStep} handleSave={handleSubmit} handleModal={() => {}} disableButton={false} />
    </div>
  );
};

export default CustomiseSurvey;
