export const subPlans = [
  {
    title: "Business Plan",
    subtitle: "Ideal for scale-ups and e-commerce",
    tag: "Popular",
    category: "researchHub",
    plan: "business",
    planId: "666b21d326ecccaade42c889",
    responses: 200,
    info: "Send up to 200 online surveys every month",
    amount: 500,
    featureTitle: "Key plan features",
    features: [
      "Access to online survey builder and audience panel.",
      "Real-time access to survey results with dynamic charts.",
      "Showcase customer reviews, feedback, and easily address concerns online.",
      "Generate QR code for easy review collection.",
    ],
  },
  {
    title: "Pro Plan",
    subtitle: "Ideal for consumer brands, retailers, agencies, and enterprises",
    tag: "",
    plan: "pro",
    planId: "666b21d726ecccaade42c88b",
    category: "researchHub",
    responses: 1000,
    info: "Send up to 1,000 online surveys every month",
    amount: 5000,
    featureTitle: "Everything in the Business plan plus, ",
    features: [
      "Access to 100 field interviews (voice/video responses)",
      "Access to 100 field interviews (text responses)",
      "250 brand tacking surveys, gather feedback from customers and track changes over time.",
    ],
  },
];

export const engagementPlans = [
  {
    title: "Starter Plan",
    subtitle: "Ideal for SMEs",
    tag: "",
    responses: 200,
    plan: "starter",
    planId: "666b222326ecccaade42c891",
    category: "engagementHub",
    info: "Manage up to 1,000 contacts every month",
    amount: 25,
    featureTitle: "Key plan features",
    features: [
      "Access to 1,000 customer insight invites, with Customer profiles, Net Promoter Score (NPS) and Customer Satisfaction (CSAT) templates.",
      "Access to multi-messaging channels with 8,000 emails, 4,000 SMS and 100 call minutes.",
      "Access to online survey builder and audience panel.",
      "Real-time access to survey results with dynamic charts.",
      "Showcase customer reviews, feedback, and easily address concerns online.",
      "Generate QR code for easy review collection.",
      "Single user access",
    ],
  },
  {
    title: "Business Plan",
    subtitle: "Ideal for scale-ups and e-commerce",
    tag: "Popular",
    responses: 200,
    plan: "business",
    planId: "666b221e26ecccaade42c88f",
    category: "engagementHub",
    info: "Manage up to 20,000 contacts every month",
    amount: 250,
    featureTitle: "Key plan features",
    features: [
      "Access to 10,000 customer insight invites, with Customer profiles, Net Promoter Score (NPS) and Customer Satisfaction (CSAT) templates.",
      "Access to multi-messaging channels with 80,000 emails, 40,000 SMS and 1,000 call minutes.",
      "Access to online survey builder and audience panel.",
      "Real-time access to survey results with dynamic charts.",
      "Showcase customer reviews, feedback, and easily address concerns online.",
      "Generate QR code for easy review collection.",
      "Team collaboration (up to 3 workspaces and 5 members each).",
      "Access to campaign and research support.",
    ],
  },
  {
    title: "Pro Plan",
    subtitle: "Ideal for consumer brands, retailers, agencies, and enterprises",
    tag: "",
    responses: 1000,
    plan: "pro",
    planId: "666b221926ecccaade42c88d",
    category: "engagementHub",
    info: "Manage up to 200,000 contacts every month",
    amount: 2500,
    featureTitle: "Key plan features",
    features: [
      "Access to 100,000 customer insight invites, with Customer profiles, Net Promoter Score (NPS) and Customer Satisfaction (CSAT) templates.",
      "Access to multi-messaging channels with 800,000 emails, 400,000 SMS and 10,000 call minutes.",
      "Access to online survey builder and audience panel.",
      "Real-time access to survey results with dynamic charts.",
      "Showcase customer reviews, feedback, and easily address concerns online.",
      "Generate QR code for easy review collection.",
      "Unlimited workspaces and team members.",
      "Access to campaign and research support.",
    ],
  },
];

export const researchData = [
  { plan: "Business Plan", cost: 500, planId: "666b21d326ecccaade42c889", package: ["200 online survey responses"] },
  {
    plan: "Pro Plan",
    cost: 5000,
    planId: "666b21d726ecccaade42c88b",
    package: [
      "1,000 online survey responses",
      "250 Brand tracking responses",
      "200 field interviews responses (Voice and text)",
    ],
  },
];

export const crmData = [
  {
    plan: "Starter Plan",
    cost: 25,
    planId: "666b222326ecccaade42c891",
  },
  {
    plan: "Business Plan",
    cost: 250,
    planId: "666b221e26ecccaade42c88f",
  },
  {
    plan: "Pro Plan",
    cost: 2500,
    planId: "666b221926ecccaade42c88d",
  },
];
