import React, { useState } from "react";
import { IoPersonCircleSharp } from "react-icons/io5";
import dayjs from "dayjs";
import { serializeMessage } from "lib/personalizationFunctions";
import useLoggedInUser from "hooks/useLoggedInUser";
import message from "assets/images/broadcast/message.png";
import pen from "assets/images/broadcast/pe.png";
import EditContentModal from "./EditModal";

const SMSPreview = ({ data, setData, setStep }) => {
  const { user } = useLoggedInUser();
  const [openModal, setOpenModal] = useState();
  const handleClicked = () => {
    setData((prevData) => ({ ...prevData, ...data }));
    setStep(0);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  console.log(data, "data");

  return (
    <div className="flex items-start justify-between">
      <div className="w-[55%]">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-[18px] text-[#0F172A] tracking-[-0.02em] font-semibold">Preview </p>
            <p>Confirm the details of the SMS before publishing.</p>
          </div>

          {/* <div className="text-right">
            <p className="text-s font-[400]">Available credit</p>

            <div className="flex items-center space-x-2 float-right">
              <img src={Coin} alt="Coin" />
              <p className="bg-gradient-to-r from-[#4568DC] to-[#B06AB3] text-transparent bg-clip-text font-semibold text-[24px]">
                {isLoading ? <Skeleton /> : wsCredit?.balance || 0}
              </p>
            </div>
          </div> */}
        </div>

        <div className="w-full rounded-[8px] border flex items-center justify-between p-5 mt-5">
          <div className="flex items-center space-x-5">
            <div className="w-[65px] h-[45px] flex items-center justify-center bg-primary-100 rounded-lg p-5">
              <img src={message} alt="" />
            </div>
            <div className="space-y-2">
              <p className="text-[14px] font-bold">{data.campaign_name}</p>
              <p className="text-[12px] text-gray-500 capitalize">{data.category}</p>
            </div>
          </div>
          <div className="w-[25px] h-[20px] cursor-pointer" onClick={handleModal}>
            <img src={pen} alt="" />
          </div>
        </div>

        <div className="p-5 border rounded-[8px] mt-5">
          <div className="">
            <p className="text-[15px] tracking-[-0.02em] font-semibold">Summary</p>
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Total units</p>
              <p className="">{data.personal}</p>
            </div>
            <hr />
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Audience</p>
              <div className="flex items-center space-x-2">
                {data?.recipientArray?.map((el, i) => {
                  return (
                    <p className="text-[12px] p-2 bg-gray-200 rounded-md " key={i}>
                      {el.name}
                    </p>
                  );
                })}
              </div>
            </div>
            <hr />
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Reach</p>

              <p className="text-right">{data.personal}</p>
            </div>

            {/* <p className="text-[15px] tracking-[-0.02em] font-semibold pt-5">Audience</p>

            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Reach</p>

              <p className="text-right">{data.personal}</p>
            </div>

            <div className="text-m flex justify-between border-b-[1px] py-4 text-right">
              <p className=" text-[#737373] font-[450]">Tags</p>
              <div className="flex items-center space-x-2">
                {data?.recipientArray?.map((el, i) => {
                  return (
                    <p className="text-[12px] p-2 bg-gray-200 rounded-md " key={i}>
                      {el.name}
                    </p>
                  );
                })}
              </div>
            </div>
            <p className="text-[15px] tracking-[-0.02em] font-semibold pt-5">Cost breakdown</p> */}

            {/* <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Number of SMS</p>

              <p className="text-right">{data.personal}</p>
            </div> */}
            {/* <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Price per SMS</p>
              <p className="text-right">{smsCost}</p>
            </div> */}
            {/* <div className="text-m flex items-center justify-between py-3">
              <p className="font-[450]">Total amount</p>
              <div className="flex items-center space-x-2 float-right">
                <img src={Coin} alt="Coin" />
                <p className="text-primary-800 font-semibold text-[24px]">{data?.personal * smsCost}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="bg-[#FAFAFA] flex flex-col items-center w-[400px]">
        <div className="bg-white flex items-center justify-center drop-shadow-md py-3 px-2 w-full">
          <p>Preview</p>
        </div>
        <div className="border-[2px]  border-black bg-white  w-[300px] h-[60vh] items-end rounded-[48px] p-2 pb-0 mt-10">
          <div className="border-[2px]  border-[#D0D5DD]  w-[100%] h-[98%] items-end rounded-[40px] overflow-hidden">
            <div className="bg-[#F5F5F5] h-[90px] flex items-center justify-center flex-col space-y-1">
              <div className="bg-[#969AA5] w-[40px] h-[40px] mx-auto rounded-full flex justify-center items-center">
                <div className=" bg-[#969AA5]  flex items-center justify-center rounded-full overflow-hidden w-[40px] h-[30px]">
                  <IoPersonCircleSharp className="text-[28px] text-white" />
                </div>
              </div>

              <p className="text-s font-[400]">{data.sender_name || "Select a sender ID"}</p>
            </div>

            <div className="text-center space-y-[1px] mt-2 text-ss">
              <p>Text message</p>
              <p>{dayjs().format("h:mm a")}</p>
            </div>

            <div className="bg-[#E9E9EB] m-3 p-3 rounded-[10px] min-h-[100px]">
              <p className="text-s">
                {data.content.length < 1
                  ? "Enter your SMS text to see preview."
                  : serializeMessage(data.content, user?.data)}
              </p>
            </div>
          </div>
        </div>
      </div>
      {openModal ? <EditContentModal data={data} setData={setData} handleOpen={handleModal} /> : null}
    </div>
  );
};

export default SMSPreview;
