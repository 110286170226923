export const creditData = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

export const surveyRecurrence = [
  // { value: "bi-weekly", label: "BiWeekly" },
  { value: "Weekly", label: "Week" },
  { value: "monthly", label: "Month" },
  { value: "quarterly", label: "Quarter" },
  { value: "yearly", label: "Year" },

  // { value: "hourly", label: "Hourly" },
];

const interestData = [
  { id: "1", name: "Business" },
  { id: "2", name: "Accommodation" },
  { id: "3", name: "Banking" },
  { id: "4", name: "School" },
  { id: "5", name: "Supermarket" },
  { id: "6", name: "Spa" },
  { id: "7", name: "Restaurant" },
  { id: "8", name: "Finance" },
  { id: "9", name: "Driving" },
  { id: "10", name: "Hotel" },
  { id: "11", name: "Transport" },
  { id: "12", name: "Logistics" },
  { id: "13", name: "Freelancer" },
  { id: "14", name: "Gas" },
  { id: "15", name: "Electricity" },
  { id: "16", name: "Networking" },
];

export const frequencyData = ["One-time", "Recurring survey"];

export const newIntrestData = interestData.map((el) => ({
  name: el.name,
  label: el.name,
}));
