import Modal from "components/Modal";
import React from "react";
import loyaltyEmail from "assets/images/dashboard/loyaltyEmail.png";
import loyaltyMessage from "assets/images/dashboard/loyaltyMessage.png";
import loyaltyVolume from "assets/images/dashboard/loyaltyVolum.png";
import { useNavigate } from "react-router-dom";

const CustomerLoyaltyModal = ({ handleModal, setOpen, flag }) => {
  const data = [
    {
      name: "Email",
      content: "Connect and share messages with your uploaded audience to expand your reach.",
      img: loyaltyEmail,
      link: "/broadcasts/email/setup",
    },
    {
      name: "SMS",
      content: "Connect and share messages with your uploaded audience to expand your reach.",
      img: loyaltyMessage,
      link: "/broadcasts/sms/create",
    },
    {
      name: "Voice",
      content: "Connect and share messages with your uploaded audience to expand your reach.",
      img: loyaltyVolume,
      link: "/broadcasts/voice/create",
    },
  ];
  const navigate = useNavigate();
  return (
    <Modal title={flag === "QA1" ? "Drive customer action" : "Drive customer loyalty"} onClose={handleModal}>
      <p className="py-1 px-5 text-[14px] font-[500]">How would you like to share your campaign?</p>
      <div className="flex items-center space-x-5 p-5">
        {data.map((el, i) => (
          <div
            key={i}
            className="w-[268px] border hover:border-blue-800 rounded-md overflow-hidden cursor-pointer"
            onClick={() => {
              flag === "QA1" ? navigate(el.link) : el.name === "SMS" ? setOpen(i) : navigate(el.link);
            }}
          >
            <div className="w-full bg-gray-50 hover:bg-blue-50 h-[119px] flex items-center justify-center">
              <div className="w-[80px] h-[80px]">
                <img src={el.img} alt="" />
              </div>
            </div>
            <div className="text-center space-y-2 p-5">
              <p className="text-[14px] font-[600]">{el.name}</p>
              <p className="text-[12px] font-light">{el.content}</p>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CustomerLoyaltyModal;
