import { surveyIcons } from "lib/surveyFunctions";
import React from "react";
import { FaTimes } from "react-icons/fa";

const QuestionsModal = ({ data, handleDropdown }) => {
  return (
    <div className="bg-grey fixed top-0 bottom-0 left-0 h-[100vh] w-full z-[99]">
      <div className="flex flex-col justify-between h-[100%]">
        <div></div>

        <div className="bg-white w-[100%] rounded-tr-3xl rounded-tl-3xl py-[2em]">
          <div className="flex justify-between items-center w-[80%] mx-auto mb-[2em]">
            <p>Questions</p>

            <FaTimes className="text-[20px] text-primary-800" onClick={handleDropdown} />
          </div>

          <div className="w-[80%] mx-auto border-[1px] rounded-md p-4 space-y-3 ">
            {data?.summary?.map((el, i) => {
              return (
                <a
                  href={`#question-${i}`}
                  className="flex space-x-2 text-[#525252] items-center cursor-pointer   h-[35px]"
                  key={i}
                >
                  <div>{surveyIcons(el.optionType)}</div>
                  <p className="text-ss">{i + 1}</p>
                  <p className="text-ss pl-4">{el.question}</p>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsModal;
