import React, { useState } from "react";
// import SurveyDetailsModal from "./components/SurveyDetailsModal";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typography from "utils/typography";
import overviewSms from "assets/images/broadcast/overviewIcons/overviewSms.png";
import overviewMessaging from "assets/images/broadcast/overviewIcons/overviewMessaging.png";

const cardData = [
  {
    id: 1,
    name: "Emails",
    value: "email",
    description: "Gather quality feedback, compose, send or schedule email surveys to your contacts.",
    image: overviewMessaging,
  },
  {
    id: 2,
    name: "SMS",
    value: "sms",
    description: "Gather quality feedback, compose, send or schedule SMS surveys to your contacts.",
    image: overviewSms,
  },
];
const DispatchChannel = ({ surveyData, setSurveyData, handleSave, handleClose }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState();

  const handleSelect = (el) => {
    setSurveyData((prevData) => ({
      ...prevData,
      dispatchChannel: el,
    }));
    setSelected(el);
  };

  const handleNext = () => {
    console.log(surveyData);
    handleSave();
  };
  return (
    <div className="w-[800px]">
      <Typography.SubText className="px-6">Choose an option to share your survey.</Typography.SubText>
      <div className="bg-white h-full rounded border-[1px] p-6 mt-3">
        <div className="flex items-center justify-center space-x-7 py-5">
          {cardData.map((el) => (
            <div
              key={el.id}
              className={`border-[1px] rounded-md h-[280px] w-full flex flex-col items-center overflow-hidden cursor-pointer ${
                el.value === selected ? "border-primary-800" : ""
              }`}
              onClick={() => handleSelect(el.value)}
            >
              <div
                className={`${
                  el.value === selected ? "bg-primary-50" : "bg-gray-50"
                } hover:bg-primary-50 h-[55%] w-[100%] flex items-start justify-between  p-4`}
              >
                <div className="w-[90%] h-[100%] flex items-center justify-center">
                  <img src={el.image} alt={el.name} className="w-[80px] mx-auto" />
                </div>

                <div
                  className={`border-[1px] rounded-full  h-[15px] w-[15px] flex items-center justify-center ${
                    el.value === selected ? "border-primary-800" : "border-gray-400"
                  }`}
                >
                  {el.value === selected ? <div className="h-[9px] w-[9px] rounded-full bg-primary-800"> </div> : null}
                </div>
              </div>

              <div className="text-center p-4  space-y-2 h-[45%] flex items-center flex-col justify-center w-[100%]">
                <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>

                <Typography.MicroText className="w-[90%] mx-auto ">{el.description}</Typography.MicroText>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={`flex justify-between border-t-[1px] px-4 pt-5 items-center bg-white  transition ease-in`}>
        <div onClick={handleClose}>
          <GreyButton text="Cancel" className="w-[140px]" />
        </div>

        <div className="flex justify-between space-x-4">
          <BlueButton text={"Next"} className="h-[40px] text-s w-[140px]" onClick={handleNext} />
        </div>
      </div>
    </div>
  );
};

export default DispatchChannel;
