import { CalenderIcon } from "assets/icons/sidebar";
import { TimeIcon } from "assets/icons/survey/TimeIcon";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

const Responses = ({ responseCount, responsesData, isFetchingResponses, currentPage, setCurrentPage }) => {
  const newData = responsesData?.data?.data[0];
  const timeFormat = moment(newData?.responseTime?.endTime);

  function getTimeDifference(startTime, endTime, threshold = 60) {
    const startTimeObj = dayjs(startTime);
    const endTimeObj = dayjs(endTime);

    const secondsDifference = endTimeObj.diff(startTimeObj, "second");
    const minutesDifference = endTimeObj.diff(startTimeObj, "minute");

    if (secondsDifference < threshold) {
      return { value: secondsDifference, unit: "seconds" };
    } else {
      return { value: minutesDifference, unit: "minutes" };
    }
  }

  const { value, unit } = getTimeDifference(newData?.responseTime?.startTime, newData?.responseTime?.endTime);
  const handleNext = () => {
    if (currentPage !== responsesData?.data?.count) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <div>
          <Typography.SubText className="font-semibold">Response Summary</Typography.SubText>
        </div>

        <div className="flex items-center space-x-4">
          <CgArrowLongLeft
            type="button"
            text="Prev"
            disabled={currentPage === 1 || isFetchingResponses}
            onClick={handleBack}
          />

          <p className="text-s font-semibold">
            {currentPage} out of {responseCount} results
          </p>

          <CgArrowLongRight
            onClick={handleNext}
            disabled={currentPage === responsesData?.data?.count || isFetchingResponses}
            text="Next"
            type="button"
          />
        </div>
      </div>

      <div className="p-3 border-t-[1px] border-gray-400 mt-[1em]">
        {newData?.responses?.map((elm, i) => {
          return (
            <div key={`singleResponses${elm}`} className="flex p-4 bg-[#FAFAFA] my-4 justify-between text-s">
              <div className="flex  w-[40%] space-x-2">
                {" "}
                <p>{i + 1}.</p>
                <p className="">{isFetchingResponses ? <Skeleton /> : elm.question}</p>
              </div>

              <div className="flex flex-col space-y-1 w-[55%]">
                {elm.response.map((el) => {
                  return <p key={`response${el}`}>{isFetchingResponses ? <Skeleton /> : el}</p>;
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex items-center justify-center space-x-4 py-[2em]">
        <div className="flex items-center space-x-2">
          <CalenderIcon fill1="#95ADFF" fill2="#1D19AF" />
          <Typography.MicroText className="font-semibold">
            {isFetchingResponses ? <Skeleton /> : false ? "" : timeFormat.format(`MMMM Do, YYYY | H:mm a`).toString()}{" "}
            GMT+1
          </Typography.MicroText>
        </div>

        <div className="flex items-center space-x-2">
          <TimeIcon fill1="#95ADFF" fill2="#1D19AF" />
          <Typography.MicroText className="font-semibold">{`${value} ${unit}`}</Typography.MicroText>
        </div>
      </div>
    </div>
  );
};

export default Responses;
