import { handleApiError } from "utils/handleApiError";
import Api from "utils/axios";

export const createSMSId = async (payload) => {
  try {
    const { data } = await Api.post("/workspace-microservice/sender-id", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllSMSId = async () => {
  try {
    const { data } = await Api.get("/workspace-microservice/sender-ids");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteSMSId = async (id) => {
  try {
    const { data } = await Api.delete(`/workspace-microservice/sender-id/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteEmailId = async (payload) => {
  try {
    const { data } = await Api.put(`/workspace-microservice/workspace/sender-email/delete`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createEmailId = async (payload) => {
  try {
    const { data } = await Api.post("/workspace-microservice/workspace/sender-email", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllEmailId = async () => {
  try {
    const { data } = await Api.get("/workspace-microservice/workspace/sender-email");
    return data?.data?.sender_mail;
  } catch (error) {
    throw handleApiError(error);
  }
};
