export const brandTrackingTemplate = [
  {
    question: "In the past six months, have you purchased any [Product/Service]",
    description: "",
    questionType: "single_choice",
    optional: false,
    options: ["Yes", "No"],
  },

  {
    question: "Please list any brands that come to mind when you think about [Product/Service].",
    description: "(Share your thoughts)",
    questionType: "text_response",
    optional: false,
    options: [],
  },

  {
    question: "Have you heard of the following brands in the [Product/Service] category?",
    description: "Please check all that apply.",
    questionType: "multi_choice",
    optional: false,
    options: ["[Brand Name]", "[Competitor 1]", "[Competitor 2]"],
  },

  {
    question:
      "Considering your needs for [Product/Service], which of these brands would you think about purchasing from in the next six months?",
    description: "Please select all that apply",
    questionType: "multi_choice",
    optional: false,
    options: ["[Brand Name]", "[Competitor 1]", "[Competitor 2]", "Not sure / None of the above"],
  },

  {
    question: "How likely are you to purchase from [Brand Name] in the next six months?",
    description: "",
    questionType: "single_choice",
    optional: false,
    options: ["Very Unlikely", "Unlikely", "Neutral", "Likely", "Very Likely"],
  },

  {
    question: "Please rate your perception of [Brand Name] on Quality",
    description: "",
    questionType: "single_choice",
    optional: false,
    options: ["Poor", "Below Average", "Average", "Good", "Excellent"],
  },

  {
    question: "Please rate your perception of [Brand Name] on Value for Money",
    description: "",
    questionType: "single_choice",
    optional: false,
    options: ["Poor", "Below Average", "Average", "Good", "Excellent"],
  },

  {
    question: "In the past six months, how often have you purchased from [Brand Name]?",
    description: "",
    questionType: "single_choice",
    optional: false,
    options: ["None", "1-2 times", "3-5 times", "More than 5 times"],
  },

  {
    question:
      "Based on your experience, how likely are you to continue purchasing from [Brand Name] in the next six months?",
    description: "",
    questionType: "single_choice",
    optional: false,
    options: ["Very Unlikely", "Unlikely", "Neutral", "Likely", "Very Likely"],
  },
];
