import React from "react";
import { BsSearch } from "react-icons/bs";
import { RiFilter2Fill } from "react-icons/ri";
import edit from "assets/icons/edit.png";
import outGoingCall from "assets/icons/phone-call-outgoing.png";
import smsGrey from "assets/icons/smsGrey.png";
import emailGrey from "assets/icons/emailGrey.png";
import activityIcon from "assets/images/contacts/activityIcon.png";
import { formatDate } from "lib/formatDate";

const dummyData = [
  {
    date: "March 26th, 2024",
    info: [
      { channel: "call", descr: "outgoing call for", time: "1m:02secs", dateCreatedAt: "4:00 pm" },
      { channel: "edit", descr: "Edited contact details", dateCreatedAt: "4:00 pm" },
      { channel: "email", descr: "Opened the email", type: "Discount sales", dateCreatedAt: "4:00 pm" },
      { channel: "sms", descr: "Received an SMS", type: "Checking up", dateCreatedAt: "4:00 pm" },
    ],
  },
  {
    date: "March 27th, 2024",
    info: [
      { channel: "email", descr: "Opened the email", type: "Welcome", dateCreatedAt: "4:00 pm" },

      { channel: "call", descr: "outgoing call for", type: "No response", dateCreatedAt: "4:00 pm" },
    ],
  },
];

const iconSelect = (icon) => {
  switch (icon) {
    case "call":
      return outGoingCall;
    case "edit":
      return edit;
    case "email":
      return emailGrey;
    case "sms":
      return smsGrey;
    default:
  }
};

const Activity = ({ data }) => {
  return (
    <div>
      <div>
        <div className="flex items-center justify-between">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search activity... "
              // onChange={handleChange}
            />
          </div>

          <div className="flex space-x-3 ">
            <button
              className={`flex items-center text-gray-500 border rounded-[6px] px-[15px] py-[9px] text-s space-x-2 
                `}
              // onClick={active ? () => toggleModal(2) : null}
            >
              <p>Filter by</p>
              <RiFilter2Fill />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-7 space-y-7">
        {data?.length !== 0 ? (
          // dummyData.map((el, i) => (
          //   <div className=" space-y-3" key={i}>
          //     <p>{el.date}</p>
          //     <div className="space-y-7">
          //       {el?.info?.map((e, j) => (
          //         <div key={j} className="flex items-start space-x-2 pb-4 border-b">
          //           <div className="w-[30px] h-[30px] rounded-full bg-gray-100 p-[6px]">
          //             <img src={iconSelect(e.channel)} alt="" />
          //           </div>
          //           <div>
          //             <p className="text-[14px] text-[#404040]">
          //               {e.descr} <span className="text-[#15803D]"> {e.time}</span>{" "}
          //               <span className={`${el.type === "No response" ? "text-red-500" : "text-primary-800"}`}>
          //                 {" "}
          //                 {e.type}
          //               </span>
          //             </p>
          //             <p className="text-[12px] text-gray-400">{e.dateCreatedAt}</p>
          //           </div>
          //         </div>
          //       ))}
          //     </div>
          //   </div>
          // ))
          <div className="p-3 space-y-3">
            {data.map((el, i) => (
              <div className="bg-gray-50 w-full p-3 space-y-3">
                <p className="font-[600] text-blue-800 text-xs">{el.message}</p>
                <p className="text-xs">{el.description}</p>
                <p className="text-xs">{formatDate(el.activityDate)}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[300px] flex flex-col gap-y-3 justify-center items-center">
            <div className="w-[81px]">
              <img src={activityIcon} alt="" />
            </div>
            <p>Looks like there are no activities to show yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Activity;
