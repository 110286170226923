import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import React from "react";

const ReasonModal = ({ toggleModal }) => {
  return (
    <Modal title="Reason" onClose={() => toggleModal(1)}>
      <div className="w-[400px] px-5 h-auto pt-[1em] pb-[2em]">
        We could not clearly see the details of your document. Please check and try again.
      </div>

      <div className="flex justify-between">
        <div></div>
        <BlueButton text="Try Again" onClick={() => toggleModal(1)} />
      </div>
    </Modal>
  );
};

export default ReasonModal;
