import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const MobileHeader = ({ handleModal }) => {
  return (
    <div className="bg-grey fixed top-0 bottom-0 left-0 h-[100vh] w-full z-20">
      <div className="bg-white w-[80%] mx-auto flex flex-col items-center mt-[10%] space-y-[2em] rounded-xl py-[2em]">
        <div className="flex items-center justify-between w-[80%]">
          <div></div>
          <FaTimes className="text-[20px] text-primary-800" onClick={handleModal} />
        </div>

        <Link to="/auth/login">
          <button className="w-[170px] h-[40px] bg-green text-primary-800 text-s rounded-md">Sign in</button>
        </Link>

        <Link to="/auth/register">
          <button className="w-[170px] h-[40px]  text-primary-800 text-s rounded-md border-[1px] border-purple">
            Register
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MobileHeader;
