import React from "react";

export const CalenderIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 3H3.75C2.50736 3 1.5 4.00736 1.5 5.25V14.25C1.5 15.4926 2.50736 16.5 3.75 16.5H14.25C15.4926 16.5 16.5 15.4926 16.5 14.25V5.25C16.5 4.00736 15.4926 3 14.25 3Z"
      fill={fill1}
    />
    <path
      d="M1.5 5.25C1.5 4.00736 2.50736 3 3.75 3H14.25C15.4927 3 16.5 4.00736 16.5 5.25V7.5H1.5V5.25Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 1.5C5.66421 1.5 6 1.83579 6 2.25V3.75C6 4.16421 5.66421 4.5 5.25 4.5C4.83579 4.5 4.5 4.16421 4.5 3.75V2.25C4.5 1.83579 4.83579 1.5 5.25 1.5Z"
      fill={fill1}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.75 1.5C13.1642 1.5 13.5 1.83579 13.5 2.25V3.75C13.5 4.16421 13.1642 4.5 12.75 4.5C12.3358 4.5 12 4.16421 12 3.75V2.25C12 1.83579 12.3358 1.5 12.75 1.5Z"
      fill={fill1}
    />
  </svg>
);
