import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useBroadcastStore = create(
  devtools((set, get) => ({
    emailTabs: {
      draft: 0,
      scheduled: 0,
      sent: 0,
    },
    setEmailTabs: (data) => {
      set((state) => ({ emailTabs: { ...state.emailTabs, ...data } }));
    },

    smsTabs: {
      draft: 0,
      scheduled: 0,
      sent: 0,
    },
    setSmsTabs: (data) => {
      set((state) => ({ smsTabs: { ...state.smsTabs, ...data } }));
    },

    voiceTabs: {
      draft: 0,
      scheduled: 0,
      sent: 0,
    },
    setVoiceTabs: (data) => {
      set((state) => ({ voiceTabs: { ...state.voiceTabs, ...data } }));
    },
  })),
);
