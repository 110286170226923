import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import PreLoader from "components/Loader/PreLoader";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
import React, { useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ContactTable from "./ContactTable";
import { addContactToTag } from "services/contactService";
import GreyButton from "components/Button/GreyButton";
import { CreateTagSuccessModal } from "./CreateTagSuccessModal";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name too short")
    .max(25, "Name too long (25 characters max)")
    .matches(/^\S*$/, "Name should not contain any whitespace")
    .required("Please fill in this field"),
});

const CreateTag = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(null);
  const { sidebar } = useSideBarStore();
  const [selectArray, setSelectArray] = useState([]);
  const initialValues = {
    name: "",
    description: "",
  };

  const { mutate, isLoading } = useMutation(addContactToTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["allTags"]);
      Toast.success("Tag Created Successfully");
      handleModal(1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });
  const onSubmit = async () => {
    const payload = {
      tag: values.name,
      contactIds: selectArray,
    };
    mutate(payload);
  };

  const handleDone = () => {
    handleModal(1);
    navigate("/contacts/all-contacts?tab=1");
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const disableButton = !values.name;

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  if (isLoading) {
    return <PreLoader text="Creating your Tag" />;
  }
  return (
    <div className="p-5 rounded-md bg-white border w-[100%]">
      <div onClick={() => navigate(-1)} className="flex items-center space-x-1 my-2 text-primary-800 cursor-pointer">
        <FiChevronLeft />
        <p>Back to contacts</p>
      </div>
      <div className="flex items-start space-x-2">
        <div className="w-[24px] mt-2 h-[24px] rounded-full bg-primary-800 text-white flex items-center justify-center">
          <p>1</p>
        </div>
        <div>
          <p className="text-[24px] font-[500]">Create new tag</p>
          <p>Enter a name and a short description for your new tag below.</p>
        </div>
      </div>
      <form action="" onSubmit={handleSubmit} className="w-[100%]">
        <div className="mt-5 flex items-center space-x-5">
          <div className="w-full">
            <SecondaryInput
              text="Tag Name"
              placeholder="Enter tag name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              name="name"
              error={getError("name")}
              type="text"
            />
            <p className="text-ss text-gray-400">E.g Creators, Influencers, leaders.</p>
          </div>
          <div className="w-full">
            <SecondaryInput
              text="Description (optional)"
              placeholder="Describe your tag"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              name="description"
              error={getError("description")}
              type="text"
            />
            <p className="text-ss text-gray-400">Describe your tag with a few words to make it easy to identify.</p>
          </div>
        </div>
        {/* <div className="w-full my-5">
          <div className="my-4 mt-[1em]">
            <div className="flex">
              <BlueButton text={"Create"} type="submit" onClick={handleSubmit} />
            </div>
          </div>
        </div> */}
        <div
          className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
            sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
          }`}
        >
          <div></div>

          <div className="flex justify-between space-x-4">
            <GreyButton text="Cancel" onClick={() => navigate(-1)} />

            <BlueButton text={`Save`} disabled={disableButton} type="submit" />
          </div>
        </div>
      </form>
      <hr className="mt-5" />
      <div className="flex items-start space-x-2 mt-5">
        <div className="w-[24px] mt-2 h-[24px] rounded-full bg-primary-800 text-white flex items-center justify-center">
          <p>2</p>
        </div>
        <div>
          <p className="text-[24px] font-[500]">Add tag to contacts</p>
          <p>Label your contacts to easily identify them</p>
        </div>
      </div>
      <div className="mt-5">
        <ContactTable selectArray={selectArray} setSelectArray={setSelectArray} />
      </div>
      {openModal === 1 ? <CreateTagSuccessModal handleModal={handleDone} /> : null}
    </div>
  );
};

export default CreateTag;
