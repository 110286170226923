import React from "react";
import Skeleton from "react-loading-skeleton";

export const SummarySkeleton = () => {
  return (
    <div className="w-[100%] p-4 space-y-3">
      {[1, 2].map((_, i) => (
        <div className="border-[1px] p-4 rounded-md" key={`SummarySkeleton-${i}`}>
          <Skeleton className="h-[30px]" />
          <Skeleton className="h-[50px]" />
          <Skeleton className="h-[20px] w-[70%] my-4" />
          <Skeleton className="h-[20px]" />
          <Skeleton className="h-[20px]" />
        </div>
      ))}
    </div>
  );
};

export const QuestionSkeleton = () => {
  return (
    <div className="w-[100%] pt-2 space-y-2 overflow-hidden">
      {[1, 2, 3, 4, 5].map((_, i) => (
        <div className="w-[100%]" key={`SummarySkeleton-${i}`}>
          <div className="flex w-[100%] space-x-2">
            <Skeleton className="h-[20px] w-[40px]" />
            <Skeleton
              className="h-[20px] "
              style={{
                width: 500,
              }}
            />
          </div>

          <Skeleton className="h-[20px] w-[100%]" />

          <hr className="border-t-[1px] my-3 " />
        </div>
      ))}
    </div>
  );
};
