import React, { useMemo, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdOutlineModeEdit } from "react-icons/md";
import { deleteContacts, getContactsBySegment } from "services/contactService";
import { useMutation, useQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import AllContacts from "../AllContacts";
import { queryClient } from "config/gateway";
import EditSegmentationModal from "pages/Contacts/EditSegmentModal";

const SingleSegment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(null);
  const [show, setShow] = useState(false);

  const deleteContactsFunc = (data) => {
    deleteMutate({ contactId: data });
  };

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  console.log(state);

  const {
    data: contact,
    isLoading: loadingContacts,
    isFetching: isFetchingContacts,
  } = useQuery(["tagContacts", contactCurrentPage], () => getContactsBySegment(id, contactCurrentPage), {
    onError: (error) => {
      Toast.error(error);
    },
    refetchOnMount: true,
  });

  const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation(deleteContacts, {
    onSuccess: () => {
      Toast.success("Contact(s) deleted successfully!!");
      queryClient.invalidateQueries(["allContacts"]);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = contact?.totalContacts;

    let startValue = (contactCurrentPage - 1) * 10;
    startValue = startValue + 1;

    let endValue = Math.min(contactCurrentPage * 10, totalValue);
    return [startValue, endValue];
  }, [contactCurrentPage, contact?.totalContacts]);

  const handlePrev = () => {
    setContactCurrentPage(contactCurrentPage - 1);
  };

  const handleNext = () => {
    setContactCurrentPage(contactCurrentPage + 1);
  };

  return (
    <div className="border rounded-md px-5 py-5 bg-white">
      <div
        onClick={() => navigate(-1)}
        className="flex items-center space-x-1 my-2 text-primary-800 pl-2 cursor-pointer"
      >
        <FiChevronLeft />
        <p>Back to segment</p>
      </div>
      <div className="flex items-start space-x-2 mt-5">
        <div>
          <p className="text-[24px] font-[500]">{state?.name}</p>
        </div>
        <div
          className="w-[24px] mt-2 h-[24px] rounded-md bg-white border flex items-center justify-center relative"
          onClick={() => handleModal(1)}
        >
          <p
            className="text-gray-500 cursor-pointer "
            onMouseEnter={() => setShow(!show)}
            onMouseLeave={() => setShow(false)}
          >
            <MdOutlineModeEdit />
          </p>
          {show ? (
            <div className="absolute top-5 bg-white border rounded-md text-blue-800 text-xs  py-1 px-2 w-[50px] justify-center flex items-center">
              Edit
            </div>
          ) : null}
        </div>
      </div>
      <p>{state?.description}</p>
      <div className="mt-5">
        <div className="border-[1px]"></div>
        <AllContacts
          data={contact}
          isLoading={loadingContacts}
          pageCountStart={pageCountStart}
          pageCountEnd={pageCountEnd}
          currentPage={contactCurrentPage}
          handlePrev={handlePrev}
          handleNext={handleNext}
          isFetchingContacts={isFetchingContacts}
          loadingDelete={loadingDelete}
          deleteContactsFunc={deleteContactsFunc}
          tableType="special"
        />
      </div>
      {openModal === 1 ? <EditSegmentationModal toggleModal={handleModal} id={state?._id} /> : null}
    </div>
  );
};

export default SingleSegment;
