import React from "react";

export const FieldIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 10.2505C16.5 11.4932 15.4927 12.5005 14.25 12.5005H6.75C5.50736 12.5005 4.5 11.4932 4.5 10.2505V5.75053C4.5 4.50789 5.50736 3.50053 6.75 3.50053H12L14.1679 2.05523C15.1648 1.39067 16.5 2.10526 16.5 3.3033V10.2505Z"
      fill={fill2}
    />
    <path
      d="M1.5 8.75C1.5 7.50736 2.50736 6.5 3.75 6.5H11.25C12.4927 6.5 13.5 7.50736 13.5 8.75V13.25C13.5 14.4927 12.4927 15.5 11.25 15.5H5.25L3.93704 16.5504C2.9549 17.3361 1.5 16.6369 1.5 15.3791V8.75Z"
      fill={fill1}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 11C4.5 10.5858 4.83579 10.25 5.25 10.25H9.75C10.1642 10.25 10.5 10.5858 10.5 11C10.5 11.4142 10.1642 11.75 9.75 11.75H5.25C4.83579 11.75 4.5 11.4142 4.5 11Z"
      fill={fill2}
    />
  </svg>
);
