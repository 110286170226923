import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, Rectangle } from "recharts";

const COLORS = ["#1D19AF", "#4852FF", "#5F0BDD", "#00DA7A"];

const ProfilingStackedBarChart = ({ data }) => {
  console.log(data);
  const keys = data === undefined ? null : Object?.keys(data[0])?.filter((key) => key !== "name");

  return (
    <div className="w-[100%]">
      <div className="w-[100%] h-[270px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: -20,
              bottom: 5,
            }}
            barSize={40}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" fontSize={10} />
            <YAxis fontSize={10} />
            <Tooltip />
            {/* <Legend /> */}

            {keys.map((key, index) => (
              <Bar
                dataKey={key}
                stackId="a"
                fill={COLORS[index % 10]}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                radius={[2, 2, 0, 0]}
                width={20}
                label={{ position: "insideTop", fill: "white", fontSize: 10 }}
              >
                {/* {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % 10]} />
                ))} */}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="flex gap-1 flex-wrap mb-4 mx-auto absolute bottom-0 left-0 w-full ">
        {keys?.map((elm, index) => (
          <div
            key={`optionTag${index}`}
            className="flex items-center space-x-2 p-1 px-2 border-[1px] rounded-[4px] mx-auto"
          >
            <div className="w-[10px] h-[10px] rounded-sm" style={{ background: COLORS[index % COLORS.length] }}></div>
            <p className="text-ss">{elm === "MooyiOption" ? "Others" : elm}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfilingStackedBarChart;
