import React from "react";
export const Flutterwave = ({ fill1 }) => (
  <svg width="268" height="127" viewBox="0 0 268 127" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6595_445749)">
      <path d="M0 4C0 1.79086 1.79086 0 4 0H264C266.209 0 268 1.79086 268 4V127H0V4Z" fill={fill1} />
      <g opacity="0.3">
        <path
          d="M8.33509 -10.1478C15.5155 -8.28917 19.9562 -1.1774 24.8887 4.36636C29.6744 9.74527 35.1338 15.0352 36.9434 22.0063C38.7263 28.8743 36.2184 35.9891 34.9081 42.9629C33.7069 49.3563 31.5952 55.3322 29.5234 61.4983C27.1679 68.5087 27.0244 76.819 21.7811 82.0307C16.5489 87.2312 8.3851 87.7374 1.25014 89.6006C-5.90054 91.4679 -13.2196 94.7729 -20.4057 93.0473C-27.5669 91.3276 -32.2769 84.722 -38.0618 80.1605C-43.475 75.892 -49.8888 72.6725 -53.6086 66.8661C-57.3322 61.0535 -57.6494 53.8856 -59.2036 47.1583C-60.8597 39.99 -64.7516 32.857 -63.123 25.6823C-61.4921 18.4971 -55.4784 13.2222 -50.3095 7.97557C-45.3912 2.98317 -40.1298 -1.92038 -33.5527 -4.33325C-27.1746 -6.67314 -20.1617 -4.61896 -13.433 -5.55298C-6.00653 -6.58387 1.07635 -12.0267 8.33509 -10.1478Z"
          fill="#FDB341"
          fill-opacity="0.37"
        />
        <path
          d="M0.848274 -32.6087C8.02872 -30.7501 12.4694 -23.6383 17.4019 -18.0946C22.1876 -12.7157 27.647 -7.4257 29.4566 -0.454594C31.2395 6.41333 28.7316 13.5282 27.4213 20.5019C26.2201 26.8953 24.1084 32.8713 22.0366 39.0374C19.681 46.0477 19.5376 54.3581 14.2943 59.5698C9.0621 64.7703 0.898285 65.2765 -6.23667 67.1397C-13.3874 69.007 -20.7064 72.312 -27.8925 70.5863C-35.0538 68.8667 -39.7637 62.2611 -45.5486 57.6996C-50.9619 53.4311 -57.3757 50.2116 -61.0954 44.4051C-64.819 38.5926 -65.1362 31.4246 -66.6904 24.6974C-68.3465 17.529 -72.2384 10.3961 -70.6098 3.22139C-68.9789 -3.96382 -62.9652 -9.23872 -57.7964 -14.4854C-52.878 -19.4778 -47.6166 -24.3813 -41.0395 -26.7942C-34.6614 -29.1341 -27.6485 -27.0799 -20.9198 -28.0139C-13.4933 -29.0448 -6.41047 -34.4876 0.848274 -32.6087Z"
          fill="#FF9B00"
          fill-opacity="0.27"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M281.335 93.8522C288.516 95.7108 292.956 102.823 297.889 108.366C302.674 113.745 308.134 119.035 309.943 126.006C311.726 132.874 309.218 139.989 307.908 146.963C306.707 153.356 304.595 159.332 302.523 165.498C300.168 172.509 300.024 180.819 294.781 186.031C289.549 191.231 281.385 191.737 274.25 193.601C267.099 195.468 259.78 198.773 252.594 197.047C245.433 195.328 240.723 188.722 234.938 184.161C229.525 179.892 223.111 176.673 219.391 170.866C215.668 165.054 215.351 157.886 213.796 151.158C212.14 143.99 208.248 136.857 209.877 129.682C211.508 122.497 217.522 117.222 222.69 111.976C227.609 106.983 232.87 102.08 239.447 99.6668C245.825 97.3269 252.838 99.381 259.567 98.447C266.993 97.4161 274.076 91.9733 281.335 93.8522Z"
          fill="#FDB341"
          fill-opacity="0.37"
        />
        <path
          d="M273.848 71.3913C281.029 73.2499 285.469 80.3617 290.402 85.9054C295.188 91.2843 300.647 96.5743 302.457 103.545C304.24 110.413 301.732 117.528 300.421 124.502C299.22 130.895 297.108 136.871 295.037 143.037C292.681 150.048 292.538 158.358 287.294 163.57C282.062 168.77 273.898 169.276 266.763 171.14C259.613 173.007 252.294 176.312 245.107 174.586C237.946 172.867 233.236 166.261 227.451 161.7C222.038 157.431 215.624 154.212 211.905 148.405C208.181 142.593 207.864 135.425 206.31 128.697C204.654 121.529 200.762 114.396 202.39 107.221C204.021 100.036 210.035 94.7613 215.204 89.5146C220.122 84.5222 225.383 79.6187 231.96 77.2058C238.339 74.8659 245.352 76.9201 252.08 75.9861C259.507 74.9552 266.59 69.5124 273.848 71.3913Z"
          fill="#FF9B00"
          fill-opacity="0.27"
        />
      </g>
      <path
        d="M190 20H78C71.3726 20 66 25.3726 66 32V95C66 101.627 71.3726 107 78 107H190C196.627 107 202 101.627 202 95V32C202 25.3726 196.627 20 190 20Z"
        fill="white"
      />
      <path
        d="M142.785 47.0492C142.349 47.0529 141.955 46.7497 141.866 46.3226C141.491 44.5159 140.578 42.8573 139.24 41.5693C137.71 40.0972 135.718 39.1999 133.602 39.0296C131.486 38.8594 129.376 39.4266 127.63 40.6351C127.335 40.8397 127.053 41.0606 126.785 41.2963C124.41 43.3893 121.562 45.6448 119.519 48.0628C118.379 49.4113 117.8 51.1454 117.901 52.9081C118.002 54.6707 118.774 56.3275 120.06 57.5374C121.346 58.7472 123.047 59.418 124.812 59.4114H142.785C144.424 59.4114 145.996 58.7602 147.155 57.601C148.315 56.4418 148.966 54.8696 148.966 53.2303C148.966 51.591 148.315 50.0188 147.155 48.8596C145.996 47.7004 144.424 47.0492 142.785 47.0492Z"
        fill={fill1}
      />
      <path
        d="M130.328 64.4944C130.695 64.4944 130.924 64.0977 130.741 63.7802L129.873 62.2775C129.305 61.2916 127.882 61.2916 127.313 62.2775L126.446 63.7802C126.263 64.0977 126.492 64.4944 126.858 64.4944C127.121 64.4944 127.334 64.7076 127.334 64.9706V66.7919C127.334 67.487 127.898 68.0505 128.593 68.0505C129.288 68.0505 129.852 67.487 129.852 66.7919V64.9706C129.852 64.7076 130.065 64.4944 130.328 64.4944Z"
        fill="#FF9B00"
      />
      <path
        d="M134.965 63.6147C134.597 63.6147 134.367 64.0129 134.551 64.3315L135.417 65.8327C135.986 66.8185 137.409 66.8191 137.979 65.8337L138.847 64.3314C139.031 64.013 138.801 63.6147 138.434 63.6147C138.17 63.6147 137.956 63.4009 137.956 63.1371V61.3149C137.956 60.6211 137.393 60.0586 136.7 60.0586C136.006 60.0586 135.443 60.6211 135.443 61.3149V63.1368C135.443 63.4008 135.229 63.6147 134.965 63.6147Z"
        fill="#262626"
      />
      <g clip-path="url(#clip1_6595_445749)">
        <path d="M115.47 76.8984H113.599V86.4607H115.47V76.8984Z" fill="#2A3362" />
        <path
          d="M122.722 83.0697C122.722 84.3846 121.862 84.9844 120.723 84.9844C119.584 84.9844 118.817 84.3962 118.817 83.1389V79.7246H116.946V83.5657C116.946 85.4804 118.154 86.5993 120.165 86.5993C121.432 86.5993 122.129 86.1379 122.606 85.6188H122.71L122.873 86.4724H124.593V79.7246H122.722V83.0697Z"
          fill="#2A3362"
        />
        <path
          d="M141.084 85.1343C139.713 85.1343 138.946 84.5114 138.818 83.6117H144.756C144.78 83.4272 144.803 83.2311 144.791 83.0465C144.78 80.6242 142.932 79.5977 140.956 79.5977C138.667 79.5977 136.935 80.9588 136.935 83.1503C136.935 85.2381 138.585 86.5992 141.072 86.5992C143.153 86.5992 144.536 85.688 144.815 84.2807H142.967C142.758 84.8344 142.095 85.1343 141.084 85.1343ZM140.968 81.0626C142.165 81.0626 142.851 81.5932 142.967 82.3314H138.864C139.039 81.6162 139.736 81.0626 140.968 81.0626Z"
          fill="#2A3362"
        />
        <path
          d="M147.988 80.6935H147.872L147.697 79.7246H146V86.4609H147.872V83.5657C147.872 82.2622 148.627 81.5356 150.045 81.5356C150.301 81.5356 150.556 81.5586 150.812 81.6048V79.7246H150.556C149.289 79.7246 148.522 79.9899 147.988 80.6935Z"
          fill="#2A3362"
        />
        <path
          d="M159.993 84.3616H159.888L158.285 80.1514H156.355L154.786 84.35H154.659L153.171 79.7246H151.312L153.601 86.4609H155.635L157.262 82.17H157.378L158.982 86.4609H161.027L163.317 79.7246H161.457L159.993 84.3616Z"
          fill="#2A3362"
        />
        <path
          d="M170.766 84.4999V82.2506C170.766 80.4166 169.209 79.5977 167.268 79.5977C165.211 79.5977 163.921 80.5666 163.747 82.0199H165.618C165.757 81.3855 166.292 81.0395 167.268 81.0395C168.244 81.0395 168.895 81.4086 168.895 82.1468V82.3198L165.839 82.5505C164.432 82.6544 163.398 83.2772 163.398 84.6037C163.398 85.9648 164.583 86.6108 166.315 86.6108C167.721 86.6108 168.57 86.2186 169.093 85.6418H169.186C169.476 86.2993 170.08 86.4839 170.72 86.4839H171.51V85.1112H171.336C170.952 85.0882 170.766 84.9036 170.766 84.4999ZM168.895 83.7848C168.895 84.8459 167.617 85.2035 166.524 85.2035C165.78 85.2035 165.292 85.019 165.292 84.4999C165.292 84.0385 165.711 83.8194 166.338 83.7617L168.907 83.5771L168.895 83.7848Z"
          fill="#2A3362"
        />
        <path
          d="M177.693 79.7246L175.508 84.6038H175.392L173.172 79.7246H171.15L174.311 86.4609H176.554L179.704 79.7246H177.693Z"
          fill="#2A3362"
        />
        <path
          d="M185.688 84.2807C185.455 84.8344 184.793 85.1343 183.794 85.1343C182.422 85.1343 181.655 84.5114 181.527 83.6117H187.466C187.489 83.4272 187.513 83.2311 187.501 83.0465C187.489 80.6242 185.641 79.5977 183.666 79.5977C181.376 79.5977 179.656 80.9588 179.656 83.1503C179.656 85.2381 181.307 86.5992 183.794 86.5992C185.874 86.5992 187.257 85.688 187.536 84.2807H185.688ZM183.666 81.0626C184.863 81.0626 185.548 81.5932 185.665 82.3314H181.562C181.737 81.6162 182.422 81.0626 183.666 81.0626Z"
          fill="#2A3362"
        />
        <path
          d="M110.566 79.2284C110.566 78.6402 110.984 78.3864 111.53 78.3864C111.786 78.3864 112.03 78.421 112.274 78.4902L112.588 77.1407C112.134 76.9792 111.658 76.8984 111.181 76.8984C109.798 76.8984 108.683 77.6251 108.683 79.1362V79.7244H107.067V81.2009H108.683V86.4607H110.566V81.2009H112.681V79.7244H110.566V79.2284Z"
          fill="#2A3362"
        />
        <path
          d="M128.742 77.832H126.999L126.906 79.7353H125.43V81.2117H126.871V84.5337C126.871 85.6641 127.452 86.6099 129.195 86.6099C129.648 86.6099 130.102 86.5638 130.543 86.46V85.0412C130.288 85.0989 130.032 85.1335 129.765 85.1335C128.835 85.1335 128.742 84.6029 128.742 84.1992V81.2002H130.601V79.7237H128.742V77.832Z"
          fill="#2A3362"
        />
        <path
          d="M134.623 77.832H132.891L132.798 79.7353H131.322V81.2117H132.763V84.5337C132.763 85.6641 133.344 86.6099 135.088 86.6099C135.541 86.6099 135.983 86.5523 136.424 86.46V85.0412C136.169 85.0989 135.913 85.1335 135.646 85.1335C134.716 85.1335 134.623 84.6029 134.623 84.1992V81.2002H136.482V79.7237H134.611V77.832H134.623Z"
          fill="#2A3362"
        />
        <path
          d="M81 76.645C81 75.5607 81.3138 74.6379 81.9878 73.9805L83.15 75.1339C81.86 76.4143 82.9873 80.3938 86.6714 84.0503C90.3555 87.7068 94.365 88.8256 95.655 87.5453L96.8172 88.6988C94.6323 90.8673 89.6698 89.3216 85.5092 85.2037C82.627 82.3316 81 79.0903 81 76.645Z"
          fill="#009A46"
        />
        <path
          d="M88.3331 89.6909C87.2406 89.6909 86.3109 89.3795 85.6484 88.7105L86.8106 87.557C88.1006 88.8374 92.1101 87.7185 95.7942 84.062C99.4783 80.4055 100.606 76.426 99.3156 75.1457L100.478 73.9922C102.663 76.1607 101.105 81.086 96.9564 85.2155C94.0626 88.0761 90.7852 89.6909 88.3331 89.6909Z"
          fill="#FF5805"
        />
        <path
          d="M97.3284 83.5641C96.6195 81.5455 95.1784 79.3885 93.2724 77.4968C89.1118 73.3674 84.1494 71.8333 81.9645 73.9903C81.8134 74.1518 81.9528 74.5324 82.2666 74.8438C82.5804 75.1553 82.9756 75.2937 83.1266 75.1437C84.4167 73.8634 88.4262 74.9823 92.1102 78.6388C93.8535 80.369 95.1551 82.3068 95.7827 84.0947C96.3289 85.6634 96.2825 86.9322 95.6433 87.5666C95.4922 87.7166 95.62 88.0972 95.9454 88.4202C96.2708 88.7432 96.6543 88.8701 96.8054 88.7201C97.9211 87.6012 98.1071 85.7672 97.3284 83.5641Z"
          fill="#F5AFCB"
        />
        <path
          d="M100.466 73.9917C99.3505 72.8843 97.5026 72.6998 95.2829 73.4611C93.2491 74.1647 91.0758 75.595 89.1698 77.4867C85.0092 81.6161 83.4636 86.5414 85.6484 88.71C85.7995 88.8599 86.1947 88.733 86.5085 88.4101C86.8222 88.0871 86.9617 87.7064 86.8106 87.5565C85.5206 86.2761 86.6479 82.2967 90.332 78.6402C92.0753 76.91 94.0277 75.6181 95.8175 74.9952C97.398 74.4531 98.6764 74.4992 99.3156 75.1336C99.4667 75.2836 99.8502 75.1567 100.176 74.8337C100.501 74.5107 100.629 74.1532 100.466 73.9917Z"
          fill="#FF9B00"
        />
      </g>
      <path
        d="M202 95C202 101.627 196.627 107 190 107H78C71.3726 107 66 101.627 66 95V32C66 25.3726 71.3726 20 78 20H190C196.627 20 202 25.3726 202 32V95ZM66.2494 94.7787C66.2494 101.406 71.622 106.779 78.2494 106.779H189.751C196.378 106.779 201.751 101.406 201.751 94.7787V32.208C201.751 25.5806 196.378 20.208 189.751 20.208H78.2494C71.622 20.208 66.2494 25.5806 66.2494 32.208V94.7787Z"
        fill="#C7C7C7"
      />
      <circle cx="240" cy="30" r="9.5" fill="white" stroke="#D4D4D4" />
    </g>
    <defs>
      <clipPath id="clip0_6595_445749">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H264C266.209 0 268 1.79086 268 4V127H0V4Z" fill="white" />
      </clipPath>
      <clipPath id="clip1_6595_445749">
        <rect width="106.537" height="16.6907" fill="white" transform="translate(81 73)" />
      </clipPath>
    </defs>
  </svg>
);
