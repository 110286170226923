import React from "react";
import { Route, Routes } from "react-router-dom";
import Summary from "./Summary";

const PublicPages = () => {
  return (
    <Routes>
      <Route exact path="/summary/:pubId/:surveyId" element={<Summary />} />
    </Routes>
  );
};

export default PublicPages;
