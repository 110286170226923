import mixpanel from "mixpanel-browser";

// const MIXPANEL_TOKEN = process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_MIXPANEL_TEST : "";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TEST;

const initMixpanel = () => {
  if (!MIXPANEL_TOKEN) {
    console.error("Mixpanel token not found. Please set the MIXPANEL_TOKEN environment variable.");
    return;
  }

  mixpanel.init(MIXPANEL_TOKEN);
};

const identifyUser = (userId, additionalProperties = {}) => {
  if (!userId) {
    console.warn("User ID not provided for Mixpanel identify.");
    return;
  }

  mixpanel.identify(userId);
  mixpanel.people.set(additionalProperties);
};

const trackEvent = (eventName, properties = {}) => {
  mixpanel.track(eventName, properties);
};

export { initMixpanel, identifyUser, trackEvent };
