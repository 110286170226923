import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingSkeleton = () => {
  return (
    <div className="flex items-center flex-col gap-[1em]">
      <div className="bg-white p-4 border-[1px] rounded-md w-[100%] ">
        <Skeleton className="h-[100px]" />

        <Skeleton className="h-[50px] w-[70%]" />

        <Skeleton className="h-[40px] w-[50%]" />

        <Skeleton className="h-[20px] w-[30%]" />
      </div>

      <div className="flex items-center gap-[1em] w-[100%]">
        <div className="bg-white p-4 border-[1px]  rounded-md w-[50%]">
          <Skeleton className="h-[100px]" />

          <Skeleton className="h-[60px] w-[70%]" />

          <Skeleton className="h-[40px] w-[80%]" />
          <Skeleton className="h-[40px] w-[50%]" />

          <Skeleton className="h-[20px] w-[100%]" />
        </div>

        <div className="bg-white p-4 border-[1px]  rounded-md w-[50%]">
          <Skeleton className="h-[100px]" />

          <Skeleton className="h-[60px] w-[70%]" />

          <Skeleton className="h-[40px] w-[80%]" />

          <Skeleton className="h-[40px] w-[50%]" />

          <Skeleton className="h-[20px] w-[100%]" />
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
