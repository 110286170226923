import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const EmailGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="80%">
      <LineChart
        width={500}
        height={250}
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line dataKey="clicked" stroke="#F765A3" activeDot={{ r: 8 }} />
        <Line dataKey="opened" stroke="#165BAA" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default EmailGraph;
