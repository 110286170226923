import React from "react";
import { GiCheckMark } from "react-icons/gi";

const Checkbox = ({ label, checked, onChange, name, value, id }) => {
  return (
    <label className="flex items-center relative h-[16px] w-[16px]  transition-all duration-300 cursor-pointer">
      <input
        id={id}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        className="appearance-none  border-[1px] border-neutral-400 checked:border-primary-800 checked:bg-primary-100 peer rounded-sm bg-white h-[16px] w-[16px] transition-all duration-300 "
      />
      <GiCheckMark className="absolute text-xs text-primary-800  hidden peer-checked:block transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transition-all duration-300 " />
    </label>
  );
};

export default Checkbox;
