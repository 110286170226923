import { useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import DropDownWithSearch from "components/DropDownWithSearch";
import Checkbox from "components/Inputs/CheckBox";
import PrimaryInput from "components/Inputs/PrimaryInput";
import SelectInput from "components/Inputs/SelectInput";
import Modal from "components/Modal";
import { newIntrestData } from "data/constants/settingsConstant";
import { genderData } from "data/gender";
import { checkInArray } from "lib/checkInArray";
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { HiOutlineChevronDown } from "react-icons/hi";
import { getCities } from "services/locationService";
import Typography from "utils/typography";

const OptionsModal = ({ surveyData, setSurveyData, newStateData, handleModal }) => {
  const { data: cityData } = useQuery(["getCity"], () => getCities("Nigeria"));

  const [open, setOpen] = useState(null);
  const [query, setQuery] = useState("");
  const [filterData, setFilteredData] = useState();

  const handleSelection = (value, title) => {
    setSurveyData({
      ...surveyData,
      [title]: surveyData[title].includes(value)
        ? [...surveyData[title].filter((el) => el !== value)]
        : [...surveyData[title], value],
    });
  };

  const handleFilter = useCallback(
    (searchTerm) => {
      const filteredData = searchTerm
        ? newIntrestData?.filter((item) => {
            const content = item.name.toLowerCase();
            return content.includes(searchTerm.toLowerCase());
          })
        : newIntrestData;

      setFilteredData(filteredData);
    },
    [newIntrestData],
  );

  useEffect(() => {
    handleFilter(query);
  }, [handleFilter, query]);

  const handleChange = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (name, value) => {
    setSurveyData({
      ...surveyData,
      [name]: value,
    });
  };

  console.log(surveyData);

  const handleSave = () => {
    // console.log(surveyData, "surveyData");
    handleModal();
  };

  const citiesArray =
    surveyData.state !== "" && cityData !== undefined
      ? cityData[surveyData.state.replace(" State", "")]?.map((el) => ({
          label: el,
          value: el,
        }))
      : { label: "", value: "" };

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  return (
    <Modal title="Set your audience demographic(s)" onClose={handleModal}>
      <div className="w-[35vw] px-5 h-[60vh] overflow-y-scroll space-y-3 pt-4 flex flex-col justify-between">
        <div className="space-y-3 ">
          <div>
            <p className="text-sm">Set respondents age range</p>
            <div className="flex items-center space-x-4">
              <div className="w-[50%]">
                <PrimaryInput type="tel" placeholder={"18"} onChange={handleChange} value={surveyData.lte} name="lte" />
              </div>

              <p className="text-xs text-gray-500">to</p>
              <div className="w-[50%]">
                <PrimaryInput type="tel" placeholder={"30"} onChange={handleChange} value={surveyData.gte} name="gte" />
              </div>
            </div>
          </div>

          <div>
            <p className="text-sm">Gender</p>
            <ul className="flex gap-x-4 mt-1">
              {genderData.map((elm, i) => {
                return (
                  <div
                    className={`bg-[#EFF2F6] rounded border-[1px] text-ss border-[#DFE5EC] flex items-center justify-center w-[120px] h-[40px] cursor-pointer ${
                      checkInArray(elm.name, surveyData.gender) ? "bg-primary-800 text-white" : null
                    }`}
                    key={`gender${i}`}
                    onClick={() => handleSelection(elm.name, "gender")}
                  >
                    <p>{elm.name}</p>
                  </div>
                );
              })}
            </ul>
          </div>

          <div className="flex items-center gap-[1em]">
            <div className="w-[50%] ">
              {/* <div className="flex space-x-2 items-center ">
                <p className="text-gray-700 text-sm font-[500] tracking-[-0.02em]">State</p>
              </div>
              <div className="">
                <SelectInput
                  options={[{ label: "Select State", value: "" }, ...newStateData]}
                  css="border-[#D0D5DD]"
                  name="state"
                  value={surveyData.state}
                  onChange={handleChange}
                />
              </div> */}
              <div>
                <Typography.SubText className="font-semibold pb-2"> State</Typography.SubText>
                <DropDownWithSearch
                  tag={surveyData.state || "Select an option"}
                  options={[{ label: "Select State", value: "" }, ...newStateData]}
                  onSelect={(select) => handleSelect("state", select.value)}
                  name="state"
                  value={surveyData.state}
                />
              </div>
            </div>

            {surveyData.state !== "" ? (
              <div className="w-[50%]">
                {/* <div className="flex space-x-2 items-center ">
                  <p className="text-gray-700 text-sm font-[500] tracking-[-0.02em]">Local Government area(s)</p>
                </div>
                <div className="">
                  <SelectInput
                    options={[{ label: "Select LGA", value: "" }, ...citiesArray]}
                    css=" border-[#D0D5DD]"
                    name="lga"
                    value={surveyData.lga}
                    onChange={handleChange}
                  />
                </div> */}
                <div>
                  <Typography.SubText className="font-semibold pb-2">Local Government area(s)</Typography.SubText>
                  <DropDownWithSearch
                    tag={surveyData.lga || "Select an option"}
                    options={[{ label: "Select LGA", value: "" }, ...citiesArray]}
                    onSelect={(select) => handleSelect("lga", select.value)}
                    name="lga"
                    value={surveyData.lga}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="w-[100%] mb-[4em]">
            <div className="flex space-x-2 items-center ">
              <p className="text-gray-700 text-sm font-[500] tracking-[-0.02em]">Category of interest</p>
            </div>

            <div className="mt-2">
              <div
                className="flex items-center justify-between border-[1px] border-gray-300 rounded p-2 py-[10px] cursor-pointer"
                onClick={() => handleOpen(1)}
              >
                <p className="text-xs">
                  {surveyData.interests.length > 0 ? <>{surveyData.interests.length} Selected </> : "Select Category"}
                </p>

                <HiOutlineChevronDown />
              </div>

              {open === 1 ? (
                <div className="border-[1px] border-grey-300 rounded mt-2 overflow-hidden h-[150px] overflow-y-scroll">
                  <div className="p-2">
                    <div className="flex items-center bg-gray-100 border space-x-2 rounded-[4px] h-[35px] px-2">
                      <div>
                        <CiSearch />
                      </div>
                      <div className="w-full">
                        <input
                          type="text"
                          className="w-full border-none focus:outline-none text-[14px] bg-gray-100"
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {filterData.map((el) => {
                    return (
                      <div
                        key={`${el.name}`}
                        className={`text-xs cursor-pointer p-2 flex items-center space-x-3 ${
                          checkInArray(el.name, surveyData.interests)
                            ? "bg-primary-200 border-b-[1px] border-white"
                            : "hover:bg-slate-100"
                        }`}
                        onClick={() => handleSelection(el.name, "interests")}
                      >
                        <Checkbox checked={checkInArray(el.name, surveyData.interests)} />

                        <p>{el.name}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between border-t-[1px] pt-4 mt-[3em]">
          <div>
            <p className="font-semibold text-sm">Targeted responses</p>
            <p className="text-sm">50</p>
          </div>

          <div className="flex items-center space-x-4">
            <GreyButton text="Cancel" />
            <BlueButton text="Save & Close" onClick={handleSave} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OptionsModal;
