import React from "react";

const PowerOffAction = ({ fill1, fill2, fill3 }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" fill="#FAFAFA" />
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" stroke={fill1} stroke-width="0.8" />
    <path
      d="M22.7243 14.6537C22.9122 14.285 23.3662 14.135 23.7118 14.3626C24.7605 15.053 25.6003 16.0253 26.13 17.1744C26.7647 18.5514 26.9175 20.1018 26.5635 21.5761C26.2096 23.0504 25.3696 24.3626 24.1789 25.3013C22.9882 26.2399 21.5162 26.7504 20 26.7504C18.4838 26.7504 17.0118 26.2399 15.8211 25.3013C14.6305 24.3627 13.7905 23.0505 13.4365 21.5762C13.0826 20.1018 13.2353 18.5514 13.87 17.1744C14.3998 16.0253 15.2395 15.053 16.2882 14.3626C16.6338 14.135 17.0878 14.285 17.2757 14.6537C17.4636 15.0224 17.313 15.4694 16.9747 15.7078C16.2229 16.2377 15.6196 16.9586 15.2309 17.8018C14.737 18.873 14.6182 20.0793 14.8936 21.2263C15.169 22.3733 15.8225 23.3942 16.7488 24.1245C17.6752 24.8547 18.8204 25.2519 20 25.2519C21.1796 25.2519 22.3249 24.8547 23.2512 24.1245C24.1775 23.3942 24.8311 22.3733 25.1064 21.2263C25.3818 20.0793 25.263 18.873 24.7691 17.8018C24.3804 16.9586 23.7771 16.2377 23.0253 15.7078C22.687 15.4694 22.5364 15.0224 22.7243 14.6537Z"
      fill={fill3}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 12.5C20.4142 12.5 20.75 12.8358 20.75 13.25V17C20.75 17.4142 20.4142 17.75 20 17.75C19.5858 17.75 19.25 17.4142 19.25 17V13.25C19.25 12.8358 19.5858 12.5 20 12.5Z"
      fill={fill2}
    />
  </svg>
);

export default PowerOffAction;
