import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import PrimaryInput from "components/Inputs/PrimaryInput";
import Toast from "config/toast";
import { useFormik } from "formik";
import { useState } from "react";
import { changePassword } from "services/userService";
import * as Yup from "yup";

const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  oldPassword: Yup.string().required("Please fill in this field"),
  newPassword: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
  confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const Password = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { mutate } = useMutation(changePassword, {
    onSuccess: () => {
      Toast.success("Password changed successfully.");
      resetForm();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const hidePassword = (id) => {
    setShowPassword(showPassword === id ? null : id);
  };

  const onSubmit = async (data) => {
    const userData = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };

    mutate(userData);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <div>
      <p className="text-[#292D32] font-semibold text-[20px]">Password</p>
      <p className="text-[#737373] text-[14px] py-2">Please enter your current password to change your password.</p>
      <hr />

      <div className="w-[80%] mt-4">
        <form action="" className="space-y-2" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4">
            <div className="relative">
              <PrimaryInput
                type={`${showPassword === 1 ? "text" : "password"}`}
                placeholder="Enter current password"
                onChange={handleChange}
                text="Current Password"
                name="oldPassword"
                onBlur={handleBlur}
                value={values.oldPassword}
                error={getError("oldPassword")}
              />

              <p
                className={`absolute select-none cursor-pointer ${showPassword === 1 ? "text-black" : "text-grey"} ${
                  getError("oldPassword") ? "top-[43%]" : "top-[52%] "
                } mr-6 right-0 text-s font-extrabold`}
                onClick={() => hidePassword(1)}
              >
                {showPassword === 1 ? "Hide" : "Show"}
              </p>
            </div>

            <div className="relative">
              <PrimaryInput
                type={`${showPassword === 2 ? "text" : "password"}`}
                placeholder="Enter New password"
                onChange={handleChange}
                text="New Password"
                name="newPassword"
                onBlur={handleBlur}
                value={values.newPassword}
                error={getError("newPassword")}
              />

              <p
                className={`absolute select-none cursor-pointer ${showPassword === 2 ? "text-black" : "text-grey"} ${
                  getError("newPassword") ? "top-[38%]" : "top-[52%] "
                } mr-6 right-0 text-s font-extrabold`}
                onClick={() => hidePassword(2)}
              >
                {showPassword === 2 ? "Hide" : "Show"}
              </p>
            </div>

            <div className="relative">
              <PrimaryInput
                type={`${showPassword === 3 ? "text" : "password"}`}
                placeholder="Confirm password"
                onChange={handleChange}
                text="Confirm Password"
                name="confirmPassword"
                onBlur={handleBlur}
                value={values.confirmPassword}
                error={getError("confirmPassword")}
              />

              <p
                className={`absolute select-none cursor-pointer ${showPassword === 3 ? "text-black" : "text-grey"} ${
                  getError("confirmPassword") ? "top-[43%]" : "top-[52%] "
                } mr-6 right-0 text-s font-extrabold`}
                onClick={() => hidePassword(3)}
              >
                {showPassword === 3 ? "Hide" : "Show"}
              </p>
            </div>
          </div>

          <BlueButton text="Update" css="ml-2" />
        </form>
      </div>
    </div>
  );
};

export default Password;
