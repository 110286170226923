import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import BrandTracking from "./BrandTracking";
import FieldInterview from "./FieldInterview";
import OnlineSurvey from "./OnlineSurvey";
import SetupResearch from "./SetupResearch";
import OnlineSurveyHome from "./OnlineSurvey/Home";
import BrandTrackingHome from "./BrandTracking/Home";
import FieldInterviewHome from "./FieldInterview/Home";
import OnlineSurveyAnalytics from "./OnlineSurvey/Analytics";
import BrandTrackingAnalytics from "./BrandTracking/Analytics";
import FieldInterviewAnalytics from "./FieldInterview/Analytics";
import { SuccessPage } from "./components/SuccessScreens/SuccessPage";
import SavedContact from "./components/FinishingModals/SavedContact";
import SingleResponse from "./FieldInterview/Analytics/SingleResponse";
// import SavedContact from "./components/SavedContact";

const Research = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/setup" element={<SetupResearch />} />
      <Route path="/brand-tracking/:id" element={<BrandTracking />} />
      <Route path="/field-interview/:id" element={<FieldInterview />} />
      <Route path="/online-survey/:id" element={<OnlineSurvey />} />
      <Route path="/online-survey" element={<OnlineSurveyHome />} />
      <Route path="/brand-tracking" element={<BrandTrackingHome />} />
      <Route path="/field-interview" element={<FieldInterviewHome />} />
      <Route path="/online-survey/analytics/:pubId/:surveyId" element={<OnlineSurveyAnalytics />} />

      <Route path="/brand-tracking/analytics/:pubId/:surveyId" element={<BrandTrackingAnalytics />} />
      <Route path="/field-interview/analytics/:pubId/:surveyId" element={<FieldInterviewAnalytics />} />
      <Route path="/field-interview/analytics/response/:surveyId/:responseId" element={<SingleResponse />} />

      <Route path="/online-survey/success" element={<SuccessPage />} />
      <Route path="/brand-tracking/success" element={<SavedContact />} />
    </Routes>
  );
};

export default Research;
