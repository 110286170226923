import React from "react";

export const TargetResponseIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0003 26.667C21.8914 26.667 26.667 21.8914 26.667 16.0003C26.667 10.1093 21.8914 5.33366 16.0003 5.33366C10.1093 5.33366 5.33366 10.1093 5.33366 16.0003C5.33366 21.8914 10.1093 26.667 16.0003 26.667ZM16.0003 29.3337C23.3641 29.3337 29.3337 23.3641 29.3337 16.0003C29.3337 8.63653 23.3641 2.66699 16.0003 2.66699C8.63653 2.66699 2.66699 8.63653 2.66699 16.0003C2.66699 23.3641 8.63653 29.3337 16.0003 29.3337Z"
      fill="#2D28FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 21.3333C18.9455 21.3333 21.3333 18.9455 21.3333 16C21.3333 13.0545 18.9455 10.6667 16 10.6667C13.0545 10.6667 10.6667 13.0545 10.6667 16C10.6667 18.9455 13.0545 21.3333 16 21.3333ZM16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
      fill="#2D28FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9997 18.6663C17.4725 18.6663 18.6663 17.4725 18.6663 15.9997C18.6663 14.5269 17.4725 13.333 15.9997 13.333C14.5269 13.333 13.333 14.5269 13.333 15.9997C13.333 17.4725 14.5269 18.6663 15.9997 18.6663Z"
      fill="#2D28FF"
    />
    <path
      d="M26.3759 3.30603C26.2203 2.21848 24.8899 1.77533 24.1131 2.55228L21.1375 5.52879C20.8408 5.82553 20.7011 6.24463 20.7604 6.66003L21.0971 9.01701L15.7235 14.3907C15.2028 14.9114 15.2028 15.7556 15.7235 16.2763C16.2442 16.797 17.0884 16.797 17.6091 16.2763L22.9828 10.9025L25.3395 11.2392C25.755 11.2986 26.1742 11.1589 26.471 10.8621L29.4472 7.88575C30.2242 7.10883 29.7807 5.77837 28.693 5.62301L26.6662 5.33351L26.3759 3.30603Z"
      fill="#1D19AF"
    />
  </svg>
);
