import React from "react";

const PlayAction = ({ fill1, fill2, fill3 }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" fill="#FAFAFA" />
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" stroke={fill1} stroke-width="0.8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 14C16.5858 14 16.25 14.3358 16.25 14.75V25.25C16.25 25.6642 16.5858 26 17 26C17.4142 26 17.75 25.6642 17.75 25.25V14.75C17.75 14.3358 17.4142 14 17 14Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 14C22.5858 14 22.25 14.3358 22.25 14.75V25.25C22.25 25.6642 22.5858 26 23 26C23.4142 26 23.75 25.6642 23.75 25.25V14.75C23.75 14.3358 23.4142 14 23 14Z"
      fill={fill2}
    />
  </svg>
);

export default PlayAction;
