import { create } from "zustand";

const useSummaryStore = create((set) => ({
  summaryData: null,
  isFetching: false,
  status: null,
  setSummaryData: (data) => set({ summaryData: data }),
  setIsFetching: (isFetching) => set({ isFetching }),
  setStatus: (status) => set({ status }),
}));

export default useSummaryStore;
