export const smsTemplates = [
  {
    id: 0,
    campaign_name: "",
    content: "",
  },

  {
    id: 12,
    content:
      "Ready to achieve your education goals? Apply for a study loan up to $100k w/o collateral or guarantor. (link)",
    campaign_name: "Study loan",
    category: "Educational",
  },

  {
    id: 1,
    content:
      "Happy birthday {{firstname}}! You’re special to us. We hope you have a truly amazing day, complete with loads of gifts and well wishes.Love",
    campaign_name: "Happy Birthday",
    category: "Promotional",
  },

  {
    id: 2,
    content:
      "It’s our one-year anniversary! You’ve been an amazing customer, so here’s a little something from us to say thank you: Discount Code, Special Offer)",
    campaign_name: "First Anniversary",
    category: "Promotional",
  },

  {
    id: 3,
    content:
      "We don’t need a special day to give you a special offer.Get 10% off your next BrandName order with the promo code: 2SPECIAL. Offer valid till ",
    campaign_name: "Special Offer/Discount",
    category: "Promotional",
  },

  {
    id: 4,
    content:
      "We’ve restocked our catalogue. Check out our new products and items before they sell out. Order now and get your items delivered before the end of today: (Link to order page)",
    campaign_name: "Shop Now",
    category: "Promotional",
  },

  {
    id: 5,
    content:
      "Dear {{firstname}},Your loan of #50,000 is due by 5pm today, after which late fees would be charged. You can repay your loan here: (Link to loan repayment page)",
    campaign_name: "Reminders",
    category: "Reminder",
  },

  {
    id: 6,
    content:
      "Hi {{firstname}}, Thank you for choosing Brandname. Welcome onboard! We know you’ll love it here. Here are some tips to help you get started: (Link to tips)",
    campaign_name: "Welcome Onboard",
    category: "Promotional",
  },

  {
    id: 7,
    content:
      "Learn about money management from the money experts. Register to hear Tosin Oladeinde of Money Africa speak on letting your money make you money. Register here: (Registration Link)",
    campaign_name: "Event Invitation",
    category: "Promotional",
  },

  {
    id: 8,
    content:
      "Dear customer, we are currently upgrading our systems. As such, there might be some system downtime for the next couple hours. Please bear with us.",
    campaign_name: "Service Downtime",
    category: "Promotional",
  },

  {
    id: 9,
    content:
      "Hi {{firstname}}. We haven’t heard from you in sometime. We miss you. Here’s a 10% discount voucher just for you. Access your voucher here: (Link)",
    campaign_name: "We miss you",
    category: "Reminder",
  },

  {
    id: 10,
    content:
      "Dear customer, we would love to hear from you. Please fill this feedback form to let us know what your experience has been with us: (Form Link)",
    campaign_name: "Feedback form",
    category: "Promotional",
  },

  {
    id: 11,
    content:
      "Dear customer, our services will be unavailable from December 29 to January 3 because of the holidays. Get all your shopping done before then: (link)",
    campaign_name: "Closed for the holidays",
    category: "Reminder",
  },
];

export const birthdayAnniversaryTemplate = [
  {
    id: 1,
    type: "Birthday",
    content:
      "Happy birthday {{firstname}}! You’re special to us. We hope you have a truly amazing day, complete with loads of gifts and well wishes. Love, (BrandName).",
  },
  {
    id: 2,
    type: "Birthday",
    content:
      "Dear{{firstname}}, Happy birthday! We’re excited for you and hope you have a lot of fun today. Thank you for being amazing. From all of us at (BrandName).",
  },
  {
    id: 3,
    type: "Anniversary",
    content:
      "It’s our one-year anniversary! You’ve been an amazing customer, so here’s a little something from us to say thank you: (Discount Code, Special Offer)",
  },
  {
    id: 4,
    type: "Anniversary",
    content:
      "Congratulations! You’ve been a BrandName Subscriber for 1 year now! Here’s 25% off your next order to thank you for being part of our community: (Coupon Link)",
  },
  {
    id: 5,
    type: "Anniversary",
    content:
      "Can you believe it? It’s been a year since you joined the BrandName family! To celebrate, we’re giving you a gift: (Discount code, Special offer, Coupon link)",
  },
  {
    id: 6,
    type: "Anniversary",
    content:
      "WE MADE IT! Thanks to you, we’ve reached our 1st anniversary. For staying and growing with us, we have a surprise for you. Check our app to see: (App Link)",
  },
];
