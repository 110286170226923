import Modal from "components/Modal";
// import { surveyTemplates } from "data/surveyTemplates";
import React, { useState } from "react";
import banner from "assets/images/broadcast/voiceTemplateBanner.png";
import voiceIcon from "assets/icons/broadcast/voiceIcon.png";
import { useNavigate } from "react-router-dom";
import SearchInput from "components/Inputs/SearchInput";
import { IoIosArrowDown } from "react-icons/io";
import dayjs from "dayjs";
// import { smsTemplates } from "data/smsTemplates";
import { voiceTemplates } from "data/voiceTemplates";
import { useStore } from "store";

const VoiceModal = ({ handleModal, onClose }) => {
  const navigate = useNavigate();
  const { setVoiceTemplate } = useStore();
  const [filterDropdown, setFilterDropDown] = useState(false);
  const [filterItem, setFilterItem] = useState("All");
  const [hover, setHover] = useState(null);
  const filterOptions = [
    { id: 1, option: "All" },
    { id: 2, option: "Promotional" },
    { id: 3, option: "Educational" },
    { id: 4, option: "Reminder" },
  ];

  const filterData = filterItem === "All" ? voiceTemplates : voiceTemplates.filter((el) => el.category === filterItem);

  const retrieveTemplate = (id) => {
    const foundTemplate = filterData.find((el) => el.id === id);
    setVoiceTemplate(foundTemplate);
    handleModal();
  };

  const handleSelectOption = (option) => {
    setFilterItem(option);
    setFilterDropDown(false);
  };

  const handleCreate = () => {
    setVoiceTemplate({});
    navigate("/broadcasts/voice/create");
  };

  const handleSelection = (id) => {
    const foundTemplate = filterData.find((el) => el.id === id);
    setVoiceTemplate(foundTemplate);
    navigate("/broadcasts/voice/create");
  };

  return (
    <Modal title="Create a voice message" onClose={onClose}>
      <div className="w-[850px] px-5">
        <p className="text-[14px]">
          Create your custom voice message, or Choose one of our available templates to proceed
        </p>
        <div className="flex justify-between items-center mt-5 relative">
          <div>
            <SearchInput placeholder="Search templates..." />
          </div>

          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => setFilterDropDown(!filterDropdown)}
          >
            <div>Filter:</div>
            <div className="flex items-center justify-between text-[14px] w-[100px] h-[44px] px-3 border">
              <p>{filterItem.length > 3 ? <span>{filterItem.slice(0, 5)}...</span> : <span>{filterItem}</span>}</p>
              <IoIosArrowDown />
            </div>
          </div>
          {filterDropdown ? (
            <div className="w-fit p-5 absolute top-10 z-20 bg-white right-0 border space-y-3 transition-all ease-in duration-300 text-[14px]">
              {filterOptions.map((item, i) => (
                <div key={i} className="cursor-pointer" onClick={() => handleSelectOption(item.option)}>
                  {item.option}
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div className="mt-[1.5em] grid grid-cols-3 gap-[2em] max-h-[60vh] overflow-scroll">
          {filterData.map((el, i) => {
            if (el.id === 0) {
              return (
                <div
                  className="bg-[#FAFAFA] w-[260px] h-[224px] text-center flex flex-col justify-center items-center space-y-3 cursor-pointer border rounded-xl"
                  key={`templates${el.id}`}
                  onClick={handleCreate}
                >
                  <div className="border-[1px] border-dashed rounded-md border-black w-[121px] h-[77px] flex items-center justify-center mx-auto bg-white mb-3">
                    <div className="bg-primary-50 w-[54px] h-[54px] flex items-center justify-center rounded-full text-[24px]">
                      <img src={voiceIcon} alt="" className="w-[47px]" />
                    </div>
                  </div>

                  <p className="text-m font-medium">New voice message</p>
                  <p className="text-ss w-[70%]">Send voice messages to your target audience or customers</p>
                </div>
              );
            } else {
              return (
                <div
                  className={`w-[260px] px-7 pt-5 bg-cover bg-no-repeat overflow-hidden rounded-xl cursor-pointer h-[224px] border relative ${
                    hover === el.id ? "bg-primary-50 border-blue-800" : ""
                  }`}
                  onMouseEnter={() => setHover(el.id)}
                  onMouseLeave={() => setHover(null)}
                  key={`temp${i}`}
                >
                  <div className=" w-full h-full items-end rounded-t-[20px] p-2 pb-0">
                    <div className="w-full h-full items-end rounded-t-[18px] p-2 overflow-hidden">
                      <div className="bg-white drop-shadow-lg">
                        {/* <div className="w-full mx-auto mb-[7px]">
                          <img src={banner} alt="logo" />
                        </div> */}

                        <div className="bg-white rounded-md p-2 pt-5">
                          <p className="text-[8px]">
                            {el.content.substring(0, 150)}
                            {el.content.length > 150 ? "..." : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white h-[70px] bottom-0 left-0 w-full absolute flex items-center justify-center">
                    {hover === el.id ? (
                      <div className="flex items-center space-x-5 text-sm">
                        <div onClick={() => retrieveTemplate(el.id)}>
                          <p className="text-primary-800 font-[600]">Preview</p>
                        </div>
                        <div className="bg-primary-100 px-5 py-2 rounded-lg " onClick={() => handleSelection(el.id)}>
                          <p className="text-primary-800 font-[600]">Select</p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm">{el.campaign_name}</p>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </Modal>
  );
};

export default VoiceModal;
