import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchBySurveyId } from "services/newSurveyService";
import { CONTACT_TYPE } from "data/constants/surveyConstants";
import { useQuery } from "@tanstack/react-query";
import { baseMooyiRewardsURL } from "helpers/getBaseUrl";
import PreLoader from "components/Loader/PreLoader";
import QrCodeFlow from "../FinishingModals/QrCodeFlow";
import SavedContact from "../FinishingModals/SavedContact";

export const SuccessPage = () => {
  const navigate = useNavigate();
  // const { id } = useParams();
  const [params] = useSearchParams();
  const id = params.get("id");
  const audienceType = params.get("audienceType");
  const [copied, setCopied] = useState(false);

  const { data, isLoading } = useQuery(["newFetchBySurveyId", id], () => fetchBySurveyId(id), {
    enabled: !!id,
  });

  const surveyData = data?.data;
  const publishId = data?.data?.publish?.publishId;
  const surveyLink = `${baseMooyiRewardsURL}generated-link/${publishId}-SURVEY-${id}`;

  useEffect(() => {
    if (copied) {
      let showClicked = setTimeout(() => setCopied(!copied), 1500);
      return () => {
        clearTimeout(showClicked);
      };
    }
    return;
  });

  const closeModal = () => {
    navigate("/surveys");
  };

  const displayContent = () => {
    switch (audienceType) {
      case CONTACT_TYPE.GENERATE_LINK:
        return <QrCodeFlow surveyLink={surveyLink} />;
      case CONTACT_TYPE.MOOYI_AUDIENCE:
        return <SavedContact />;
      case CONTACT_TYPE.PERSONAL_CONTACT:
        return <SavedContact />;
      default:
    }
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="w-[100%] h-[100vh] bg-white  top-0 left-0 z-[3000]">
      <div className="flex justify-between mx-auto w-[80%] pt-[4em]">
        <div></div>

        <FaTimes onClick={closeModal} className="text-[24px]  cursor-pointer" />
      </div>

      <div className="h-[80vh] flex items-center justify-center">{displayContent()}</div>
    </div>
  );
};
