import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import useLoggedInUser from "hooks/useLoggedInUser";
import { createTestCampaign } from "services/campaignService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import AudioPlayer from "components/AudioPlayer";
import message from "assets/images/broadcast/message.png";
import pen from "assets/images/broadcast/pe.png";
import EditVoiceModal from "./EditVoiceModal";

const Publish = ({ data, setData }) => {
  const [open, setOpen] = useState(false);
  const { user } = useLoggedInUser();

  const handleModal = () => {
    setOpen(!open);
  };

  const { mutate: testMutate } = useMutation(createTestCampaign, {
    onSuccess: () => {
      Toast.success("Test created");
      queryClient.invalidateQueries(["wsCredit"]);
      setData({
        ...data,
        testContact: "",
      });
    },
    onMutate: () => {
      Toast.success("Sending Test Campaign");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  return (
    <div className="flex items-start justify-between">
      <div className="w-[50%]">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-[18px] text-[#0F172A] tracking-[-0.02em] font-semibold">Preview </p>
            <p>Confirm the details of your voice message before publishing.</p>
          </div>
        </div>

        <div className="w-full rounded-[8px] border flex items-center justify-between p-5 mt-5">
          <div className="flex items-center space-x-5">
            <div className="w-[65px] h-[45px] flex items-center justify-center bg-primary-100 rounded-lg p-5">
              <img src={message} alt="" />
            </div>
            <div className="space-y-2">
              <p className="text-[14px] font-bold">Here’s a special discount for you!</p>
              <p className="text-[12px] text-gray-500">Promotional</p>
            </div>
          </div>
          <div className="w-[25px] h-[20px] cursor-pointer" onClick={handleModal}>
            <img src={pen} alt="" />
          </div>
        </div>

        <div className="p-5 border rounded-[8px] mt-5">
          <div className="">
            <p className="text-[15px] tracking-[-0.02em] font-semibold">Summary</p>
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Total units</p>
              <p className="">{data.campaign_name}</p>
            </div>
            <hr />
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Audience</p>
              <div className="flex items-center space-x-2">
                {data?.recipientArray?.map((el, i) => {
                  return (
                    <p className="text-[12px] p-2 bg-gray-200 rounded-md " key={i}>
                      {el.name}
                    </p>
                  );
                })}
              </div>
            </div>
            <hr />
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Reach</p>

              <p className="text-right">{data.personal}</p>
            </div>
          </div>
        </div>

        {/* <div className="p-5 border rounded-lg mt-10">
          <div className="">
            <p className="text-[15px] tracking-[-0.02em] font-semibold">Audience</p>

            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Total unit</p>

              <p className="text-right text-primary-600 font-[700] text-[20px]">{data.personal}</p>
            </div>

            <div className="text-m flex justify-between border-b-[1px] py-4 text-right">
              <p className=" text-[#737373] font-[450]">Tags</p>
              <div className="flex items-center space-x-2">
                {data?.recipientArray?.map((el, i) => {
                  return (
                    <p className="text-[12px] p-2 bg-gray-200 rounded-md " key={i}>
                      {el.name}
                    </p>
                  );
                })}
              </div>
            </div>
            <p className="text-[15px] tracking-[-0.02em] font-semibold pt-5">Details</p>
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Title</p>
              <p className="">{data.campaign_name}</p>
            </div>

            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Objective</p>
              <p className=" max-w-[65%] leading-5 text-left break-all">{data.campaignObjective}</p>
            </div>
            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Broadcast type</p>
              <p className=" max-w-[65%] leading-5 text-left break-all">Voice</p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="w-[45%] bg-gray-50">
        <div className="bg-white drop-shadow-md py-5 px-2 w-full flex justify-center">
          <p>Preview</p>
        </div>
        <div className="p-4">
          <div className="mt-2">
            <AudioPlayer url={data?.content} className="h-[300px]" />
          </div>
        </div>
      </div>
      {open ? <EditVoiceModal handleOpen={handleModal} data={data} setData={setData} /> : null}
    </div>
  );
};

export default Publish;
