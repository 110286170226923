import { useState } from "react";

/*This custom hooks was created specifically for the dropdowns in the contact section to manage closing the drop down when outside the modal is being clicked */
export default function useModalHooks() {
  const [openDropdown, setOpenDropdown] = useState();

  const toggleDropdown = (e, id) => {
    e.stopPropagation();
    setOpenDropdown(id);
  };

  const closeModal = () => {
    setOpenDropdown(null);
  };

  return [openDropdown, toggleDropdown, closeModal];
}
