import ModalWithoutClose from "components/ModalWithoutClose";
import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import flutterwaveBanner from "assets/images/contacts/flutterbanner.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import SecondaryInput from "components/Inputs/SecondaryInput";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import { useNavigate } from "react-router-dom";

const FlutterwaveSegmentModal = ({ onClose }) => {
  const navigate = useNavigate();
  const initialValues = {
    businessName: "",
    merchantId: "",
  };

  const validationSchema = Yup.object({
    businessName: Yup.string()
      .min(2, "Name too short")
      .max(25, "Name too long (25 characters max)")
      .required("Please fill in this field"),
    merchantId: Yup.string().required("Please fill in this field"),
  });

  const onSubmit = async (data) => {
    console.log(data);
    navigate("/contacts/segment/create/flutterwave");
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const disableButton = !values.merchantId || !values.businessName;

  return (
    <ModalWithoutClose>
      <div className="w-[587px] rounded-xl overflow-hidden">
        <div className="w-full h-[130px] bg-[#FFCA7D] pr-5 py-3">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-3">
              <div className="w-[250px]">
                <img src={flutterwaveBanner} alt="" />
              </div>
              <div>
                <p className="font-[600] text-[12px] p-1 bg-white rounded w-fit">Flutterwave integration</p>
                <p className="font-[500] text-[14px] w-[63%]">Integrate and segment your customers with ease.</p>
              </div>
            </div>
            <div
              className="w-[20px] h-[20px] rounded-full p-1 flex items-center justify-center bg-white cursor-pointer"
              onClick={onClose}
            >
              <IoCloseOutline />
            </div>
          </div>
        </div>
        <div className="bg-white">
          <p className="text-[15px] p-5">Enter your store details to proceed.</p>
          <form action="" onSubmit={handleSubmit} className="mt-2">
            <div className="px-5">
              <div className="w-full">
                <SecondaryInput
                  text="Business name"
                  placeholder="Enter your business name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessName}
                  name="businessName"
                  error={getError("businessName")}
                  type="text"
                />
              </div>
              <div className="w-full mt-2">
                <SecondaryInput
                  text="Merchant ID"
                  placeholder="Enter your merchant ID"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.merchantId}
                  name="merchantId"
                  error={getError("merchantId")}
                  type="text"
                />
              </div>
            </div>
            <hr className="mt-5" />
            <div className="flex justify-end px-5 py-5">
              <div className="flex space-x-4">
                <GreyButton text="Cancel" onClick={onClose} />

                <BlueButton text={`Connect`} disabled={disableButton} type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </ModalWithoutClose>
  );
};

export default FlutterwaveSegmentModal;
