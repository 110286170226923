import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import React from "react";
import contactMascot from "assets/images/contactMascot.svg";
import { BsDot } from "react-icons/bs";

const UploadModal = ({ toggleModal, handleSubmit, tagArr, number }) => {
  return (
    <Modal title="Contacts uploaded" onClose={toggleModal}>
      <div className="w-[300px] px-5 flex items-center flex-col space-y-5 mt-4">
        <img src={contactMascot || ""} alt="contactMascot" />

        <div className="bg-neutral-100 p-4 w-[100%] text-neutral-800 border-[1px] rounded space-y-2">
          <p className="text-sm ">
            <span className="text-2xl font-semibold">{number}</span> {"  "}
            contacts will be added to your list
          </p>

          <div className="text-sm space-y-2">
            <div className="flex items-center ">
              <BsDot className="text-xl" />

              <p>Imported from: File upload</p>
            </div>

            <div className="flex items-center ">
              <BsDot className="text-xl" />

              <p className="flex space-x-3 ">
                Tagged:
                {tagArr.map((el) => {
                  return <span key={`tagArr${el}`}>{el}</span>;
                })}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4 w-[100%]">
          <GreyButton text="Cancel" className="w-[50%]" onClick={toggleModal} />

          <BlueButton text="Done" className="w-[50%]" onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
