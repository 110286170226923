import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Typography from "utils/typography";
import { motion } from "framer-motion";
import { Container } from "./questionTypes";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { BsFillEmojiSmileFill } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import useSurveyStore from "store/insightStore";
import usePageStore from "store/usePageStore";

const WelcomePanel = ({ info, nameKey }) => {
  const [open, setOpen] = useState("");
  const { setScreenConfig } = useSurveyStore();
  const { setPage } = usePageStore();

  const handleStuff = (i) => {
    handleOpen(i);
    setPage(-1);
  };

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const isOpen = open === `QuestionsPanel-welcome`;

  return (
    <div>
      <div className="space-y-4 ">
        <div
          className="flex items-center justify-between border-[1px] shadow h-[40px] px-4 hover:bg-gray-50 cursor-pointer transition duration-300 ease-in-out"
          onClick={() => handleStuff(`QuestionsPanel-welcome`)}
        >
          <div className="flex items-center space-x-2 w-[80%]">
            <div className="w-[25px] text-gray-700">
              {nameKey === "welcome" ? <BsFillEmojiSmileFill /> : <FaHeart />}
            </div>

            <Typography.MicroText className="w-[100%] truncate ...">{info.pryText}</Typography.MicroText>
          </div>

          <motion.div initial={{ rotate: 0 }} animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.4 }}>
            {isOpen ? <IoIosArrowDown /> : <IoIosArrowDown />}
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: isOpen ? 1 : 0, height: isOpen ? "auto" : 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ overflow: "hidden" }} // Ensures that the content is hidden when height is 0
        >
          <div className="border-[1px] rounded mb-4">
            <div>
              <Container>
                <PrimaryInput
                  type="text"
                  value={info.pryText}
                  onChange={(e) => setScreenConfig(nameKey, "pryText", e.target.value)}
                  text={<Typography.MicroText>Questions</Typography.MicroText>}
                />
              </Container>

              <div className="border-t-[1px] border-b-[1px]">
                <Container>
                  <PrimaryInput
                    type="text"
                    value={info.description}
                    onChange={(e) => setScreenConfig(nameKey, "description", e.target.value)}
                    text={<Typography.MicroText>Description</Typography.MicroText>}
                  />
                </Container>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default WelcomePanel;
