import React, { useState } from "react";
import subMascot from "assets/images/subMascot.png";
import Typography from "utils/typography";
import BlueButton from "components/Button/BlueButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { unSubscribe } from "services/userService";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";

const subData = [
  "I no longer want to recieve these emails",
  "I did not give my consent to the receive this information",
  "Other, please specify:",
];

const Reason = () => {
  const navigate = useNavigate();
  const [reason, setReason] = useState("");
  const [othersValue, setOthersValue] = useState("");

  const [params] = useSearchParams();
  const email = params.get("email");

  const { mutate, isLoading } = useMutation(unSubscribe, {
    onSuccess: () => {
      Toast.success("Successfully unsubscribed!!");
      navigate("/unsubscribe/resubscribe");
    },
    onError: () => {
      Toast.error("Something went wrong");
    },
  });

  const handleReason = (value) => {
    setReason(value);
    setOthersValue("");
  };

  const handleSubmit = () => {
    const userData = {
      email,
      reason: othersValue === "" ? reason : othersValue,
    };

    mutate(userData);
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div>
      <div className=" pb-4">
        <img src={subMascot || ""} alt="subMascot" />
      </div>

      <Typography.Headers>Please tell us why you unsubscribed? </Typography.Headers>

      <div className="space-y-2 my-[2em]">
        {subData.map((el, i) => (
          <div className="flex items-center space-x-2 cursor-pointer" key={el} onClick={() => handleReason(el)}>
            <div className="border-[1px] border-gray-500 w-[15px] h-[15px] rounded-full flex items-center justify-center">
              {reason === el ? <div className="bg-primary-800 w-[8px] h-[8px] rounded-full"></div> : null}
            </div>

            <Typography.SubText className=" text-gray-600">{el}</Typography.SubText>

            {i === 2 ? (
              <input
                type="text"
                className="border-b-[1px] border-gray-900 outline-none text-sm text-gray-600"
                disabled={reason !== el}
                onChange={(e) => setOthersValue(e.target.value)}
                value={othersValue}
              />
            ) : null}
          </div>
        ))}
      </div>

      <div className="flex items-center justify-center">
        <BlueButton
          text="Submit"
          className="bg-primary-800 text-white"
          onClick={handleSubmit}
          disabled={reason === "Other, please specify:" && othersValue === ""}
        />
      </div>
    </div>
  );
};

export default Reason;
