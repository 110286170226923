import React, { useState } from "react";
import Logo from "assets/images/newLogo.png";
import { Link } from "react-router-dom";
import { RiMenu4Fill } from "react-icons/ri";
import MobileHeader from "./MobileHeader";

const Header = ({ text }) => {
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <div className="bg-white h-[70px] flex items-center justify-center border-b border-[#D1D5DB]">
      <div className="w-[90%] mx-auto flex justify-between items-center">
        <div className="flex justify-center items-center space-x-3 ">
          <Link to="/">
            <img src={Logo} alt="logo" className="w-[120px]" />
          </Link>

          <div className="h-[40px] border-l-[1px] border-[#D1D5DB]"></div>

          <p className="text-2xl font-bold md:flex hidden">{text}</p>
        </div>

        <RiMenu4Fill className="text-[24px] flex md:hidden" onClick={handleModal} />

        <div className="space-x-4 sm:flex hidden">
          <Link to="/auth/login">
            <button className="w-[170px] h-[40px] bg-green text-primary-800 text-s rounded-md">Sign in</button>
          </Link>

          <Link to="/auth/register">
            <button className="w-[170px] h-[40px]  text-primary-800 text-s rounded-md border-[1px] border-purple">
              Register
            </button>
          </Link>
        </div>

        {open ? <MobileHeader handleModal={handleModal} /> : null}
      </div>
    </div>
  );
};

export default Header;
