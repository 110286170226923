import React from "react";
import OverviewCard from "../components/OverviewCard";
import overviewDoc from "assets/images/broadcast/overviewIcons/overviewOutcall.png";
import overviewOpened from "assets/images/broadcast/overviewIcons/overviewAnswered.png";
import overviewClicked from "assets/images/broadcast/overviewIcons/overviewFailed.png";
import { useQuery } from "@tanstack/react-query";
import { getCampOverview } from "services/campaignService";

const VoiceTab = () => {
  const { data, isLoading } = useQuery(["voiceOverview"], () => getCampOverview("robocall"));
  const campValue = data?.data?.robocall;
  const emailSUmmary = [
    { id: 1, name: "Total calls", image: overviewDoc, value: campValue?.total_sent },
    { id: 2, name: "Answered", image: overviewOpened, value: campValue?.answered },
    { id: 3, name: "Failed", image: overviewClicked, value: campValue?.failed },
  ];

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between space-x-4">
        {emailSUmmary.map((elm) => (
          <OverviewCard key={elm.id} elm={elm} loading={isLoading} />
        ))}
      </div>
    </div>
  );
};

export default VoiceTab;
