import React from "react";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

const OverviewCard = ({ elm, loading }) => {
  return (
    <div className="flex items-start space-x-4 border-[1px] w-[33%] h-[90px] p-4 rounded-lg bg-white">
      <div>
        <img src={elm.image} alt={elm.id} className="w-[30px]" />
      </div>

      <div className="w-[80%]">
        {loading ? <Skeleton count={1} className="w-[40%]" /> : <Typography.H3>{elm.value}</Typography.H3>}

        {loading ? <Skeleton className="w-[60%]" /> : <Typography.SubText>{elm.name}</Typography.SubText>}
      </div>
    </div>
  );
};

export default OverviewCard;
