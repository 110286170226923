import { useQuery } from "@tanstack/react-query";
import { getWorkspaceToken } from "helpers/authHelper";
import { getWorkspaceCredit } from "services/workspaceService";

const useCredit = () => {
  const wsToken = getWorkspaceToken();
  const { data: wsCredit, isFetching } = useQuery(["wsCredit"], getWorkspaceCredit, {
    enabled: !!wsToken,
  });

  return {
    wsCredit,
    isFetching,
  };
};

export default useCredit;
