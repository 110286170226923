import React from "react";
import BlueButton from "components/Button/BlueButton";
import owlbehnid from "assets/images/owlbehind.png";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";

export const CreateTagSuccessModal = ({ handleModal }) => {
  return (
    <Modal title="Tag created" onClose={handleModal}>
      <div className="w-[388px] bg-white flex flex-col justify-center">
        <div className="flex justify-center mt-3 py-5 bg-gray-50">
          <img src={owlbehnid} alt="" className="w-[163px]" />
        </div>
        <div className="flex justify-center mt-5">
          <p className="text-center text-[14px] w-[80%]">Success! Your new tag has been created.</p>
        </div>
        <div className="flex justify-end items-center w-full space-x-5 pt-5 mt-5 border-t px-5">
          <GreyButton text="Cancel" type="button" onClick={handleModal} />
          <BlueButton text="Done" type="submit" onClick={handleModal} />
        </div>
      </div>
    </Modal>
  );
};
