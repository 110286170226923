import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import Typography from "utils/typography";

const COLORS = ["#29085C", "#00DA7A", "#5F0BDD", "#A26BF4", "#CEAFFA", "pink"];

const RADIAN = Math.PI / 180;

const PieCharts = ({ data, questionOption, filterLength }) => {
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const dataValue = data[index].value;

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle" className="text-s font-bold">
        {`${(percent * 100).toFixed(0)}% (${dataValue})`}
      </text>
    );
  };

  return (
    <div>
      <div className="flex gap-4 flex-wrap">
        {data?.map((elm, index) => {
          return (
            <div key={`optionTag${index}`} className="flex items-center space-x-2 p-1 px-2 border-[1px] rounded-[4px]">
              <div className="w-[10px] h-[10px] rounded-sm" style={{ background: COLORS[index % COLORS.length] }}></div>
              <p className="text-ss">{elm.name}</p>
            </div>
          );
        })}
      </div>

      {questionOption !== undefined ? (
        <div className="flex items-center justify-between py-2">
          <Typography.SubText className="font-semibold">
            {questionOption?.min?.value} - {questionOption?.min?.label}
          </Typography.SubText>

          <Typography.SubText className="font-semibold">
            {questionOption?.max?.value} - {questionOption?.max?.label}
          </Typography.SubText>
        </div>
      ) : null}

      <div className="sm:w-[100%] w-[50%] h-[400px] px-0">
        <PieChart width={300} height={400} className="mx-auto">
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            innerRadius={70}
            outerRadius={150}
            fill="#8884d8"
            labelLine={false}
            dataKey={`${filterLength === 0 ? "value" : "staticValue"}`}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default PieCharts;
