import { setToken } from "helpers/authHelper";
import Api from "utils/axios";
import { handleApiError } from "utils/handleApiError";

export const signup = async ({ payload, invitationToken }) => {
  const url =
    invitationToken === undefined || "id"
      ? "/auth-microservice/user/create"
      : `/auth-microservice/user/create?invitationToken=${invitationToken}`;
  try {
    const { data } = await Api.post(url, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const login = async (payload) => {
  try {
    const { data } = await Api.post("/auth-microservice/user/signin", payload);
    setToken(data?.data?.token);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const forgotPassword = async (payload) => {
  try {
    const { data } = await Api.post("/auth-microservice/send-resetToken", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const resendAccountVerification = async (payload) => {
  try {
    const { data } = await Api.post("/auth-microservice/resend-acc-token", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const verify = async (id) => {
  try {
    const { data } = await Api.get(`/auth-microservice/user/verify/${id}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const resetPassword = async (payload) => {
  try {
    const { data } = await Api.post("/auth-microservice/reset-password", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

// putting contact sales in auth services
export const contactSales = async (payload) => {
  try {
    const { data } = await Api.post("/auth-microservice/contact-sales/reachout", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const checkProfileStatus = async () => {
  try {
    const { data } = await Api.get("/auth-microservice/user/check-profile-update");
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

// checking senderID for confirmation email
