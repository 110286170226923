// import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import WelcomeModal from "./WelcomeModal";
import { useStore } from "store";
import useLoggedInUser from "hooks/useLoggedInUser";
import Typography from "utils/typography";
import { Link, useNavigate } from "react-router-dom";
import SurveyIcon from "assets/images/surveyIconImage.png";
import EmailIcon from "assets/images/emailIcon.png";
import { useQuery } from "@tanstack/react-query";
import { recentActivities } from "services/surveyService";
import { toTitleCase } from "lib/titleCase";
import DashboardWeeklyDashbaord from "components/NewCalender/DashboardWeeklyDashbaord";
import { upcomingEvents } from "services/contactService";
import GiftIcon from "assets/images/gifts.png";
import GiftSMS from "assets/images/giftSms.png";
import GiftEmail from "assets/images/giftEmail.png";
import NoContent from "assets/images/noContent.svg";
import dayjs from "dayjs";
import CustomerLoyaltyModal from "./CustomerLoyaltyModal";
import SmsModal from "./quickActionModals/SmsModal";
import SmsPreview from "./quickActionModals/TemplatePreview";
import SurveyDetailsModal from "./quickActionModals/SurveyDetailsModal";
import actions from "assets/images/dashboard/actions.png";
import insights from "assets/images/dashboard/insights.png";
import loyalty from "assets/images/dashboard/loyalty.png";
import RecentCardSkeleton from "pages/Broadcasts/homeTab/components/RecentCardSkeleton";
import { motion } from "framer-motion";
import classNames from "classnames";

const quickActions = [
  {
    id: 1,
    name: "Drive customer action.",
    info: "Nudge customers to act or buy.",
    image: (
      <div className="flex items-center justify-center">
        <img src={actions} alt="Actions" />
      </div>
    ),
    link: "/contacts",
  },
  {
    id: 2,
    name: "Drive customer loyalty.",
    info: "Send a heartfelt message.",
    image: (
      <div className="flex items-center justify-center">
        <img src={loyalty} alt="Actions" />
      </div>
    ),
    link: "/broadcasts",
  },
  // { id: 3, name: "Get customer feedback.", info: "Gather feedback with ease and speed.", image: Feedback },
  {
    id: 4,
    name: "Get insights & leads.",
    info: "Expand your knowledge and user base.",
    image: (
      <div className="w-[45px] h-[45px] flex items-center justify-center">
        <img src={insights} alt="Actions" />
      </div>
    ),
    link: "/surveys",
  },
];

export default function DefaultDashboard() {
  const { user } = useLoggedInUser();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["recentActivities"], recentActivities);
  console.log(data?.joinedData, "joined data");

  const objectFilter = data?.joinedData?.map((el) => el.hasOwnProperty("surveyId"));

  console.log(objectFilter, "object filter");

  const { data: upcomingData } = useQuery(["upcomingEvents"], upcomingEvents);

  console.log(upcomingData, "upcomingData");

  const [open, setOpen] = useState(null);

  const campNavigate = (channel, id) => {
    switch (channel) {
      case "sms":
        return `/broadcasts/sms/${id}`;
      case "email":
        return `/broadcasts/email/${id}`;
      case "robocall":
        return `/broadcasts/voice/${id}`;
      default:
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.2, // Stagger the animation by 0.2 seconds per card
        duration: 0.5,
        ease: "easeInOut",
      },
    }),
  };

  const { onBoarding, setOnBoardingCount, onBoardingCount } = useStore();
  const [hover, setHover] = useState(null);
  const [displayOnboarding, setDisplayOnboarding] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);

  const handleWelcomeModal = () => {
    setWelcomeModal(!welcomeModal);
  };

  const { everLoggedIn } = onBoarding ?? false;

  // console.log({ onBoarding, onBoardingCount });

  const handleModal = useCallback(
    (id) => {
      setOpen(open === id ? null : id);
    },
    [open],
  );

  const handleHover = (id) => {
    setHover(hover === id ? null : id);
  };

  // console.log(resSurveys, "resSurveys");

  useEffect(() => {
    setOnBoardingCount(1);
  }, [setOnBoardingCount]);

  useEffect(() => {
    if (onBoarding?.importContacts === false) {
      setDisplayOnboarding(true);
      localStorage.setItem("modal", 1);
    } else {
      setDisplayOnboarding(false);
    }
  }, [onBoarding?.importContacts]);

  return (
    <div className="flex flex-col justify-between h-[100%]">
      {onBoardingCount < 3 && displayOnboarding ? (
        <WelcomeModal handleModal={() => setDisplayOnboarding(false)} />
      ) : null}

      <div>
        <p className="text-lg font-medium">
          Welcome back <span className="">{user?.data?.firstname}</span>
        </p>
      </div>

      <div className="flex items-center gap-[1em] my-[1em] overflow-x-scroll [&::-webkit-scrollbar]:h-0 py-2 w-[100%]">
        {quickActions.map((el) => (
          <div
            className={`w-full h-[90px] ${
              hover === el.id ? "border-blue-800 border" : ""
            } bg-white transition-all duration-75 shadow-md  rounded-lg flex items-center space-x-4 p-4 cursor-pointer`}
            onClick={() => handleModal(`QA${el.id}`)}
            onMouseEnter={() => handleHover(el.id)}
            onMouseLeave={() => handleHover(el.id)}
          >
            <div
              className={`h-[60px] w-[60px] ${
                hover === el.id ? "bg-blue-100" : "bg-gray-100"
              } hover:bg-blue-100 transition-all duration-75 flex items-center justify-center object-center overflow-hidden`}
            >
              {el.image}
            </div>

            <div className="space-y-1">
              <p className="text-sm font-semibold">{el.name}</p>
              <p className="text-xs font-normal text-neutral-600">{el.info}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-start rounded-md gap-[1em] py-4 pt-6 ">
        <div className="border-[1px] w-[60%] p-4 rounded-md bg-white h-[530px]">
          <div className="flex items-center justify-between h-[5%]">
            <Typography.Text>Recent campaigns</Typography.Text>

            <div></div>
          </div>

          <div className="mt-[1em] h-[80%]">
            {data?.joinedData?.length > 0 ? (
              <div className="space-y-4 ">
                {/* {data?.joinedData.slice(0, 4).map((el) => (
                  <div
                    className="border-[1px] rounded-lg h-[25%] bg-[#F8FAFFA8] p-4 flex items-center justify-between gap-4 cursor-pointer hover:border hover:border-primary-800 opacity-95 hover:opacity-100"
                    onClick={() =>
                      el.hasOwnProperty("surveyId")
                        ? navigate(`/research/online-survey/analytics/${el.publishId}/${el?.surveyId}`, { state: el })
                        : navigate(campNavigate(el.campaign_channel, el._id))
                    }
                  >
                    <div>
                      {el?.surveyName ? (
                        <img src={SurveyIcon} alt="SurveyIcon" className="w-[40px]" />
                      ) : (
                        <img src={EmailIcon} alt="EmailIcon" className="w-[40px]" />
                      )}
                    </div>
                  </div>
                ))} */}

                <div className="w-full">
                  {data?.joinedData?.length > 0 ? (
                    <div className="space-y-4 ">
                      {data?.joinedData.slice(0, 4).map((el) => (
                        <div
                          className="border-[1px] rounded-lg h-[100px] bg-[#F8FAFFA8] p-4 flex items-center justify-between gap-4 cursor-pointer hover:border hover:border-primary-800 opacity-95 hover:opacity-100"
                          onClick={() =>
                            el.hasOwnProperty("surveyId")
                              ? navigate(`/research/online-survey/analytics/${el.publishId}/${el?.surveyId}`, {
                                  state: el,
                                })
                              : navigate(campNavigate(el.campaign_channel, el._id))
                          }
                        >
                          <div>
                            {el?.surveyName ? (
                              <img src={SurveyIcon} alt="SurveyIcon" className="w-[40px]" />
                            ) : (
                              <img src={EmailIcon} alt="EmailIcon" className="w-[40px]" />
                            )}
                          </div>

                          <div className="w-[50%]">
                            <Typography.SubText className="font-medium">
                              {el?.surveyName || el?.campaign_name}
                            </Typography.SubText>
                            <Typography.SubText>
                              {el?.surveyCategory || toTitleCase(el?.campaignObjective)}
                            </Typography.SubText>
                          </div>

                          <div className="border-l-[1px] h-[100%]"></div>

                          <div className="w-[50%] flex flex-col justify-around h-[100%]">
                            {el?.expectedResponse ? (
                              <Typography.SubText className="font-medium">
                                Responses: {el?.responses}
                              </Typography.SubText>
                            ) : (
                              <Typography.SubText className="font-medium">
                                Delivered: {el?.metrics?.delivered?.value}
                              </Typography.SubText>
                            )}

                            {el?.expectedResponse ? (
                              <div className="bg-slate-200 w-[100%] h-[6px] rounded-md overflow-hidden">
                                <div
                                  className="bg-green-400 h-[100%]"
                                  style={{
                                    width: `${(el?.responses / el?.expectedResponse) * 100}%`,
                                  }}
                                ></div>
                              </div>
                            ) : (
                              <div className="bg-slate-200 w-[100%] h-[6px] rounded-md overflow-hidden">
                                <div
                                  className="bg-green-400 h-[100%]"
                                  style={{
                                    width: `${(+el?.metrics?.delivered?.value / +el?.metrics?.sent?.value) * 100}%`,
                                  }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="h-[100%] flex flex-col items-center justify-center space-y-4">
                      <img src={NoContent} alt="NoContent" className="w-[60px]" />
                      <p className="text-sm">Looks like you don't have any event to show.</p>
                    </div>
                  )}
                </div>
              </div>
            ) : // </div>
            null}
          </div>
        </div>
        <div className="border-[1px] w-[40%] p-4 rounded-md bg-white h-[530px] ">
          <div className="flex items-center justify-between">
            <Typography.Text>Upcoming events</Typography.Text>

            <Link to="/calender">
              <Typography.SubText className="text-primary-800 font-medium">See all</Typography.SubText>
            </Link>
          </div>

          <div>
            <DashboardWeeklyDashbaord />
          </div>

          {upcomingData?.length > 0 ? (
            <div className="flex flex-col justify-between">
              {upcomingData?.slice(0, 3).map((el) => (
                <div className="flex items-center justify-between  border-b-[1px] p-2 py-4 h-[150px]">
                  <div className="flex items-start space-x-2 ">
                    <div>
                      <img src={GiftIcon} alt="GiftIcon" className="w-[30px]" />
                    </div>

                    <div>
                      <div className="flex items-center space-x-2">
                        <p className="text-sm ">
                          {el?.firstname} {el?.lastname}{" "}
                        </p>

                        <div className="bg-green-200 h-[15px] w-[60px] text-[10px] flex items-center justify-center rounded-sm">
                          <p>Active</p>
                        </div>
                      </div>

                      <p className="text-xs text-gray-500">{dayjs(el?.birthday).format("D MMM, YYYY")}</p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2">
                    <img src={GiftSMS} alt="GiftSMS" className="w-[30px]" />
                    <img src={GiftEmail} alt="GiftEmail" className="w-[30px]" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="h-[50%] flex flex-col items-center justify-center space-y-4">
              <img src={NoContent} alt="NoContent" className="w-[60px]" />
              <p className="text-sm">Looks like you don't have any event to show.</p>
            </div>
          )}
        </div>

        {open === "QA2" || open === "QA1" ? (
          <CustomerLoyaltyModal flag={open} handleModal={handleModal} setOpen={setOpen} />
        ) : null}
        {open === "QA4" ? <SurveyDetailsModal handleOpen={handleModal} /> : null}
        {open === 1 ? <SmsModal handleModal={handleModal} /> : null}
        {open === 3 ? <SmsPreview handleModal={() => handleModal(1)} /> : null}
        {welcomeModal ? <WelcomeModal handleWelcomeModal={handleWelcomeModal} trackLogin={everLoggedIn} /> : null}
      </div>
    </div>
  );
}
