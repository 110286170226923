import React from "react";

const EditAction = ({ fill1, fill2, fill3 }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" fill="#FAFAFA" />
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" stroke={fill1} stroke-width="0.8" />
    <path
      d="M13.9893 26.8936L17.7441 26.1426C18.0345 26.0845 18.3012 25.9418 18.5106 25.7324L24.5501 19.6928L20.3075 15.4502L14.2679 21.4897C14.0585 21.6991 13.9158 21.9658 13.8577 22.2562L13.1068 26.011C13.0018 26.5358 13.4645 26.9986 13.9893 26.8936Z"
      fill={fill3}
    />
    <path
      d="M26.1445 14.916L25.0838 13.8553C24.2052 12.9766 22.7806 12.9766 21.9019 13.8553L20.3076 15.4496L24.5503 19.6922L26.1445 18.0979C27.0232 17.2193 27.0232 15.7946 26.1445 14.916Z"
      fill={fill2}
    />
  </svg>
);

export default EditAction;
