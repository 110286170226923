import React from "react";

export const Volume = ({ fill1, fill2 }) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0616 13.0117C12.1968 13.4276 12.6491 13.6616 13.0298 13.4466C13.5212 13.1691 13.9502 12.7882 14.2854 12.3267C14.7768 11.6505 15.0414 10.836 15.0414 10.0001C15.0414 9.16415 14.7768 8.34968 14.2854 7.67341C13.9502 7.21197 13.5212 6.83104 13.0298 6.55352C12.6491 6.33848 12.1968 6.57255 12.0616 6.98838C11.9265 7.4042 12.1669 7.84204 12.5157 8.10577C12.7011 8.24603 12.8661 8.41362 13.0045 8.60405C13.2993 9.00985 13.4581 9.49847 13.4581 10.0001C13.4581 10.5016 13.2993 10.9903 13.0045 11.396C12.8661 11.5865 12.7011 11.7541 12.5157 11.8944C12.1669 12.1581 11.9265 12.5959 12.0616 13.0117Z"
      fill={fill1}
    />
    <path
      d="M13.5856 15.2706C13.7207 15.6864 14.1702 15.9184 14.5659 15.7325C15.5289 15.2801 16.3665 14.5904 16.9969 13.7228C17.783 12.6407 18.2064 11.3376 18.2064 10.0001C18.2064 8.66267 17.783 7.35951 16.9969 6.27747C16.3665 5.40986 15.5289 4.72012 14.5659 4.26776C14.1702 4.08186 13.7207 4.31385 13.5856 4.72968C13.4505 5.1455 13.6824 5.58648 14.07 5.78871C14.7179 6.12665 15.2823 6.61127 15.7159 7.20813C16.3056 8.01966 16.6231 8.99699 16.6231 10.0001C16.6231 11.0032 16.3056 11.9805 15.7159 12.7921C15.2823 13.3889 14.7179 13.8736 14.07 14.2115C13.6824 14.4137 13.4505 14.8547 13.5856 15.2706Z"
      fill={fill1}
    />
    <path
      d="M10.2917 15.6911V3.66967C10.2917 2.30933 8.68949 1.5823 7.66571 2.47809L3.95833 5.72203H2.375C1.06333 5.72203 0 6.78536 0 8.09703V11.2637C0 12.5754 1.06333 13.6387 2.375 13.6387H3.95833L7.66571 16.8826C8.68949 17.7784 10.2917 17.0514 10.2917 15.6911Z"
      fill={fill2}
    />
  </svg>
);
