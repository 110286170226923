import React from "react";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import BlueButton from "components/Button/BlueButton";

const ReactivateModal = ({ toggleModal, workspaceName, openWorkspaceModal }) => {
  const handleClose = () => {
    toggleModal();
  };

  const initialValues = {
    accountName: "",
    email: "",
    workspaceName: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Enter a valid email address").required("Please fill in this field"),
  });

  const onSubmit = async (data) => {
    console.log({ data });
  };

  const { handleChange, values, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Modal title={`Reactivate ${workspaceName}`} onClose={handleClose}>
      <div className="w-[400px]">
        <p className="text-s mt-4">
          Please fill the details below. You will get an email with the next steps to reactivating your workspace.
        </p>
        <div>
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="mt-2">
              <p className="font-[600] text-[14px] pb-2">Account name</p>
              <div className="border-[1px] h-[40px] rounded-[8px] flex justify-between w-[100%] px-4 items-center">
                <input
                  placeholder="Enter account name"
                  className="w-[100%] outline-none h-[100%]"
                  name="accountName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accountName}
                />
              </div>
            </div>
            <div className="mt-2">
              <p className="font-[600] text-[14px] pb-2">Email address</p>
              <div className="border-[1px] h-[40px] rounded-[8px] flex justify-between w-[100%] px-4 items-center">
                <input
                  placeholder="Enter email address"
                  className="w-[100%] outline-none h-[100%]"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </div>
            </div>
            <div className="mt-2">
              <p className="font-[600] text-[14px] pb-2">Workspace name</p>
              <div className="border-[1px] h-[40px] rounded-[8px] flex justify-between w-[100%] px-4 items-center">
                <input
                  placeholder="Enter your workspace name"
                  className="w-[100%] outline-none h-[100%]"
                  name="workspaceName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.workspaceName}
                />
              </div>
            </div>
            <div className="flex justify-between mt-[1em]">
              <div></div>
              <div className="flex gap-[2em] mt-[1em]">
                <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={toggleModal} />
                <BlueButton
                  text="Reactivate"
                  type="submit"
                  disabled={values?.email === "" && values?.workspaceName === ""}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ReactivateModal;
