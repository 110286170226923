import React, { useEffect, useState } from "react";
import { TiInfo } from "react-icons/ti";
import Upload from "../../../assets/images/upload.svg";
import { useQuery } from "@tanstack/react-query";
import { getAllTags } from "../../../services/contactService";
import MatchColumns from "./MatchColumns";
import * as XLSX from "xlsx";
import BlueButton from "../../../components/Button/BlueButton";
import { AiOutlineFile, AiOutlineSearch } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { byteConverter } from "../../../lib/byteConverter";
import { Line } from "rc-progress";
import Mock from "../../../assets/mock.csv";
import useOutsideClick from "../../../hooks/useOutsideClick";
import Toast from "../../../config/toast";
import { IoIosArrowDown, IoMdCheckmark, IoMdPie } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import CreateTag from "../CreateTag";
import Checkbox from "../../../components/Inputs/CheckBox";
import CreateTagModal from "../CreateTagModal";
import { Link } from "react-router-dom";

const UploadCSV = ({ step, setStep, outSideContact, handleModal }) => {
  const { data: tagData } = useQuery(["allTags"], getAllTags);
  const [tagArr, setTagArr] = useState([]);
  const [openDrop, setOpenDrop] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [rawFile, setRawFile] = useState({});
  const [fileHeaders, setFileHeaders] = useState([]);
  const [topThreeRows, setTopThreeRows] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [uploadText, setUploadText] = useState(false);
  const [searchedArray, setSearchedArray] = useState(tagData);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const uploadRef = React.useRef();
  const [isChecked, setIsChecked] = useState(false);

  const handleDropdown = () => {
    setOpenDrop(!openDrop);
  };

  const refer = useOutsideClick(() => setOpenDrop(false));

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    if (query !== "") {
      const filteredData = tagData?.filter(
        (filtered) => filtered?.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
      );
      if (filteredData.length !== 0) {
        setSearchedArray(filteredData);
      }
    } else {
      setSearchedArray(tagData);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    setSearchedArray(tagData);
  }, [setSearchedArray, tagData]);

  const removedFromArray = (el) => {
    const filterArray = tagArr?.filter((elm) => elm !== el);
    setTagArr(filterArray);
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in tagArr) {
      if (tagArr[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const handleCheck = (e, el) => {
    if (e.target.checked) {
      if (!tagArr.includes(el)) {
        setTagArr([...tagArr, el]);
      }
    } else {
      const filterArray = tagArr?.filter((elm) => elm !== el);
      setTagArr(filterArray);
    }
  };

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
    } else {
      try {
        setUploadText(true);
        let file = e.target.files[0];
        setRawFile(file);
        setFileName(file.name);
        setFileSize(file.size);

        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        const workbookToJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        let headers = Object.keys(workbookToJson[0]);
        if (workbookToJson && headers) {
          setDisableButton(false);
          setUploadText(false);
        }

        setFileHeaders(headers);
        setTopThreeRows(workbookToJson.slice(0, 6));
      } catch (error) {
        Toast.error(error);
      }
    }
  };

  const clearEntry = () => {
    setRawFile({});
    setFileName("");
    setFileSize("");
    setFileHeaders([]);
    setDisableButton(true);
  };

  return (
    <div>
      {step ? (
        <div className="w-full">
          <div className="border-[1px] border-primary-100 p-4 rounded-[8px]  bg-primary-50 flex space-x-2 my-4 mt-2 w-full">
            <TiInfo className="text-[#FBBF24] text-[24px]" />
            <div>
              <p className="text-[14px font-[600]">PLEASE NOTE</p>

              <p className="text-s font-[400]">
                We do not send confirmation emails to uploaded contacts. Ensure you have obtained permission before
                adding contacts, and follow guidelines to prevent misuse.
              </p>
            </div>
          </div>

          <div className="w-full py-2">
            <div>
              <div className="flex justify-between items-center mt-2 ">
                <p className="font-[600] text-sm">Upload File</p>
                {/* <a
                  href={Mock}
                  className="flex text-ss text-primary-800 underline underline-offset-1"
                  download="Mooyi Template"
                >
                  Download template
                </a> */}
              </div>

              {!fileName ? (
                <div className="h-[200px] border-[1px] mt-2 bg-[#FAFAFA] border-[#D0D5DD] rounded-[8px] flex items-center flex-col justify-center space-y-1 ">
                  <img src={Upload} alt="" className="" />
                  <p className="text-[#262626] text-sm">Drag and drop CSV or XLSX file </p>
                  <p className="text-[#737373] text-ss">or select it from your computer</p>

                  <div className="flex space-x-4 pt-3">
                    <div className="px-4 rounded border-neutral-400 text-neutral-700 font-semibold py-2 border-[1px] text-sm relative">
                      <input
                        type="file"
                        className="w-[100%] h-[100%] absolute cursor-pointer top-0 left-0 opacity-0"
                        accept=".csv, .xlsx"
                        onChange={onSelectFile}
                        ref={uploadRef}
                      />

                      <p className="font-semibold">Browse files</p>
                    </div>

                    <a href={Mock} download="Mooyi Template">
                      <div className="px-4 rounded border-neutral-400 text-neutral-700 font-semibold py-2 border-[1px] text-sm ">
                        Download template
                      </div>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="border-[1px] rounded-[6px]">
                  <div className="flex  p-2 space-x-2">
                    <div className="w-[30px] h-[30px] bg-[#29085C1A] text-primary-800 text-[18px] flex items-center justify-center rounded-full">
                      <AiOutlineFile />
                    </div>

                    <div className="flex justify-between w-[90%]">
                      <div>
                        <p className="text-s">{fileName}</p>
                        <p className="text-ss text-[#A3A3A3]">{byteConverter(fileSize)}</p>
                      </div>

                      <MdOutlineDelete onClick={clearEntry} className="cursor-pointer" />
                    </div>
                  </div>

                  <div className="flex space-x-2 px-4 items-center mb-2 justify-between">
                    <div className="min-w-[75%] rounded-[8px]">
                      <Line percent={100} strokeWidth={1} strokeColor="#29085C" trailWidth={1} />
                    </div>

                    {disableButton ? (
                      <p className="text-[#253241] text-ss">Uploading</p>
                    ) : (
                      <p className="text-[#253241] text-ss">Uploaded</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              <div className="flex justify-between">
                <div className="flex space-x-2 items-center">
                  <p className="text-sm">Select tag(s)</p>
                  {/* <Tooltip text="Select the category of contacts you want to send your campaign to." /> */}
                </div>

                <p className="text-s text-primary-800 font-[500] cursor-pointer" onClick={() => handleModal(2)}>
                  Create Tag
                </p>
              </div>
              <div
                className="rounded-[6px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%] flex items-center relative"
                onClick={handleDropdown}
              >
                <p className="text-[#A3A3A3] text-xs">{tagArr.length} tags selected</p>

                <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[18px]">
                  <IoIosArrowDown />
                </div>
              </div>

              <div className=" flex-wrap gap-[1em]  py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
                {tagArr?.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className="bg-white border-[1px] border-[#BFCCDA] flex items-center px-4 rounded-[6px] h-[30px]  text-ss font-semibold cursor-pointer"
                    >
                      {el}
                      <FaTimes className="ml-2" onClick={() => removedFromArray(el)} />
                    </div>
                  );
                })}
              </div>

              {openDrop && (
                <div className="rounded-[6px] shadow-secondary pb-4 h-[250px] overflow-y-scroll bg-white" ref={refer}>
                  <div
                    className="bg-[#F5F5F5]  flex min-h-[45px] items-center flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%]"
                    // onClick={handleDropdown}
                  >
                    <span className="text-[#737373]">
                      <AiOutlineSearch size={20} />
                    </span>

                    <input
                      type="text"
                      className="w-auto bg-black pl-2 h-[80%] bg-transparent outline-none text-s"
                      placeholder="Search for tags"
                      value={query}
                      name="query"
                      onChange={handleQueryChange}
                    />
                  </div>

                  {searchedArray?.map((el) => {
                    return (
                      <div
                        className={`px-4 text-ss font-extrabold text-[#404040] border-b-[1px] border-[#D0D5DD] py-[8px] flex items-center justify-between hover:text-white hover:bg-primary-800 group ${
                          checkInArray(el?.name) ? "bg-[#E5E5E5] hover:bg-[#E5E5E5] hover:text-primary-800" : "bg-white"
                        }`}
                        key={el._id}
                        onClick={handleDropdown}
                      >
                        <div className="flex items-center space-x-4 relative w-[100%]">
                          <div>
                            <input
                              type="checkbox"
                              name="recipient"
                              id="recipient"
                              onChange={(e) => handleCheck(e, el?.name)}
                              checked={checkInArray(el?.name)}
                              className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                            />

                            <IoMdPie
                              className={` text-[24px] ${
                                checkInArray(el?.name) ? "block group-hover:text-black" : "group-hover:text-white"
                              }`}
                            />
                          </div>
                          <div>
                            <p className="text-m font-[500]">{el?.name}</p>
                            <p className="text-ss font-[400]">{el?.contactCount} contacts</p>
                          </div>
                        </div>

                        <IoMdCheckmark
                          className={`group-hover:block text-[24px] ${
                            checkInArray(el?.name) ? "block group-hover:text-black" : "hidden group-hover:text-white"
                          }`}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {/* {open ? <CreateTag toggleModal={toggleModal} /> : null} */}
          </div>

          <div className="mb-4">
            <div className="flex items-start space-x-3 w-full">
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

              <div className="-mt-1">
                <p className="text-ss mt-1">
                  I confirm that I have permission from my customers (or they have signed up to receive messages) to
                  share their data with third-party platforms for marketing purposes. By proceeding, you agree to our{" "}
                  <Link to="https://usemooyi.com/terms-of-use" className="text-blue-800">
                    {" "}
                    Terms of use{" "}
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <BlueButton
            text={uploadText ? "Uploading" : "Import"}
            disabled={disableButton || !isChecked}
            onClick={() => setStep(!step)}
            type="button"
          />
        </div>
      ) : (
        <MatchColumns
          rawFile={rawFile}
          fileName={fileName}
          fileHeaders={fileHeaders}
          topThreeRows={topThreeRows}
          tagArr={tagArr}
          handleModal={handleModal}
          outSideContact={outSideContact}
        />
      )}
    </div>
  );
};

export default UploadCSV;
