import React, { useState } from "react";
import { questionIcon, questionTag, questionTypes } from "./questionTypes";
import Typography from "utils/typography";
import { IoIosArrowDown } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";
import useSurveyStore from "store/insightStore";
import usePageStore from "store/usePageStore";

const QuestionsPanel = ({ question, index }) => {
  const {
    setSurveyQuestion,
    addOptionToQuestion,
    handleOptionValues,
    deleteValueOptions,
    handleLikertType,
    handleRatingTextType,
    handleRatingSelectType,
  } = useSurveyStore();
  const { setPage } = usePageStore();

  const [open, setOpen] = useState(null);

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const isOpen = open === `QuestionsPanel-${index}`;

  const handleStuff = (i) => {
    handleOpen(`QuestionsPanel-${i}`);
    setPage(i);
  };

  const handleText = (e) => {
    setSurveyQuestion(index, e.target.name, e.target.value);
  };

  const createValueOptions = () => {
    addOptionToQuestion(index);
  };

  const handleOptionChange = (e, optionIndex) => {
    handleOptionValues(index, optionIndex, e.target.value);
  };

  const handleDeleteOption = (optionIndex) => {
    deleteValueOptions(index, optionIndex);
  };

  const handleLikertChange = (e, parent) => {
    handleLikertType(index, parent, e.target.name, e.target.value);
  };

  const handleRatingTextChange = (e) => {
    handleRatingTextType(e.target.name, e.target.value, index);
  };

  const handleRatingSelectChange = (e) => {
    handleRatingSelectType(e.target.name, e.target.value, index);
  };

  const handleRatingIconChange = (value) => {
    handleRatingSelectType("shape", value, index);
  };

  return (
    <div className="space-y-4 ">
      <div
        className="flex items-center justify-between border-[1px] shadow h-[40px] px-4 hover:bg-gray-50 cursor-pointer transition duration-300 ease-in-out"
        onClick={() => handleStuff(index)}
      >
        <div className="flex items-center space-x-2 w-[85%]">
          <div className="w-[25px] text-gray-700">{questionIcon(question.questionType)}</div>

          <AnimatePresence mode="wait">
            {isOpen ? (
              <motion.div
                key="questionType"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <Typography.MicroText>{questionTag(question.questionType)}</Typography.MicroText>
              </motion.div>
            ) : (
              <motion.div
                key="question"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
                className="w-[95%]"
              >
                <Typography.MicroText className="truncate ... w-[100%]">{question.question}</Typography.MicroText>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <motion.div initial={{ rotate: 0 }} animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.4 }}>
          {isOpen ? <IoIosArrowDown /> : <IoIosArrowDown />}
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: isOpen ? 1 : 0, height: isOpen ? "auto" : 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{ overflow: "hidden" }} // Ensures that the content is hidden when height is 0
      >
        <div className="border-[1px] rounded mb-4">
          {questionTypes({
            questionType: question.questionType,
            handleText,
            question,
            createValueOptions,
            handleOptionChange,
            handleDeleteOption,
            handleLikertChange,
            handleRatingTextChange,
            handleRatingSelectChange,
            handleRatingIconChange,
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default QuestionsPanel;
