import React, { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowDown, IoMdCheckmark, IoMdPie } from "react-icons/io";

const SelectContactBySegment = ({ segmentData, contactSize, setContactSize, populatedArray, setPopulatedArray }) => {
  const [query, setQuery] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const refer = useOnclickOutside(() => setOpenDropdown(false));

  const [searchedArray, setSearchedArray] = useState(segmentData);

  useEffect(() => {
    setSearchedArray(segmentData);
  }, [segmentData]);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleCheck = (e, el) => {
    const count = el?.contactCount || 0;

    if (e.target.checked) {
      if (!populatedArray?.find((item) => item.name === el.name) && count > 0) {
        setContactSize((prev) => prev + count);
        setPopulatedArray([...populatedArray, { name: el?.name, noOfContact: count }]);
      }
    } else {
      setPopulatedArray((prevArray) => {
        const updatedArray = prevArray.filter((item) => item.name !== el.name);
        setContactSize((prev) => Math.max(0, prev - count));
        return updatedArray;
      });
    }
  };

  const handleChange = (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);
    if (searchQuery !== "") {
      const filteredData = segmentData?.filter((segment) =>
        segment?.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setSearchedArray(filteredData.length > 0 ? filteredData : segmentData);
    } else {
      setSearchedArray(segmentData);
    }
  };

  const removedFromArray = (el) => {
    setPopulatedArray((prevArray) => {
      const updatedArray = prevArray.filter((item) => item.name !== el.name);
      setContactSize((prev) => Math.max(0, prev - el.noOfContact));
      return updatedArray;
    });
  };

  const checkInArray = (name) => populatedArray.some((item) => item.name === name);

  return (
    <div>
      <div
        className="rounded-[8px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] mt-5 max-w-[100%] flex items-center relative"
        onClick={toggleDropdown}
        ref={refer}
      >
        {populatedArray.length === 0 ? (
          <p className="text-neu-400 text-ss">One of the segments is displayed here.</p>
        ) : (
          <p className="text-neu-400 text-ss">{populatedArray.length} Selected</p>
        )}
        <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[22px]">
          <IoIosArrowDown />
        </div>
      </div>
      <div ref={refer} className="flex-wrap gap-[1em] py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
        {populatedArray?.map((el, i) => (
          <div
            key={i}
            className="bg-bg border-[1px] bg-gray-50 border-blue-100 flex items-center px-4 rounded-[6px] h-[30px] text-ss cursor-pointer"
          >
            {el?.name}
            <span className="ml-2 p-1 bg-gray-300 rounded-full">
              <FaTimes className="font-thin text-[9px] text-white" onClick={() => removedFromArray(el)} />
            </span>
          </div>
        ))}
      </div>
      {openDropdown && (
        <div
          ref={refer}
          className="rounded-[8px] shadow-secondary pb-4 h-[300px] overflow-y-scroll bg-white cursor-pointer"
        >
          <div className="bg-[#F5F5F5] flex min-h-[40px] items-center gap-[.7em] px-[1em] py-[5px] max-w-[100%]">
            <span className="text-[#737373]">
              <AiOutlineSearch size={20} />
            </span>
            <input
              type="text"
              className="w-full h-[100%] outline-none text-s placeholder:text-s bg-transparent"
              placeholder="Search for segments"
              value={query}
              name="query"
              onChange={handleChange}
            />
          </div>
          {searchedArray?.map((el) => (
            <div
              className={`px-4 my-1 mx-3 text-s text-primary-800-1 hover:text-white border-b-[1px] border-[#D0D5DD] py-[8px] flex items-center justify-between cursor-pointer hover:bg-primary-800 hover:rounded-[8px] group ${
                checkInArray(el.name) ? "bg-neu-100 rounded-lg hover:!bg-white hover:text-primary-800" : ""
              }`}
              key={el._id}
            >
              <div className="flex items-center space-x-4 relative w-[100%] cursor-pointer">
                <div>
                  <input
                    type="checkbox"
                    name="recipient"
                    id="recipient"
                    onChange={(e) => handleCheck(e, el)}
                    checked={checkInArray(el?.name)}
                    className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                  />
                  <IoMdPie
                    className={` text-[24px] ${
                      checkInArray(el?.name)
                        ? "block text-primary-800 group-hover:text-primary-800"
                        : "group-hover:text-white"
                    }`}
                  />
                </div>
                <div
                  className={`${checkInArray(el?.name) ? "group-hover:text-primary-800" : "group-hover:text-white"}`}
                >
                  <p className="text-m font-[500]">{el?.name}</p>
                  <p className="text-ss font-[400]">{el?.contactCount} contacts</p>
                </div>
              </div>
              <IoMdCheckmark
                className={`text-[24px] ${
                  checkInArray(el?.name) ? "block group-hover:text-primary-800" : "hidden group-hover:text-white"
                }`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectContactBySegment;
