export function formatFileSize(sizeInBytes) {
  const KB_THRESHOLD = 1024; // 1 KB = 1024 bytes
  const MB_THRESHOLD = 1024 * 1024; // 1 MB = 1024 KB

  if (sizeInBytes < KB_THRESHOLD) {
    return sizeInBytes.toString() + " B"; // Less than 1 KB, return bytes
  } else if (sizeInBytes < MB_THRESHOLD) {
    const sizeInKB = sizeInBytes / KB_THRESHOLD;
    return sizeInKB.toFixed(2) + " KB"; // Convert to KB and return with 2 decimal places
  } else {
    const sizeInMB = sizeInBytes / MB_THRESHOLD;
    return sizeInMB.toFixed(2) + " MB"; // Convert to MB and return with 2 decimal places
  }
}
