import { baseMooyiRewardsURL } from "helpers/getBaseUrl";

export const CONTACT_TYPE = {
  PERSONAL_CONTACT: "use_saved_contacts",
  MOOYI_AUDIENCE: "buy_targeted_response",
  GENERATE_LINK: "generate_link",
  CONNECT_API: "connect_api",
};

export const SurveyStatuses = {
  DRAFT: "draft",
  ACTIVE: "active",
  COMPLETED: "completed",
  CLOSED: "closed",
  SCHEDULED: "scheduled",
  PROGRESS: "progress",
};

export const LogicTypes = {
  SCREENING_QUESTION: "screening_question",
  BRANCHING_QUESTION: "branching_question",
};

export const SurveyChannel = {
  EMAIL: "email",
  SMS: "sms",
  WHATSAPP: "whatsapp",
};

export const SurveyStatusDraft = ["all", "draft", "active", "completed", "closed", "scheduled", "progress"];

export const SurveyTypeEnum = {
  Insight: "Insight",
  fieldTextSurvey: "fieldTextSurvey",
  fieldMediaSurvey: "fieldMediaSurvey",
  OnlineSurvey: "onlineSurvey",
  BrandTracking: "brandTracking",
};

export const getAudience = (x) => {
  switch (x) {
    case CONTACT_TYPE.GENERATE_LINK:
      return "Generate Link";
    case CONTACT_TYPE.MOOYI_AUDIENCE:
      return "Buy Targeted Response";
    case "offline_survey":
      return "Buy Targeted Response";
    case CONTACT_TYPE.PERSONAL_CONTACT:
      return "Saved Contact";
    default:
  }
};

export const generatedLinkUrl = (type, surveyId, pubId) => {
  switch (type) {
    case CONTACT_TYPE.GENERATE_LINK:
      return `${baseMooyiRewardsURL}generated-link/${pubId}-SURVEY-${surveyId}`;
    case CONTACT_TYPE.PERSONAL_CONTACT:
      return `${baseMooyiRewardsURL}generated-link/${pubId}-SURVEY-${surveyId}`;
    case CONTACT_TYPE.MOOYI_AUDIENCE:
      return `${baseMooyiRewardsURL}${pubId}-SURVEY-${surveyId}`;
    default:
  }
};
