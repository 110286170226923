import React from "react";

export const UseSavedContactIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6663 14.6667C21.6119 14.6667 23.9997 12.2789 23.9997 9.33333C23.9997 6.38781 21.6119 4 18.6663 4C15.7208 4 13.333 6.38781 13.333 9.33333C13.333 12.2789 15.7208 14.6667 18.6663 14.6667Z"
      fill="#1D19AF"
    />
    <path
      d="M16 17.333C11.5817 17.333 8 20.9147 8 25.333C8 26.8058 9.19391 27.9997 10.6667 27.9997H26.6667C28.1395 27.9997 29.3333 26.8058 29.3333 25.333V23.9997C29.3333 20.3178 26.3485 17.333 22.6667 17.333H16Z"
      fill="#1D19AF"
    />
    <path
      d="M9.33366 17.333C5.65177 17.333 2.66699 20.3178 2.66699 23.9997V25.333C2.66699 26.8058 3.8609 27.9997 5.33366 27.9997H21.3337C22.8065 27.9997 24.0003 26.8058 24.0003 25.333V23.9997C24.0003 20.3178 21.0155 17.333 17.3337 17.333H9.33366Z"
      fill="#2D28FF"
    />
    <path
      d="M13.3333 14.6667C16.2789 14.6667 18.6667 12.2789 18.6667 9.33333C18.6667 6.38781 16.2789 4 13.3333 4C10.3878 4 8 6.38781 8 9.33333C8 12.2789 10.3878 14.6667 13.3333 14.6667Z"
      fill="#2D28FF"
    />
  </svg>
);
