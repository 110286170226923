import { Link } from "react-router-dom";
import Logo from "assets/images/newLogo.png";
// import BrandLogo from "assets/images/BrandLogo.svg";
import GreyButton from "components/Button/GreyButton";
import { getEmail } from "helpers/authHelper";
import { useMutation } from "@tanstack/react-query";
import { forgotPassword } from "services/authService";
import Toast from "config/toast";

export default function EmailSentPassword() {
  const email = getEmail();
  const { mutate } = useMutation(forgotPassword, {
    onSuccess: () => {
      Toast.success("Email sent!");
    },
  });

  const handleClick = () => {
    mutate({
      email,
    });
  };

  return (
    <div className="w-[90%] mx-auto">
      <div className="mt-[2em]">
        <Link to="/" className=" ">
          <img src={Logo} alt="Mooyi logo" className="w-[100px]" />
        </Link>

        <div></div>
      </div>

      <div className="w-[40%] mt-[10%] mx-auto">
        <p className="text-[24px] font-semibold">Reclaim your account</p>

        <p className="my-[2em] text-s">
          We’ve sent a message to <span className="text-green">{email}</span> with a link to reset your password.{" "}
        </p>

        <div className="space-x-4">
          <GreyButton text="Resend Password mail" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
}
