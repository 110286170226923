import PrimaryButton from "components/Button/PrimaryButton";
import React, { useEffect, useMemo, useState } from "react";
import BroadcastImage from "assets/images/broadcastImg.png";
import { getAllCampaigns } from "services/campaignService";
import Drafts from "./tableTabs/Drafts";
import Scheduled from "./tableTabs/Scheduled";
import Sent from "./tableTabs/Sent";
import { Messaging } from "assets/svgs/Messaging";
import { Link, useNavigate } from "react-router-dom";
import { useBroadcastStore } from "store/broadcastStore/broadcastState";
import { useQuery } from "@tanstack/react-query";

const Email = () => {
  const navigate = useNavigate();
  const [campCurrentPage, setCampCurrentPage] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const { emailTabs, setEmailTabs } = useBroadcastStore();

  const {
    data: draftData,
    isLoadingDraft,
    isFetching: isFetchingDraft,
  } = useQuery(["allEmailCampaignsDraft", campCurrentPage], () =>
    getAllCampaigns({ num: campCurrentPage, type: "email", status: "draft" }),
  );

  const {
    data: scheduledData,
    isLoadingScheduled,
    isFetching: isFetchingScheduled,
  } = useQuery(["allEmailCampaignsScheduled", campCurrentPage], () =>
    getAllCampaigns({ num: campCurrentPage, type: "email", status: "scheduled" }),
  );

  const {
    data: sentData,
    isLoadingSent,
    isFetching: isFetchingSent,
  } = useQuery(["allEmailCampaignsSent", campCurrentPage], () =>
    getAllCampaigns({ num: campCurrentPage, type: "email", status: "sent" }),
  );

  useEffect(() => {
    setEmailTabs({
      draft: draftData?.totalCount,
      scheduled: scheduledData?.totalCount,
      sent: sentData?.totalCount,
    });
  }, [draftData?.totalCount, scheduledData?.totalCount, sentData?.totalCount, setEmailTabs]);

  const tab = [
    { name: "Sent", value: 0, icon: Messaging, count: emailTabs?.sent },
    { name: "Drafts", value: 1, icon: Messaging, count: emailTabs?.draft },
    { name: "Scheduled", value: 2, icon: Messaging, count: emailTabs?.scheduled },
  ];

  const switchTab = (value) => {
    setTabValue(value);
  };

  const availableData = emailTabs?.draft !== 0 || emailTabs?.scheduled !== 0 || emailTabs?.sent !== 0 ? false : true;

  const total =
    tabValue === 0
      ? sentData?.totalCount
      : tabValue === 1
      ? draftData?.totalCount
      : tabValue === 2
      ? scheduledData?.totalCount
      : 0;

  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = total;

    let startValue = (campCurrentPage - 1) * 10;
    startValue = startValue + 1;

    let endValue = Math.min(campCurrentPage * 10, totalValue);
    return [startValue, endValue];
  }, [campCurrentPage, total]);

  const handlePrev = () => {
    setCampCurrentPage(campCurrentPage - 1);
  };

  const handleNext = () => {
    setCampCurrentPage(campCurrentPage + 1);
  };

  const displayTab = () => {
    switch (tabValue) {
      case 0:
        return (
          <Sent
            data={sentData}
            isFetching={isFetchingSent}
            isLoading={isLoadingSent}
            pageCountStart={pageCountStart}
            pageCountEnd={pageCountEnd}
            currentPage={campCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <div>
            <Drafts
              data={draftData}
              isFetching={isFetchingDraft}
              isLoading={isLoadingDraft}
              pageCountStart={pageCountStart}
              pageCountEnd={pageCountEnd}
              currentPage={campCurrentPage}
              handlePrev={handlePrev}
              handleNext={handleNext}
            />
          </div>
        );
      case 2:
        return (
          <Scheduled
            data={scheduledData}
            isFetching={isFetchingScheduled}
            isLoading={isLoadingScheduled}
            pageCountStart={pageCountStart}
            pageCountEnd={pageCountEnd}
            currentPage={campCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      default:
    }
  };

  return (
    <div className="border rounded-md bg-white p-5 overflow-y-auto h-[calc(100vh-80px)]">
      {!availableData ? (
        <div className="">
          <Link to="/broadcasts/email/setup">
            <div className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 cursor-pointer my-5">
              <p className="text-sm font-medium text-white">Create new email</p>
            </div>
          </Link>

          <div className="grid grid-cols-3 gap-x-[3em]">
            {tab.map((el, i) => {
              const IconComponent = el.icon;
              return (
                <div
                  key={i}
                  className={`${
                    tabValue === el.value ? "bg-[#F2F5FE] border-primary-600" : ""
                  } border cursor-pointer border-b-0 p-4 rounded-t-md`}
                  onClick={() => switchTab(el.value)}
                >
                  <div className="flex items-center space-x-3">
                    <IconComponent
                      fill1={tabValue === el.value ? "#95ADFF" : "#D4D4D4"}
                      fill2={tabValue === el.value ? "#1D19AF" : "#404040"}
                    />
                    <div className="flex items-center space-x-5">
                      <p className={`${tabValue === el.value ? "font-[600] text-primary-800" : ""}`}>{el.name}</p>
                      <div
                        className={`w-[24px] h-[24px] rounded flex items-center justify-center bg-gray-200 text-gray-500 ${
                          tabValue === el.value ? "font-[700] text-primary-800 bg-primary-200" : ""
                        }`}
                      >
                        <p>{el.count}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div>{displayTab()}</div>
        </div>
      ) : (
        <div className="w-[600px] h-[250px] p-6 border-[1px] rounded-xl flex items-center">
          <div className="flex flex-col space-y-3">
            <p className="bg-primary-100 text-primary-800 w-[100px] flex  justify-center items-center text-xs rounded-xl py-1 font-semibold">
              Messaging
            </p>

            <p className="text-2xl font-medium text-primary-800">Send out E-mails</p>
            <p className="text-sm w-[75%]">
              Connect and share messages with your uploaded audience to expand your reach
            </p>

            <PrimaryButton
              text="Get Started"
              css="bg-primary-800 text-white h-[40px] text-xs w-[150px]"
              onClick={() => navigate("/broadcasts/email/setup")}
            />
          </div>

          <img src={BroadcastImage} alt="BroadcastImage" className="max-h-[150px]" />
        </div>
      )}
    </div>
  );
};

export default Email;
