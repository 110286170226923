import React, { useRef, useState } from "react";
import audioWave from "assets/images/survey/audioWave.png";
import roundedPause from "assets/images/survey/roundedPause.png";
import PlayIcon from "assets/images/survey/PlayIcon.png";
import Toast from "config/toast";

const AudioPlayer = ({ url, className }) => {
  const [isPlaying, setIsPlaying] = useState(false); // Autoplay set to true initially
  const [isLoaded, setIsLoaded] = useState(false);
  const audioRef = useRef(null);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (audio && isLoaded) {
      if (audio.paused) {
        audio
          .play()
          .then(() => setIsPlaying(true))
          .catch(() => {
            setIsPlaying(false);
            handlePlayError();
          });
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    } else if (!isLoaded) {
      handlePlayError();
    }
  };

  console.log({ url });

  const handlePlayError = () => {
    Toast.error("An error occurred while trying to play the audio:");
  };

  const handleLoadedData = () => {
    setIsLoaded(true);
  };

  const handleError = (error) => {
    setIsLoaded(false);
    console.log(error);
    handlePlayError(error);
  };

  return (
    <div>
      <div className={`${className} w-full flex items-center justify-center bg-primary-100 relative`}>
        <img src={audioWave} alt="" className="object-cover" />
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <img
            src={isPlaying ? roundedPause : PlayIcon}
            alt=""
            className="w-[56px] h-[56px] cursor-pointer"
            onClick={togglePlayPause}
          />
        </div>
      </div>
      <div className="w-full bg-gray-100">
        <audio
          ref={audioRef}
          controls
          className="w-full"
          src={url}
          onLoadedData={handleLoadedData}
          onError={handleError}
        >
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  );
};

export default AudioPlayer;
