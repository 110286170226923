import { surveyTypes } from "lib/surveyFunctions";
import { surveyType } from "lib/surveyFunctions";
import { surveyIcons } from "lib/surveyFunctions";
import React, { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { LuPlus } from "react-icons/lu";
import { useLocation } from "react-router-dom";

const Questions = ({ surveyQuestionsArray, setSurveyQuestionsArray, surveyQuestion, newCardRef }) => {
  const [open, setOpen] = useState("");
  const location = useLocation();
  const pathArray = location.pathname.split("/");

  const createQuestion = (value) => {
    if (newCardRef.current) {
      newCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    if (value === "likert") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: {
            max: {
              value: 10,
              label: "",
            },
            min: {
              value: 1,
              label: "",
            },
          },
        },
      ]);
    } else if (value === "rating") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: {
            max: {
              value: 10,
              label: "",
            },
            min: {
              value: 1,
              label: "",
            },
          },
        },
      ]);
    } else if (value === "matrix") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: {
            row: [],
            column: [],
            multichoice: false,
          },
        },
      ]);
    } else if (value === "text_response") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
        },
      ]);
    } else {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: [],
        },
      ]);
    }

    handleOpen();
  };

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const surveyTypeArray = pathArray.includes("field-interview") ? ["text_response"] : surveyTypes;

  return (
    <div className="">
      <div className="mt-4 space-y-2">
        {surveyQuestionsArray.map((el, i) => {
          return (
            <div className="flex items-start  min-h-[35px]" ref={newCardRef} key={`sideQues-${i}`}>
              <div className="min-w-[30px]">{surveyIcons(el.questionType)}</div>
              <p className="text-xs min-w-[30px]">{i + 1}.</p>

              <p className="text-xs">{el.question}</p>
            </div>
          );
        })}
      </div>

      <div className="relative">
        <div
          className="border-[1px] h-[35px] flex items-center px-2 rounded hover:bg-slate-50 cursor-pointer justify-between text-neutral-700 my-2"
          onClick={() => handleOpen(1)}
        >
          <div className="flex items-center space-x-2">
            <LuPlus />

            <p className="text-xs">Add question</p>
          </div>

          <IoChevronDown />
        </div>

        {open === 1 ? (
          <div className="shadow-md bg-white overflow-hidden rounded-[8px] absolute top-[2.5em] right-2 w-[230px] z-20 border-[1px] ">
            {surveyTypeArray.map((elm) => {
              return (
                <div
                  className="flex items-center space-x-2 h-[35px] text-slate-700 hover:bg-slate-50 pl-4  cursor-pointer "
                  key={elm}
                  onClick={() => createQuestion(elm)}
                >
                  <div className="w-[30px]">{surveyIcons(elm)}</div>
                  <p className="text-ss">{surveyType(elm)}</p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Questions;
