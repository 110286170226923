import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import React from "react";
import Typography from "utils/typography";
import PreviewCard from "./PreviewCard";
import ModalWithoutClose from "components/ModalWithoutClose";

const PreviewTemplateModal = ({ handleClose, data, handleTemplateUse }) => {
  console.log(data);
  return (
    <ModalWithoutClose>
      <div className="w-[800px] h-[700px] mt-3 flex justify-center rounded-lg items-center overflow-hidden bg-[#FAFAFA] relative">
        <div className=" w-full h-full pb-10 overflow-hidden ">
          <PreviewCard data={data} />
        </div>

        <div className="flex absolute bottom-4 w-full justify-between mt-[1em] border-t pt-5 bg-white px-5">
          <GreyButton
            text="Go back"
            css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s"
            onClick={() => handleClose(2)}
          />
          <BlueButton text="Use template" onClick={handleTemplateUse} />
        </div>
      </div>
    </ModalWithoutClose>
  );
};

export default PreviewTemplateModal;
