import React from "react";

const AuthButton = ({ text, onClick, type, disabled }) => {
  return (
    <button
      className={`bg-primary-800 text-white text-s  hover:bg-primary-600 font-[600] rounded  py-2 px-4 w-[100%] hover:shadow-md h-[45px] ${
        disabled && "opacity-[0.5]"
      }`}
      onClick={onClick}
      type={type}
      disabled={disabled || false}
    >
      {text}
    </button>
  );
};

export default AuthButton;
