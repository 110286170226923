import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import SelectInput from "components/Inputs/SelectInput";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
import { useFormik } from "formik";
import { createSMSId } from "services/senderIdService";
import * as Yup from "yup";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";
import Modal from "components/Modal";
import Tooltip from "components/Tooltip";

const options = [
  { value: "", label: "Select type" },
  { value: "promotional", label: "Promotional" },
  { value: "service", label: "Service" },
];

const initialValues = {
  name: "",
  sender_type: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Please fill in this field").max(11, "Must not exceed 11 characters"),
});

const SmsIDModal = ({ toggleModal }) => {
  const { user } = useLoggedInUser();
  const { mutate } = useMutation(createSMSId, {
    onSuccess: () => {
      Toast.success("Request sent");
      queryClient.invalidateQueries(["smsSenderIDs"]);
      trackEvent("Create senderID success", {
        email: user?.data?.email,
        settingSection: "sender ID",
        attributeUpdated: "sms sender",
        date: dayjs().format(),
      });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    const userData = {
      ...data,
    };
    mutate(userData);
    toggleModal();

    trackEvent("create_senderID success", {
      email: user?.data?.email,
      settingSection: "sender ID",
      attributeUpdated: "sms sender",
      date: dayjs().format(),
    });
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched, isValid } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <Modal title="New sender ID" onClose={toggleModal}>
      <div className="w-[450px] px-5 relative">
        <div className="absolute top-1 left-[7rem]">
          <Tooltip text="This allows you to set a unique text that appears to your recipients. It can be the name of your company. Your sender ID should not exceed 11 characters." />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mt-[1em]">
            <SecondaryInput
              text="Sender name"
              placeholder="Enter sms sender name"
              onChange={handleChange}
              name="name"
              type="text"
              onBlur={handleBlur}
              value={values.name}
              error={getError("name")}
            />
          </div>

          <div>
            <p className="mt-[1em] font-[500]">Sender Type</p>
            <SelectInput
              name="sender_type"
              options={options}
              // disabled={true}
              onChange={handleChange}
              value={values.sender_type}
              css=" h-[50px] w-[100%] rounded-[6px]  bg-transparent"
            />
          </div>

          <div className="flex space-x-5 justify-end items-center my-[2em] mt-[2em]">
            <div
              className="rounded-[4px] h-[40px] px-8 text-sm font-bold bg-primary-300 flex items-center justify-center text-white cursor-pointer"
              onClick={toggleModal}
            >
              cancel
            </div>

            <div className="flex space-x-4">
              <BlueButton text="Create" />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SmsIDModal;
