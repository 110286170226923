export const BranchingIcon = ({ fill1, fill2 }) => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2880_73176)">
      {/* <mask id="mask0_2880_73176" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="11" height="14">
        <path
          d="M6.20494 2.67229L15.163 3.77139L14.2848 15.1922L5.62835 14.5266L5.84064 3.05458L5.47634 3.43687L6.14222 3.48807L6.20494 2.67229Z"
          fill="#D9D9D9"
        />
      </mask> */}
      <g mask="url(#mask0_2880_73176)">
        <path
          d="M10.5696 11.5452C10.9423 12.477 11.8449 13.0881 12.8485 13.0881H15.2037L14.5543 12.4386C14.2348 12.1191 14.2348 11.601 14.5543 11.2815C14.8738 10.962 15.3918 10.962 15.7113 11.2815L17.4663 13.0365C17.9456 13.5157 17.9456 14.2928 17.4663 14.7721L15.7113 16.5271C15.3918 16.8466 14.8738 16.8466 14.5543 16.5271C14.2348 16.2076 14.2348 15.6895 14.5543 15.37L15.1998 14.7244H12.8485C11.1758 14.7244 9.67153 13.706 9.0502 12.1528L7.42333 8.08557C7.05058 7.15368 6.14801 6.54261 5.14434 6.54261H2.45099C1.99913 6.54261 1.63281 6.17631 1.63281 5.72443C1.63281 5.27257 1.99913 4.90625 2.45099 4.90625H5.14434C6.81713 4.90625 8.32137 5.92469 8.94269 7.47784L10.5696 11.5452Z"
          fill={fill2}
        />
      </g>
      <path
        d="M16.5338 0.649801C16.2143 0.330276 15.6962 0.330276 15.3767 0.649792C15.0572 0.969309 15.0572 1.48736 15.3767 1.80687L16.0223 2.45242H13.671C11.9982 2.45242 7.1682 2.12974 6.54688 3.68288L6.25536 4.13053C5.88261 5.06244 4.98005 5.67346 3.97637 5.67346H1.28303C0.83116 5.67346 0.464844 6.03976 0.464844 6.49164C0.464844 6.94353 0.83116 7.30983 1.28303 7.30983H3.97637C5.64916 7.30983 6.54688 5.72834 7.77473 4.73828L8.18324 4.50107C9.00142 4.09197 12.6673 4.08878 13.671 4.08878H16.0262L15.3767 4.73828C15.0572 5.0578 15.0572 5.57584 15.3767 5.89539C15.6962 6.21489 16.2143 6.21489 16.5338 5.89531L18.2887 4.14038C18.768 3.66109 18.768 2.88404 18.2887 2.40476L16.5338 0.649801Z"
        fill={fill1}
      />
      <circle cx="16.3626" cy="3.27273" r="3.27273" fill={fill1} />
      <circle cx="16.3626" cy="13.9094" r="3.27273" fill={fill2} />
    </g>
    <defs>
      <clipPath id="clip0_2880_73176">
        <rect width="19.6364" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
