export const surveyCategory = [
  { value: "Concept Test", label: "Concept Test" },
  { value: "Ad Test", label: "Ad Test" },
  { value: "Product Test", label: "Product Test" },
  { value: "Customer Profile", label: "Customer Profile" },
  { value: "Jobs to be done", label: "Jobs to be done" },
  { value: "Feedback from local communities", label: "Feedback from local communities" },
  { value: "Competitor Research", label: "Competitor Research" },
  { value: "General market research", label: "General market research" },
  { value: "Customer Feedback ", label: "Customer Feedback" },
  { value: "Event Feedback", label: "Event feedback" },
  { value: "Pricing Research", label: "Pricing Research" },
  { value: "Affiliate Program Feedback", label: "Affiliate Program Feedback" },
  { value: "Others", label: "Others" },
];
