import React from "react";

function Typography(props) {
  return <p {...props} className={`${props.className} text-sm`} />;
}

Typography.Heading = function (props) {
  return (
    <h2
      {...props}
      className={`${props.className} md:text-[64px] md:leading-[64px] leading-11  text-4xl font-secondary font-semibold text-center sm:text-left`}
    >
      {props.children}
    </h2>
  );
};

Typography.SubHeading = function (props) {
  return (
    <h2
      {...props}
      className={`${props.className} text-gray-700 sm:text-2xl text-sm font-primary text-center sm:text-left`}
    >
      {props.children}
    </h2>
  );
};

Typography.Headers = function (props) {
  return (
    <h2 {...props} className={`text-center sm:text-3xl text-2xl font-secondary font-semibold ${props.className}`}>
      {props.children}
    </h2>
  );
};

Typography.H2 = function (props) {
  return (
    <h2 {...props} className={`text-2xl font-secondary font-semibold ${props.className}`}>
      {props.children}
    </h2>
  );
};

Typography.H3 = function (props) {
  return (
    <h2 {...props} className={`text-xl font-secondary font-semibold ${props.className}`}>
      {props.children}
    </h2>
  );
};

Typography.H4 = function (props) {
  return (
    <h2 {...props} className={`text-lg font-secondary font-semibold ${props.className}`}>
      {props.children}
    </h2>
  );
};

Typography.Text = function (props) {
  return (
    <h2 {...props} className={`text-base font-secondary font-semibold ${props.className}`}>
      {props.children}
    </h2>
  );
};

Typography.SubText = function (props) {
  return (
    <h2 {...props} className={`text-sm font-secondary text-gray-600 tracking-[-0.02em] ${props.className}`}>
      {props.children}
    </h2>
  );
};

Typography.MicroText = function (props) {
  return (
    <h2 {...props} className={`text-xs font-primary text-gray-600 ${props.className}`}>
      {props.children}
    </h2>
  );
};

export default Typography;
