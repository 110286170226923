import React, { useState } from "react";
import MetricCard from "./metricsCard";
import StackedBarCharts from "../Charts/StackedBarCharts";
import CopyAction from "assets/svgs/actionsSvgs/CopyAction";
import DownloadAction from "assets/svgs/actionsSvgs/DownloadAction";
import PowerOffAction from "assets/svgs/actionsSvgs/PowerOffAction";
import PlayAction from "assets/svgs/actionsSvgs/PlayAction";
import ShareAction from "assets/svgs/actionsSvgs/ShareAction";
import Loading from "assets/images/loading.gif";
import EditAction from "assets/svgs/actionsSvgs/EditAction";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "utils/typography";

const OverviewTab = ({ overViewData, graphData, isLoading, insights }) => {
  const [active, setActive] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const locationArray = location.pathname.split("/");

  const handleHover = (id) => {
    setActive(active === id ? null : id);
  };

  const handleEdit = () => {
    if (locationArray.includes("nps")) {
      navigate(`/insights/setup/nps?insightId=${insights._id}`);
    } else if (locationArray.includes("csat")) {
      navigate(`/insights/setup/csat?insightId=${insights._id}`);
    } else {
      navigate(`/insights/setup/customer-profile?insightId=${insights._id}`);
    }
  };

  const actionButtons = [
    {
      id: 1,
      name: "Copy link",
      action: (
        <CopyAction
          fill1={active === 1 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 1 ? "#95ADFF" : "#404040"}
          fill3={active === 1 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 2,
      name: "Export",
      action: (
        <DownloadAction
          fill1={active === 2 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 2 ? "#95ADFF" : "#404040"}
          fill3={active === 2 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 3,
      name: "Close survey",
      action: (
        <PowerOffAction
          fill1={active === 3 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 3 ? "#95ADFF" : "#404040"}
          fill3={active === 3 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 4,
      name: "Pause survey",
      action: (
        <PlayAction
          fill1={active === 4 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 4 ? "#95ADFF" : "#404040"}
          fill3={active === 4 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
    {
      id: 5,
      name: "Share",
      action: (
        <ShareAction
          fill1={active === 5 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 5 ? "#95ADFF" : "#404040"}
          fill3={active === 5 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
    },
  ];

  return (
    <div>
      <div>
        <div className="flex w-full items-center justify-between">
          <div>
            <Typography.SubText>Filter:</Typography.SubText>
          </div>
          <div className="flex space-x-2 items-center">
            <Typography.SubText>Actions:</Typography.SubText>
            <div className="flex items-center space-x-2 relative">
              {insights?.surveyStatus === "draft" ? (
                <div
                  className="cursor-pointer"
                  onMouseEnter={() => handleHover(6)}
                  onMouseLeave={() => setActive(null)}
                  onClick={handleEdit}
                >
                  <EditAction
                    fill1={active === 6 ? "#1D19AF" : "#DFE5EC"}
                    fill2={active === 6 ? "#95ADFF" : "#404040"}
                    fill3={active === 6 ? "#1D19AF" : "#A3A3A3"}
                  />

                  {active === 6 ? (
                    <div className="absolute top-10">
                      <p className="px-3 py-1 w-fit rounded-md bg-white text-[10px]">Edit</p>
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  {actionButtons.map((el, i) => (
                    <div
                      key={i}
                      className="cursor-pointer"
                      onMouseEnter={() => handleHover(el.id)}
                      onMouseLeave={() => setActive(null)}
                    >
                      {el.action}
                      {active === el.id ? (
                        <div className="absolute top-10">
                          <p className="px-3 py-1 w-fit rounded-md bg-white text-[10px]">{el.name}</p>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-5 w-full mt-5">
          {overViewData?.map((el, i) => (
            <div key={i} className="w-full">
              <MetricCard elm={el} type={el.name} value={el.value} title={el.name} loading={isLoading} />
            </div>
          ))}
        </div>
        <div>
          <div className="bg-white p-6 w-full mt-[3rem] rounded-lg border-[1px] ">
            {isLoading ? (
              <div className="w-full h-full flex items-center justify-center">
                <img src={Loading} alt="Loading..." className="w-[200px]" />
              </div>
            ) : (
              <StackedBarCharts data={graphData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
