import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "utils/typography";
import Tooltip from "components/Tooltip";
import BlueButton from "components/Button/BlueButton";
import researchGif from "assets/images/researchGif.png";
import OverviewTab from "./components/OverviewTab";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import detractors from "assets/images/detractors.png";

const tabs = [
  { id: 1, name: " Customer Profiling" },
  { id: 2, name: "NPS Survey" },
  { id: 3, name: "Customer Satisfaction" },
];

const Home = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const handleTabChange = (e) => {
    setStep(e);
  };

  const onlineSummary = [
    {
      id: "onlineSummary1",
      name: "Total NPS score",
      image: totalScore,
      value: 10,
    },
    {
      id: "onlineSummary2",
      name: "Promoters",
      image: promoters,
      value: 10,
    },
    {
      id: "onlineSummary3",
      name: "Passives",
      image: passives,
      value: 10,
    },
    {
      id: "onlineSummary3",
      name: "Detractors",
      image: detractors,
      value: 10,
    },
  ];

  const displayStep = () => {
    switch (step) {
      case 1:
        return <OverviewTab nameKey="customerProfile" overViewData={onlineSummary} />;
      case 2:
        return <OverviewTab nameKey="nfs" overViewData={onlineSummary} />;
      case 3:
        return <OverviewTab nameKey="cSat" overViewData={onlineSummary} />;

      default:
    }
  };

  return (
    <div className="">
      <div className="border-[1px] rounded-lg h-[240px] w-[100%] bg-white ">
        <div className="bg-contactBg bg-cover h-[100%] flex items-center justify-center ">
          <div className="w-[40%] space-y-4">
            <Typography.H3 className="font-semibold text-primary-800">Get insights from your customers</Typography.H3>

            <Typography.SubText className="w-[70%]">
              Know your audience's needs, preferences, and feedback to create meaningful interactions that drive loyalty
              and growth.
            </Typography.SubText>

            <BlueButton text="Get started" onClick={() => navigate("setup")} />
          </div>

          <div className="w-[40%] h-[100%]">
            <img src={researchGif} alt="researchGif" className="h-[100%]" />
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2 pt-5">
        <Typography.Text>Overview</Typography.Text>
        <Tooltip text="This shows a summary of the number of surveys sent, average completion and drop-off rates." />
      </div>

      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <Typography.SubText
              className={` pb-[4px] cursor-pointer ${
                step === el.id ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.id !== 4 ? () => handleTabChange(el.id) : null}
              key={el.id}
            >
              {el.name}
            </Typography.SubText>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
    </div>
  );
};

export default Home;
