import { MdMessage } from "react-icons/md";
import dayjs from "dayjs";
import { BsDot } from "react-icons/bs";
import React from "react";

export const survStatus = (x) => {
  switch (x) {
    case "scheduled":
      return (
        <p className="text-[11px] flex gap-1 items-center text-[#1F6BF1] w-[80px]">
          <p> Scheduled</p>
        </p>
      );
    case "closed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#E24444] bg-[#FCCDCD] justify-center rounded py-[2px] w-[80px]">
          <p>Closed</p>
        </p>
      );
    case "draft":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#AA8609] bg-[#FAECBA] justify-center rounded py-[2px] w-[80px]">
          <p> Draft</p>
        </p>
      );
    case "completed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-primary-800 w-[80px]">
          <p> Completed</p>
        </p>
      );
    case "active":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#12B76A] bg-[#B2F9C8] justify-center rounded py-[2px] w-[80px]">
          <p>Active</p>
        </p>
      );
    default:
  }
};

export const calenderSurveyStatus = (el) => {
  switch (el.surveyStatus) {
    case "scheduled":
      return (
        <div
          className={`text-[10px] p-[2px] pl-2  bg-primary-100 rounded-[2px] cursor-pointer flex items-start  mt-2 space-x-2 border-l-[2px] border-primary-800`}
        >
          <div>
            <MdMessage className={"mt-1 text-primary-800"} />
          </div>

          <div className="w-[100%]">
            <p className={"w-[80%] truncate ..."}>{el.surveyDetails.surveyName}</p>
            <div className={"flex items-center text-[6px"}>
              <p>{dayjs(el.publishDate).format("h:mm a")}</p>
              <BsDot className="text-xs" />
              <p className={"font-bold"}>{survStatusEnum(el.surveyStatus)}</p>
            </div>
          </div>
        </div>
      );
    case "closed":
      return (
        <div
          className={`text-[10px] p-[2px] pl-2  bg-red-100 rounded-[2px] cursor-pointer flex items-start  mt-2 space-x-2 border-l-[2px] border-red-800`}
        >
          <div>
            <MdMessage className={"mt-1 text-red-800"} />
          </div>

          <div className="w-[100%]">
            <p className={"w-[80%] truncate ..."}>{el.surveyDetails.surveyName}</p>
            <div className={"flex items-center text-[6px"}>
              <p>{dayjs(el.publishDate).format("h:mm a")}</p>
              <BsDot className="text-xs" />
              <p className={"font-bold"}>Closed</p>
            </div>
          </div>
        </div>
      );
    case "draft":
      return (
        <div
          className={`text-[10px] p-[2px] pl-2  bg-[#FDEAD7] rounded-[2px] cursor-pointer flex items-start  mt-2 space-x-2 border-l-[2px] border-warning-800`}

          // onClick={() =>console.log(el)}
        >
          <div>
            <MdMessage className={"mt-1 text-warning-800"} />
          </div>

          <div className="w-[100%]">
            <p className={"w-[80%] truncate ..."}>{el.surveyDetails.surveyName}</p>
            <div className={"flex items-center text-[6px"}>
              <p className={""}>{dayjs(el.publishDate || el.createdAt).format("h:mm a")}</p>
              <BsDot className="text-xs" />
              <p className={"font-bold"}>Draft</p>
            </div>
          </div>
        </div>
      );
    case "completed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-primary-800">
          <p> Completed</p>
        </p>
      );
    case "active":
      return (
        <div
          className={`text-[10px] p-[2px] pl-2  bg-[#DCFCE7] rounded-[2px] cursor-pointer flex items-start  mt-2 space-x-2 border-l-[2px] border-green-800`}

          // onClick={() =>console.log(el)}
        >
          <div>
            <MdMessage className={"mt-1 text-green-800"} />
          </div>

          <div className="w-[100%]">
            <p className={"w-[80%] truncate ..."}>{el.surveyDetails.surveyName}</p>
            <div className={"flex items-center text-[6px"}>
              <p className={""}>{dayjs(el.publishDate).format("h:mm a")}</p>
              <BsDot className="text-xs" />
              <p className={"font-bold"}>Active</p>
            </div>
          </div>
        </div>
      );
    default:
  }
};

export const survStatusEnum = (x) => {
  switch (x) {
    case "scheduled":
      return "Scheduled";
    case "closed":
      return "Closed";
    case "draft":
      return "Draft";
    case "completed":
      return "Completed";
    case "active":
      return "Active";
    default:
  }
};

export const accStatus = (x) => {
  switch (x) {
    case "scheduled":
      return (
        <p className="text-[11px] flex gap-1 items-center text-[#1F6BF1] w-[80px]">
          <p> {x}</p>
        </p>
      );
    case "closed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#E24444] bg-[#FCCDCD] justify-center rounded py-[2px] w-[80px]">
          <p>{x}</p>
        </p>
      );
    case "Pending":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#AA8609] bg-[#FAECBA] justify-center rounded py-[2px] w-[80px]">
          <p> {x}</p>
        </p>
      );
    case "completed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-primary-800 w-[80px]">
          <p> {x}</p>
        </p>
      );
    case "Approved":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#12B76A] bg-[#B2F9C8] justify-center rounded py-[2px] w-[80px]">
          <p>{x}</p>
        </p>
      );
    default:
  }
};

export const reportStatus = (x) => {
  switch (x) {
    case "Scheduled":
      return (
        <p className="text-[11px] flex gap-1 items-center text-[#1F6BF1] w-fit h-[20px] px-2">
          <p> {x}</p>
        </p>
      );
    case "Closed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#E24444] bg-[#FCCDCD] justify-center rounded py-[2px] w-fit h-[20px] px-2">
          <p>{x}</p>
        </p>
      );
    case "Pending":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#AA8609] bg-[#FAECBA] justify-center rounded py-[2px] w-fit h-[20px] px-2">
          <p> {x}</p>
        </p>
      );
    case "completed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-primary-800 w-fit h-[20px] px-2">
          <p> {x}</p>
        </p>
      );
    case "Sent":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#12B76A] bg-[#B2F9C8] justify-center rounded py-[2px] w-fit h-[20px] px-2">
          <p>{x}</p>
        </p>
      );
    default:
  }
};
