import React from "react";
import moment from "moment";
import { campStatus } from "lib/campaignFunction";
// import { campType } from "lib/campaignFunction";
import { toTitleCase } from "lib/titleCase";
// import Banner from "assets/images/Specialofferbanner2.png";

const Details = ({ data }) => {
  return (
    <div>
      {/* <p className="py-2 text-[20px] font-[500]">Details</p> */}

      <div className="flex items-start justify-between gap-4">
        <div className="w-[50%] p-5 border rounded-lg space-y-3 bg-white">
          {/* <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Subject</p>
            <p className=" text-black font-[500]">{data?.campaignSubject}</p>
          </div> */}

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Objective</p>
            <p className=" text-black font-[500]">{data?.campaignObjective}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Type</p>
            <p className=" text-black font-[500]">{toTitleCase(data?.CampaignChannel)}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Published</p>
            <p className=" text-black font-[500]">{moment(data?.campaignDate).format("MMMM Do YYYY, h:mm:ss a")}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px] items-center">
            <p className="w-[30%] text-[#525252] font-[400]">Tag</p>
            <div className="flex space-x-4">
              {data?.audience?.personalContacts?.map((el, i) => {
                return (
                  <p key={i} className="text-[12px] p-2 bg-gray-200 rounded-md">
                    {el}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Status</p>
            <p className=" text-black font-[500]">{campStatus(data?.campaignStatus)}</p>
          </div>
          <div className="text-m flex justify-between p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Unit spent</p>
            <p className=" text-[20px] font-[700] text-primary-700">{data.campaignCost}</p>
          </div>
        </div>
        <div className="w-[50%]">
          {/* <div className="w-full h-[60px] flex items-center px-5 drop-shadow-lg bg-white">
            <p>Email Preview</p>
          </div> */}
          <div className="flex justify-center">
            {/* <div className="border p-5 w-[60%] mt-10">
              <p className="font-[700] text-[20px] text-[#F68509]">Company Logo</p>
              <div>
                <img src={Banner} alt="" className="w-full py-5" />
              </div>
              <div className="text-[12px]">
                <p className="font-[700px]">Hey there!</p>
                <p className="pt-3">
                  Welcome to the mooyi family! 🌟 We're thrilled to have you on board and wanted to kick off this
                  exciting journey with a special treat just for you! As a token of our appreciation, here's a 10%
                  discount on your first purchase with us! Simply use the code WELCOME10 at checkout to redeem your
                  discount. Hurry, this offer expires in two weeks! Start exploring our fantastic collection and treat
                  yourself to something fabulous. We can't wait to see what catches your eye! Happy shopping! Best
                  regards, [Your Name] [Your Brand]
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
