import { useMutation } from "@tanstack/react-query";
import Toast from "config/toast";
import { formatDate } from "lib/formatDate";
import React, { useCallback, useEffect, useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import customEmpty from "assets/icons/contacts/CustomEmpty.png";
import EmptyState from "pages/Contacts/EmptyState";
import CreateCustomFieldModal from "./CreateCustomFieldModal";
import { CreateCustomSuccessModal } from "./CreateCustomSuccessModal";
import { BsSearch } from "react-icons/bs";
import { Delete } from "assets/icons/contacts/Delete";
import { createCustom } from "services/contactService";
import { CiEdit } from "react-icons/ci";
import { queryClient } from "config/gateway";
import DeleteContact from "pages/Contacts/DeleteContact";
import { deleteCustom } from "services/contactService";

const actionsIcons = [{ id: 4, icon: Delete, label: "Delete" }];

const CustomFields = ({ custom, isLoading, isFetching }) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [selectArray, setSelectArray] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(null);
  const [action, setAction] = useState("");
  const [hoverTag, setHoverTag] = useState(null);

  const handleHoverTag = (id) => {
    setHoverTag(hover === id ? null : id);
  };

  const { mutate: customDelete } = useMutation(deleteCustom, {
    onSuccess: () => {
      Toast.success("custom field deleted");
      queryClient.invalidateQueries(["allCustom"]);
      handleDelete(4);
    },
    onError: (error) => {
      Toast.error(`${error}`);
    },
  });

  const { mutate: create } = useMutation(createCustom, {
    onSuccess: () => {
      Toast.success("custom field created successfully");
      navigate("/contacts/all-contacts?tab=3");
      queryClient.invalidateQueries(["allCustom"]);
      handleCreate({ id: 2, action: "" });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDelete = (id) => {
    setOpenModal(openModal !== id && active ? id : null);
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      custom?.fields?.map((el) => selectArray.push(el.fieldName));
      for (let i = 0; i < custom?.fields?.length; i++) {
        setSelectArray([...selectArray, custom[i]?.fieldName]);
      }
    } else {
      setSelectArray([]);
    }
  };

  const handleHover = (id) => {
    setHover(hover !== id ? id : null);
  };

  const handleDeleteCustom = () => {
    const payload = {
      fieldNames: selectArray,
    };
    customDelete(payload);
  };

  const checkArray = useCallback(() => {
    if (selectArray?.length > 0) {
      setActive(true);
    } else if (selectArray?.length <= 0) {
      setActive(false);
    }
  }, [selectArray?.length, setActive]);

  const checkInArray = (y) => {
    let checked = false;
    for (let x in selectArray) {
      if (selectArray[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectArray([...selectArray, x]);
    } else {
      const filterArray = selectArray?.filter((el) => el !== x);
      setSelectArray(filterArray);
    }
  };

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  // const handleMore = (id) => {
  //   navigate(`/contacts/tag/${id}`);
  // };

  const handleCreate = ({ id, action }) => {
    setOpenModal(openModal === id ? null : id);
    setAction(action);
  };

  const columns = [
    {
      field: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={(e) => selectAll(e)}
          />
        </p>
      ),
    },
    { field: "fieldName", header: "Field name" },
    { field: "contactSize", header: "Contact size" },
    {
      field: "dateCreated",
      header: (
        <p className="flex items-center justify-between font-[700]">
          {" "}
          Date created <FaArrowDown />
        </p>
      ),
    },
    {
      field: "lastUpdated",
      header: (
        <p className="flex items-center justify-between font-[700]">
          {" "}
          last Updated <FaArrowDown />
        </p>
      ),
    },
  ];

  const customData = custom?.fields?.map((item, i) => ({
    inputProps: (
      <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0 z-[100]">
        <input
          type="checkbox"
          name="recipient"
          id="recipient"
          checked={checkInArray(item?.fieldName, selectArray)}
          className="pl-0 w-[15px] h-[15px] accent-purple"
          onChange={(e) => handleCheck(e, item?.fieldName)}
        />
      </p>
    ),
    id: item._id,
    fieldName: (
      <p
        className="cursor-pointer flex items-center space-x-1"
        onMouseEnter={() => handleHover(i)}
        onMouseOut={() => handleHover(i)}
        onClick={() => handleCreate({ id: 1, action: "edit" })}
      >
        {item.fieldName} {hover === i ? <CiEdit /> : null}
      </p>
    ),
    contactSize: item.contactCount,
    dateCreated: formatDate(item.created_at),
    lastUpdated: formatDate(item.updatedAt),
  }));

  const handleSubmit = (data) => {
    const payload = {
      fieldName: data.name,
      fieldType: data.dataType,
    };
    create(payload);
  };

  return (
    <div>
      <div className="p-4 flex items-center justify-between relative border-x">
        <div className="flex items-center space-x-2">
          <p>Actions:</p>
          <div className="flex items-center space-x-2">
            {actionsIcons.map((el, i) => {
              const IconComponent = el.icon;
              return (
                <div
                  onClick={() => handleDelete(el.id)}
                  onMouseEnter={() => handleHoverTag(el.id)}
                  onMouseLeave={() => setHover(null)}
                  className={`w-[40px] h-[40px] cursor-pointer rounded-[4px] border ${
                    active ? "border-primary-800" : ""
                  } flex items-center justify-center`}
                >
                  <IconComponent fill1={active ? "#95ADFF" : "#A3A3A3"} fill2={active ? "#1D19AF" : "#404040"} />
                  {hoverTag === el.id ? (
                    <p
                      className={`bg-white drop-shadow-md text-[12px] ${
                        active ? "text-primary-800" : ""
                      } py-1 px-2 top-12 rounded absolute`}
                    >
                      {el.label}
                    </p>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search custom fields... "
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <table className="w-[100%] mb-4 border-b-[1px] bg-white">
        <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
          <tr className="h-[50px] border-r-[1px] rounded-[2px] overflow-hidden">
            {columns &&
              columns.map((head, i) => (
                <th key={i} className="px-2 text-left border-l-[1px]">
                  {head.header}
                </th>
              ))}
          </tr>
        </thead>
        {customData?.length === 0 || customData?.length === undefined ? null : (
          <tbody>
            {isLoading || isFetching ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                  <tr
                    key={i}
                    className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] "
                  >
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-2 border-l-[1px] relative">
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <>
                {customData &&
                  customData?.map((row, i) => (
                    <tr
                      className="h-[50px] text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] hover:bg-slate-50 cursor-pointer"
                      key={i}
                    >
                      {columns?.map((col, j) => (
                        <td key={j} className="px-4 border-l-[1px] relative">
                          {row[col.field] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        )}
      </table>
      {customData?.length === 0 || customData?.length === undefined ? (
        <tbody className="flex justify-center w-full mt-20">
          <EmptyState
            title="No custom fields."
            type="custom"
            icon={customEmpty}
            content={
              <p>
                Custom fields allow you to capture specific contact information that is most relevant to you and your
                business. Click the 'Create new custom field' button to begin.
              </p>
            }
            button="Create new field"
            handleClick={() => setOpenModal(1)}
          />
        </tbody>
      ) : null}
      {openModal === 1 ? (
        <CreateCustomFieldModal
          handleSubmitClick={handleSubmit}
          handleModal={() => setOpenModal(null)}
          action={action}
        />
      ) : null}
      {openModal === 2 ? <CreateCustomSuccessModal handleModal={handleCreate} /> : null}
      {openModal === 4 ? (
        <DeleteContact
          toggleModal={handleDelete}
          deleteContactsFunc={handleDeleteCustom}
          title="Delete custom field(s)"
          content="Are you sure you want to delete? This action cannot be undone."
        />
      ) : null}
    </div>
  );
};

export default CustomFields;
