import dayjs from "dayjs";
import React from "react";
import { PiDotOutlineFill } from "react-icons/pi";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";
import defaultSurveyImage from "assets/images/defaultSurveyImage.png";

const RecentCards = ({
  surveyName,
  surveyDate,
  responses,
  expectedResponse,
  isLoading,
  isFetching,
  surveyType,
  percentage,
}) => {
  return (
    <div
      className={`flex items-center space-x-2 bg-[#F8FAFFB2] border-[1px] p-3 rounded-lg cursor-pointer hover:border-primary-800 w-[100%]`}
    >
      <div className="w-[15%] border-[1px] h-[50px] rounded-md overflow-hidden">
        <img src={defaultSurveyImage} alt="defaultSurveyImage" className="w-[100%] h-[100%] object-cover" />
      </div>

      <div className="w-[60%]">
        {isLoading || isFetching ? (
          <Skeleton className="w-[70%]" />
        ) : (
          <Typography.SubText className="font-semibold w-[100%] truncate ... ">{surveyName}</Typography.SubText>
        )}

        {isLoading || isFetching ? (
          <Skeleton className="w-[90%]" />
        ) : (
          <div className="flex items-center  w-[100%]">
            <Typography.MicroText>{surveyType}</Typography.MicroText>
            <PiDotOutlineFill className="text-gray-500" />
            <Typography.MicroText>{dayjs(surveyDate).format("ddd, DD MMM, YYYY, hh:mm a")}</Typography.MicroText>
          </div>
        )}
      </div>

      <div className="border-l-[1px] w-[1px] pr-2 h-[60px] border-gray-300"></div>

      <div className="w-[20%] space-y-2">
        {isLoading || isFetching ? (
          <Skeleton className="w-[100%]" />
        ) : (
          <Typography.MicroText>Responses: {responses} </Typography.MicroText>
        )}

        {isLoading || isFetching ? (
          <Skeleton className="w-[100%]" />
        ) : (
          <div className="bg-neutral-200 rounded-lg h-[7px] overflow-hidden">
            <div
              className="bg-success-600 h-[100%]"
              style={{
                width: percentage ? percentage : responses !== 0 ? `${(responses / expectedResponse) * 100}%` : "0%",
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentCards;
