import { handleApiError } from "utils/handleApiError";
import Api from "utils/axios";
// import { cl } from "@fullcalendar/core/internal-common";

export const createSmsCampaign = async (payload) => {
  try {
    const { data } = await Api.post("/campaign-microservice/campaign", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const singleSmsCampaign = async (payload) => {
  try {
    const { data } = await Api.post("/campaign-microservice/campaign/single-broadcast", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createTestCampaign = async (payload) => {
  try {
    const { data } = await Api.post("/campaign-microservice/campaign/test", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createRobocallsCampaign = async (payload) => {
  try {
    const { data } = await Api.post("/campaign-microservice/campaign", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const reTargetCampaign = async (payload) => {
  try {
    const { data } = await Api.post("/campaign-microservice/campaign/retarget", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllCampaigns = async ({ num, type, status }) => {
  try {
    if (status !== undefined) {
      const { data } = await Api.get(`/campaign-microservice/campaigns?batch=${num}&type=${type}&status=${status}`);
      return data?.data;
    } else {
      const { data } = await Api.get(`/campaign-microservice/campaigns?batch=${num}&type=${type}`);
      return data?.data;
    }
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllSmsCampaigns = async (page) => {
  const [draft, scheduled, sent] = await Promise.all([
    getAllCampaigns({ num: page, type: "sms", status: "draft" }),
    getAllCampaigns({ num: page, type: "sms", status: "scheduled" }),
    getAllCampaigns({ num: page, type: "sms", status: "sent" }),
  ]);

  return {
    draft,
    scheduled,
    sent,
  };
};

//campaign-microservice/campaign/metrics/

export const getACampaign = async (id) => {
  try {
    const { data } = await Api.get(`campaign-microservice/campaign/metrics/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getOverview = async (id) => {
  try {
    const { data } = await Api.get(`campaign-microservice/campaign/overview`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getACampaignReport = async (id) => {
  try {
    const { data } = await Api.get(`campaign-microservice/campaign/details/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCampaignReport = async (id) => {
  try {
    const { data } = await Api.get(`campaign-microservice/campaign/metrics/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCampaignGraph = async ({ id, select }) => {
  try {
    const { data } = await Api.get(
      `campaign-microservice/campaign/metrics/graph/${id}?period=${select === undefined ? "" : select}`,
    );
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getTemplate = async (payload) => {
  try {
    const { data } = await Api.post("/template/create", payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteCampaign = async (payload) => {
  try {
    const { data } = await Api.delete(`/campaign-microservice/campaign/delete`, {
      data: payload,
    });
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const costCampaign = async (payload) => {
  try {
    const { data } = await Api.post(`/campaign-microservice/campaign/cost`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const textToSpeech = async (payload) => {
  try {
    const { data } = await Api.post(`/workspace-microservice/workspace/txt-convertor`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAudience = async () => {
  try {
    const { data } = await Api.get(`/mooyi-audience/tags`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

//campaign calendar with holidays
export const getAllCampaignsCalendar = async (year, month) => {
  try {
    const { data } = await Api.get(`/campaign-microservice/campaign/calendar?month=${month}&year=${year}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllHolidayCalendar = async () => {
  try {
    const { data } = await Api.get(`/campaign-microservice/campaign/holidays`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAudienceCount = async (payload) => {
  try {
    const { data } = await Api.post(`/mooyi-audience/count`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const saveAndExit = async ({ payload, campaignId }) => {
  try {
    if (campaignId === undefined) {
      const { data } = await Api.post(`campaign-microservice/campaign/draft?shouldPublish=false`, payload);
      return data;
    } else {
      const { data } = await Api.post(
        `campaign-microservice/campaign/draft?campaignId=${campaignId}&shouldPublish=false`,
        payload,
      );
      return data;
    }
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCampaignDetails = async (id) => {
  try {
    const { data } = await Api.get(`campaign-microservice/campaign/details/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const campaignSettings = async ({ channel, payload }) => {
  try {
    const { data } = await Api.post(`/campaign-microservice/broadcast/setting/set?channel=${channel}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCampaignSettings = async (channel) => {
  try {
    const { data } = await Api.get(`/campaign-microservice/broadcast/setting/view?channel=${channel}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCampOverview = async (channel) => {
  try {
    const { data } = await Api.get(`campaign-microservice/campaign/messaging-overview?campaignChannel=${channel}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getRecent = async () => {
  try {
    const { data } = await Api.get(`/campaign-microservice/campaign/recent-campaign`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getTopPerforming = async () => {
  try {
    const { data } = await Api.get(`/campaign-microservice/campaign/top-performing-campaign`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
