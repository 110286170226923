import React from "react";

const PrimaryButton = ({ text, css, onClick, type }) => {
  return (
    <button
      className={`px-6 flex hover:bg-primary-600 hover:text-white items-center justify-center ${css} rounded-[6px] font-bold transition-all duration-100`}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
