import React from "react";

export const SkipLogicIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.94557 3.69573C6.90921 2.88214 3.78822 4.68404 2.97463 7.72041C2.86743 8.12046 2.45617 8.35791 2.05607 8.25074C1.65597 8.14349 1.41853 7.73226 1.52574 7.33214C2.55374 3.49562 6.49724 1.21884 10.3338 2.24684C13.0683 2.97953 14.9573 5.17776 15.4794 7.76151C15.5615 8.16749 15.2988 8.56319 14.8929 8.64524C14.4869 8.72729 14.0912 8.46464 14.0091 8.05866C13.593 5.99916 12.0972 4.27229 9.94557 3.69573Z"
      fill="#1D19AF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0248 4.51404C16.4248 4.62122 16.6623 5.03244 16.5551 5.43256L15.8141 8.19804C15.7625 8.39049 15.6364 8.55459 15.4637 8.65397C15.291 8.75334 15.0858 8.77989 14.8934 8.72777L12.1301 7.97867C11.7304 7.87029 11.4941 7.45832 11.6025 7.05857C11.7109 6.65874 12.1228 6.42257 12.5226 6.53094L14.5599 7.08324L15.1062 5.04436C15.2134 4.64424 15.6247 4.40679 16.0248 4.51404Z"
      fill="#95ADFF"
    />
    <circle cx="3" cy="12.9141" r="1.5" fill="#1D19AF" />
    <circle cx="8.25" cy="12.9141" r="0.75" fill="#1D19AF" />
    <circle cx="14.25" cy="12.9141" r="1.5" fill="#1D19AF" />
  </svg>
);
