import React from "react";
import { Link, Outlet } from "react-router-dom";
import Logo from "assets/images/newLogo.png";
import BlueButton from "components/Button/BlueButton";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const UnSubscribeLayout = () => {
  return (
    <div>
      <div className="h-[70px] flex items-center justify-center">
        <div className="w-[90%] flex items-center justify-between">
          <a href={process.env.REACT_APP_WEB_BASE_URL} className="logo">
            <img src={Logo} alt="Mooyi logo" className="w-[120px] " />
          </a>

          <div className=" flex items-center space-x-6">
            <Link to="/auth/login" className="text-sm text-primary-800">
              Login
            </Link>

            <Link to="/auth/register">
              <BlueButton text="Sign up" className="w-[130px]" />
            </Link>
          </div>
        </div>
      </div>

      <div className="h-[calc(100vh-120px)] w-[100vw] bg-primary-800">
        <div className="bg-subBg bg-cover bg-no-repeat w-[100%] h-[100%]  flex items-center justify-center">
          <div className="w-[550px] bg-white rounded-xl p-10">
            <Outlet />
          </div>
        </div>
      </div>

      <div className="bg-white flex items-center justify-center">
        <div className="h-[50px] flex items-center justify-between text-sm w-[90%]">
          <p>© 2024 Mooyi™. All right reserved.</p>

          <div className="flex items-center space-x-6">
            <p>Terms of use</p>
            <p>Privacy policy</p>

            <div className="flex items-center space-x-4 text-xl ">
              <a href="https://www.facebook.com/mooyi.africa?mibextid=LQQJ4d">
                <FaFacebook />
              </a>

              <a href="https://x.com/mooyiafrica?s=21&t=z3mhh3-Ie8KzpOqK2ePxzA">
                <FaSquareXTwitter />
              </a>

              <a href="https://www.instagram.com/mooyi.africa?igsh=MThmZXRuZ242NXhoOA==">
                <FaInstagram />
              </a>

              <a href="*">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnSubscribeLayout;
