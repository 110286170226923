import React from "react";
// import WelImg from "assets/images/mascot.svg";
import WelImg from "assets/svgs/Mooyimascot.svg";
// import IconButton from "components/Button/IconButton";
// import { HiArrowNarrowRight } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BlueButton from "components/Button/BlueButton";

const WelcomeModal = ({ handleModal, trackLogin }) => {
  const navigate = useNavigate();

  const checkRoute = () => {
    navigate("/dashboard/kyc");
  };

  return (
    <div className="bg-grey fixed top-0 bottom-0 left-0 h-[100vh] w-full flex justify-center items-center z-[999]">
      <div className="bg-white text-[#292D32]  rounded-[8px] overflow-hidden">
        <div className="text-center w-[614px] h-[300px] flex justify-between ">
          <div className="bg-mascotBg w-[40%] flex justify-center items-center">
            <img src={WelImg} alt="Welcome" className="w-[80%]" />
          </div>

          <div className="w-[55%] py-[2em] px-[1.5em]  text-left">
            <div className="flex justify-between pb-[1em]">
              <p></p>

              <FaTimes className="text-[14px] cursor-pointer" onClick={handleModal} />
            </div>
            <p className="font-semibold text-[24px]">{trackLogin === false ? "Welcome aboard!" : "Ready now?"}</p>
            <p className="text-s text-[#667085] font-[450] my-[0.5em] w-[90%] ">
              {trackLogin === false
                ? "Please complete your profile to unlock additional features and 100 free email credits."
                : "Please complete your profile for branded SMS and 100 free email credits"}
            </p>

            <div className="flex items-center mt-[1em]" onClick={checkRoute}>
              <BlueButton type="button" text="Continue" />
              {/* <IconButton type="button" text="Get Started" icon={<HiArrowNarrowRight />} /> */}
            </div>
            <div className="mt-5" onclick={handleModal}>
              {trackLogin !== false && <p className="underline text-blue-800 text-[12px]">Don't remind me again</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
