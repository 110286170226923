import React from "react";

export const TimeIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 1.5C7.08579 1.5 6.75 1.83579 6.75 2.25C6.75 2.66421 7.08579 3 7.5 3H8.25V3.79642C5.29027 4.16549 3 6.69029 3 9.75C3 13.0637 5.68629 15.75 9 15.75C12.3137 15.75 15 13.0637 15 9.75C15 6.69029 12.7097 4.16549 9.75 3.79642V3H10.5C10.9142 3 11.25 2.66421 11.25 2.25C11.25 1.83579 10.9142 1.5 10.5 1.5H7.5Z"
      fill={fill1}
    />
    <path
      d="M5.07851 3.3549C4.86236 3.00241 4.39895 2.88924 4.06751 3.13646C3.77497 3.35465 3.49724 3.59201 3.23613 3.84698C2.94029 4.13587 2.97988 4.61124 3.29438 4.87968C3.60889 5.14811 4.07885 5.10722 4.38044 4.82435C4.53218 4.68203 4.69042 4.54679 4.85465 4.41907C5.18105 4.16524 5.29467 3.70739 5.07851 3.3549Z"
      fill={fill2}
    />
    <path
      d="M12.9215 3.3549C13.1376 3.00241 13.601 2.88924 13.9325 3.13646C14.225 3.35465 14.5028 3.59201 14.7638 3.84698C15.0596 4.13587 15.0201 4.61124 14.7056 4.87968C14.3911 5.14811 13.9211 5.10722 13.6195 4.82435C13.4678 4.68203 13.3095 4.54679 13.1453 4.41907C12.8189 4.16524 12.7053 3.70739 12.9215 3.3549Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4053 8.40533C11.6982 8.11245 11.6982 7.63755 11.4053 7.34467C11.1124 7.05178 10.6376 7.05178 10.3447 7.34467L9.3885 8.30078C9.2646 8.2677 9.1344 8.25 9 8.25C8.17155 8.25 7.5 8.92155 7.5 9.75C7.5 10.5785 8.17155 11.25 9 11.25C9.82845 11.25 10.5 10.5785 10.5 9.75C10.5 9.6156 10.4823 9.4854 10.4492 9.3615L11.4053 8.40533Z"
      fill={fill2}
    />
  </svg>
);
