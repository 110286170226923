import React, { useState } from "react";
import reportClicked from "assets/images/broadcast/reportClicked.png";
// import reportDoc from "assets/images/broadcast/reportDoc.png";
import reportsent from "assets/images/broadcast/reportsent.png";
// import smsBlueClick from "assets/images/broadcast/smsBlueClick.png";
import unsubscribed from "assets/images/broadcast/unsubscribed.png";
import costIcon from "assets/images/broadcast/costIcon.png";
import smsCircle from "assets/images/broadcast/smsCircle.png";
import Graph from "./Graph";
import ToggleButton from "components/Button/ToogleButton";

const Overview = ({ data, campDetails, graphData }) => {
  const [toggleValue, setToggleValue] = useState(false);
  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  // const graphData = [
  //   {
  //     sent: 350,
  //     delivered: 290,
  //   },
  // ];

  // const getPercentage = (x, y) => {
  //   if (x === 0) {
  //     return 0;
  //   } else {
  //     return <p className="text-[28px] font-semibold">{(x / y) * 100}%</p>;
  //   }
  // };
  return (
    <div>
      <div className="flex items-center space-x-3 my-5">
        <p className={`font-[500] text-[16px] ${toggleValue ? "text-gray-300" : ""}`}>Absolute (#)</p>
        <ToggleButton toggleValue={toggleValue} onChange={handleToggleChange} />
        <p className={`font-[500] text-[16px] ${!toggleValue ? "text-gray-300" : ""}`}>Percentage (%)</p>
      </div>

      <div className="">
        {!toggleValue ? (
          <div className="space-x-4 flex items-center justify-between">
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={smsCircle} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.sent?.value}</p>
                    <p className="text-m">Sent</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={reportsent} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.delivered?.value}</p>
                    <p className="text-m">Delivered</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={reportClicked} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.clicked?.value}</p>
                    <p className="text-m">Clicked</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={unsubscribed} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.failed?.value}</p>
                    <p className="text-m">Failed</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={costIcon} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.clickRate?.value}</p>
                    <p className="text-m">Cost</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-x-4 flex items-center justify-between">
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={reportsent} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.delivered?.percentage}</p>
                    <p className="text-m">Delivered</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={reportClicked} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.clicked?.percentage}</p>
                    <p className="text-m">Click through rate</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
              <div className="flex justify-between">
                <div className="flex items-center justify-between space-x-5">
                  <img src={unsubscribed} alt="" className="w-[51px] h-[51px]" />
                  <div>
                    <p className="text-[28px] font-semibold">{data?.failed?.percentage}</p>
                    <p className="text-m">Failed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="w-full bg-white h-[400px] text-ss rounded-[12px] border-[1px] mt-4 p-4">
          <p className="text-lg font-semibold mb-[1em]">Campaign performance</p>
          <Graph data={graphData} />

          <div className="flex justify-center items-center space-x-4">
            <div className="flex justify-center items-center space-x-2">
              <div className="w-[10px] h-[10px] bg-[#FFD345]"></div>
              <p>Sent</p>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <div className="w-[10px] h-[10px] bg-[#00DA7A]"></div>
              <p>Delivered</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
