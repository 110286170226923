const baseAppURL =
  process.env.REACT_APP_NODE_ENV !== "production"
    ? `${process.env.REACT_APP_APP_STAGING_BASE_URL}/`
    : `${process.env.REACT_APP_APP_BASE_URL}/`;

const baseMooyiRewardsURL =
  process.env.REACT_APP_NODE_ENV !== "production"
    ? `${process.env.REACT_APP_MOOYI_REWARDS_STAGING_URL}/`
    : `${process.env.REACT_APP_MOOYI_REWARDS_PRODUCTION_URL}/`;

const baseApiURL =
  process.env.REACT_APP_NODE_ENV !== "production"
    ? `${process.env.REACT_APP_STAGING_URL}/v2/api`
    : `${process.env.REACT_APP_BACKEND_LIVE_URL}/v2/api`;

export { baseAppURL, baseMooyiRewardsURL, baseApiURL };
