export const getDateIndex = (monthName) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the index of the month name
  const index = months.indexOf(monthName);

  // Check if the month name is valid
  if (index !== -1) {
    return index;
  } else {
    return "Invalid month name";
  }
};
