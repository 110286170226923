import dayjs from "dayjs";
import moment from "moment";

export const formatDate = (x) => {
  return moment(x).format("Do MMM YYYY, h:mm:ss a");
  // return new Date(x).toLocaleDateString();
};

export const formatDateAlone = (x) => {
  return moment(x).format("Do MMM YYYY");
  // return new Date(x).toLocaleDateString();
};

export const formatDateWithDay = (x) => {
  return moment(x).format("ddd, Do MMM YYYY, h:mm:ss a");
  // return new Date(x).toLocaleDateString();
};

export const compareTime = (a, b) => {
  // Check if both objects have 'campaign_date' property
  if (a.hasOwnProperty("campaign_date") && b.hasOwnProperty("campaign_date")) {
    return new Date(b.campaign_date) - new Date(a.campaign_date); // Sort by 'campaign_date'
  }
  // Check if both objects have 'createdAt' property
  else if (a.hasOwnProperty("createdAt") && b.hasOwnProperty("createdAt")) {
    return new Date(b.createdAt) - new Date(a.createdAt); // Sort by 'createdAt'
  }
  // If neither 'campaign_date' nor 'createdAt' property exists, maintain the current order
  return 0;
};

export const getTimeDuration = (startTime, endTime) => {
  const start = dayjs(startTime);
  const end = dayjs(endTime);

  const durationMs = end.diff(start);
  // Calculate duration in hours, minutes, and seconds
  const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
  const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
  const durationSeconds = Math.floor((durationMs % (1000 * 60)) / 1000);

  // Format the duration
  const formattedDuration = `${Math.abs(durationHours).toString().padStart(2, "0")}:${Math.abs(durationMinutes)
    .toString()
    .padStart(2, "0")}:${Math.abs(durationSeconds).toString().padStart(2, "0")}`;
  return formattedDuration;
};

export const timeFormat = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const getDaysLeft = (endDate) => {
  const now = new Date();
  const end = new Date(endDate);
  const timeDifference = end - now;

  // Convert time difference from milliseconds to days
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft;
};
