import { useQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import { handleApiError } from "utils/handleApiError";
import Instance from "utils/instance";
import SURVEY_BACKEND_URLS from "utils/urls";

export const getAllSurvey = async ({ page, filter, search }) => {
  const urlPath = `?page=${page}&limit=10`;
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.survey.all}${urlPath}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const marginError = async (size) => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.survey.margin_error}size=${size}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const recentActivities = async () => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.surveyRes.recentSurveys}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const closeSurvey = async (payload) => {
  try {
    const { data } = await Instance.patch(`${SURVEY_BACKEND_URLS.survey.close}`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteSurvey = async (payload) => {
  try {
    const { data } = await Instance.delete(`${SURVEY_BACKEND_URLS.survey.delete}`, { data: payload });
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const closeMultipleSurvey = async (surveyIds) => {
  try {
    const { data } = await Instance.patch(`${SURVEY_BACKEND_URLS.survey.closeMultiple}`, {
      surveyIds,
    });
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createSurvey = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.survey.create}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const duplicateSurvey = async (id) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.survey.duplicate}?surveyId=${id}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const toggleSurveyLink = async ({ id, value }) => {
  try {
    const { data } = await Instance.patch(`${SURVEY_BACKEND_URLS.survey.toggleShare}?surveyId=${id}&share=${value}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const saveSurvey = async (payload) => {
  // console.log(payload, "fromSaveSurvey");
  try {
    const { data } = await Instance.patch(
      `${SURVEY_BACKEND_URLS.survey.save}?surveyId=${payload.id}&flag=save`,
      payload.surveyData,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const publishSurvey = async (payload, surveyId) => {
  try {
    const { data } = await Instance.patch(
      `${SURVEY_BACKEND_URLS.survey.save}?surveyId=${payload.id}&flag=dispatch`,
      payload.surveyData,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const costSurvey = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.survey.coster}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const useUploadImage = async (payload) => {
  try {
    const { data } = await Instance.post(
      `${SURVEY_BACKEND_URLS.survey.uploads}${payload?.flags ? `?flags=${payload?.flags}` : ""} `,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const useGetAllSurveyStats = (from) => {
  return useQuery(["stats"], () =>
    Instance.get(`${SURVEY_BACKEND_URLS.survey.statistic}`)
      .then((res) => res.data?.data)
      .catch((err) => {
        throw err.response.data;
      }),
  );
};

export const useGetAllLocation = () => {
  return useQuery(["location"], () =>
    Instance.get(`${SURVEY_BACKEND_URLS.survey.locations}`)
      .then((res) => res?.data?.data)
      .catch((err) => {
        throw err.response.data;
      }),
  );
};
export const useGetAllInterest = () => {
  return useQuery(["interest"], () =>
    Instance.get(`${SURVEY_BACKEND_URLS.survey.interests}`)
      .then((res) => res?.data?.data)
      .catch((err) => {
        throw err.response.data;
      }),
  );
};

export const useGetAllSurveyReport = ({ id, flag }) => {
  return useQuery(["questionSummary", id, flag], () =>
    Instance.get(`${SURVEY_BACKEND_URLS.surveyRes.reports}?surveyId=${id}&flag=${flag}`)
      .then((res) => res.data)
      .catch((err) => {
        throw err.response.data;
      }),
  );
};

export const useGetAllSurveyResponses = ({ id, flag, page }) => {
  return useQuery(["questionResponses", id, flag, page], () =>
    Instance.get(`${SURVEY_BACKEND_URLS.surveyRes.reports}?surveyId=${id}&flag=${flag}&page=${page}&limit=1`)
      .then((res) => res.data)
      .catch((err) => {
        throw err.response.data;
      }),
  );
};

// ------------- RESPONDENT SERVICES -----------------
export const useGetAllSurveyQuestion = (id) => {
  return useQuery(
    ["question", id],
    () =>
      Instance.get(`${SURVEY_BACKEND_URLS.surveyRes.question}${id}`)
        .then((res) => res.data)
        .catch((err) => {
          throw handleApiError(err);
        }),
    {
      onError: (error) => {
        Toast.error(error);
      },
    },
  );
};

export const trackResponse = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.surveyRes.tracker}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const verifyScreeningQuestion = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.surveyRes.screening}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const respondToSurvey = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.surveyRes.respond}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const confirmContact = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.surveyRes.verifyContact}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const preClaimReward = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.surveyRes.preClaim}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const collectReward = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.surveyRes.claimReward}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const addAudience = async (payload) => {
  try {
    const { data } = await Instance.post("survey-microservice/new-leads", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCalenderSurvey = async (year, month) => {
  try {
    const { data } = await Instance.get(`/survey-microservice/survey/calendar?month=${month}&year=${year}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getSingleSurvey = async (surveyId) => {
  try {
    const { data } = await Instance.get(`/survey-microservice/survey/single/${surveyId}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getPublicSurvey = async (id) => {
  try {
    const { data } = await Instance.get(`/survey-microservice/analytics/share-report?surveyId=${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getDraftedSurvey = async (id) => {
  try {
    const { data } = await Instance.get(`/survey-microservice/retrieve-drafted-survey?surveyId=${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAnalyzedFilterSurvey = async ({ id, flag }) => {
  try {
    const { data } = await Instance.get(`/survey-microservice/analytics/filter?surveyId=${id}&flag=${flag}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const Survey = async () => {};
