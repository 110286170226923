import { byteConverter } from "lib/byteConverter";
import React, { useState } from "react";
import { AiOutlineFile } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { TiInfo } from "react-icons/ti";
import { Line } from "recharts";
import * as XLSX from "xlsx";
import Mock from "assets/mock.csv";
// import useOutsideClick from "hooks/useOutsideClick";
import Toast from "config/toast";
import Upload from "assets/images/upload.svg";
// import { useQuery } from "@tanstack/react-query";
// import { getAllTags } from "services/contactService";
import UploadingScreen from "./UploadingScreen";
import MatchColumns from "./MatchColumns";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const UploadList = () => {
  // const { data: tagData } = useQuery(["allTags"], getAllTags);
  const [step, setStep] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  // const [openDrop, setOpenDrop] = useState(false);
  const [tagArr, setTagArr] = useState([]);
  const [fileName, setFileName] = useState("");
  const [rawFile, setRawFile] = useState({});
  const [uploadText, setUploadText] = useState(false);
  const [fileSize, setFileSize] = useState("");
  const [fileHeaders, setFileHeaders] = useState([]);
  const [topThreeRows, setTopThreeRows] = useState([]);
  // const refer = useOutsideClick(() => setOpenDrop(false));
  // const [open, setOpen] = useState(null);
  // const [searchedArray, setSearchedArray] = useState(tagData);
  const uploadRef = React.useRef();
  // const [query, setQuery] = useState("");
  const [number, setNumber] = useState(0);
  const navigate = useNavigate();

  // const onSelectFile = async (e) => {
  //   if (!e.target.files || e.target.files.length === 0) {
  //   } else {
  //     try {
  //       setUploadText(true);
  //       let file = e.target.files[0];
  //       setRawFile(file);
  //       setFileName(file.name);
  //       setFileSize(file.size);

  //       const data = await file.arrayBuffer();
  //       const wb = XLSX.read(data);
  //       const workbookToJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
  //       let headers = Object.keys(workbookToJson[0]);
  //       if (workbookToJson && headers) {
  //         setDisableButton(false);
  //         setUploadText(false);
  //         setStep(false);
  //       }

  //       setNumber(workbookToJson.length);

  //       setFileHeaders(headers);
  //       setTopThreeRows(workbookToJson.slice(0, 6));
  //     } catch (error) {
  //       Toast.error(error);
  //     }
  //   }
  // };

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      // Handle no file selected
    } else {
      try {
        setUploadText(true);
        let file = e.target.files[0];
        setRawFile(file);
        setFileName(file.name);
        setFileSize(file.size);

        const data = await file.arrayBuffer();
        const wb = XLSX.read(data, { type: "array" });

        // Parsing all rows and columns including those with empty values
        const options = { defval: "" }; // Set default value for empty cells to an empty string
        const workbookToJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], options);

        let headers = Object.keys(workbookToJson[0]);

        if (workbookToJson && headers) {
          setDisableButton(false);
          setUploadText(false);
          setStep(false);
        }

        setNumber(workbookToJson.length);
        setFileHeaders(headers);
        setTopThreeRows(workbookToJson.slice(0, 6));
      } catch (error) {
        Toast.error(error.message || "An error occurred while processing the file.");
      }
    }
  };

  const clearEntry = () => {
    setRawFile({});
    setFileName("");
    setFileSize("");
    setFileHeaders([]);
    setDisableButton(true);
  };

  return (
    <div className="bg-white rounded-md p-5">
      <div
        className="w-full flex justify-start items-center text-[14px] text-primary-800 space-x-2 cursor-pointer pb-5"
        onClick={() => navigate(-1)}
      >
        <IoChevronBackOutline />
        <p>Back to contacts</p>
      </div>
      {uploadText && <UploadingScreen />}
      {step ? (
        <>
          <div className="w-full">
            <p className="text-xl font-semibold">Upload a CSV or XLSX to quickly import user data.</p>

            <p className="text-sm text-neutral-600">
              Choose a CSV or XLSX document using column headers to identify which fields you’d like your contacts to
              have. Your list must should your contact’s full name, email address, gender, date of birth and a verified
              phone number. Click{" "}
              <a
                href="https://www.youtube.com/channel/UC-KbRjjhExwbHkOurEtdNFQ"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-800 underline underline-offset-1"
              >
                here
              </a>{" "}
              to learn more about making the most of your CSV data.{" "}
            </p>

            <div className="w-full py-2">
              <div>
                {!fileName ? (
                  <div className="h-[200px] border-[1px] mt-2 bg-[#FAFAFA] border-[#D0D5DD] rounded-[8px] flex items-center flex-col justify-center space-y-1 ">
                    <img src={Upload} alt="" className="" />
                    <p className="text-[#262626] text-sm">Drag and drop CSV or XLSX file </p>
                    <p className="text-[#737373] text-ss">or select it from your computer</p>

                    <div className="flex space-x-4 pt-3">
                      <div className="px-4 rounded border-neutral-400 text-neutral-700 font-semibold py-2 border-[1px] text-sm relative">
                        <input
                          type="file"
                          className="w-[100%] h-[100%] absolute cursor-pointer top-0 left-0 opacity-0"
                          accept=".csv, .xlsx"
                          onChange={onSelectFile}
                          ref={uploadRef}
                        />

                        <p className="font-semibold">Browse files</p>
                      </div>

                      <a href={Mock} download="Mooyi Template">
                        <div className="px-4 rounded border-neutral-400 text-neutral-700 font-semibold py-2 border-[1px] text-sm ">
                          Download template
                        </div>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="border-[1px] rounded-[6px]">
                    <div className="flex  p-2 space-x-2">
                      <div className="w-[30px] h-[30px] bg-[#29085C1A] text-primary-800 text-[18px] flex items-center justify-center rounded-full">
                        <AiOutlineFile />
                      </div>

                      <div className="flex justify-between w-[90%]">
                        <div>
                          <p className="text-s">{fileName}</p>
                          <p className="text-ss text-[#A3A3A3]">{byteConverter(fileSize)}</p>
                        </div>

                        <MdOutlineDelete onClick={clearEntry} className="cursor-pointer" />
                      </div>
                    </div>

                    <div className="flex space-x-2 px-4 items-center mb-2 justify-between">
                      <div className="min-w-[75%] rounded-[8px]">
                        <Line percent={100} strokeWidth={1} strokeColor="#29085C" trailWidth={1} />
                      </div>

                      {disableButton ? (
                        <p className="text-[#253241] text-ss">Uploading</p>
                      ) : (
                        <p className="text-[#253241] text-ss">Uploaded</p>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="mt-3">
                <div className="flex justify-between">
                  <div className="flex space-x-2 items-center">
                    <p className="text-sm">Tags</p>
                  
                  </div>

                  <p className="text-s text-primary-800 font-[500] cursor-pointer" onClick={() => toggleModal(2)}>
                    Create Tag
                  </p>
                </div>
                <div
                  className="rounded-[6px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%] flex items-center relative"
                  onClick={handleDropdown}
                >
                  <p className="text-[#A3A3A3] text-xs">{tagArr.length} tags selected</p>

                  <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[18px]">
                    <IoIosArrowDown />
                  </div>
                </div>

                <div className=" flex-wrap gap-[1em]  py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
                  {tagArr?.map((el, i) => {
                    return (
                      <div
                        key={i}
                        className="bg-white border-[1px] border-[#BFCCDA] flex items-center px-4 rounded-[6px] h-[30px]  text-ss font-semibold cursor-pointer"
                      >
                        {el}
                        <FaTimes className="ml-2" onClick={() => removedFromArray(el)} />
                      </div>
                    );
                  })}
                </div>

                {openDrop && (
                  <div className="rounded-[6px] shadow-secondary pb-4 h-[250px] overflow-y-scroll bg-white" ref={refer}>
                    <div
                      className="bg-[#F5F5F5]  flex min-h-[45px] items-center flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%]"
                      // onClick={handleDropdown}
                    >
                      <span className="text-[#737373]">
                        <AiOutlineSearch size={20} />
                      </span>

                      <input
                        type="text"
                        className="w-auto bg-black pl-2 h-[80%] bg-transparent outline-none text-s"
                        placeholder="Search for tags"
                        value={query}
                        name="query"
                        onChange={handleQueryChange}
                      />
                    </div>

                    {searchedArray?.map((el) => {
                      return (
                        <div
                          className={`px-4 text-ss font-extrabold text-[#404040] border-b-[1px] border-[#D0D5DD] py-[8px] flex items-center justify-between hover:text-white hover:bg-primary-800 group ${
                            checkInArray(el?.name)
                              ? "bg-[#E5E5E5] hover:bg-[#E5E5E5] hover:text-primary-800"
                              : "bg-white"
                          }`}
                          key={el._id}
                          onClick={handleDropdown}
                        >
                          <div className="flex items-center space-x-4 relative w-[100%]">
                            <div>
                              <input
                                type="checkbox"
                                name="recipient"
                                id="recipient"
                                onChange={(e) => handleCheck(e, el?.name)}
                                checked={checkInArray(el?.name)}
                                className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                              />

                              <IoMdPie
                                className={` text-[24px] ${
                                  checkInArray(el?.name) ? "block group-hover:text-black" : "group-hover:text-white"
                                }`}
                              />
                            </div>
                            <div>
                              <p className="text-m font-[500]">{el?.name}</p>
                              <p className="text-ss font-[400]">{el?.contactCount} contacts</p>
                            </div>
                          </div>

                          <IoMdCheckmark
                            className={`group-hover:block text-[24px] ${
                              checkInArray(el?.name) ? "block group-hover:text-black" : "hidden group-hover:text-white"
                            }`}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div> */}
              {/* {open ? <CreateTag toggleModal={toggleModal} /> : null} */}
            </div>

            {/* <div className="mb-4">
              <div className="flex space-x-4 items-start w-full">
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

                <div className="-mt-1">
                  <p className="text-ss">
                    I confirm that I have obtained permission from my customers (or they have signed up to receive
                    messages) to share their data with third-party platforms for marketing purposes.
                  </p>
                  <p className="text-ss mt-2">By proceeding, you agree to our terms and conditions.</p>
                </div>
              </div>
            </div> */}

            {/* <BlueButton
              text={uploadText ? "Uploading" : "Import"}
              disabled={disableButton || !isChecked}
              onClick={() => setStep(!step)}
              type="button"
            /> */}

            <div className="border-[1px] p-4 rounded-[8px] bg-primary-50 flex space-x-2 my-4 mt-2">
              <TiInfo className="text-primary-800 text-[24px]" />
              <div>
                <p className="text-[14px font-[600] text-primary-800">Important</p>

                <p className="text-s font-[400]">
                  We do not send confirmation emails to uploaded contacts. Ensure you have obtained permission before
                  adding contacts, and follow guidelines to prevent misuse.
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <MatchColumns
          rawFile={rawFile}
          fileName={fileName}
          fileHeaders={fileHeaders}
          topThreeRows={topThreeRows}
          tagArr={tagArr}
          fileSize={fileSize}
          number={number}
        />
      )}
    </div>
  );
};

export default UploadList;
