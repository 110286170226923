const ScreeningQuesIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      fill={fill1}
    />
    <path
      d="M9 14.25C9.41421 14.25 9.75 13.9142 9.75 13.5C9.75 13.0858 9.41421 12.75 9 12.75C8.58579 12.75 8.25 13.0858 8.25 13.5C8.25 13.9142 8.58579 14.25 9 14.25Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.0011 6C8.34912 6 7.79255 6.41632 7.58622 6.99994C7.44821 7.39047 7.01971 7.59517 6.62918 7.45713C6.23864 7.31909 6.03395 6.8906 6.17198 6.50006C6.58346 5.33588 7.6937 4.5 9.0011 4.5C10.6579 4.5 12.0011 5.84314 12.0011 7.5C12.0011 8.89785 11.045 10.0724 9.7511 10.4055V11.25C9.7511 11.6642 9.41532 12 9.0011 12C8.58687 12 8.2511 11.6642 8.2511 11.25V9.75C8.2511 9.33577 8.58687 9 9.0011 9C9.82947 9 10.5011 8.32845 10.5011 7.5C10.5011 6.67157 9.82947 6 9.0011 6Z"
      fill={fill2}
    />
  </svg>
);

export default ScreeningQuesIcon;
