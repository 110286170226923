import React from "react";
import Typography from "utils/typography";

const EmptyState = ({ icon, text, content }) => {
  return (
    <div className="flex items-center flex-col justify-center h-[40vh] w-[100%] space-y-1">
      <img src={icon} alt={text} className="w-[70px] mx-auto" />
      <Typography.Text className="text-center">{text}</Typography.Text>
      <Typography.SubText className="text-center text-gray-500">{content}</Typography.SubText>
    </div>
  );
};

export default EmptyState;
