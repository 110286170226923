export const CustomButton = ({ onClick, text, icon, css }) => {
  return (
    <div
      onClick={onClick}
      className={`bg-[#EFF2F6] text-primary-800 w-[148px] h-[40px] rounded-[4px] flex items-center justify-center space-x-2 cursor-pointer ${css}`}
    >
      {icon}
      <p className="text-s font-semibold">{text}</p>
    </div>
  );
};
