import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import Dropdown from "components/DropDown";
import PrimaryInput from "components/Inputs/PrimaryInput";
import Tooltip from "components/Tooltip";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
import dayjs from "dayjs";
import useLoggedInUser from "hooks/useLoggedInUser";
import useOutsideClick from "hooks/useOutsideClick";
import { formatDate } from "lib/formatDate";
import { toTitleCase } from "lib/titleCase";
import React, { useMemo, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import { deleteEmailId, getAllEmailId } from "services/senderIdService";
import BlueButton from "components/Button/BlueButton";
import { campaignSettings, getCampaignSettings } from "services/campaignService";
import EmailIDModal from "../SenderID/EmailIDModal";
import PreLoader from "components/Loader/PreLoader";
import { formatTime } from "lib/checkTime";
import Pagination from "components/Pagination";

const cleanUpDate = [
  { value: "weeks", label: "weeks" },
  { value: "months", label: "months" },
];

const creditData = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

const emailColumns = [
  { field: "email", header: "Email Address" },
  { field: "name", header: "Name" },
  { field: "createdAt", header: "Date Added" },
  { field: "verified", header: "Status" },
  { field: "viewProps", header: "" },
];

const Email = () => {
  const { data: email } = useQuery(["emailSenderIDs"], getAllEmailId);
  const { user } = useLoggedInUser();
  // const [setAmount] = useState("");
  const [open, setOpen] = useState();
  const [sendIDModal, setSendIDModal] = useState(false);
  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  console.log(open);
  const refer = useOutsideClick(() => setOpen(null));

  // const handleSelect = (x) => {
  //   setAmount(x);
  // };

  const { data, isLoading: loadSettings } = useQuery(["emailSettings"], () => getCampaignSettings("email"));

  const settings = data?.data;

  const initialValues = {
    amount: settings?.sendingLimit?.amount,
    timeframe: settings?.sendingLimit?.timeframe,
    cleanUp: settings?.cleanupList?.unit,
    interval: settings?.cleanupList?.interval,
    from: loadSettings ? "" : formatTime(settings?.deliveryWindow?.from),
    to: loadSettings ? "" : formatTime(settings?.deliveryWindow?.to),
    automaticRetry: settings?.automaticRetry?.retry,
  };

  const { mutate: mutateEmail } = useMutation(deleteEmailId, {
    onSuccess: () => {
      Toast.success("SenderId Deleted");
      queryClient.invalidateQueries(["emailSenderIDs"]);
      toggleModal();
      trackEvent("Deleted emailIDs", {
        email: user?.data?.email,
        settingSection: "sender ID",
        attributeUpdated: "Delete email id",
        date: dayjs().format(),
      });
    },
    onError: () => {},
  });

  const deleteEmail = (mail, name) => {
    mutateEmail({
      email: mail,
      name: name,
    });
    trackEvent("Delete emailIDs", {
      email: user?.data?.email,
      settingSection: "sender ID",
      attributeUpdated: "Delete email id",
      date: dayjs().format(),
    });
  };

  const { mutate: updateEmail, isLoading } = useMutation(campaignSettings, {
    onSuccess: () => {
      Toast.success("Email setting updated successfully");
      queryClient.invalidateQueries(["emailSettings"]);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    const channel = "email";
    const payload = {
      sendingLimit: {
        amount: data?.amount,
        timeframe: data?.timeframe,
      },
      deliveryWindow: {
        from: data?.from,
        to: data?.to,
      },
      cleanupList: {
        unit: data?.cleanUp,
        interval: data.interval,
      },
      automaticRetry: data.automaticRetry,
    };
    try {
      updateEmail({ channel, payload });
    } catch (err) {
      Toast.error(err);
    }

    trackEvent("update_settings", {
      email: user?.data?.email,
      date: dayjs().format(),
      channel: "email",
    });
  };

  const { handleChange, values, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const emailData = email?.map((el) => ({
    id: el?._id,
    name: el?.name,
    createdAt: formatDate(el?.dateAdded),
    email: el?.email,
    active: (
      <>{el?.isActive ? <p className="text-[#22C55E]">Verified</p> : <p className="text-[#737373]">Not Verified</p>}</>
    ),
    verified: <>{<p className="">{toTitleCase(el?.status)}</p>}</>,
    viewProps: (
      <div className="relative">
        {el?.isDefault ? null : (
          <div className="flex items-center space-x-2 text-black cursor-pointer" onClick={() => toggleModal(el?._id)}>
            <BsThreeDots />
          </div>
        )}

        {open === el?._id ? (
          <div
            className="shadow-lg rounded-[5px] bg-white absolute w-[150px] z-[10] right-[2em] cursor-pointer"
            ref={refer}
          >
            <div
              className="flex items-center space-x-[5px] text-s border-b-[1px] p-[9px] text-[#EF4444] hover:text-[#d31212]"
              onClick={() => deleteEmail(el?.email, el?.name)}
            >
              <MdOutlineDelete />
              <p>Delete</p>
            </div>
          </div>
        ) : null}
      </div>
    ),
  }));

  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * 5;
    const end = start + 5;
    return emailData.slice(start, end);
  }, [emailData, currentPage]);

  if (loadSettings) {
    return <PreLoader />;
  }

  return (
    <div className="bg-white p-5">
      <div>
        <div className="flex justify-between items-center">
          <p className="font-[500] text-[20px]">{`Sender ID (${email.length})`}</p>
          <div>
            <BlueButton type="button" text="New sender ID" onClick={() => setSendIDModal(true)} />
          </div>
        </div>
        <p className="font-semibold text-[18px] my-4">Registered Email ID</p>
        <table className="w-[100%]">
          <thead className=" text-s bg-[#F9FAFB]  border-b-[1px] ">
            <tr className=" h-[45px] px-7 text-left">
              {emailColumns &&
                emailColumns.map((head, i) => (
                  <th key={i} className="pl-4">
                    {head.header}{" "}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {paginatedData &&
              paginatedData.map((row, i) => (
                <tr key={i} className="h-[50px] border-b-[1px] text-s text-[#404040] font-[500]">
                  {emailColumns?.map((col, i) => (
                    <td key={i} className="pl-4">
                      {row[col.field]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination data={emailData} itemsPerPage={5} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>

      <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

      <form
        action="
      "
        onSubmit={handleSubmit}
      >
        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Set sending limit</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Limit the number of emails to be sent out from your account in a specified period.
          </p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <p className="text-sm font-semibold">Enter number of Emails</p>
              <div className="my-1 ">
                <PrimaryInput
                  name="amount"
                  text=""
                  type="number"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="0"
                />
              </div>
            </div>

            <div>
              <p className="text-sm font-semibold">Time frame</p>
              <div className="my-1">
                <Dropdown
                  tag={values.timeframe || "Choose an option"}
                  options={creditData}
                  onSelect={(select) => setFieldValue("timeframe", select.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Auto retry</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Automatically retries unsent messages after 48 hours of hitting the sending limit
          </p>

          <p className="text-sm font-semibold">Retry messages after:</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="automaticRetry"
                  text=""
                  value={values.automaticRetry}
                  type="number"
                  onChange={handleChange}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="my-1 w-[150px]">
              <div className="border p-2 w-[100px] rounded-md flex items-center justify-center text-[12px]">
                <p>Hours</p>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Delivery window</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">Set a time to send out your email.</p>

          <p className="text-sm font-semibold">Set a timeframe</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="from"
                  value={values.from}
                  text=""
                  onBlur={handleBlur}
                  type="time"
                  onChange={handleChange}
                />
              </div>
            </div>
            <p>to</p>
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="to"
                  value={values.to}
                  text=""
                  onBlur={handleBlur}
                  type="time"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Clean up list</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Remove contacts who have not interacted with your email over a period of time.
          </p>

          <p className="text-sm font-semibold">Set a timeframe</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="cleanUp"
                  text=""
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cleanUp}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="my-1 w-[150px]">
              <Dropdown
                tag={values.interval || "Choose an option"}
                options={cleanUpDate}
                value={values.interval}
                onSelect={(select) => setFieldValue("interval", select.value)}
              />
            </div>
          </div>
          <div>
            <BlueButton type="submit" text="Save" loading={isLoading} />
          </div>
        </div>
      </form>
      {sendIDModal ? <EmailIDModal toggleModal={() => setSendIDModal(false)} /> : null}
    </div>
  );
};

export default Email;
