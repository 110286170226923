import React from "react";

const ModalWithoutClose = ({ children }) => {
  return (
    <div className="bg-black bg-opacity-50 backdrop-blur-[2px]  fixed top-0 bottom-0 left-0 h-[100vh] w-full flex justify-center items-center z-30">
      <div className="rounded-[8px] md:w-auto w-[90%] overflow-hidden">{children}</div>
    </div>
  );
};

export default ModalWithoutClose;
