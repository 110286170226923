import axios from "axios";
import { getToken, getWorkspaceToken, removeToken } from "helpers/authHelper";

const Api = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV !== "production"
      ? `${process.env.REACT_APP_STAGING_URL}/v2/api`
      : `${process.env.REACT_APP_BACKEND_LIVE_URL}/v2/api`,
  headers: {
    Accept: "application/json",
    "i-am-mooyi-dev": "c27eb735-6a16-423e-8ea6-af1c4e6ac1a5",
  },
});

Api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const wpToken = getWorkspaceToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (wpToken) {
      config.headers["workspace"] = `${wpToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error.response) {
      if ([401].includes(error.response.status)) {
        window.location.replace("/auth/login");
        removeToken();
      }
    }
    return Promise.reject(error);
  },
);

export default Api;
