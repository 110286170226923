import React from "react";
import subMascot from "assets/images/subMascot.png";
import Typography from "utils/typography";
import BlueButton from "components/Button/BlueButton";
import { useNavigate } from "react-router-dom";

const ReSubscribe = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/unsubscribe/success");
  };

  return (
    <div>
      <div className=" pb-4">
        <img src={subMascot || ""} alt="subMascot" />
      </div>

      <Typography.Headers>We hope to see you again.</Typography.Headers>

      <Typography.SubText className=" text-gray-600 w-[80%] mx-auto text-center my-3">
        This doesn't have be goodbye. If you want to continue getting our emails, newsletters and updates, you can
        resubscribe here.{" "}
      </Typography.SubText>

      <div className="flex items-center justify-center">
        <BlueButton text="Re-subscribe" className="bg-primary-800 text-white" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default ReSubscribe;
