import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import Mascot from "assets/images/saveProgressMascot.png";
import React from "react";

const SaveAndExitModal = ({ handleModal, handleSave, loading }) => {
  return (
    <Modal title="Save your progress" onClose={handleModal}>
      <div className="w-[400px]">
        <div className="mt-5">
          <hr />
        </div>
        <div className="bg-primary-50 py-5">
          <img src={Mascot} alt="Mascot" className="w-[200px] mx-auto my-6" />
        </div>

        <p className="text-sm text-center py-3 px-5">
          If you leave without saving, your current progress would be lost.{" "}
        </p>
        <div className="mb-5">
          <hr />
        </div>
        <div className="flex items-center justify-end space-x-4 px-5">
          <GreyButton text="Don't save" onClick={handleModal} />

          <BlueButton text="Yes, save" type="button" onClick={handleSave} loading={loading} />
        </div>
      </div>
    </Modal>
  );
};

export default SaveAndExitModal;
