import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import React from "react";
import { TiInfo } from "react-icons/ti";
import { createSearchParams, useNavigate } from "react-router-dom";

const Compliance = ({ toggleModal }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate({
      pathname: "/account",
      search: `?${createSearchParams({
        g: "compliance",
      })}`,
    });
  };

  return (
    <Modal title={<TiInfo className="text-[#FBBF24] font-semibold text-[28px] min-w-[40px]" />} onClose={toggleModal}>
      <div className="w-[420px] h-[auto] px-5">
        <div className="flex flex-col mt-2">
          <p className="text-[24px] font-medium">Almost There! Verify Your Business for Full Access.</p>
          <p className="text-s font-[400] mt-2">
            Before you can proceed, we need to verify your business. By completing the business verification process,
            you ensure a higher level of security and compliance with regulatory requirements.
          </p>
        </div>

        <div className="flex justify-between items-center space-x-4  mt-[2em]">
          <GreyButton text="Cancel" onClick={toggleModal} className="w-[100%]" />

          <BlueButton text="Verify Business" onClick={handleBack} className="w-[100%]" />
        </div>
      </div>
    </Modal>
  );
};

export default Compliance;
