import React from "react";
import surveyCardImage from "assets/images/surveyCardImage.png";
import Typography from "utils/typography";
import { HiDotsVertical } from "react-icons/hi";
import { getAudience } from "data/constants/surveyConstants";
import dayjs from "dayjs";
import Checkbox from "components/Inputs/CheckBox";
import { checkInArray } from "lib/checkInArray";
import { useLocation, useNavigate } from "react-router-dom";

const HomeCards = ({
  surveyName,
  surveyDate,
  surveyStatus,
  publishId,
  expectedResponse,
  responses,
  audienceType,
  surveyImage,
  clickFn,
  surveyId,
  handleCheck,
  selectArray,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationArray = location.pathname.split("/");

  const checkEditRoute = () => {
    switch (surveyStatus) {
      case "draft":
      case "scheduled":
        if (locationArray.includes("brand-tracking")) {
          navigate(`/research/brand-tracking/${surveyId}`);
        } else if (locationArray.includes("online-survey")) {
          navigate(`/research/online-survey/${surveyId}`);
        } else {
          navigate(`/research/field-interview/${surveyId}`);
        }
        break;
      case "active":
      case "closed":
        if (locationArray.includes("brand-tracking")) {
          navigate(`/research/brand-tracking/analytics/${publishId}/${surveyId}`);
        } else if (locationArray.includes("online-survey")) {
          navigate(`/research/online-survey/analytics/${publishId}/${surveyId}`);
        } else {
          navigate(`/research/field-interview/analytics/${publishId}/${surveyId}`);
        }
        break;
      default:
    }
  };

  return (
    <div className="border-[1px] h-[250px] w-[100%] border-gray-300 hover:border-primary-800 hover:shadow-md transition ease-in-out duration-500 cursor-pointer rounded-lg overflow-hidden flex flex-col justify-between">
      <div className="h-[40%] ">
        <img src={surveyImage || surveyCardImage} alt="surveyCardImage" className="w-[100%] h-[100%] object-cover" />

        <div className="absolute top-3 left-4">
          <Checkbox checked={checkInArray(surveyId, selectArray)} onChange={(e) => handleCheck(e, surveyId)} />
        </div>
      </div>

      <div className="p-4 space-y-4 bg-white h-[45%]">
        <div className="flex items-center justify-between ">
          <div>
            <Typography.SubText className="font-semibold hover:underline underline-offset-2" onClick={checkEditRoute}>
              {surveyName}
            </Typography.SubText>

            <Typography.MicroText>{getAudience(audienceType)}</Typography.MicroText>
          </div>

          <div className="relative">
            <div className="flex items-center justify-center -pl-2 cursor-pointer" onClick={clickFn}>
              <HiDotsVertical />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="bg-neutral-200 rounded-lg h-[7px] overflow-hidden w-[65%]">
            <div
              className="bg-success-600 h-[100%]"
              style={{
                width: responses !== 0 ? `${(responses / expectedResponse) * 100}%` : "0%",
              }}
            ></div>
          </div>

          <Typography.MicroText> {responses} Responses</Typography.MicroText>
        </div>
      </div>

      <div className="h-[15%] border-t-[1px] border-gray-300 flex items-center px-4 bg-white">
        <Typography.MicroText>
          {dayjs(surveyDate).format("dddd DD MMM YYYY")} | {dayjs(surveyDate).format("hh:mm a")} GMT+1
        </Typography.MicroText>
      </div>
    </div>
  );
};

export default HomeCards;
