import React from "react";

import uploadMascot from "assets/images/uploadMascot.png";

const UploadingScreen = () => {
  return (
    <div className="absolute bg-white w-[100vw]  h-[100vh] top-0 left-0 z-[999] text-center flex items-center flex-col justify-center space-y-2">
      <img src={uploadMascot || ""} alt="uploadMascot" className="w-[300px] mx-auto mb-[2em]" />

      <p className="text-xl font-semibold">Uploading your CSV...</p>

      <p className="text-sm text-neutral-700">We are processing your file, it shouldn't take too long</p>
    </div>
  );
};

export default UploadingScreen;
