import cancel from "assets/images/pricing/cancel.png";
import fullCheck from "assets/images/pricing/fullCheck.png";

const tableData1 = [
  {
    planDetails: "Contact Limit",
    starterPlan: "1,000 Contacts",
    businessPlan: "20,000 contacts",
    proPlan: "200,000 contacts",
  },
  {
    planDetails: "Tags",
    starterPlan: "same",
    businessPlan: "same",
    proPlan: "same",
  },
  {
    planDetails: "Segments & Custom fields",
    starterPlan: "",
    businessPlan: "same",
    proPlan: "same",
  },
];

const tableData2 = [
  {
    planDetails: "SMS",
    starterPlan: "4,000",
    businessPlan: "40,000",
    proPlan: "400,000",
  },
  {
    planDetails: "Email",
    starterPlan: "8,000",
    businessPlan: "80,000",
    proPlan: "800,000",
  },
  {
    planDetails: "Voice (minutes)",
    starterPlan: "100",
    businessPlan: "1,000",
    proPlan: "10,000",
  },
  {
    planDetails: "Customer surveys",
    starterPlan: "1,000 invites",
    businessPlan: "10,000 invites",
    proPlan: "100,000 invites",
  },
];
const tableData4 = [
  {
    planDetails: "Team collaboration",
    starterPlan: "Single user access",
    businessPlan: "up to 3 workspaces and 5 members each",
    proPlan: "Unlimited workspaces and team members",
  },
];

const Tables = ({ toggleValue }) => {
  const columns = [
    { field: "planDetails", header: "Plan Details" },
    { field: "starterPlan", header: "Starter Plan" },
    { field: "businessPlan", header: "Business Plan" },
    { field: "proPlan", header: "Pro Plan" },
  ];

  return (
    <div className=" w-full md:px-0 mt-[3em]">
      <div className="md:flex items-center bg-neutral-100 p-2 ">
        {columns.map((el) => {
          return (
            <p
              className={
                el.field === "planDetails" ? "w-[30%] font-[600]" : "w-[22%] text-primary text-center font-[600]"
              }
            >
              {el.header}
            </p>
          );
        })}
      </div>

      <div>
        <p className="font-[600] text-center mt-6">Customer Management Hub</p>
      </div>
      <div className="text-sm">
        {tableData1.map((el, i) => {
          return (
            <div key={i} className={`flex items-center p-3 text-[14px] ${i % 2 === 0 ? "bg-neutral-100" : ""} my-2 `}>
              <p className={`w-[50%] md:w-[30%]`}>{el.planDetails}</p>
              <p className={`w-[50%] md:w-[22%] text-center flex justify-center `}>
                {el.starterPlan === "same" ? (
                  <div className="w-[24px] h-[24px] flex justify-center text-center">
                    <img src={fullCheck} alt="" />
                  </div>
                ) : el.starterPlan === "" ? (
                  <div className="w-[24px] h-[24px] flex justify-center text-center">
                    <img src={cancel} alt="" />
                  </div>
                ) : (
                  el.starterPlan
                )}
              </p>
              <p className={`w-[50%] md:w-[22%] flex text-center justify-center `}>
                {el.businessPlan === "same" ? (
                  <div className="w-[24px] h-[24px] text-center">
                    <img src={fullCheck} alt="" />
                  </div>
                ) : (
                  el.businessPlan
                )}
              </p>

              <p className={`w-[50%] md:w-[22%] flex text-center justify-center`}>
                {el.proPlan === "same" ? (
                  <div className="w-[24px] h-[24px] flex items-center justify-center">
                    <img src={fullCheck} alt="" />
                  </div>
                ) : (
                  el.proPlan
                )}
              </p>
            </div>
          );
        })}
      </div>

      <div>
        <p className="font-[600] text-center my-6">Customer engagement</p>
      </div>
      <div className="">
        {tableData2.map((el, i) => {
          return (
            <div key={i} className={`flex items-center p-3 ${i % 2 === 0 ? "bg-neutral-100" : ""} my-2 `}>
              <p className={`w-[50%] md:w-[30%]`}>{el.planDetails}</p>
              <p className={`w-[50%] md:w-[22%] md:block text-center flex justify-center `}>{el.starterPlan}</p>
              <p className={`w-[50%] md:w-[22%] md:block flex text-center  justify-cente`}>{el.businessPlan}</p>
              <p className={`w-[50%] md:w-[22%] md:block flex text-center  justify-center`}>{el.proPlan}</p>
            </div>
          );
        })}
      </div>

      <div>
        <p className="font-[600] text-center my-6">User access</p>
      </div>

      <div className="space-y-4 bg-neutral-100 p-3 text-sm ">
        {tableData4.map((el) => {
          return (
            <div className="flex items-center">
              <p className={`w-[50%] md:w-[30%]`}>{el.planDetails}</p>
              <p className={`w-[50%] md:w-[22%] md:block flex text-center justify-center `}>{el.starterPlan}</p>
              <p className={`w-[50%] md:w-[22%] md:block flex text-center justify-center `}>
                <p className="w-[80%]">{el.businessPlan}</p>
              </p>
              <p className={`w-[50%] md:w-[22%] md:block flex text-center justify-center `}>
                <p className="w-[80%]">{el.proPlan}</p>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tables;
