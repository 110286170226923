import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import React from "react";
import subMascot from "assets/images/subMascot.png";
import { Link, useSearchParams } from "react-router-dom";

const Home = () => {
  const [params] = useSearchParams();
  const email = params.get("email");

  return (
    <div className="text-center">
      <div className=" pb-4">
        <img src={subMascot || ""} alt="subMascot" />
      </div>
      <p className="text-2xl font-semibold">We are sorry to see you go!</p>
      <p className="my-4 text-gray-600">
        Do you really want to unsubscribe from our mailing list? If you unsubscribe, you will stop receiving our emails,
        newsletters and updates.{" "}
      </p>

      <div className="flex items-center justify-between gap-[2em] mt-6 w-[100%]">
        <Link to={`reason?email=${email}`} className="w-[50%]">
          <GreyButton text="Unsubscribe" className="w-[100%]" />
        </Link>

        <Link to="success" className="w-[50%]">
          <BlueButton text="Keep me subscribed" className="w-[100%]" />
        </Link>
      </div>
    </div>
  );
};

export default Home;
