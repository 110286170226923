import { useMutation, useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import SelectInput from "components/Inputs/SelectInput";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "utils/upload";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";
import { byteConverter } from "lib/byteConverter";
import { BsDot } from "react-icons/bs";
import { IoIosArrowDown, IoMdCheckmark, IoMdPie } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import useOutsideClick from "hooks/useOutsideClick";
import { createTag, getAllTags } from "services/contactService";
// import CreateTag from "../contactTabs/Tags/CreateTag";
import Checkbox from "components/Inputs/CheckBox";
import UploadModal from "./UploadModal";
import PreLoader from "components/Loader/PreLoader";
import CreateTagModal from "../CreateTagModal";
import { Link } from "react-router-dom";

const MatchColumns = ({ fileHeaders, topThreeRows, rawFile, fileName, fileSize, number }) => {
  const { data: tagData } = useQuery(["allTags"], getAllTags);
  const [open, setOpen] = useState(false);
  const [tagArr, setTagArr] = useState([]);
  const [openDrop, setOpenDrop] = useState(false);
  const refer = useOutsideClick(() => setOpenDrop(false));
  const [query, setQuery] = useState("");
  const [searchedArray, setSearchedArray] = useState(tagData);
  const [isChecked, setIsChecked] = useState(false);
  const [columnMatch, setColumMatch] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    email: "",
    phone: "",
    birthday: "",
    address: "",
  });
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const { user } = useLoggedInUser();

  const { mutate, isLoading } = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(["allContacts"], ["onBoarding"]);
      Toast.success("Contact added!");
      navigate("/contacts");
      // toggleModal(3)
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: create, isLoading: createLoading } = useMutation(createTag, {
    onSuccess: () => {
      Toast.success("Tag created");
      queryClient.invalidateQueries(["allTags"]);
      toggleModal();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDropdown = () => {
    setOpenDrop(!openDrop);
  };

  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in tagArr) {
      if (tagArr[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  // const { mutate1, isLoading1 } = useMutation(uploadFile, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(["allContacts"], ["onBoarding"]);
  //     Toast.success("Contact added!");

  //     // handleModal();
  //     trackEvent("Add contact successful", {
  //       email: user?.data?.email,
  //       addContactMethod: "CSV upload",
  //       date: dayjs().format(),
  //     });
  //   },
  //   onError: (error) => {
  //     Toast.error(error);
  //   },
  // });

  const [defaultFields] = useState([
    { value: "", label: "DO NOT IMPORT" },
    { value: "firstname", label: "FIRST NAME" },
    { value: "lastname", label: "LAST NAME" },
    { value: "gender", label: "GENDER" },
    { value: "email", label: "EMAIL" },
    { value: "phone", label: "PHONE" },
    { value: "address", label: "ADDRESS" },
    { value: "birthday", label: "DATE OF BIRTH" },
  ]);

  const handleColumnMatch = (e, headingName) => {
    setColumMatch((prevState) => {
      return {
        ...prevState,
        [e.target.value]: headingName,
      };
    });

    if (e.target.value === "email" || e.target.value === "phone") {
      setDisableButton(false);
    }
  };

  const topRowsFormatted = useMemo(() => {
    // compile the three top rows data into a
    // format that makes for easy display in column matching table

    let finalObj = {};

    for (const heading of fileHeaders) {
      for (const row of topThreeRows) {
        if (heading in finalObj) {
          finalObj[heading].push(row[heading]);
        } else {
          finalObj[heading] = [row[heading]];
        }
      }
    }

    return finalObj;
  }, [fileHeaders, topThreeRows]);

  const handleSubmit = async (e) => {
    const generateUploadObj = () => {
      let finalObj = {};

      for (const property in columnMatch) {
        if (columnMatch[property]) {
          finalObj[`fields[${property}]`] = columnMatch[property];
        }
      }

      return finalObj;
    };

    generateUploadObj();

    const parts = fileName.split(".");
    const lastString = parts[parts.length - 1];

    try {
      e.preventDefault();
      const userData = {
        ...generateUploadObj(),
        contact: rawFile,
        fileType: lastString,
        tag: tagArr,
      };

      mutate(userData);

      trackEvent("add_contact", {
        email: user?.data?.email,
        addContactMethod: "CSV upload",
        date: dayjs().format(),
      });
    } catch (error) {
      Toast.error(error);
    }
  };

  const handleCheck = (e, el) => {
    if (e.target.checked) {
      if (!tagArr.includes(el)) {
        setTagArr([...tagArr, el]);
      }
    } else {
      const filterArray = tagArr?.filter((elm) => elm !== el);
      setTagArr(filterArray);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    if (query !== "") {
      const filteredData = tagData?.filter(
        (filtered) => filtered?.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
      );
      if (filteredData.length !== 0) {
        setSearchedArray(filteredData);
      }
    } else {
      setSearchedArray(tagData);
    }
  };

  const handleTagInfo = (data) => {
    create(data);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const removedFromArray = (el) => {
    const filterArray = tagArr?.filter((elm) => elm !== el);
    setTagArr(filterArray);
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="pb-[3em]">
      {open === 3 ? (
        <UploadModal toggleModal={toggleModal} handleSubmit={handleSubmit} tagArr={tagArr} number={number} />
      ) : null}

      <p className="text-xl font-semibold">Here is a preview of your data.</p>

      <p className="text-sm text-neutral-600 w-[70%]">
        Properly match your columns to various fields. You can click to edit additional column headers on the table.{" "}
        <a href="*" className="text-primary-800 underline underline-offset-1">
          Click here
        </a>
          to learn more about making the most of your CSV data.
      </p>

      <div className="border-[1px] my-4 rounded-lg p-4">
        <div className="flex items-center">
          <p className="text-sm">{fileName}</p>
          <BsDot className="text-xl" />

          <p className="text-xs text-neutral-600">{byteConverter(fileSize)}</p>
        </div>

        <div className="flex w-[100%]   p-2 overflow-x-scroll">
          {fileHeaders.map((heading, i) => {
            return (
              <div key={i} className="rounded-[12px]  w-[200px]">
                <div className="w-[100%] bg-neutral-200">
                  <p className=" text-black border-b-[1px] border-neutral-300  py-2 pl-5 text-s font-[500]">
                    {heading}
                  </p>
                  {/* <p className="font-[600]">Match to</p> */}

                  <div className="w-[100%] pl-5">
                    <SelectInput
                      options={defaultFields}
                      name={heading}
                      onChange={(e) => handleColumnMatch(e, heading)}
                      css="h-[35px] text-sm border-none bg-white font-medium text-black "
                    />
                  </div>
                </div>

                <div className=" w-[100%] bg-white">
                  {/* <p className=" text-black bg-[#F5F5F5] px-4 py-2 text-s font-[500]">{heading}</p> */}

                  <div className="space-y-1 text-s font-[400]  border-t-[1px] ">
                    {topRowsFormatted[heading].map((ele, i) => {
                      return (
                        <p key={i} className=" border-b-[1px]  text-xs pl-8 w-[100%] py-2 truncate ...">
                          {ele}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="w-[40%]">
        <div className="mt-3">
          <div className="flex justify-between">
            <div className="flex space-x-2 items-center">
              <p className="text-sm">Tags</p>
              {/* <Tooltip text="Select the category of contacts you want to send your campaign to." /> */}
            </div>

            <p className="text-s text-primary-800 font-[500] cursor-pointer" onClick={() => toggleModal(2)}>
              Create Tag
            </p>
          </div>
          <div
            className="rounded-[6px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%] flex items-center relative"
            onClick={handleDropdown}
          >
            <p className="text-[#A3A3A3] text-xs">{tagArr.length} tags selected</p>

            <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[18px]">
              <IoIosArrowDown />
            </div>
          </div>

          <div className=" flex-wrap gap-[1em]  py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
            {tagArr?.map((el, i) => {
              return (
                <div
                  key={i}
                  className="bg-white border-[1px] border-[#BFCCDA] flex items-center px-4 rounded-[6px] h-[30px]  text-ss font-semibold cursor-pointer"
                >
                  {el}
                  <FaTimes className="ml-2" onClick={() => removedFromArray(el)} />
                </div>
              );
            })}
          </div>

          {openDrop && (
            <div className="rounded-[6px] shadow-secondary pb-4 h-[250px] overflow-y-scroll bg-white" ref={refer}>
              <div
                className="bg-[#F5F5F5]  flex min-h-[45px] items-center flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%]"
                // onClick={handleDropdown}
              >
                <span className="text-[#737373]">
                  <AiOutlineSearch size={20} />
                </span>

                <input
                  type="text"
                  className="w-auto bg-black pl-2 h-[80%] bg-transparent outline-none text-s"
                  placeholder="Search for tags"
                  value={query}
                  name="query"
                  onChange={handleQueryChange}
                />
              </div>

              {searchedArray?.map((el) => {
                return (
                  <div
                    className={`px-4 text-ss font-extrabold text-[#404040] border-b-[1px] border-[#D0D5DD] py-[8px] flex items-center justify-between hover:text-white hover:bg-primary-800 group ${
                      checkInArray(el?.name) ? "bg-[#E5E5E5] hover:bg-[#E5E5E5] hover:text-primary-800" : "bg-white"
                    }`}
                    key={el._id}
                    onClick={handleDropdown}
                  >
                    <div className="flex items-center space-x-4 relative w-[100%]">
                      <div>
                        <input
                          type="checkbox"
                          name="recipient"
                          id="recipient"
                          onChange={(e) => handleCheck(e, el?.name)}
                          checked={checkInArray(el?.name)}
                          className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                        />

                        <IoMdPie
                          className={` text-[24px] ${
                            checkInArray(el?.name) ? "block group-hover:text-black" : "group-hover:text-white"
                          }`}
                        />
                      </div>
                      <div>
                        <p className="text-m font-[500]">{el?.name}</p>
                        <p className="text-ss font-[400]">{el?.contactCount} contacts</p>
                      </div>
                    </div>

                    <IoMdCheckmark
                      className={`group-hover:block text-[24px] ${
                        checkInArray(el?.name) ? "block group-hover:text-black" : "hidden group-hover:text-white"
                      }`}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {open === 2 ? (
          <CreateTagModal closeModal={toggleModal} handleSubmitClick={handleTagInfo} isLoading={createLoading} />
        ) : null}
      </div>

      <div className="mb-4">
        <div className="flex items-start space-x-3 w-full">
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

          <div className="-mt-[3px]">
            <p className="text-ss mt-1">
              I confirm that I have permission from my customers (or they have signed up to receive messages) to share
              their data with third-party platforms for marketing purposes. By proceeding, you agree to our{" "}
              <Link to="https://usemooyi.com/terms-of-use" className="text-blue-800">
                {" "}
                Terms of use{" "}
              </Link>
            </p>
          </div>
        </div>
      </div>

      <BlueButton
        text="Continue"
        disabled={disableButton || isLoading || !isChecked}
        onClick={() => toggleModal(3)}
        type="button"
      />
    </div>
  );
};

export default MatchColumns;
