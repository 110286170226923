import React from "react";

export const FileAddin = ({ fill1, fill2 }) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.375 4.45801C2.375 3.14633 3.43833 2.08301 4.75 2.08301H12.4746C13.1045 2.08301 13.7086 2.33323 14.154 2.77863L15.9294 4.55405C16.3748 4.99945 16.625 5.60354 16.625 6.23343V15.5413C16.625 16.8531 15.5617 17.9163 14.25 17.9163H4.75C3.43833 17.9163 2.375 16.8531 2.375 15.5413V4.45801Z"
      fill={fill1}
    />
    <path
      d="M14.0155 2.6428L16.0626 4.68988C16.421 5.04831 16.6224 5.53444 16.6224 6.04134H14.2474C13.3729 6.04134 12.6641 5.33246 12.6641 4.45801V2.08301C13.171 2.08301 13.6571 2.28437 14.0155 2.6428Z"
      fill={fill2}
    />
    <path
      d="M10.2943 8.41667C10.2943 7.97945 9.93984 7.625 9.5026 7.625C9.06537 7.625 8.71094 7.97945 8.71094 8.41667V10H7.1276C6.69038 10 6.33594 10.3544 6.33594 10.7917C6.33594 11.2289 6.69038 11.5833 7.1276 11.5833H8.71094V13.1667C8.71094 13.6039 9.06537 13.9583 9.5026 13.9583C9.93984 13.9583 10.2943 13.6039 10.2943 13.1667V11.5833H11.8776C12.3148 11.5833 12.6693 11.2289 12.6693 10.7917C12.6693 10.3544 12.3148 10 11.8776 10H10.2943V8.41667Z"
      fill={fill2}
    />
  </svg>
);
