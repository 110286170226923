import React from "react";
import Skeleton from "react-loading-skeleton";

const RecentCardSkeleton = () => {
  return (
    <div
      className={`flex items-center space-x-2 bg-[#F8FAFFB2] border-[1px] p-3 rounded-lg cursor-pointer hover:border-primary-800 w-[100%]`}
    >
      <div className="w-[15%] border-[1px] h-[50px] rounded-md overflow-hidden"></div>

      <div className="w-[60%]">
        <Skeleton className="w-[70%]" />

        <Skeleton className="w-[90%]" />
      </div>

      <div className="border-l-[1px] w-[1px] pr-2 h-[60px] border-gray-300"></div>

      <div className="w-[20%] space-y-2">
        <Skeleton className="w-[100%]" />

        <Skeleton className="w-[100%]" />
      </div>
    </div>
  );
};

export default RecentCardSkeleton;
