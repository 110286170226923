import React, { useState } from "react";
import cP from "assets/images/cProfile.png";
import nps from "assets/images/nps.png";
import cSat from "assets/images/cSat.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typography from "utils/typography";
import GreyButton from "components/Button/GreyButton";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import usePageStore from "store/usePageStore";

const cardData = [
  {
    id: "researchCard1",
    name: "Customer Profiling",
    description: "Helps gather information about the preferences and behaviors of your customers.",
    image: cP,
    key: "cProfiling",
    path: "customer-profile",
  },
  {
    id: "researchCard2",
    name: "NPS Survey",
    description: "Measure your customer loyalty with a quick net promoter score survey.",
    image: nps,
    key: "nps",
    path: "nps",
  },
  {
    id: "researchCard3",
    name: "Customer Satisfaction Survey",
    description: "Measure your customer satisfaction with your product or service using this survey.",
    image: cSat,
    key: "cSat",
    path: "csat",
  },
];

const SetupInsight = () => {
  const [open, setOpen] = useState(null);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const { setPage } = usePageStore();

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleNext = () => {
    if (selected === "onlineSurvey") {
      handleOpen(2);
    } else {
      handleOpen(3);
    }
  };

  return (
    <div>
      <div className="bg-white h-[calc(100vh-100px)] rounded border-[1px] p-6 py-[3em]">
        <div className="flex items-center ">
          <Typography.SubText
            className="flex items-center text-primary-700 space-x-2 cursor-pointer "
            onClick={() => navigate("/research")}
          >
            <FaChevronLeft className="" />
            <span className="font-semibold"> Go back</span>
          </Typography.SubText>

          <Typography.H3 className="w-[85%] text-center">
            Gather insights from your target customers in multiple ways
          </Typography.H3>
        </div>

        <div className="flex items-center space-x-7 py-[3em] w-[85%] mx-auto">
          {cardData.map((el) => (
            <Link
              to={el.path}
              key={el.id}
              className={`border-[1px] rounded-md h-[280px] w-[33%] flex flex-col justify-between items-center overflow-hidden cursor-pointer ${
                el.key === selected ? "border-primary-800" : ""
              }`}
              onClick={() => setPage(-1)}
            >
              <div className="bg-gray-50 h-[40%] w-[100%] flex items-start justify-between  p-4">
                <div className="w-[100%] h-[100%] flex items-center justify-center">
                  <img src={el.image} alt={el.id} className="mx-auto" />
                </div>

                {/* <div
                  className={`border-[1px] rounded-full  h-[15px] w-[15px] flex items-center justify-center ${
                    el.key === selected ? "border-primary-800" : "border-gray-400"
                  }`}
                >
                  {el.key === selected ? <div className="h-[9px] w-[9px] rounded-full bg-primary-800"> </div> : null}
                </div> */}
              </div>

              <div className="p-4  space-y-2 h-[25%] flex flex-col w-[100%]">
                <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>

                <Typography.MicroText className="">{el.description}</Typography.MicroText>
              </div>

              <div className="h-[20%] flex items-center justify-center space-x-6 border-t-[1px] w-[100%] ">
                <Typography.MicroText className="text-primary-800 font-semibold">Preview</Typography.MicroText>

                <GreyButton
                  text={<Typography.MicroText className="text-primary-800">Use Templates</Typography.MicroText>}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* 
      <div
        className={`flex justify-between border-t-[1px] fixed bottom-0 right-0 px-4 h-[60px] items-center bg-white ${
          params.get("sidebar") === "true" ? "w-[calc(100vw-80px)] " : "w-[calc(100vw-250px)]"
        } transition ease-in`}
      >
        <div onClick={() => navigate("/insights")}>
          <GreyButton text="Cancel" className="w-[140px]" />
        </div>

        <div className="flex justify-between space-x-4">
          <BlueButton text={"Next"} className="h-[40px] text-s w-[140px]" onClick={handleNext} />
        </div>
      </div> */}

      {/* {open === 3 ? <SurveyDetailsModal from={selected} handleOpen={() => handleOpen(3)} /> : null} */}

      {/* {open === 2 ? <CreateSurveyModal handleModal={handleOpen} /> : null} */}
    </div>
  );
};

export default SetupInsight;
