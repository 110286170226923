import { useQuery } from "@tanstack/react-query";
import { fetchPlan } from "services/userService";

const usePlan = () => {
  const { data: plan, isFetching } = useQuery(["getPlan"], fetchPlan);

  return {
    plan,
    isFetching,
  };
};

export default usePlan;
