import { useMutation } from "@tanstack/react-query";
import AuthButton from "components/Button/AuthButton";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import React from "react";
import { updateInterest } from "services/userService";
import { useFormik } from "formik";
import * as Yup from "yup";
import PreLoader from "components/Loader/PreLoader";
import { useNavigate } from "react-router-dom";
import Checkbox from "components/Inputs/CheckBox";

const tabs = [
  { id: 1, name: "To drive customer action." },
  { id: 2, name: "To drive customer loyalty." },
  { id: 3, name: "To get customer feedback." },
  {
    id: 4,
    name: "To get insights and leads.",
  },
  {
    id: 5,
    name: "To harness testimonials.",
  },
  {
    id: 5,
    name: "To build brand awareness.",
  },
];

const Goals = ({ user, setStep }) => {
  const navigate = useNavigate();
  const { mutate, isLoading: isSubmitting } = useMutation(updateInterest, {
    onSuccess: () => {
      Toast.success("Updated successfully!!");
      queryClient.invalidateQueries(["currentUser"]);
      navigate("/dashboard");
      setStep(3);
    },
  });
  const initialValues = {
    interests: user?.data?.interests || [],
  };

  const validationSchema = Yup.object().shape({
    interests: Yup.array().min(1, "Please select at least one interest."),
  });
  const onSubmit = async (values) => {
    mutate({
      interests: values.interests,
    });
  };

  const { handleSubmit, handleChange, isValid, values, errors, touched } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  if (isSubmitting) {
    return <PreLoader text="Submitting goals..." />;
  }
  return (
    <div>
      <p className="text-[#292D32] font-semibold text-[24px] mb-1">What do you plan to use Mooyi for?</p>

      <p className="text-[#737373] text-s font-[450]">Please choose from the following options: </p>

      <div className="mt-2 space-y-2">
        {tabs.map((el, i) => {
          return (
            <div className="flex py-2 items-center" key={i}>
              <Checkbox
                checked={values.interests.includes(el.name)}
                onChange={handleChange}
                value={el.name}
                name="interests"
                id={el.id}
              />

              <label
                htmlFor={el.id}
                className={`pl-4 ${values.interests.includes(el.name) ? " text-black" : "text-[#5C6570]"} `}
              >
                {el.name}
              </label>
            </div>
          );
        })}
        {touched.interests && errors.interests && (
          <div className="text-red-500 -mt-2 mb-3 text-ss italic">{errors.interests}</div>
        )}
      </div>

      <div className="mt-[1em]">
        <AuthButton text="Submit" css="ml-2" onClick={handleSubmit} disabled={!isValid || isSubmitting} />
      </div>
    </div>
  );
};

export default Goals;
