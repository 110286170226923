import React from "react";

export const HomeIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 8.50234C1.5 7.86446 1.77072 7.25663 2.24483 6.82993L7.49483 2.10493C8.3505 1.33482 9.6495 1.33482 10.5052 2.10493L15.7552 6.82993C16.2293 7.25663 16.5 7.86446 16.5 8.50234V14.2503C16.5 15.4929 15.4927 16.5003 14.25 16.5003H12H11.9958H6.00418H6H3.75C2.50736 16.5003 1.5 15.4929 1.5 14.2503V8.50234Z"
      fill={fill1}
    />
    <path
      d="M6.75 12C6.75 11.1716 7.42157 10.5 8.25 10.5H9.75C10.5784 10.5 11.25 11.1716 11.25 12V16.5H6.75V12Z"
      fill={fill2}
    />
  </svg>
);
