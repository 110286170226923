import React from "react";
import Tooltip from "components/Tooltip";
import Skeleton from "react-loading-skeleton";
import { TimeIcon } from "assets/icons/survey/TimeIcon";
import { SurveyIcon } from "assets/icons/sidebar";
import { BsSearch } from "react-icons/bs";

import VideoPlaceHolder from "assets/images/survey/videoPlaceholder.png";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ResponseCard from "../components/ResponseCard";

const videoData = [];

for (let i = 0; i < 10; i++) {
  videoData.push({
    id: 1 + i,
    name: `Field response - ${i + 1}`,
    placeholder: VideoPlaceHolder,
    duration: "2min 53secs",
    responseType: "audio",
    date: dayjs().format("ddd. MMMM D, YYYY | h:mma"),
    location: "Lagos, Nigeria",
  });
}

const AllResponse = ({ overview, allData, count }) => {
  const navigate = useNavigate();
  const analyticData = [
    {
      id: 1,
      icon: <SurveyIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Responses
          <Tooltip text="The number of people who completed your survey compared to the total number of people who received your survey." />
        </div>
      ),
      number: overview?.responses === undefined ? "0" : overview?.responses,
    },

    {
      id: 2,
      icon: <SurveyIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Completion rate
          <Tooltip text=" The number of people who started and completed your survey, i.e., clicked the 'Submit' button." />
        </div>
      ),
      number: `${overview?.completionRate === undefined ? "0" : overview?.completionRate}%`,
    },
    {
      id: 3,
      icon: <TimeIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Drop-off rate
          <Tooltip text="The number of people who started your survey but did not complete it, i.e. did not click the 'Submit' button." />
        </div>
      ),
      number: `${overview?.dropOffRate === undefined ? "0" : overview?.dropOffRate}%`,
    },
  ];

  const handleChange = (e) => {
    console.log(e.target.value);
  };

  return (
    <div>
      <div className="flex justify-between space-x-5">
        {analyticData.map((el) => {
          return (
            <div className="md:w-full " key={`analyticData1-${el.id}`}>
              <div>
                <div className="flex items-start text-left bg-white h-[81px] w-full border print:border-[1px] rounded-lg space-x-4 p-4">
                  <div className="w-[30px] h-[30px] rounded-full bg-primary-50 flex items-center justify-center ">
                    {el.icon}
                  </div>
                  <div>
                    <p className="font-semibold text-lg text-gray-800">
                      {el.number === 0 ? "0" : el.number || <Skeleton />}
                    </p>

                    <div className="text-s text-neutral-500">{el.title || <Skeleton />}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bg-white p-6 mt-[1em] border rounded-xl">
        <div className="flex items-center justify-between">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search responses... "
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="text-[#9CA3AF]">
              {allData?.length} of {count} responses
            </p>
          </div>
        </div>
        <div className="mt-5">
          <div className="grid grid-cols-4 gap-x-12 gap-y-6 ">
            {allData?.map((el, i) => (
              <div
                key={i}
                onClick={() =>
                  navigate(`/research/field-interview/analytics/response/${el?.surveyId}/${el?._id}`, { state: el })
                }
                className="cursor-pointer"
              >
                <ResponseCard
                  name={`Field response-${i + 1}`}
                  mediaTime={el.mediaTimeInSeconds}
                  mediaType={el.mediaType}
                  videoPlaceholder={el.videoPlaceholder}
                  date={el.createdAt}
                  location={el.location}
                  mediaUrl="https://groundible.fra1.digitaloceanspaces.com/survey/80bb7149-90cd-411b-8f6c-6177684fb542-df7a2b7b-3f1e-4cc2-a484-7527d8eb1244.aac"
                  responseType={el.responseType}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="mt-10">Pagination lives here</div> */}
      </div>
    </div>
  );
};

export default AllResponse;
