import { CartesianGrid, Bar, BarChart, ResponsiveContainer, Tooltip, YAxis } from "recharts";

const Graph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="80%">
      <BarChart
        width={500}
        height={250}
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <YAxis />
        <Tooltip />
        <Bar dataKey="sent" fill="#FFD345" barSize={50} />
        <Bar dataKey="delivered" fill="#00DA7A" barSize={50} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Graph;
