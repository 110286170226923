import React, { useState } from "react";
import HomeCards from "./components/HomeCards";
import { motion } from "framer-motion";
import Typography from "utils/typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useOutsideClick from "hooks/useOutsideClick";
import { copyFunc } from "lib/copyFunc";
import { closeSurvey } from "services/newSurveyService";
import { useMutation, useQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { duplicateBySurveyId } from "services/newSurveyService";
import { SurveyStatuses } from "data/constants/surveyConstants";
import { generatedLinkUrl } from "data/constants/surveyConstants";
import CloseSurveyModal from "./components/CloseSurveyModal";
import EmptyState from "../EmptyState";
import emptySurvey from "assets/images/emptySurveyIcon.png";
import { deleteSurvey } from "services/newSurveyService";
import DuplicateSuccessModal from "./components/DuplicateSuccessModal";
import DeleteSurveyModal from "./components/DeleteSurveyModal";
// import SurveyDetailsModal from "../SurveyDetailsModal";

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.2, // Stagger the animation by 0.2 seconds per card
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
};

const Active = ({ surveyData, status, selectArray, handleCheck, drafts }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [clickId, setClickedId] = useState("");
  const refer = useOutsideClick(() => setOpen(0));
  const [survey, setSurvey] = useState("");
  const [surveyInfo, setSurveyInfo] = useState();
  const [surveyId, setSurveyId] = useState();
  const location = useLocation();

  console.log(open, "modal");

  const pathArray = location.pathname.split("/");
  console.log(pathArray);

  const handleModal = (id, surveyId) => {
    setOpen(open === id ? null : id);
    setSurveyId(surveyId);
  };

  // console.log(open);

  const duplicateFunc = (id, data) => {
    setClickedId(id);
    setSurveyInfo(data);
    handleModal();
  };

  const callBackFunc = (value, msg) => {
    copyFunc(value, msg);
    handleModal();
  };

  const handleOpenDuplicate = () => {
    handleEdit(drafts[0]?.surveyId);
  };

  const handleCloseModal = (num, value) => {
    handleModal(num);
    setSurvey(value);
  };

  const { mutate: mutateSurvey, isLoading: isClosingSurvey } = useMutation(closeSurvey, {
    onSuccess: () => {
      Toast.success("Survey closed.");
      handleModal();
      queryClient.invalidateQueries([
        "onlineSurveyActive",
        "onlineSurveyDraft",
        "onlineSurveyClosed",
        "onlineSurveyScheduled",
        "brandTrackingActive",
        "brandTrackingDraft",
        "brandTrackingClosed",
        "brandTrackingScheduled",
        "offline_surveyActive",
        "offline_surveyDraft",
        "offline_surveyClosed",
        "offline_surveyScheduled",
      ]);
      // setSelectArray([]);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: mutateDeleteSurvey, isLoading: isDeletingSurvey } = useMutation(deleteSurvey, {
    onSuccess: () => {
      Toast.success("Survey deleted.");
      handleModal();
      queryClient.invalidateQueries([
        "onlineSurveyActive",
        "onlineSurveyDraft",
        "onlineSurveyClosed",
        "onlineSurveyScheduled",
        "brandTrackingActive",
        "brandTrackingDraft",
        "brandTrackingClosed",
        "brandTrackingScheduled",
        "offline_surveyActive",
        "offline_surveyDraft",
        "offline_surveyClosed",
        "offline_surveyScheduled",
      ]);
      queryClient.invalidateQueries(["onBoarding"]);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDeleteSurvey = (id) => {
    mutateDeleteSurvey({
      surveyIds: [id],
    });
  };

  useQuery(["duplicateBySurveyId", clickId], () => duplicateBySurveyId(clickId), {
    enabled: !!clickId,
    onSuccess: () => {
      Toast.success("Survey duplicated.");
      queryClient.invalidateQueries(["onlineSurveyDraft"]);
      queryClient.invalidateQueries(["brandTrackingDraft"]);
      queryClient.invalidateQueries(["offline_surveyDraft"]);
      handleModal(5);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleClosedSurvey = () => {
    mutateSurvey({
      surveyIds: [survey],
    });
  };

  const clickFn = (surveyId) => {
    handleModal(`${surveyId}-pop`);
  };

  const handleEdit = (surveyId) => {
    if (pathArray.includes("brand-tracking")) {
      navigate(`/research/brand-tracking/${surveyId}`);
    } else if (pathArray.includes("online-survey")) {
      navigate(`/research/online-survey/${surveyId}`, { state: "Edit" });
    } else {
      navigate(`/research/field-interview/${surveyId}`);
    }
  };

  return (
    <>
      {surveyData?.length === 0 ? (
        <div className="h-[50vh]">
          <EmptyState icon={emptySurvey} text={`No ${status} survey found.`} />
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-6 ">
          {surveyData?.map((el, i) => (
            <motion.div
              key={i}
              custom={i}
              initial="hidden"
              animate="visible"
              variants={cardVariants}
              className="relative"
            >
              <HomeCards
                key={el.surveyId}
                surveyStatus={status}
                publishId={el.publishId}
                surveyId={el.surveyId}
                surveyImage={el.surveyImage}
                surveyName={el.surveyName}
                surveyDate={el.date}
                expectedResponse={el.expectedResponse}
                responses={el.responses}
                audienceType={el.audienceType}
                clickFn={() => clickFn(el.surveyId)}
                handleCheck={handleCheck}
                selectArray={selectArray}
              />

              {open === `${el.surveyId}-pop` ? (
                <div
                  className="bg-white shadow-md rounded-md overflow-hidden space-y-3 py-3 px-4 absolute w-[150px] top-[55%] right-[2em] cursor-pointer z-[999999] border-[1px]"
                  ref={refer}
                >
                  {status === SurveyStatuses.DRAFT ? null : (
                    <Typography.SubText>
                      <Link to={`./analytics/${el.publishId}/${el.surveyId}`} className="">
                        View report
                      </Link>
                    </Typography.SubText>
                  )}

                  <Typography.SubText className="" onClick={() => duplicateFunc(el.surveyId, el)}>
                    Duplicate
                  </Typography.SubText>
                  {status === SurveyStatuses.DRAFT ? (
                    <Typography.SubText className="" onClick={() => handleEdit(el?.surveyId)}>
                      Edit
                    </Typography.SubText>
                  ) : null}

                  {status === SurveyStatuses.DRAFT ? null : (
                    <Typography.SubText
                      className="cursor-pointer"
                      onClick={() =>
                        callBackFunc(generatedLinkUrl(el.audienceType, el.surveyId, el.publishId), "Link Copied")
                      }
                    >
                      Copy link
                    </Typography.SubText>
                  )}

                  <Typography.SubText className="cursor-pointer" onClick={() => handleModal(1, el.surveyId)}>
                    Delete survey
                  </Typography.SubText>

                  {status === SurveyStatuses.CLOSE || status === SurveyStatuses.DRAFT ? null : (
                    <Typography.SubText className="cursor-pointer" onClick={() => handleCloseModal(4, el.surveyId)}>
                      Close survey
                    </Typography.SubText>
                  )}
                </div>
              ) : null}
            </motion.div>
          ))}
          {open === 1 ? (
            <DeleteSurveyModal handleModal={handleModal} handleDeleteSurvey={() => handleDeleteSurvey(surveyId)} />
          ) : null}

          {open === 4 ? <CloseSurveyModal handleModal={handleModal} handleClosedSurvey={handleClosedSurvey} /> : null}
          {open === 5 ? (
            <DuplicateSuccessModal
              handleModal={handleModal}
              handleOpenDuplicate={handleOpenDuplicate}
              surveyData={surveyInfo}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default Active;
