import React from "react";
import Typography from "utils/typography";

const SelectInput = ({ options, name, value, onChange, text, css, disabled, defaultValue, error }) => {
  return (
    <div className="space-y-1">
      {text !== "" && (
        <Typography.SubText className="font-semibold" htmlFor={name}>
          {text}
        </Typography.SubText>
      )}
      <div className="text-primary flex flex-col w-[100%] relative">
        <select
          name={name}
          disabled={disabled || false}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          className={` ${css} rounded border-[1px] h-[40px] pl-2 outline-none  bg-[#FAFAFA] text-xs cursor-pointer appearance-none bg-transparent pr-8 ${
            text !== "" ? "mb-1" : ""
          }`}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} className="bg-white cursor-pointer xs">
              {option.label}
            </option>
          ))}
        </select>

        <div className="pointer-events-none absolute  inset-y-0 top-0 right-0 flex items-center pr-2">
          <svg className="fill-current h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 12l-6-6 1.5-1.5L10 9l4.5-4.5L16 6l-6 6z" clipRule="evenodd" />
          </svg>
        </div>
      </div>
      <p className="text-ss text-red-500">{error}</p>
    </div>
  );
};

export default SelectInput;
