import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Reason from "./Reason";
import ReSubscribe from "./ReSubscribe";
import SuccessReSubscribe from "./SuccessReSubscribe";

const UnSubscribe = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/reason" element={<Reason />} />
      <Route exact path="/resubscribe" element={<ReSubscribe />} />
      <Route exact path="/success" element={<SuccessReSubscribe />} />
    </Routes>
  );
};

export default UnSubscribe;
