import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import React, { useEffect, useState } from "react";
import { getTemplate } from "services/campaignService";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const PluginComponent = ({ setStep, setBroadcastData, broadcastData, setEditorOpen }) => {
  const { sidebar } = useSideBarStore();
  const { mutate, isLoading } = useMutation(getTemplate, {
    onSuccess: (data) => {
      setStep(3);
      setEditorOpen(false);

      // console.log(data, "here");
      setBroadcastData({
        ...broadcastData,
        content: { ...data },
      });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  /**************************** *********************************/

  /**************************** *********************************/
  useEffect(() => {
    const request = (method, url, data, callback) => {
      var req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          callback(req.responseText);
        } else if (req.readyState === 4 && req.status !== 200) {
          console.error("Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values");
        }
      };
      req.open(method, url, true);
      if (method !== "GET") {
        req.setRequestHeader("content-type", "application/json");
      }
      req.send(data);

      // Simulated request function
    };

    const loadDemoTemplate = (callback) => {
      request("GET", "https://raw.githubusercontent.com/AyoOluwa-Israel/mooyi-email/main/mooyi.html", null, (html) => {
        request("GET", "https://raw.githubusercontent.com/AyoOluwa-Israel/mooyi-email/main/mooyi.css", null, (css) => {
          callback({ html: html, css: css });
        });
      });
    };

    const initPlugin = (template) => {
      const apiRequestData = { emailId: 123 };

      const script = document.createElement("script");
      script.id = "stripoScript";
      script.type = "text/javascript";
      script.src = "https://plugins.stripo.email/static/latest/stripo.js";
      script.onload = () => {
        window.Stripo.init({
          settingsId: "stripoSettingsContainer",
          previewId: "stripoPreviewContainer",
          codeEditorButtonId: "codeEditor",
          undoButtonId: "undoButton",
          redoButtonId: "redoButton",
          locale: "en",
          html: template.html,
          // css: template.css,
          apiRequestData: apiRequestData,
          userFullName: "Plugin Demo User",
          versionHistory: {
            changeHistoryLinkId: "changeHistoryLink",
            onInitialized: () => {
              document.getElementById("changeHistoryContainer").style.display = "block";
            },
          },

          getAuthToken: (callback) => {
            request(
              "POST",
              "https://plugins.stripo.email/api/v1/auth",
              JSON.stringify({
                pluginId: "7780a83c82cc4f0d8addae064ce776d2",
                secretKey: "e069607fc8d14d779c7999da36b13280",
              }),
              (data) => {
                callback(JSON.parse(data).token);
              },
            );
          },
        });
      };
      document.body.appendChild(script);
    };

    loadDemoTemplate(initPlugin);
  }, []); // Run effect only once after initial render

  const handlePreviewClick = () => {
    window.StripoApi.compileEmail((error, html, ampHtml) => {
      // Implement your preview logic here

      mutate({
        template: html,
      });

      console.log("Compiled HTML:", html);
      console.log("Compiled AMP HTML:", ampHtml);
    });
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div>
      <div id="externalSystemContainer" className="flex items-center space-x-2  justify-between text-xs">
        {/* Plugin buttons will be rendered here */}

        <div></div>
        {/* <div className="space-x-4">
          <button id="codeEditor" className="bg-primary-800 text-white w-[130px] rounded-md px-4 py-2">
            Code editor
          </button>

          <button onClick={handlePreviewClick} className="bg-primary-800 text-white w-[130px] rounded-md px-4 py-2">
            Save
          </button>
        </div> */}

        <span id="changeHistoryContainer" style={{ display: "none" }}>
          Last change:{" "}
          <a id="changeHistoryLink" href="*">
            .
          </a>
        </span>
      </div>
      <div className="notification-zone"></div>
      <div className="flex w-[100%]">
        {/* Plugin containers */}
        <div id="stripoSettingsContainer" className="w-[30%]">
          <PreLoader />
        </div>
        <div id="stripoPreviewContainer" className="w-[70%]"></div>
      </div>

      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        {/* <div onClick={() => (4)}> */}
        <GreyButton text="Back" onClick={() => setStep(1)} />
        {/* </div> */}

        <div className="flex justify-between space-x-4">
          {/* {<GreyButton text="Back" onClick={() => setStep(1)} />} */}

          <BlueButton
            text={`${"Next"}`}
            className={`h-[40px] text-s`}
            // disabled={disableButton}
            onClick={handlePreviewClick}
          />
        </div>
      </div>
    </div>
  );
};

export default PluginComponent;
