export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const setEmail = (token) => {
  localStorage.setItem("email", token);
};

export const getEmail = () => {
  return localStorage.getItem("email");
};

export const removeEmail = () => {
  localStorage.removeItem("email");
};

export const setWorkspaceToken = (token) => {
  localStorage.setItem("wpToken", token);
};

export const getWorkspaceToken = () => {
  if (typeof window === "undefined") return undefined;

  if (!localStorage.getItem("wpToken")) return undefined;

  const wpToken = localStorage.getItem("wpToken");

  if (!!wpToken) {
    return localStorage.getItem("wpToken");
  }
};

export const removeWorkspaceToken = () => {
  localStorage.removeItem("wpToken");
};
