import { useMutation, useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { formatDate } from "lib/formatDate";
import React, { useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { HiChevronDown } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { deleteContacts, getCSVData, getSingleContact } from "services/contactService";
import csvDownload from "json-to-csv-export";
import EditContactModal from "./EditContactModal";
import DeleteContact from "./DeleteContact";
import dayjs from "dayjs";
import useOutsideClick from "hooks/useOutsideClick";
import emailGrey from "assets/icons/emailGrey.png";
import smsGrey from "assets/icons/smsGrey.png";
import callIcon from "assets/icons/callIcon.png";
import Activity from "./ActivityTab/Activity";
import SavedNotes from "./ActivityTab/SavedNotes";
import EmailModal from "./IndividualEmail/EmailModal";

const SingleContact = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState();
  const [tabValue, setTabValue] = useState(0);

  const actions = [
    // { id: 1, icon: callIcon, name: "Call", link: `/contacts/${id}/call` },
    { id: 2, icon: smsGrey, name: "SMS", link: `/contacts/${id}/sms` },
    { id: 3, icon: emailGrey, name: "Email", link: `/contacts/${id}/email` },
  ];

  const Tabs = [
    { name: "Activity", value: 0 },
    // { name: "Saved notes", value: 1 },
  ];

  const switchTab = (value) => {
    setTabValue(value);
  };

  const displayTab = () => {
    switch (tabValue) {
      case 0:
        return <Activity data={data?.contactActivity} />;
      // case 1:
      //   return <SavedNotes />;
      default:
    }
  };

  const toggleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const refer = useOutsideClick(toggleModal);

  const { data, isFetching, isLoading } = useQuery(["singleContact"], () => getSingleContact(id), {
    refetchOnMount: true,
  });

  const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation(deleteContacts, {
    onSuccess: () => {
      Toast.success("Contact(s) deleted successfully!!");
      queryClient.invalidateQueries(["allContacts"]);
      navigate("/contact");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  console.log(data);

  const deleteContactsFunc = () => {
    let arr = [];
    arr.push(id);
    deleteMutate({ contactId: arr });
    toggleModal();
  };

  const { mutate: csvMutate } = useMutation(getCSVData, {
    onSuccess: (data) => {
      const dataToConvert = {
        data: data,
        filename: "Contact",
        delimiter: ",",
      };

      csvDownload(dataToConvert);
      toggleModal(2);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  if (isFetching || isLoading) {
    return <PreLoader text="Loading contact information" />;
  }

  if (loadingDelete) {
    return <PreLoader text="Deleting contact information" />;
  }

  const exportCSV = () => {
    let arr = [];
    arr.push(id);
    csvMutate({ contactIds: arr });
  };

  return (
    <div className="border rounded-md px-5 py-5 bg-white">
      <div
        onClick={() => navigate(-1)}
        className="flex items-center space-x-1 my-2 text-primary-800 pl-2 cursor-pointer"
      >
        <FiChevronLeft />
        <p>Back to contacts</p>
      </div>

      <div className="flex justify-between">
        <div className="flex space-x-2 my-2">
          <div className="w-[104px] h-[104px] bg-primary-100 rounded-full flex justify-center items-center ">
            <p className="text-primary-800 font-semibold text-[28px]">
              {data?.firstname === null ? "" : data?.firstname[0]}
              {data?.lastname === null ? "" : data?.lastname[0]}
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-[24px] font-semibold">
              {data?.firstname === null ? "" : data?.firstname} {data?.lastname === null ? "" : data?.lastname}
            </p>
            <p>Created at {formatDate(data?.createdAt)}.</p>
            <div className="flex items-center space-x-5">
              {actions.map((el, i) => (
                <div
                  key={i}
                  className="flex items-center space-x-3 cursor-pointer"
                  onClick={() => (el.name !== "Email" ? navigate(el.link) : toggleModal(4))}
                >
                  <div className="w-[30px] h-[30px] rounded-full bg-gray-100 p-[6px]">
                    <img src={el.icon} alt="" />
                  </div>
                  <p>{el.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="relative">
          <div
            className="border-[1px] flex h-[45px] space-x-4 rounded-[8px] items-center px-4 text-primary-800 cursor-pointer"
            onClick={() => toggleModal(2)}
          >
            <p className="text-ss font-semibold">Action</p>
            <HiChevronDown />
          </div>

          {openModal === 2 ? (
            <div
              className="bg-white shadow-activeShadow rounded-[8px] w-[250px] absolute right-0 mt-2 cursor-pointer"
              ref={refer}
            >
              <p
                className="text-[#737373] hover:bg-gray-200 text-s space-x-2 border-b-[1px] p-3 "
                onClick={() => toggleModal(1)}
              >
                Edit contact
              </p>
              <p className="text-[#737373] hover:bg-gray-200 text-s space-x-2 border-b-[1px] p-3 " onClick={exportCSV}>
                Export as CSV
              </p>
              <p
                className="text-[#737373] hover:bg-gray-200 text-s space-x-2 border-b-[1px] p-3 "
                onClick={() => toggleModal(3)}
              >
                Delete contact
              </p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <div className="w-[35%] space-y-5">
          <div className=" border rounded-lg p-5">
            <p className="font-semibold text-[16px] py-2">Profile Information</p>
            <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

            <div className=" grid grid-cols-3 py-2 text-[14px]">
              <p className=" text-[#737373]">First name</p>
              <p className="font-[600] col-span-2 pl-5">{data?.firstname} </p>
            </div>

            <div className="grid grid-cols-3 py-2 text-[14px]">
              <p className=" text-[#737373]">Last name</p>
              <p className="font-[600] col-span-2 pl-5">{data?.lastname}</p>
            </div>

            <div className="grid grid-cols-3 py-2 text-[14px]">
              <p className=" text-[#737373]">Email Address</p>
              <p className="font-[600] col-span-2 pl-5">{data?.email}</p>
            </div>

            <div className="grid grid-cols-3 py-2 text-[14px]">
              <p className=" text-[#737373]">Phone Address</p>
              <p className="font-[600] col-span-2 pl-5">{data?.phone}</p>
            </div>

            {/* <div className="flex py-2 text-[14px]">
            <p className="w-[50%] text-[#737373]">Gender</p>
            <p className="font-[600]">{data?.gender}</p>
          </div> */}

            <div className="grid grid-cols-3 py-2 text-[14px]">
              <p className=" text-[#737373]">Birthday</p>
              <p className="font-[600] col-span-2 pl-5">{dayjs(data?.birthday).format("DD/MM/YYYY")}</p>
            </div>

            <div className="grid grid-cols-3 py-2 text-[14px]">
              <p className="text-[#737373]">Address</p>
              <p className="font-[600] col-span-2 pl-5">{data?.address}</p>
            </div>
            <div className="mt-3">
              <p className="font-semibold text-[16px] py-2">Custom fields</p>
              <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

              <div className=" grid grid-cols-3 py-2 text-[14px]">
                <p className=" text-[#737373]">Salary</p>
                <p className="font-[600] col-span-2 pl-5">50,000NGN</p>
              </div>

              <div className="grid grid-cols-3 py-2 text-[14px]">
                <p className=" text-[#737373]">Dress size</p>
                <p className="font-[600] col-span-2 pl-5">24</p>
              </div>
              <div className="grid grid-cols-3 py-2 text-[14px]">
                <p className=" text-[#737373]">Shoe size</p>
                <p className="font-[600] col-span-2 pl-5">48</p>
              </div>
            </div>
          </div>

          <div className="border rounded-lg p-5">
            <p className="font-semibold text-[16px] py-2">Tags</p>
            <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

            {data?.segment.length ? (
              <div className="">
                <div className=" py-2 flex items-start justify-start gap-[10px] flex-wrap">
                  {data?.tag.map((tag) => (
                    <p className="text-xs text-[#292D32] leading-[2rem] font-bold bg-white rounded-md border border-[#DFE5EC] px-[12px]">
                      {tag}
                    </p>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-[63%] border rounded-lg p-5">
          <div className="flex items-end space-x-5 border-b">
            {Tabs.map((el, i) => (
              <div>
                <p
                  className={`font-semibold text-[16px] py-2 cursor-pointer ${
                    tabValue === el.value ? "border-primary-800 border-b-2" : "text-gray-400 "
                  }`}
                  onClick={() => switchTab(el.value)}
                >
                  {el.name}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div> */}
          <div className="mt-5">{displayTab()}</div>

          {/* {data?.contactActivity?.length === 0 ? (
            <p>No Activity Yet</p>
          ) : (
            <>
              {data?.contactActivity?.map((el) => {
                return (
                  <div className="flex py-2">
                    <p className="w-[50%] text-[#737373]">{formatDate(el.avtivityDate)}</p>
                    <p className="font-[600]">{el.activity}</p>
                  </div>
                );
              })}
            </>
          )} */}
        </div>
      </div>

      {openModal === 1 ? <EditContactModal data={data} contactId={id} toggleModal={toggleModal} /> : null}

      {openModal === 3 ? <DeleteContact toggleModal={toggleModal} deleteContactsFunc={deleteContactsFunc} /> : null}

      {openModal === 4 ? <EmailModal toggleModal={toggleModal} /> : null}

      {/* <CallModal /> */}
    </div>
  );
};

export default SingleContact;
