import ContactModal from "components/Modals/ContactModal";
import Tooltip from "components/Tooltip";
import CreateTagModal from "pages/Contacts/CreateTagModal";
import SelectContacts from "./SelectContacts";
import SelectContactBySegment from "./SelectContactBySegment";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllTags } from "services/contactService";
import Toast from "config/toast";
import { getAllSegments } from "services/contactService";

const SavedContacts = ({ data, setData }) => {
  const [openModal, setOpenModal] = useState(null);
  const [contactSize, setContactSize] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [sortBy] = useState("name");

  const { data: tagData } = useQuery(["allTags"], getAllTags, {
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { data: segmentData } = useQuery(["allSegment", sortBy], () => getAllSegments(sortBy), {
    onError: (error) => {
      Toast.error(error);
    },
  });
  const tabs = [
    { name: "Tags", value: 0 },
    { name: "Segments", value: 1 },
  ];

  const toggleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const displayTab = () => {
    switch (tabValue) {
      case 0:
        return (
          <SelectContacts
            tagData={tagData}
            data={data}
            setData={setData}
            contactSize={contactSize}
            setContactSize={setContactSize}
          />
        );
      case 1:
        return (
          <SelectContactBySegment
            segmentData={segmentData}
            data={data}
            setData={setData}
            contactSize={contactSize}
            setContactSize={setContactSize}
          />
        );
      default:
    }
  };

  return (
    <div>
      {openModal === 1 ? <ContactModal outSideContact={true} toggleModal={toggleModal} /> : null}
      {openModal === 2 ? <CreateTagModal closeModal={() => toggleModal(1)} /> : null}
      <div className="border-[1px] rounded-md p-4 w-[100%]">
        <div className="flex items-center justify-between ">
          <div className="flex items-center space-x-5">
            {tabs.map((el, i) => (
              <div
                key={i}
                className={`flex space-x-2 items-center cursor-pointer  ${
                  tabValue === el.value ? "border-b-2 border-primary-800 text-black" : "text-gray-300"
                }`}
                onClick={() => setTabValue(el.value)}
              >
                <p className="text-s font-[500]">{el.name}</p>
                {/* <Tooltip text="Select the category of contacts you want to send your survey to." /> */}
              </div>
            ))}
          </div>
          <div>
            <p className="text-[14px] text-primary-500 cursor-pointer" onClick={() => toggleModal(1)}>
              Add contacts
            </p>
          </div>
        </div>
        <div>{displayTab()}</div>
        <div className="h-10">
          <div className="flex gap-x-2 items-center ">
            <p className="text-s my-1 font-[500] text-[#262626]">Contact size</p>
          </div>
          <p className="text-neu-1 text-s">{contactSize}</p>
        </div>
      </div>
    </div>
  );
};

export default SavedContacts;
