import BlueButton from "components/Button/BlueButton";
import Checkbox from "components/Inputs/CheckBox";
import Modal from "components/Modal";
import React from "react";

const SkipLogicModalPreview = ({ handleModal, selectedQuestion }) => {
  console.log("first", selectedQuestion);
  return (
    <Modal title="Add logic" onClose={handleModal}>
      <div className="w-[550px] px-5">
        <p className="my-4 text-sm text-neutral-600">Add logic to your survey questions</p>

        <hr className=" bg-neutral-300 my-4" />

        <div className="w-[95%] mx-auto">
          <div className="h-[200px] rounded-2xl bg-primary-50 w-[100%]"></div>

          <p className="text-xs py-4 text-neutral-600">
            Skip logic enables you send your respondents to another question based on the response they provide. You
            cannot skip to an item, which is placed before the question that triggers the “jump to” consequence. You can
            only skip forwards, but never backwards!
          </p>

          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Checkbox />
              <p className="text-sm font-semibold text-primary-800">Skip video guide on next launch</p>
            </div>

            <BlueButton text="Next" type="button" className="w-[120px]" onClick={() => handleModal(6)} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SkipLogicModalPreview;
