import React from "react";
import { UIConstants } from "data/constants/uiConstants";
import Typography from "utils/typography";
import PreviewCard from "../PreviewCard";
import message from "assets/images/broadcast/message.png";
import { LuDot } from "react-icons/lu";
import { useLocation } from "react-router-dom";

const Details = () => {
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const isNPS = locationArray.includes("nps");
  return (
    <div className="h-[70vh] flex items-start gap-[2em] p-5 bg-white rounded-lg border">
      <div
        className={`w-[60%] border-[1px] shadow-md h-[100%]  overflow-y-auto p-10 rounded-[8px] ${UIConstants.customScrollBarClassNames}`}
      >
        <div className="">
          <div className="flex items-center space-x-5">
            <div className="w-[65px] h-[45px] flex items-center justify-center bg-primary-100 rounded-lg p-5">
              <img src={message} alt="" />
            </div>
            <div className="space-y-2">
              <p className="text-[14px] font-bold">New survey</p>
              <div className="flex items-center space-x-1">
                <Typography.SubText className="text-[12px] text-gray-500 capitalize">
                  {isNPS ? "NPS survey" : "CSAT survey"}
                </Typography.SubText>
                <LuDot />
                <Typography.SubText className="text-[12px] text-gray-500 capitalize">date</Typography.SubText>
              </div>
            </div>
          </div>
          <div className="py-5">
            <hr />
          </div>
          <div className="">
            <div className="text-m flex justify-between py-4">
              <Typography.SubText className=" text-[#737373] font-[450]">Channel</Typography.SubText>
              <Typography.SubText className="">Emails</Typography.SubText>
            </div>
            <hr />
            <div className="text-m flex justify-between py-4">
              <Typography.SubText className=" text-[#737373] font-[450]">Audience</Typography.SubText>
              <div className="flex items-center space-x-2">
                {/* {data?.recipientArray?.map((el, i) => {
                    return (
                      <Typography.SubText className="text-[12px] Typography.SubText-2 bg-gray-200 rounded-md " key={i}>
                        {el.name}
                      </Typography.SubText>
                    );
                  })} */}
                Audience
              </div>
            </div>
            <hr />
            <div className="text-m flex justify-between py-4">
              <Typography.SubText className=" text-[#737373] font-[450]">Frequency</Typography.SubText>

              <Typography.SubText className="text-right">20 times</Typography.SubText>
            </div>
            <hr />
            <div className="text-m flex justify-between py-4">
              <Typography.SubText className=" text-[#737373] font-[450]">Total units</Typography.SubText>
              <Typography.SubText className="">6000</Typography.SubText>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-[40%] border-[1px] shadow-md h-[100%] bg-neutral-100  overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <div className="h-[50px] border-b-[1px] flex items-center justify-center px-4 bg-white">
          <Typography.SubText>Preview</Typography.SubText>
        </div>

        <div className="h-[calc(100%-50px)] flex items-center justify-center">
          <PreviewCard />
        </div>
      </div>
    </div>
  );
};

export default Details;
