import axios from "axios";
import Toast from "config/toast";
import { removeToken } from "helpers/authHelper";

const ExternalInstance = axios.create({
  baseURL: "https://integration-server.mooyi.africa/api/v1/mooyi-integration",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "i-am-mooyi-dev": "c27eb735-6a16-423e-8ea6-af1c4e6ac1a5",
  },
});

ExternalInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

ExternalInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error.response) {
      if ([401].includes(error.response.status)) {
        window.location.replace("/auth/login");
        Toast.error("Token expired, redirecting to login...");
        removeToken();
      }
    }
    return Promise.reject(error);
  },
);
export default ExternalInstance;
