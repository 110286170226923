import { handleApiError } from "utils/handleApiError";
import Instance from "utils/instance";

export const getRates = async () => {
  try {
    const { data } = await Instance.get("/workspace-microservice/admin/workspace/currency/rate");
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
