import React from "react";
import recentEmptyState from "assets/images/broadcast/overviewIcons/recentEmptyState.png";
import Typography from "utils/typography";

const RecentsEmptyState = ({ className }) => {
  return (
    <figure className={`flex flex-col h-[calc(100%-15px)] justify-center items-center ${className}`}>
      <div className="w-[70px] h-[70px]">
        <img src={recentEmptyState} alt="empty_state.svg" />
      </div>
      <Typography.SubText>
        <p>Your most recent campaigns will be displayed here.</p>
      </Typography.SubText>
    </figure>
  );
};

export default RecentsEmptyState;
