import React from "react";
import { subPlans } from "../../data";
import PlanCard from "../../components/PlanCard";
import ResearchTable from "../ResearchTable";

const PremiumPlan = ({ conversionRate, toggleValue }) => {
  return (
    <div>
      <div>
        <div className=" py-[2rem] ">
          <div className="justify-center space-x-10 items-start hidden md:flex">
            {subPlans.map((el, i) => (
              <PlanCard
                title={el.title}
                subtitle={el.subtitle}
                plan={el.plan}
                planId={el.planId}
                category={el.category}
                tag={el.tag}
                info={el.info}
                featureTitle={el.featureTitle}
                features={el.features}
                amount={el.amount}
                responses={el.responses}
                conversionRate={conversionRate}
                toggle={toggleValue}
                key={i}
              />
            ))}
          </div>
        </div>
        <div className=" bg-white pt-[2rem] mb-[2rem] mt-[24px] ">
          <p className="font-secondary font-[500] text-[20px] text-center" id="explore">
            Explore all our features
          </p>
          <div className="flex justify-center">
            <p className="text-[14px] text-center w-[60%] px-3">
              Discover the benefits of each pricing tier and choose the one that best fit your business.
            </p>
          </div>
          <div className="flex justify-center">
            <div className="md:w-full px-4 mt-10">
              <ResearchTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPlan;
