import BlueButton from "components/Button/BlueButton";
// import { useState } from "react";
// import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi2";
import checker from "assets/images/pricing/check.png";
import { HashLink } from "react-router-hash-link";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPlan } from "services/userService";
import { useStore } from "store";
import { useCallback, useEffect, useState } from "react";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { paymentPlans, verifyPayment } from "services/workspaceService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";

const publicKey =
  process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_FLW_KEY : process.env.REACT_APP_FLW_LIVE_KEY;

const PlanCard = ({
  title,
  subtitle,
  category,
  tag,
  responses,
  planId,
  info,
  plan,
  amount,
  featureTitle,
  features,
  conversionRate,
  toggle,
}) => {
  const { user } = useStore();
  const [researchHub, setResearchHub] = useState("");
  const [engagementHub, setEngagementHub] = useState("");
  const handleContactSupport = () => {
    window.location.href = "mailto:support@enterscale.com";
  };

  const configs = {
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: "",
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user?.email,
      phone_number: `+234${user?.phone}`,
      name: user?.firstname + " " + user?.lastname,
    },
    customizations: {
      title: "MOOYI",
      description: "Plan Upgrade",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const initializePayment = useFlutterwave(configs);

  const { mutate: paymentVerify } = useMutation(verifyPayment, {
    onSuccess: () => {
      closePaymentModal();
      Toast.success("plan purchase successful");
      queryClient.invalidateQueries(["myPlan"]);
      queryClient.invalidateQueries(["getPlan"]);
      queryClient.invalidateQueries(["wsCredit"]);
      queryClient.invalidateQueries(["wsData"]);
    },
    onError: () => {
      Toast.error("Try again");
    },
  });

  const { mutate: payment } = useMutation(paymentPlans, {
    onSuccess: ({ data }) => {
      console.log(data);
      // configs.amount = data?.plan_price;
      console.log(data);
      configs.amount = data?.amount;
      initializePayment({
        callback: (response) => {
          const userData = {
            tx_ref: data?.tx_ref,
            transactionId: response?.transaction_id,
          };
          paymentVerify(userData);
        },
        onClose: () => {
          // routeFn();
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const payload = {
    plan_id: [planId],
    email: user?.email,
    fullname: user?.fullname,
  };

  const handleSubmit = (planId) => {
    title === "Pro Plan" ? handleContactSupport() : payment(payload);
  };

  const { data } = useQuery(["myPlan"], fetchPlan);
  const planData = data?.plan;
  const buttonText =
    category === "engagementHub"
      ? plan === engagementHub
        ? "Current plan"
        : title === "Pro Plan"
        ? "Contact us"
        : "Buy now"
      : plan === researchHub
      ? "Current plan"
      : title === "Pro Plan"
      ? "Contact us"
      : "Buy now";

  const findPlan = useCallback(() => {
    planData?.map((el) => {
      return el?.category === "researchHub" ? setResearchHub(el.plan) : setEngagementHub(el.plan);
    });
  }, [planData]);

  useEffect(() => {
    findPlan();
  }, [findPlan]);

  return (
    <div
      className={`border bg-white rounded-2xl w-[411px] ${
        category === "engagementHub"
          ? `h-[52rem] ${plan === engagementHub ? "border-primary-800" : ""} `
          : `h-[37rem] ${plan === researchHub ? "border-primary-800" : ""} `
      } p-3 shadow-md
      } `}
    >
      <div className="bg-blue-100 p-5 rounded-t-xl h-[7rem]">
        <div className="flex justify-between">
          <p className="text-[20px] font-[600] text-blue-800">{title}</p>
          {tag !== "" || undefined ? (
            <div className="bg-white p-2 w-fit rounded flex items-center justify-center">
              <p className="text-blue-800  text-[12px] font-[600]">{tag}</p>
            </div>
          ) : null}
        </div>
        <p className="text-grey-600 md:text-[15px] text-[13px]">{subtitle}</p>
      </div>
      <div className="mt-5">
        <p className="md:text-[16px] text-[14px]">{info}</p>
        {/* <div className="mt-3">
          <p className="text-[12px]">Responses</p>
          <div className="flex items-center mt-1">
            <div
              className={`border rounded-l-md h-[30px] border-r-0  p-2 flex items-center justify-center cursor-pointer  ${
                newresponses < 300 ? "text-gray-400 cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={() => setNewResponses(newresponses > 200 ? newresponses - 100 : newresponses)}
            >
              <HiOutlineMinus />
            </div>
            <div className="border p-2 flex items-center justify-center h-[30px] text-[12px]">{responses}</div>
            <div
              className="border rounded-r-md border-l-0 h-[30px]  p-2 flex items-center justify-center cursor-pointer"
              onClick={() => setNewResponses(newresponses + 100)}
            >
              <HiOutlinePlus />
            </div>
          </div>
        </div> */}
        {title === "Pro Plan" ? (
          <div>
            <p className="text-[38px] md:text-[40px] font-[600]">Custom</p>
          </div>
        ) : (
          <div className="flex items-center space-x-3">
            <p className="text-[38px] md:text-[40px] font-[600]">
              {toggle ? `₦${(amount * conversionRate).toLocaleString()}` : `$${amount.toLocaleString()}`}
            </p>
            <p className="font-[600] text-gray-500 ">Per month</p>
          </div>
        )}
        <div className="mt-5">
          {/* <Link
            to={
              category === "engagementHub"
                ? plan === engagementHub
                  ? null
                  : title === "Pro Plan" && "mailto:support@enterscale.com"
                : plan === researchHub
                ? null
                : title === "Pro Plan" && "mailto:support@enterscale.com"
            }
          > */}
          <BlueButton
            text={buttonText}
            disabled={category === "engagementHub" ? plan === engagementHub : plan === researchHub}
            type="button"
            className="w-full"
            onClick={() => handleSubmit(planId)}
          />
          {/* </Link> */}
          {/* <p className="mt-2 text-gray-500 md:text-[14px] text-[13px]">{`Additional responses would be charged at ${
            toggle ? `₦${(2 * conversionRate).toLocaleString()}` : "$2"
          } per response for online surveys.`}</p> */}
        </div>
        <div className="my-5">
          <hr />
        </div>
        <div>
          <p className="font-secondary">{featureTitle}</p>
          {features !== undefined
            ? features.map((el, i) => (
                <div className="flex items-start space-x-3 mt-3" key={i}>
                  <div className="mt-1 w-[20px] h-[20px]">
                    <img src={checker} alt="" />
                  </div>
                  <p className="text-[13px] w-[90%]">{el}</p>
                </div>
              ))
            : null}
        </div>
        <HashLink smooth to="#">
          <div className="flex items-center space-x-3 text-blue-800 underline text-[12px] mt-5">
            <p>Explore all features</p>{" "}
          </div>
        </HashLink>
      </div>
    </div>
  );
};

export default PlanCard;
