import React from "react";

const PieChart = ({ className, value }) => {
  let size = 150;

  let max = 100;
  let strokeWidth = 10;

  const radius = (size - strokeWidth) / 2;
  const circumference = Math.PI * radius;
  const progressOffset = circumference - (value / max) * circumference;

  return (
    <div>
      <svg width={size} height={size / 2} className="overflow-visible">
        {/* Background Path */}
        <path
          d={`M ${strokeWidth / 2} ${radius + strokeWidth / 2} A ${radius} ${radius} 0 0 1 ${size - strokeWidth / 2} ${
            radius + strokeWidth / 2
          }`}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="transparent"
          className="text-gray-200"
          strokeLinecap="round"
        />

        {/* Progress Path */}
        <path
          d={`M ${strokeWidth / 2} ${radius + strokeWidth / 2} A ${radius} ${radius} 0 0 1 ${size - strokeWidth / 2} ${
            radius + strokeWidth / 2
          }`}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          className={`${className} transition-all duration-300`}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default PieChart;
