import React, { useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";

const Tooltip = ({ text }) => {
  const [show, setShow] = useState(false);

  return (
    <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className="cursor-pointer relative ">
      <IoMdInformationCircle className="text-sm" />
      {show ? (
        <span className="w-[200px] h-auto rounded-[4px] bg-white shadow-primary p-2 text-[10px] absolute top-4 z-50 border-[1px]">
          {text}
        </span>
      ) : null}
    </div>
  );
};

export default Tooltip;
