import dayjs from "dayjs";
import React, { useState } from "react";
import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";

const DashboardWeeklyDashbaord = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const getWeekDates = (date) => {
    const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      weekDates.push(new Date(startOfWeek));
      startOfWeek.setDate(startOfWeek.getDate() + 1);
    }
    return weekDates;
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const goToPreviousWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
  };

  const goToNextWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
  };

  const weekDates = getWeekDates(new Date(currentDate));

  const getMonthAndYear = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  };

  console.log(weekDates);

  return (
    <div className="flex flex-col items-center border-[1px] rounded-lg p-3 my-[1em]">
      <div className="flex items-center justify-between w-[100%]">
        <p className="text-sm font-medium text-gray-500">{getMonthAndYear(currentDate)}</p>

        <div className="flex justify-between text-gray-600 space-x-2 ">
          <div
            onClick={goToPreviousWeek}
            className="border-[1px] rounded hover:bg-primary-50 h-[25px] w-[25px] flex items-center justify-center text-lg cursor-pointer"
          >
            <HiMiniChevronLeft />
          </div>
          <div
            onClick={goToNextWeek}
            className="border-[1px] rounded hover:bg-primary-50 h-[25px] w-[25px] flex items-center justify-center text-lg cursor-pointer"
          >
            <HiMiniChevronRight />
          </div>
        </div>
      </div>

      <div className=" w-[100%] mx-auto flex space-x-3 justify-between max-w-md mt-4">
        {weekDates.map((date, index) => (
          <div
            key={index}
            className={`text-center p-2 rounded w-20 text-sm ${
              isSameDay(date, new Date()) ? "bg-primary-100 text-primary-800" : "text-gray-600"
            }`}
          >
            <p> {dayjs(date).format("ddd")}</p>

            <p> {dayjs(date).format("D")}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardWeeklyDashbaord;
