import React from "react";
import Typography from "utils/typography";

const HalfPieChart = () => {
  let size = 300;
  let value = 80;

  let max = 100;
  let strokeWidth = 20;

  const radius = (size - strokeWidth) / 2;
  const circumference = Math.PI * radius;
  const progressOffset = circumference - (value / max) * circumference;

  return (
    <div className="w-[100%] h-[450px]  px-0 flex items-center justify-center">
      <div className="flex justify-center items-center relative">
        <svg width={size} height={size / 2} className="overflow-visible">
          {/* Background Path */}
          <path
            d={`M ${strokeWidth / 2} ${radius + strokeWidth / 2} A ${radius} ${radius} 0 0 1 ${
              size - strokeWidth / 2
            } ${radius + strokeWidth / 2}`}
            stroke="currentColor"
            strokeWidth={strokeWidth}
            fill="transparent"
            className="text-gray-200"
            strokeLinecap="round"
          />

          {/* Progress Path */}
          <path
            d={`M ${strokeWidth / 2} ${radius + strokeWidth / 2} A ${radius} ${radius} 0 0 1 ${
              size - strokeWidth / 2
            } ${radius + strokeWidth / 2}`}
            stroke="currentColor"
            strokeWidth={strokeWidth}
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={progressOffset}
            className="text-blue-500 transition-all duration-300"
            strokeLinecap="round"
          />
        </svg>

        {/* Centered Value */}

        <div className="absolute text-2xl font-semibold text-center -bottom-3">
          <span>{Math.round((value / max) * 100)}</span>

          <Typography.SubText>NPS Score</Typography.SubText>
        </div>
      </div>
    </div>
  );
};

export default HalfPieChart;
