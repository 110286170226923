import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import useOnclickOutside from "react-cool-onclickoutside";

const Dropdown = ({ tag, options, onSelect, selectedValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    if (selectedValue) {
      const selected = options.find((option) => option.value === selectedValue);
      setSelectedOption(selected || null);
    }
  }, [selectedValue, options]);

  const refer = useOnclickOutside(() => setIsOpen(false));

  return (
    <div className="relative" ref={refer}>
      <div
        className="flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-[100%] h-[40px]"
        onClick={toggleDropdown}
      >
        <p className="w-full">
          {selectedOption ? (
            <span className="flex items-center space-x-3">
              {selectedOption.flag ? (
                <img src={selectedOption.flag} alt="option-flag" className="w-[20px] h-[20px]" />
              ) : null}

              <p className="text-xs text-gray-700 font-normal"> {selectedOption.label}</p>
            </span>
          ) : (
            <div className="text-left text-xs text-gray-700 font-normal">{tag}</div>
          )}
        </p>
        <svg
          className={`w-4 h-4 ml-1 transition-transform duration-200 ${isOpen ? "transform rotate-180" : ""}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      <motion.div
        className="absolute right-0 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-10 overflow-hidden"
        initial={{ maxHeight: 0, opacity: 0 }}
        animate={{ maxHeight: isOpen ? 400 : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <ul className="max-h-[400px] overflow-y-scroll">
          {options?.map((option, index) => (
            <li
              key={`${index}dropdown`}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b-[1px] text-xs"
              onClick={() => handleOptionClick(option)}
            >
              {option?.label}
            </li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default Dropdown;
