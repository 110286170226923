import { useMutation, useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import Toast from "config/toast";
import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getInsightQuestion } from "services/insightService";
import { createInsightName } from "services/insightService";
import Typography from "utils/typography";

const CreationModal = ({ handleModal, from }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const insightId = params.get("insightId");

  const { data } = useQuery(["getInsightQuestion"], getInsightQuestion);

  console.log(data, "data");

  // from = nps, csat, cp

  const { mutate, isLoading } = useMutation(createInsightName, {
    onSuccess: (data) => {
      // console.log(data?._id, "data");
      Toast.success("Progress Saved!");

      if (from === "nps") {
        navigate(`/insights/setup/nps?insightId=${data?._id}`);
      } else if (from === "csat") {
        navigate(`/insights/setup/csat?insightId=${data?._id}`);
      } else {
        navigate(`/insights/setup/customer-profile?insightId=${data?._id}`);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const Title = from === "nps" ? "NPS survey" : from === "csat" ? "CSAT survey" : "Customer Profile Survey";

  const [name, setName] = useState("");

  const handleSave = () => {
    const userData = {
      insightId: insightId ? insightId : null,
      insightName: name,
      insightType: from,
    };

    mutate(userData);
  };

  return (
    <Modal title={Title} onClose={handleModal}>
      <div className="w-[440px]">
        <Typography.SubText className="px-5">Give your survey a title</Typography.SubText>
        <div className="mt-2 px-5">
          <p className="font-[600] text-[14px] pb-2">Title</p>
          <div className=" h-[40px] rounded-[8px] flex justify-between w-full items-center">
            <SecondaryInput
              placeholder="Enter title"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
        </div>
        <div className="flex justify-between mt-[1em] px-5">
          <div></div>
          <div className="flex gap-[1em] mt-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <BlueButton text="Save" onClick={handleSave} disabled={name === "" || isLoading} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreationModal;
