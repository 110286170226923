import PluginComponent from "pages/PluginComponent";
import React, { useState } from "react";
import GreaterThan from "assets/images/greaterThan.svg";
import { useLocation, useNavigate } from "react-router-dom";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const tabs = [
  { name: "Edit Content", value: 0 },
  { name: "Publish", value: 1 },
];

const IndividualEmail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [editorOpen, setEditorOpen] = useState(true);
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(null);
  const { sidebar } = useSideBarStore();
  const [disableButton, setDisabledButton] = useState(true);
  const [broadcastData, setBroadcastData] = useState({
    campaignName: state?.campaignName,
    emailSubject: state?.emailSubject,
    objective: state?.objective,
    senderEmail: state?.senderEmail,
    senderName: state?.senderEmail,
  });

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleNext = () => {
    if (step === 1) {
      // getTemplateId();
    }
    if (step < 3) {
      setStep(step + 1);
    } else if (step === 3) {
      setOpen(1);
    }
  };

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            {editorOpen ? (
              <PluginComponent
                broadcastData={broadcastData}
                setBroadcastData={setBroadcastData}
                setStep={setStep}
                setEditorOpen={setEditorOpen}
              />
            ) : null}
          </div>
        );
      case 1:
        return (
          <div>this is case 2</div>
          //   <Content
          //     broadcastData={broadcastData}
          //     setBroadcastData={setBroadcastData}
          //     data={templateData}
          //     setData={setTemplateData}
          //     existingData={data}
          //     loading={fetchDetails}
          //   />
        );
      default:
    }
  };
  return (
    <div>
      <div className="flex space-x-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%] mx-auto">
        {tabs.map((el, i) => {
          return (
            <div
              className="flex items-center justify-between w-[50%] cursor-pointer"
              key={el.value}
              onClick={() => moveToStep(el.value)}
            >
              <div className="flex items-center justify-center w-[100%]">
                <p
                  className={`${
                    step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                  } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                >
                  {el.value + 1}
                </p>
                <p
                  className={`px-2 text-s cursor-pointer ${
                    step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                  } `}
                >
                  {el.name}
                </p>
              </div>

              {el.value === 1 ? null : <img src={GreaterThan} alt="GreaterThan" />}
            </div>
          );
        })}
      </div>

      <div>{displayStep()}</div>
      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <GreyButton text="Back" onClick={() => (step !== 0 ? setStep(step - 1) : navigate(-1))} />

        <div className="flex justify-between space-x-4">
          <GreyButton
            text="Save & exit"
            onClick={() => handleModal(4)}
            disabled={(broadcastData.content === "") | (broadcastData.content === undefined) ? true : false}
          />
          <BlueButton
            text={`${step === 1 ? "Publish" : "Next"}`}
            css={`text-white ${step === 3 ? "bg-[#25C15E]" : "bg-primary-800"}  h-[40px] text-s`}
            disabled={disableButton}
            onClick={step === 1 ? () => setEditorOpen(true) : handleNext}
          />
        </div>
      </div>
    </div>
  );
};

export default IndividualEmail;
