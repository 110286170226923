// import SecondaryInput from "components/Inputs/SecondaryInput";
import React, { useState } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { updateUser } from "services/userService";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import AuthButton from "components/Button/AuthButton";
import { roleData } from "pages/MyAccount/data";
import SecondarySelectInput from "components/Inputs/SecondarySelectInput";
import PreLoader from "components/Loader/PreLoader";
import PhoneNumberInput from "components/Inputs/PhoneNumberInput";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { useFormik } from "formik";

const validationSchema = Yup.object({
  email: Yup.string("Your emails will be sent from this address")
    .email("Enter a valid email address")
    .required("Please fill in this field"),
  firstname: Yup.string().min(3, "Add at least three letters").required("Please enter your first Name"),
  lastname: Yup.string().min(3, "Add at least three letters").required("Please enter your last Name"),
  phone: Yup.string().required("Please fill in this field"),
  userRole: Yup.string().required("Please fill in this field"),
});

const Profile = ({ user, setStep }) => {
  const [selectedCode, setSelectedCode] = useState("+234");
  const { mutate, isLoading: isSubmitting } = useMutation(updateUser, {
    onSuccess: () => {
      Toast.success("Updated successfully!!");
      queryClient.invalidateQueries(["currentUser"]);
      setStep(1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const checkPhone = (x) => {
    if (x === null || x === "") {
      return "";
    } else if (x?.startsWith("234")) {
      return x?.substring(3);
    } else if (x?.startsWith("+234")) {
      return x?.substring(4);
    } else {
      return x;
    }
  };

  const initialValues = {
    firstname: user?.data?.firstname || "",
    lastname: user?.data?.lastname || "",
    // businessname: user?.data?.business?.businessName || "",
    email: user?.data?.email || "",
    phone: `${checkPhone(user?.data?.phone)}` || "",
    userRole: user?.data?.userRole || "",
  };

  const onSubmit = async (data) => {
    const userData = {
      ...data,
      phone: `${data.phone === "" ? "" : `+234${data.phone}`}`,
    };
    mutate(userData);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isSubmitting) {
    return <PreLoader text="Submitting data..." />;
  }
  return (
    <div>
      <p className="text-[#292D32] font-semibold text-[24px]">Personal Information</p>

      <p className="text-[#737373] text-s font-[450]">First things first, let’s get to know you..</p>

      <form onSubmit={handleSubmit}>
        <div className="w-[100%] mt-[2em] space-y-4">
          <PrimaryInput
            type="text"
            placeholder="Enter first name"
            onChange={handleChange}
            text="First Name"
            name="firstname"
            onBlur={handleBlur}
            value={values.firstname}
            error={getError("firstname")}
          />

          <PrimaryInput
            type="text"
            placeholder="Enter last name"
            onChange={handleChange}
            text="Last Name"
            name="lastname"
            onBlur={handleBlur}
            value={values.lastname}
            error={getError("lastname")}
          />

          <PrimaryInput
            type="email"
            name="email"
            placeholder=""
            onChange={handleChange}
            text="Email Address"
            onBlur={handleBlur}
            value={values.email}
            error={getError("email")}
          />

          <PhoneNumberInput
            text="Mobile Number"
            // onChange={handleInputChange}
            value={values.phone}
            name="phone"
            setSelectedCode={setSelectedCode}
            selectedCode={selectedCode}
            error={getError("phone")}
          />

          {/* <div className="text-primary flex flex-col w-[100%]">
              <label htmlFor="phone" className="text-[#292D32] text-sm font-[500]">
                Phone Number
              </label>

              <div className="border-[#D0D5DD] border-[1px] flex items-center rounded-[8px] my-2 ">
                <div className="flex items-center px-3">
                  <img src={Flag} alt="Flag" />
                  <p className="ml-2 text-sm">+234</p>
                </div>
                <Field
                  as="input"
                  type="tel"
                  placeholder="806 xxxx xxx"
                  name="phone"
                  className="border-l-[1px] border-[#D0D5DD] h-[48px] pl-2 outline-none w-[70%] bg-[#FAFAFA] text-m"
                  maxLength="10"
                />
              </div>
              <ErrorMessage name="phone" component="div" className="text-red-500 !text-ss italic mb-3 !-mt-3" />
            </div> */}

          <SecondarySelectInput
            text="What’s your role"
            options={roleData}
            name="userRole"
            value={values.userRole}
            onChange={handleChange}
            error={getError("userRole")}
          />

          <div className="mt-[1em]">
            <AuthButton text="Continue" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Profile;
