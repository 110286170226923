import { useMutation } from "@tanstack/react-query";
import IconButton from "components/Button/IconButton";
import PrimaryInput from "components/Inputs/PrimaryInput";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import { useFormik } from "formik";
import { useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { signup } from "services/authService";
import * as Yup from "yup";
import { setEmail } from "helpers/authHelper";
import Checkbox from "components/Inputs/CheckBox";
import PhoneNumberInput from "components/Inputs/PhoneNumberInput";
import { verifyPayment } from "services/workspaceService";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
// import dayjs from "dayjs";
import { trackEvent } from "config/mixpanel";
import { queryClient } from "config/gateway";

const publicKey =
  process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_FLW_KEY : process.env.REACT_APP_FLW_LIVE_KEY;

const initialValues = {
  email: "",
  password: "",
  firstname: "",
  lastname: "",
  phone: "",
  promotion: false,
};

const validationSchema = Yup.object({
  email: Yup.string("Your emails will be sent from this address")
    .email("Enter a valid email address")
    .required("Please fill in this field"),
  firstname: Yup.string().min(3, "Add at least three letters").required("Please enter your first Name"),
  lastname: Yup.string().min(3, "Add at least three letters").required("Please enter your last Name"),
  phone: Yup.string().required("Please fill in this field"),
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&*.])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
  promotion: Yup.boolean(),
});

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+234");
  const navigate = useNavigate();
  const { id, pId } = useParams();

  console.log({ id, pId });

  const priceId = pId !== undefined ? pId?.split("-").map((el) => el) : [];

  const configs = {
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: "",
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: "",
      phone_number: "",
      name: "",
    },
    customizations: {
      title: "MOOYI",
      description: "Plan Upgrade",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const initializePayment = useFlutterwave(configs);

  const { mutate: paymentVerify } = useMutation(verifyPayment, {
    onSuccess: () => {
      closePaymentModal();
      Toast.success("plan purchase successful");
      navigate("/auth/email-sent");
      queryClient.invalidateQueries(["myPlan"]);
      queryClient.invalidateQueries(["getPlan"]);
      queryClient.invalidateQueries(["wsCredit"]);
      queryClient.invalidateQueries(["wsData"]);
    },
    onError: () => {
      Toast.error("Try again");
    },
  });

  const { mutate, isLoading } = useMutation(signup, {
    onSuccess: ({ data }) => {
      Toast.success("Signup Successful !");
      configs.customer.email = data?.email;
      configs.customer.phone_number = data?.phone;
      configs.customer.name = data?.firstname + " " + data?.lastname;
      configs.amount = data.paymentPayload.plan_price;
      if (pId === undefined) {
        navigate("/auth/email-sent");
      } else {
        initializePayment({
          callback: (response) => {
            const userData = {
              tx_ref: data.paymentPayload.tx_ref,
              transactionId: response?.transaction_id,
            };
            paymentVerify(userData);
          },
          onClose: () => {
            // routeFn();
          },
        });
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    trackEvent("create_account", { email: initialValues.email });
    const userData = {
      ...data,
      phone: `${data.phone === "" ? "" : `+234${data.phone}`}`,
      planId: priceId,
    };

    try {
      if (id) {
        mutate({
          payload: userData,
          invitationToken: id,
        });
      } else {
        mutate({
          payload: userData,
          invitationToken: undefined,
        });
      }

      setEmail(data.email);
    } catch (err) {
      // errorHandler(err, toast);
    }
  };

  const hidePassword = () => {
    setShowPassword(!showPassword);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    setFieldValue(name, numericValue);
  };
  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isLoading) {
    return <PreLoader text="Keep Calm" />;
  }

  return (
    <div className="w-[80%] mx-auto flex flex-col mb-2 justify-center h-[100%]">
      <h1 className="font-semibold text-[28px] leading-[32px]">Just two clicks away from your free trial!</h1>
      <p className="text-[#737373] mt-2 mb-2">
        Create a new account or &nbsp;
        <Link to="/auth/login" className="text-primary-800">
          log in
        </Link>
      </p>

      <form action="" onSubmit={handleSubmit}>
        <div className="space-y-2 mt-3">
          <div className="">
            <PrimaryInput
              type="email"
              name="email"
              placeholder=""
              onChange={handleChange}
              text="Email Address"
              onBlur={handleBlur}
              value={values.email}
              error={getError("email")}
            />
            <p className="text-ss text-neu-500">Your emails will be sent from this address</p>
          </div>
          <PrimaryInput
            type="text"
            placeholder="Enter first name"
            onChange={handleChange}
            text="First Name"
            name="firstname"
            onBlur={handleBlur}
            value={values.firstname}
            error={getError("firstname")}
          />

          <PrimaryInput
            type="text"
            placeholder="Enter last name"
            onChange={handleChange}
            text="Last Name"
            name="lastname"
            onBlur={handleBlur}
            value={values.lastname}
            error={getError("lastname")}
          />

          <PhoneNumberInput
            text="Phone Number"
            onChange={handleInputChange}
            value={values.phone}
            name="phone"
            setSelectedCode={setSelectedCode}
            selectedCode={selectedCode}
            error={getError("phone")}
          />

          <div className="relative">
            <PrimaryInput
              type={`${showPassword ? "text" : "password"}`}
              placeholder="8+ characters"
              onChange={handleChange}
              text="Password"
              name="password"
              onBlur={handleBlur}
              value={values.password}
              error={getError("password")}
            />

            <p
              className={`absolute select-none cursor-pointer ${showPassword ? "text-black" : "text-grey"} ${
                getError("password") ? "top-[30%] 2xl:top-[45%]" : "top-[47%] 2xl:[52%] "
              } mr-6 right-0 text-s font-[500]`}
              onClick={hidePassword}
            >
              {showPassword ? "Hide" : "Show"}
            </p>
          </div>
        </div>
        <div className="mt-2 flex space-x-1">
          <Checkbox
            name="promotion"
            value={values.promotion}
            onChange={(e) => {
              handleChange(e); // Formik handleChange
              const checked = e.target.checked;
              setFieldValue("promotion", checked); // Formik setFieldValue
            }}
          />
          <p className="text-ss -mt-[2px]">I don't want to receive promotional emails from Mooyi</p>
        </div>
        <p className="text-ss my-4">
          By clicking "one more step" below, you are creating a Mooyi account and you agree to Mooyi's&nbsp;
          <a
            href="https://mooyi.africa/terms-of-use"
            target="_blank"
            rel="noreferrer"
            className="text-primary-800 hover:underline hover:font-medium"
          >
            Terms of Use
          </a>{" "}
          and &nbsp;
          <a
            href="https://mooyi.africa/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="text-primary-800 hover:underline hover:font-medium"
          >
            Privacy Policy
          </a>
        </p>

        <IconButton
          type="submit"
          text="One more step"
          css="w-[100%] flex items-center justify-center font-[500]"
          icon={<HiArrowNarrowRight />}
        />
      </form>
    </div>
  );
}
