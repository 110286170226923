import React from "react";
import { FaTimes } from "react-icons/fa";
import Typography from "utils/typography";

const SideModal = ({ children, title, onClose }) => {
  return (
    <div className="backdrop-blur-[1px] bg-grey fixed top-[-20px] right-0 h-[100vh] w-full flex justify-end items-start z-[999] mt-0">
      <div className="bg-white text-[#292D32] rounded-[8px] h-full mt-10 py-5 ">
        <div className="flex items-center justify-between px-5">
          <Typography.Text>{title || null}</Typography.Text>
          <div
            className="text-[#737373] bg-[#ECECECB2] h-[30px] w-[30px] flex items-center justify-center rounded-full text-[20px] cursor-pointer"
            onClick={onClose}
          >
            <FaTimes className="w-[10px] " />
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default SideModal;
