import React from "react";
import { TiInfo } from "react-icons/ti";

const ImportContacts = () => {
  return (
    <div>
      <div className="border-[1px] p-4 rounded-[8px]  bg-gradient-to-r from-[#4568dc1c] to-[#b16ab311] flex space-x-2 my-4 mt-2">
        <TiInfo className="text-[#FBBF24] text-[24px]" />
        <div>
          <p className="text-[14px font-[600]">Important</p>

          <p className="text-s font-[400]">
            We do not send confirmation emails for uploaded contacts. Ensure you have obtained proper permission before
            adding them, and kindly adhere to guidelines to prevent misuse. If you have any questions, feel free to
            contact our support team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImportContacts;
