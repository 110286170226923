import { useMutation } from "@tanstack/react-query";
import BlueButton from "../../components/Button/BlueButton";
import SecondaryInput from "../../components/Inputs/SecondaryInput";
import PreLoader from "../../components/Loader/PreLoader";
import Modal from "../../components/Modal";
import { queryClient } from "../../config/gateway";
import Toast from "../../config/toast";
import { useFormik } from "formik";
import React from "react";
import { createTag } from "../../services/contactService";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name too short")
    .max(25, "Name too long (25 characters max)")
    .matches(/^\S*$/, "Name should not contain any whitespace")
    .required("Please fill in this field"),
});

const CreateTag = ({ toggleModal }) => {
  const initialValues = {
    name: "",
  };

  const { mutate, isLoading } = useMutation(createTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["allTags"]);
      toggleModal(2);
      Toast.success("Tag Created Successfully");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });
  const onSubmit = async (data) => {
    mutate(data);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isLoading) {
    return <PreLoader text="Creating your Tag" />;
  }
  return (
    <Modal title="Create Tag" onClose={toggleModal}>
      <div className="w-[400px] h-[250px] px-5">
        <p className="mb-2"> What should we name this tag?</p>
        <SecondaryInput
          text="Tag Name"
          placeholder="Enter tag name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          name="name"
          error={getError("name")}
          type="text"
        />
        <p className="text-ss">Examples like Influencers, leaders or creators.</p>

        <div className="flex justify-between my-4 mt-[3em]">
          <div></div>

          <div className="flex">
            <BlueButton text={"Create"} type="submit" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTag;
