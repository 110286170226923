import React from "react";
import NewLogo from "assets/images/newLogo.png";
import { FaTimes } from "react-icons/fa";
import CreationSuccess from "assets/images/creationSuccess.svg";
import { useNavigate } from "react-router-dom";
import GreyButton from "components/Button/GreyButton";

const SuccessPage = () => {
  const navigate = useNavigate();

  const routeFn = () => {
    navigate("/broadcasts/sms");
  };

  return (
    <>
      <div className="absolute h-[100vh] w-[100vw] left-0 right-0 bottom-0  z-50 bg-white flex justify-center items-center flex-col space-y-6">
        <img src={NewLogo} alt="NewLogo" className="w-[140px] my-6" />
        <div className="flex justify-between w-[50%]">
          <div></div>

          <FaTimes className="text-2xl text-gray-600 cursor-pointer" onClick={routeFn} />
        </div>

        <img src={CreationSuccess} alt="CreationSuccess" className="w-[250px]" />

        <p className="text-xl font-bold">Congratulations!</p>
        <p className="text-sm text-gray-600 w-[30%] mx-auto text-center">
          survey has been sent. Monitor progress on the dashboard and expect an email when response target number has
          been met.
        </p>

        <div className="flex items-center space-x-4">
          <GreyButton text="Done" onClick={routeFn} className="w-[160px]" />
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
