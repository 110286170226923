import React from "react";

export const ContactIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 8.25C12.1569 8.25 13.5 6.90685 13.5 5.25C13.5 3.59315 12.1569 2.25 10.5 2.25C8.84315 2.25 7.5 3.59315 7.5 5.25C7.5 6.90685 8.84315 8.25 10.5 8.25Z"
      fill={fill2}
    />
    <path
      d="M9 9.75C6.51472 9.75 4.5 11.7647 4.5 14.25C4.5 15.0785 5.17157 15.75 6 15.75H15C15.8285 15.75 16.5 15.0785 16.5 14.25V13.5C16.5 11.429 14.821 9.75 12.75 9.75H9Z"
      fill={fill2}
    />
    <path
      d="M5.25 9.75C3.17894 9.75 1.5 11.429 1.5 13.5V14.25C1.5 15.0785 2.17157 15.75 3 15.75H12C12.8285 15.75 13.5 15.0785 13.5 14.25V13.5C13.5 11.429 11.821 9.75 9.75 9.75H5.25Z"
      fill={fill1}
    />
    <path
      d="M7.5 8.25C9.15685 8.25 10.5 6.90685 10.5 5.25C10.5 3.59315 9.15685 2.25 7.5 2.25C5.84315 2.25 4.5 3.59315 4.5 5.25C4.5 6.90685 5.84315 8.25 7.5 8.25Z"
      fill={fill1}
    />
  </svg>
);
