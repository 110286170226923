import React from "react";
import OverviewCard from "./components/OverviewCard";
import totalSent from "assets/images/totalSent.png";
import completionRate from "assets/images/completionRate.png";
import dropOffRate from "assets/images/dropOffRate.png";
import RecentCards from "./components/RecentCards";
import Typography from "utils/typography";
import { useQuery } from "@tanstack/react-query";
import emptySurvey from "assets/images/emptySurveyIcon.png";
import RecentCardSkeleton from "./components/RecentCardSkeleton";
import useSurveyOverview from "hooks/useSurveyOverview";
import { getTopSurveys } from "services/newSurveyService";
import useFieldSurveys from "hooks/useFieldSurveys";
import EmptyState from "../components/EmptyState";
import { motion } from "framer-motion";
import { cardVariants } from "animation";

const FieldInterviewTab = () => {
  const { overviewData, fetchingOverview } = useSurveyOverview({
    key: "fieldInterviewOverview",
    surveyType: "field",
  });

  const { data: topData } = useQuery(["topfieldInterview"], () => getTopSurveys({ page: 1, surveyType: "field" }));

  const { newSurveyData, fetchActive, loadingActive } = useFieldSurveys({
    page: 1,
    surveyType: "offline_survey",
  });

  const newTopData = topData?.data;

  const onlineSummary = [
    {
      id: "fieldInterview1",
      name: "Total sent",
      image: totalSent,
      value: overviewData?.totalSent !== null ? overviewData?.totalSent : 0,
    },
    {
      id: "fieldInterview2",
      name: "Completion rate",
      image: completionRate,
      value: overviewData?.completionRate !== null ? `${overviewData?.completionRate}%` : `0%`,
    },
    {
      id: "fieldInterview3",
      name: "Drop-off rate",
      image: dropOffRate,
      value: overviewData?.totalDropOffRate !== null ? `${overviewData?.totalDropOffRate}%` : `0%`,
    },
  ];

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between space-x-4">
        {onlineSummary.map((elm) => (
          <OverviewCard key={elm.id} elm={elm} loading={fetchingOverview} />
        ))}
      </div>

      <div className="flex items-center justify-between space-x-6">
        <div className="bg-white rounded-lg p-4 border-[1px] w-[50%]">
          <Typography.Text className="pb-2">Recent interviews</Typography.Text>

          <div className="flex items-start justify-between">
            <div className="flex flex-col justify-center items-center">
              {[1, 2, 3, 4].map((el) => (
                <div className="flex flex-col justify-center items-center" key={`line1=${el}`}>
                  <div className="w-[10px] h-[10px] border-[2px] border-primary-500  rounded-full bg-primary-300"></div>

                  <div className="h-[88px] border-l-[1px]"></div>
                </div>
              ))}
            </div>

            <div className="space-y-4 w-[95%]">
              {loadingActive ? (
                <>
                  {[1, 2, 3, 4].map((el, i) => (
                    <motion.div
                      key={i}
                      custom={i}
                      initial="hidden"
                      animate="visible"
                      variants={cardVariants}
                      className="relative"
                    >
                      <RecentCardSkeleton key={el} />
                    </motion.div>
                  ))}
                </>
              ) : (
                <>
                  {newSurveyData?.length === 0 ? (
                    <EmptyState icon={emptySurvey} text="Your most recent interviews will be displayed here." />
                  ) : (
                    <>
                      {newSurveyData?.slice(0, 4).map((el, i) => (
                        <motion.div
                          key={i}
                          custom={i}
                          initial="hidden"
                          animate="visible"
                          variants={cardVariants}
                          className="relative"
                        >
                          <RecentCards
                            key={`RecentCards-${el.surveyId}`}
                            surveyName={el.surveyName}
                            surveyDate={el.date}
                            responses={el.responses}
                            expectedResponse={el.expectedResponse}
                            isLoading={loadingActive}
                            isFetching={fetchActive}
                            surveyType="Field Interview"
                          />
                        </motion.div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 border-[1px] w-[50%]">
          <Typography.Text className="pb-2">Top performing interviews</Typography.Text>

          <div className="flex items-start justify-between">
            <div className="flex flex-col justify-center items-center">
              {[1, 2, 3, 4].map((el) => (
                <div className="flex flex-col justify-center items-center" key={`line1=${el}`}>
                  <div className="w-[10px] h-[10px] border-[2px] border-primary-500  rounded-full bg-primary-300"></div>

                  <div className="h-[88px] border-l-[1px]"></div>
                </div>
              ))}
            </div>

            <div className="space-y-4 w-[95%] ">
              {loadingActive ? (
                <>
                  {[1, 2, 3, 4].map((el, i) => (
                    <motion.div
                      key={i}
                      custom={i}
                      initial="hidden"
                      animate="visible"
                      variants={cardVariants}
                      className="relative"
                    >
                      <RecentCardSkeleton key={el} />
                    </motion.div>
                  ))}
                </>
              ) : (
                <>
                  {newTopData?.length === 0 ? (
                    <EmptyState icon={emptySurvey} text="Your interviews with the most reach will be displayed here" />
                  ) : (
                    <>
                      {newTopData?.slice(0, 4).map((el, i) => (
                        <motion.div
                          key={i}
                          custom={i}
                          initial="hidden"
                          animate="visible"
                          variants={cardVariants}
                          className="relative"
                        >
                          <RecentCards
                            key={`RecentCards-${el.surveyId}`}
                            surveyName={el.surveyName}
                            surveyDate={el.publishedDate}
                            responses={el.response.count}
                            percentage={el.response.percentage}
                            expectedResponse={el.expectedResponse}
                            isLoading={loadingActive}
                            isFetching={fetchActive}
                            surveyType="Brand Tracking"
                          />
                        </motion.div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldInterviewTab;
