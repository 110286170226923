import React from "react";

const CopyAction = ({ fill1, fill2, fill3 }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" fill="#FAFAFA" />
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" stroke={fill1} stroke-width="0.8" />
    <path
      d="M21.5 13.25H15.5C14.2574 13.25 13.25 14.2574 13.25 15.5V21.5C13.25 22.7426 14.2574 23.75 15.5 23.75H21.5C22.7426 23.75 23.75 22.7426 23.75 21.5V15.5C23.75 14.2574 22.7426 13.25 21.5 13.25Z"
      fill={fill2}
    />
    <path
      d="M24.5 16.25H18.5C17.2574 16.25 16.25 17.2574 16.25 18.5V24.5C16.25 25.7426 17.2574 26.75 18.5 26.75H24.5C25.7426 26.75 26.75 25.7426 26.75 24.5V18.5C26.75 17.2574 25.7426 16.25 24.5 16.25Z"
      fill={fill3}
    />
  </svg>
);

export default CopyAction;
