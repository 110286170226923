// import { useQuery } from "@tanstack/react-query";
// import Toast from "config/toast";
import { formatDate } from "lib/formatDate";
import React, { useCallback, useEffect, useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
// import { getAllSegments } from "services/contactService";
import customEmpty from "assets/icons/contacts/CustomEmpty.png";
import EmptyState from "pages/Contacts/EmptyState";
import { BsSearch } from "react-icons/bs";
import { MessagingIcon } from "assets/icons/sidebar";
import { SmsIcon } from "assets/svgs/SmsIcon";
import { Tag } from "assets/icons/contacts/Tag";
import { Delete } from "assets/icons/contacts/Delete";
import { useContactStore } from "store/surveyStore/contactStates";
import EmailModal from "pages/Contacts/IndividualEmail/EmailModal";
import DeleteContact from "pages/Contacts/DeleteContact";
import SurveyDetailsModal from "pages/Dashboard/quickActionModals/SurveyDetailsModal";
import { SurveyIcon } from "assets/icons/sidebar";
import { GoArrowDown } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import { deleteSegments } from "services/contactService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";

const actionsIcons = [
  { id: 1, icon: MessagingIcon, label: "Email" },
  { id: 2, icon: SmsIcon, label: "SMS" },
  { id: 3, icon: SurveyIcon, label: "Surveys" },
  { id: 4, icon: Delete, label: "Delete" },
];

const Segments = ({ segment, isLoading, isFetching }) => {
  // const [sortBy, setSortBy] = useState("name");
  const navigate = useNavigate();
  const [selectSegmentArray, setSelectSegmentArray] = useState([]);
  const [selectSegmentObj, setSelectSegmentObj] = useState([]);
  const [tableData, setTableData] = useState();
  const [active, setActive] = useState(false);
  const [openModal, setopenModal] = useState(null);
  const [query, setQuery] = useState("");
  const { setContactsTabs } = useContactStore();
  const [hover, setHover] = useState(null);

  const handleHover = (id) => {
    setHover(hover === id ? null : id);
  };

  useEffect(() => {
    setContactsTabs({ segment: segment?.length });
  }, [setContactsTabs, segment?.length]);

  const { mutate: deleteSegment, isLoading: loadingSegmentDelete } = useMutation(deleteSegments, {
    onSuccess: () => {
      Toast.success("segment deleted successfully");
      queryClient.invalidateQueries(["allSegment"]);
      setopenModal(null);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const stringArray = JSON.stringify(selectSegmentObj);
  console.log(stringArray);

  const selectAll = (e) => {
    if (e.target.checked) {
      segment?.map((el) => selectSegmentArray.push(el.name));
      segment?.map((el) => selectSegmentObj.push(el));
      for (let i = 0; i < segment?.length; i++) {
        setSelectSegmentArray([...selectSegmentArray, segment[i]?.name]);
        setSelectSegmentObj([...selectSegmentObj, segment[i]]);
      }
    } else {
      setSelectSegmentArray([]);
    }
  };

  const checkArray = useCallback(() => {
    if (selectSegmentArray?.length > 0) {
      setActive(true);
    } else if (selectSegmentArray?.length <= 0) {
      setActive(false);
    }
  }, [selectSegmentArray?.length, setActive]);

  const checkInArray = (y) => {
    let checked = false;
    for (let x in selectSegmentArray) {
      if (selectSegmentArray[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  // const deleteSegment=()=>{
  //   deleteSegmentFunc()
  // }
  const handleSegmentDelete = () => {
    setActive(false);
    const payload = { name: selectSegmentArray };
    deleteSegment(payload);
  };

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectSegmentArray([...selectSegmentArray, x?.name]);
      setSelectSegmentObj([...selectSegmentObj, x]);
    } else {
      const filterArray = selectSegmentArray?.filter((el) => el !== x?.name);
      setSelectSegmentArray(filterArray);
    }
  };

  const handleModal = (id) => {
    id === 2 ? navigate("/broadcasts/sms/create") : setopenModal(openModal !== id && active ? id : null);
    localStorage.setItem("segmentData", stringArray);
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const searchFilter = useCallback(
    (searchTerm) => {
      const dataSet = segment?.filter((item) => {
        const content = item?.name?.toLowerCase();
        return content?.includes(searchTerm?.toLowerCase());
      });

      setTableData(dataSet);
    },
    [segment],
  );

  useEffect(() => {
    searchFilter(query);
  }, [query, searchFilter]);

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  const handleMore = ({ name, data }) => {
    console.log(data);
    navigate(`/contacts/segment/${name}`, { state: data });
  };

  const columns = [
    {
      field: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={(e) => selectAll(e)}
          />
        </p>
      ),
    },
    { field: "segmentName", header: "Segment name" },
    { field: "contactSize", header: "Contact size" },
    {
      field: "dateCreated",
      header: (
        <p className="flex items-center space-x-5">
          {" "}
          <span className="font-[700]">Date created</span> <GoArrowDown />
        </p>
      ),
    },
    {
      field: "lastUpdated",
      header: (
        <p className="flex items-center space-x-5 ">
          {" "}
          <span className="font-[700]">Last Updated</span> <GoArrowDown />
        </p>
      ),
    },
  ];

  const segmentData = tableData?.map((item, i) => ({
    inputProps: (
      <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0 z-[100]">
        <input
          type="checkbox"
          name="recipient"
          id="recipient"
          checked={checkInArray(item?.name, selectSegmentArray)}
          className="pl-0 w-[15px] h-[15px] accent-purple"
          onChange={(e) => handleCheck(e, item)}
        />
      </p>
    ),
    id: item._id,
    segmentName: (
      <p className="hover:underline cursor-pointer" onClick={() => handleMore({ name: item.name, data: item })}>
        {item.name}
      </p>
    ),
    contactSize: item.contactCount,
    dateCreated: formatDate(item.created_at),
    lastUpdated: item.lastUpdated,
  }));

  return (
    <div>
      <div className="p-4 flex items-center justify-between relative border-x">
        <div className="flex items-center space-x-2">
          <p>Actions:</p>
          <div className="flex items-center space-x-2">
            {actionsIcons.map((el, i) => {
              const IconComponent = el.icon;
              return (
                <div
                  onClick={() => handleModal(el.id)}
                  onMouseEnter={() => handleHover(el.id)}
                  onMouseLeave={() => setHover(null)}
                  className={`w-[40px] h-[40px] cursor-pointer rounded-[4px] border ${
                    active ? "border-primary-800" : ""
                  } flex items-center justify-center`}
                >
                  <IconComponent fill1={active ? "#95ADFF" : "#A3A3A3"} fill2={active ? "#1D19AF" : "#404040"} />
                  {hover === el.id ? (
                    <p
                      className={`bg-white drop-shadow-md text-[12px] ${
                        active ? "text-primary-800" : ""
                      } py-1 px-2 top-12 rounded absolute`}
                    >
                      {el.label}
                    </p>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search segments... "
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <table className="w-[100%] mb-4 border-b-[1px] bg-white">
        <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
          <tr className="h-[50px] border-r-[1px] rounded-[2px] overflow-hidden">
            {columns &&
              columns.map((head, i) => (
                <th key={i} className="px-2 text-left border-l-[1px]">
                  {head.header}
                </th>
              ))}
          </tr>
        </thead>
        {segmentData?.length === 0 || segmentData?.length === undefined ? null : (
          <tbody>
            {isLoading || isFetching || loadingSegmentDelete ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                  <tr
                    key={i}
                    className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px]   "
                  >
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-2 border-l-[1px] relative">
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <>
                {segmentData &&
                  segmentData?.map((row, i) => (
                    <tr
                      className="h-[50px] text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] hover:bg-slate-50 cursor-pointer"
                      key={i}
                    >
                      {columns?.map((col, j) => (
                        <td key={j} className="px-4 border-l-[1px] relative">
                          {row[col.field] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        )}
      </table>
      {segmentData?.length === 0 || segmentData?.length === undefined ? (
        <tbody className="flex justify-center w-full mt-20">
          <EmptyState
            title="No saved segments"
            icon={customEmpty}
            content={
              <p>
                Segments provide a filtered view into your contacts. <br /> Click the 'Create segment' button to get
                started.
              </p>
            }
            link="/contacts/segment/create"
            button="Create new segment"
          />
        </tbody>
      ) : null}
      {openModal === 1 ? <EmailModal toggleModal={handleModal} data={selectSegmentArray} /> : null}
      {openModal === 3 ? <SurveyDetailsModal handleOpen={handleModal} /> : null}

      {openModal === 4 ? (
        <DeleteContact
          toggleModal={handleModal}
          deleteContactsFunc={handleSegmentDelete}
          title="Delete segment(s)"
          content="Are you sure you want to delete? This action cannot be undone."
        />
      ) : null}
    </div>
  );
};

export default Segments;
