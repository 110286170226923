import SecondaryInput from "components/Inputs/SecondaryInput";
import SelectInput from "components/Inputs/SelectInput";
import React, { useState } from "react";
import { Tooltip } from "recharts";
// import { debounce } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { getAllEmailId } from "services/senderIdService";
import Audience from "./Audience";
import Dropdown from "components/DropDown";
import SavedContacts from "pages/Broadcasts/Contacts/SavedContacts";
import { useLocation } from "react-router-dom";

const option1 = [{ value: "", label: "Loading..." }];

// const debounceHandleContent = debounce((value, setText, setPersonalizationArray) => {
//   setText(value);

//   // This function is to return the literals in the text into an array
//   setPersonalizationArray(value?.match(/{{(.*?)}}/g) === null ? [] : value?.match(/{{(.*?)}}/g));
// }, 300);

const Content = ({ broadcastData, setBroadcastData, data, setData }) => {
  // const [personalizationArray, setPersonalizationArray] = useState([]);
  // const [open, setOpen] = useState(false)

  const { data: email, isLoading: emailLoading } = useQuery(["emailSenderIDs"], getAllEmailId);
  const options = email?.map((el) => ({
    value: `${el?.name}${" "}${el?.email}`,
    label: <>{`${el?.name}<${el?.email}>`}</>,
  }));

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const arrayString = params.get("array");

  console.log(arrayString);

  console.log(location);

  const handleChange = (e) => {
    setBroadcastData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const objectives = [
    { label: "Educational", value: "Educational" },
    { label: "Promotional", value: "Promotional" },
    { label: "Reminder", value: "Reminder" },
  ];

  // Clean up the debounce function when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     debounceHandleContent.cancel();
  //   };
  // }, []);

  // const handlePreview = () => {
  //   setData({
  //     ...data,
  //     testMessage: text,
  //   });
  // };

  const handleDropdownSelect = (e) => {
    setBroadcastData({
      ...broadcastData,
      campaignObjective: e,
    });
  };

  const handleSelect = (e) => {
    let x = e.target.value;
    var values = x.split(" ");
    var email = values[values.length - 1];
    values.pop();
    var name = values
      .toString()
      .replace(",", " ")
      .toString()
      .replace(",", " ")
      .toString()
      .replace(",", " ")
      .toString()
      .replace(",", " ");
    setBroadcastData({
      ...broadcastData,
      senderEmail: email,
      senderName: name.toString().replace(",", " "),
    });
  };

  console.log(broadcastData?.campaignObjective);

  return (
    <div className="flex w-[100%] gap-[3em] items-start">
      <div className="w-[100%] min-h-[70vh] p-4 space-y-[1.5em]">
        <div className="border-[1px] p-4 rounded-lg">
          <p className="font-semibold">Email details</p>
          <p className="text-sm">Setup your email details.</p>

          <div className="my-4 gap-[2em] grid grid-cols-2">
            <SecondaryInput
              text="Email subject"
              placeholder="Enter email subject"
              onChange={handleChange}
              name="campaignName"
              type="text"
              value={broadcastData.campaignName}
            />
            <div>
              <div className="flex space-x-2 items-center">
                <p className="text-sm font-medium">Email Sender ID</p>
                <Tooltip text="This will appear as the sender name in your audience's inbox, e.g. Muyiwa from Mooyi." />
              </div>
              <SelectInput
                options={
                  emailLoading
                    ? option1
                    : [
                        {
                          label: "Select a Sender ID",
                          value: "",
                        },
                        ...options,
                      ]
                }
                disabled={emailLoading ? true : false}
                css="h-[40px] rounded-[8px]"
                name="senderName"
                onChange={handleSelect}
              />
            </div>
            <div className="w-full">
              <div className="flex space-x-2 items-center ">
                <p className="text-m font-[500]">Objective</p>
              </div>
              <div className="mt-2">
                <Dropdown
                  tag={
                    broadcastData?.campaignObjective !== ""
                      ? broadcastData?.campaignObjective
                      : "What is the aim of the message?"
                  }
                  options={objectives}
                  onSelect={(el) => handleDropdownSelect(el.value)}
                  defaultValue={broadcastData.campaignObjective}
                />
              </div>
            </div>
            {/* <SecondaryInput
              text="Campaign Name"
              placeholder="Welcome Discount"
              onChange={handleChange}
              name="campaignName"
              type="text"
              value={broadcastData.campaignName}
            /> */}
          </div>
        </div>

        <div className="border-[1px] p-4 rounded-lg">
          <div className="w-[50%]">
            <div className="mb-5">
              <p className="text-base font-medium">Add your audience</p>
              <p className="text-sm text-gray-700"> Select the contact to share your message with.</p>
            </div>
            <SavedContacts data={broadcastData} setData={setBroadcastData} />
            {/* <Audience data={broadcastData} setData={setBroadcastData} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
