import { create } from "zustand";
import useSurveyStore from "./insightStore";

const usePageStore = create((set) => ({
  page: -1,

  // Action to set the page
  setPage: (newPage) => set({ page: newPage }),

  // Action to increment the page
  incrementPage: () =>
    set((state) => {
      const surveyQuestionsArray = useSurveyStore.getState().surveyQuestionsArray;
      return {
        page: Math.min(state.page + 1, surveyQuestionsArray.length - 1),
      };
    }),

  // Action to decrement the page
  decrementPage: () =>
    set((state) => ({
      page: state.page - 1,
    })),
}));

export default usePageStore;
