import React from "react";

const IconButton = ({ text, onClick, type, disabled, icon, css, leftAlign }) => {
  return (
    <button
      className={` text-white text-s rounded-[4px] px-6 flex items-center space-x-2 font-[450] ${css} h-[40px]  ${
        disabled ? "bg-primary-300 text-white" : "bg-primary-800 hover:shadow-md hover:bg-primary-600"
      }`}
      onClick={disabled ? null : onClick}
      type={type}
      disabled={disabled || false}
    >
      {leftAlign ? <div className="mr-2">{icon}</div> : null}
      <span className="font-[500]">{text}</span>

      {leftAlign ? null : <div className="ml-2">{icon}</div>}
    </button>
  );
};

export default IconButton;
