import Modal from "components/Modal";
import { surveyTemplates } from "data/surveyTemplates";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useStore } from "store";
import Coin from "assets/images/coin.png";
import Logo from "assets/images/newLogo.png";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import useSurveyStore from "store/insightStore";
// import usePageStore from "store/usePageStore";
import dayjs from "dayjs";

const CreateSurveyModal = ({ handleModal, handleTemplatePreview }) => {
  const { user } = useLoggedInUser();
  const { setSurveyTemplate } = useStore();
  const { surveyQuestionsArray, setSurveyQuestionsArray } = useSurveyStore();
  const retrieveTemplate = (id) => {
    const foundTemplate = surveyTemplates.find((el) => el.id === id);

    setSurveyTemplate(foundTemplate);
    handleModal(3);

    trackEvent("survey template selected", {
      email: user?.data?.email,
      templateName: foundTemplate?.surveyName,
      date: dayjs().format(),
      surveyType: "Online survey",
    });
  };

  const handlePreview = (id) => {
    const foundTemplate = surveyTemplates.find((el) => el.id === id);
    setSurveyQuestionsArray(foundTemplate?.questions);
    setSurveyTemplate(foundTemplate);
    handleTemplatePreview(foundTemplate.id);
  };

  console.log(surveyQuestionsArray);

  const handleBlankSurvey = () => {
    handleModal(3);
    setSurveyTemplate({
      surveyName: "",
      surveyCategory: "",
      surveyDescription: "",
      coverImage: "",
      reward: 0,
      campaignType: "",
      bg: "bg-bgOne",
      questions: [],
    });
  };
  return (
    <Modal title="Create a survey" onClose={() => handleModal(2)}>
      <div className="w-[870px] px-5">
        <p className="">Start survey from scratch or choose from our available templates to begin.</p>

        <div className="mt-[1.5em] grid grid-cols-3 gap-[1em] max-h-[60vh] overflow-y-scroll">
          {surveyTemplates.map((el, i) => {
            if (el.id === 0) {
              return (
                <div
                  className="bg-[#FAFAFA] rounded-md w-[260px] h-[250px] text-center flex flex-col justify-center items-center space-y-3 cursor-pointer"
                  key={`templates${el.id}`}
                  onClick={handleBlankSurvey}
                >
                  <div className="border-[1px] border-dashed rounded-md border-black w-[130px] h-[130px] flex items-center justify-center mx-auto bg-white mb-3">
                    <div className="bg-primary-50 w-[54px] h-[54px] flex items-center justify-center rounded-full text-[24px]">
                      <FaPlus className="text-primary-800" />
                    </div>
                  </div>

                  <p className="text-m font-medium">Blank template</p>
                  <p className="text-ss w-[70%]">Create your survey from the ground up.</p>
                </div>
              );
            } else {
              return (
                <div className={`w-[260px] shadow-md overflow-hidden rounded-md h-[250px]`} key={`temp${i}`}>
                  <div className="w-[100%] h-[88px] overflow-hidden">
                    <img src={el.coverImage} alt="lol" className="w-[100%] h-[100%] object contain" />
                  </div>

                  <div className="my-[0.7em] rounded-md p-2">
                    <p className="text-[14px] font-semibold">{el.surveyName}</p>
                    {/* <div className="flex items-center space-x-2 text-ss">
                      <div className="">
                        <img src={Coin} alt="Coin" className="w-[10px]" />
                      </div>
                      <p className="text-[8px]">
                        <span className="text-[#6C6685]">Reward: </span>
                        {el.reward}
                        <span> Credits. </span>
                      </p>
                    </div>
                    <p className="text-[8px]">Estimated time: 2-5 minutes</p> */}
                    <p className="text-[12px] pt-1">
                      {el.surveySubTitle}
                      {/* {el.surveyDescription.length > 75 ? "..." : ""} */}
                    </p>
                  </div>
                  <div className="my-3">
                    <hr />
                  </div>
                  <div className="flex items-center justify-center space-x-5 text-[14px]">
                    <div onClick={() => handlePreview(el.id)} className="cursor-pointer">
                      <p className="text-primary-800 font-[600]">Preview</p>
                    </div>
                    <div>
                      <p
                        className="px-5 py-2 bg-primary-100 rounded-[4px] flex items-center justify-center text-primary-800 font-[600] cursor-pointer"
                        onClick={() => retrieveTemplate(el.id)}
                      >
                        Use template
                      </p>
                    </div>
                  </div>

                  {/* <div className="bg-white rounded-md p-2">
                    <p className="text-[10px] font-semibold">Description</p>
                    <p className="text-[8px]">
                      {el.surveyDescription.substring(0, 150)}
                      {el.surveyDescription.length > 150 ? "..." : ""}
                    </p>
                  </div> */}
                </div>
              );
            }
          })}
        </div>
      </div>
    </Modal>
  );
};

export default CreateSurveyModal;
