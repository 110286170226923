import { handleApiError } from "utils/handleApiError";
import Api from "utils/axios";

export const fetchUser = async () => {
  try {
    const { data } = await Api.get("/auth-microservice/user");
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchPlan = async () => {
  try {
    const { data } = await Api.get("/plans/my-plan");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchAllPlans = async () => {
  try {
    const { data } = await Api.get("/plans/plans");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchAPlan = async (id) => {
  try {
    const { data } = await Api.get(`/plans/plans/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const upgradePlan = async (payload) => {
  try {
    const { data } = await Api.post(`/plans/payment-payload`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const approvePlan = async (payload) => {
  try {
    const { data } = await Api.post(`/plans/verify-payment`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateUser = async (payload) => {
  try {
    const { data } = await Api.put("/auth-microservice/user/update-profile", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateBusiness = async (payload) => {
  try {
    const { data } = await Api.patch("/auth-microservice/user/business-update", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateInterest = async (payload) => {
  try {
    const { data } = await Api.put("/auth-microservice/user/goals-update", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const changePassword = async (payload) => {
  try {
    const { data } = await Api.put("/auth-microservice/user/change-password", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const addNewUser = async (payload) => {
  try {
    const { data } = await Api.put("/workspace-microservice/workspace-invite", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteAUser = async (email) => {
  try {
    const { data } = await Api.put(`/workspace-microservice/workspace/delete/${email}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchDashboard = async () => {
  try {
    const { data } = await Api.get("/dashboard");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteAccount = async () => {
  try {
    const { data } = await Api.get(`/auth-microservice/user/delete-account`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const deactivateAccount = async () => {
  try {
    const { data } = await Api.get(`/workspace-microservice/workspace/deactivate`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const onboardingProgress = async () => {
  try {
    const { data } = await Api.get(`/onboarding`);
    return data.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAccountLegder = async (batch) => {
  try {
    const { data } = await Api.get(`/plans/ledger/view?batch=${batch}`);
    return data.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const unSubscribe = async (payload) => {
  try {
    const { data } = await Api.post(`/contact/unsubscribe`, payload);
    return data.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
