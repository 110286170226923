import Toast from "config/toast";
import { handleApiError } from "utils/handleApiError";
import axios from "axios";
import React, { useState } from "react";
import fileupload_icon from "assets/icons/broadcast/fileIcon.png";
import blueVoiceIcon from "assets/icons/broadcast/blueVoiceIcon.png";
import audioLoading from "assets/icons/broadcast/audioLoading.png";
import Api from "utils/axios";
import { queryClient } from "config/gateway";
import { formatFileSize } from "lib/formatFileSize";
import { HiMiniTrash } from "react-icons/hi2";

const MediaUpload = ({ setData, data, name, handleClear }) => {
  const [uploading, setUploading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [file, setFile] = useState({ name: "", size: 0 });

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const allowedTypes = ["mp3", "mpeg"];
      const typeCheck = e.target.files[0].type.split("/")[1].toLocaleLowerCase();
      console.log(typeCheck);
      if (!e.target.files || e.target.files.length === 0) {
        Toast.error("Please select only one image");
      } else if (!allowedTypes.includes(typeCheck)) {
        Toast.error("invalid format, please upload mp3");
      } else {
        const imageFile = new FormData();
        imageFile.append("file", e.target.files[0]);
        // imageFile.append("target", "resource");
        for (const entry of imageFile.entries()) {
          console.log(entry);
          if (entry[0] === "file") {
            const fileValue = entry[1];
            setFile({ name: fileValue.name, size: fileValue.size });
            break;
          }
        }
        setUploading(true);

        let progress = 0;

        try {
          const { data: newData } = await Api.post(`/workspace-microservice/media/public-upload`, imageFile, {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total !== undefined) {
                progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setPercent(progress);
              } else {
                setUploading(false);
              }
            },
          });
          setUploading(false);
          // setData(data?.data[0]?.file_name);
          setData({
            ...data,
            [name]: newData?.data.file,
          });

          queryClient.invalidateQueries({ queryKey: ["resource"] });
        } catch (error) {
          if (axios.isCancel(error)) {
            Toast.error("Upload canceled");
          } else {
            throw Toast.error(handleApiError(error));
          }
        }
      }
    } else return null;
  };

  return (
    <div>
      {uploading ? (
        <div>
          <div className="w-full rounded-xl bg-white border p-5">
            <div className="w-full flex justify-center">
              <img src={audioLoading} alt="" className="w-[32px] h-[32px]" />
            </div>
            <div className="flex space-x-2 items-center mt-2 ">
              <div className="bg-gray-200 h-[5px] rounded-full w-[100%]">
                <div
                  className={`${percent === 100 ? "bg-[#16A34A]" : "bg-primary-600"} rounded-full h-[100%]`}
                  style={{ width: `${percent}%` }}
                ></div>
              </div>
              {percent === 100 ? (
                <p className="text-xs text-grey-200">complete</p>
              ) : (
                <p className="text-xs text-grey-200">{percent}%</p>
              )}
            </div>

            <div className="flex items-center justify-center">
              <div className="flex items-center space-x-2">
                <span className="w-[32px] h-[32px] p-1 rounded-full items-center justify-center bg-[#EBF1FF]">
                  <img src={fileupload_icon} alt="" />
                </span>
                <p className="text-xs w-[70%] truncate">{file?.name}</p>
                <p className="text-xs">{formatFileSize(file?.size)}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {data === "" && !uploading ? (
            <div className="flex items-center justify-center w-full border-2 border-dotted rounded-md">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[95px] border border-gray-100 rounded-lg cursor-pointer bg-transparent"
              >
                <div className="flex flex-col items-center justify-center">
                  <svg
                    className="mb-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                  >
                    <path
                      d="M11.4 12.8H14.6V6.40002H19.4L13 0L6.59997 6.40002H11.4V12.8ZM16.2 9.20003V11.6672L23.5264 14.4L13 18.3249L2.47356 14.4L9.79998 11.6672V9.20003L0.199951 12.8V19.2001L13 24.0001L25.8 19.2001V12.8L16.2 9.20003Z"
                      fill="#78797F"
                    />
                  </svg>
                  <p className="text-xs text-gray-500">
                    Drag and drop mp3 file or <span className="text-primary-600 underline">browse computer </span>{" "}
                  </p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={onSelectFile} />
              </label>
            </div>
          ) : (
            <div>
              <div className="py-5">
                <div className="border rounded-[8px] p-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2 w-[70%]">
                      <img src={blueVoiceIcon} alt="" className="w-[28px] h-[28px]" />
                      <div className="text-xs">
                        <p className="truncate w-[70%]">{file.name}</p>
                        <p>{formatFileSize(file.size)}</p>
                      </div>
                    </div>
                    <div
                      className="bg-gray-400 rounded-full w-[24px] h-[24px] p-2 flex items-center justify-center cursor-pointer"
                      onClick={handleClear}
                    >
                      <p className="text-white">
                        <HiMiniTrash />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MediaUpload;
