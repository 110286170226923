import React from "react";

const GreyButton = ({ text, onClick, type, disabled, className }) => {
  return (
    <button
      className={`${className} ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } text-s  rounded-[4px] h-[40px] px-6 text-primary-800 hover:text-primary-600 hover:bg-primary-100  font-semibold bg-primary-50  `}
      onClick={disabled ? null : onClick}
      type={type}
      disabled={disabled || false}
    >
      {text}
    </button>
  );
};

export default GreyButton;
