import React from "react";

export const Messaging = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 3H3.75C2.50736 3 1.5 4.00736 1.5 5.25V12.75C1.5 13.9926 2.50736 15 3.75 15H14.25C15.4926 15 16.5 13.9926 16.5 12.75V5.25C16.5 4.00736 15.4926 3 14.25 3Z"
      fill={fill1}
    />
    <path
      d="M8.1825 9.21863L1.5 4.875C1.5 3.83947 2.33947 3 3.375 3H14.625C15.6605 3 16.5 3.83947 16.5 4.875L9.8175 9.21863C9.3204 9.54173 8.6796 9.54173 8.1825 9.21863Z"
      fill={fill2}
    />
  </svg>
);
