import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import SecondaryInput from "components/Inputs/SecondaryInput";
import PreLoader from "components/Loader/PreLoader";
import Modal from "components/Modal";
import { queryClient } from "config/gateway";
import { useFormik } from "formik";
import { RiArrowDownSLine } from "react-icons/ri";
import { createWorkspace } from "services/workspaceService";
import * as Yup from "yup";
import BlueButton from "components/Button/BlueButton";
import Toast from "config/toast";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";

const options = [
  { id: 1, color: "bg-[#3505e3]", name: "Blue", code: "3505e3" },
  { id: 2, color: "bg-[#00DC76]", name: "Green", code: "00DC76" },
  { id: 3, color: "bg-[#a8e305]", name: "Yellow", code: "a8e305" },
  { id: 4, color: "bg-[#dc6e00]", name: "Orange", code: "dc6e00" },
  { id: 5, color: "bg-[#000000]", name: "Black", code: "000000" },
];

const initialValues = {
  name: "",
};

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Please enter a organisation name")
    .max(11, "Workspace name cannot be more than 11 characters"),
});

const WorkSpaceModal = ({ toggleModal }) => {
  const [presentColor, setPresentColor] = useState("dc6e00");
  const [dropDown, setDropDown] = useState(false);
  const { user } = useLoggedInUser();

  const { mutate, isLoading } = useMutation(createWorkspace, {
    onSuccess: () => {
      queryClient.invalidateQueries(["allWorkspaces"]);
      toggleModal();
      trackEvent("create workspace successful", {
        email: user?.data?.email,
        date: dayjs().format(),
      });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleSelection = () => {
    setDropDown(!dropDown);
  };

  const handleColor = (x) => {
    setPresentColor(x);
    setDropDown(!dropDown);
  };

  const onSubmit = async (data) => {
    trackEvent("create workspace ", {
      email: user?.data?.email,
      date: dayjs().format(),
    });
    const userData = {
      colour: presentColor,
      ...data,
    };
    mutate(userData);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <Modal title="Create a Workspace" onClose={toggleModal}>
      <form className="w-[400px] h-[280px] px-5 pt-6" onSubmit={handleSubmit}>
        <SecondaryInput
          text="Workspace name"
          placeholder="Enter a name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          error={getError("name")}
        />
        {/* <p className="text-[10px]"></p> */}

        <div className="flex items-center mt-4 ">
          <p className="font-semibold text-[18px] mr-2">Color:</p>

          <div className="bg-[#F5F5F5] border-[1px] border-[#E5E5E5] p-2 rounded-[5px]  relative">
            <div className="flex justify-between w-[60px]" onClick={handleSelection}>
              <div className={`h-[20px] w-[20px] bg-[#${presentColor}] rounded-full`}></div>

              <RiArrowDownSLine />
            </div>

            {dropDown && (
              <div className="w-[100px] absolute top-[3em] bg-white shadow-primary left-0 rounded-[5px]">
                {options.map((el) => {
                  return (
                    <div
                      key={el.code}
                      className="flex h-[40px] items-center pl-2 cursor-pointer"
                      onClick={() => handleColor(el.code)}
                    >
                      <div className={`h-[20px] w-[20px] bg-[#${el.code}] rounded-full mr-4`}></div>
                      <p className="text-s">{el.name}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <div></div>
          <BlueButton text="Create" type="submit" disabled={values.name.length > 11} />
        </div>
      </form>
    </Modal>
  );
};

export default WorkSpaceModal;
