import { toTitleCase } from "lib/titleCase";
import React from "react";

const Preview = ({ campDetails }) => {
  return (
    <div>
      <p className="font-semibold">Preview</p>
      <p className="text-gray-500 text-sm">Confirm the details of the email before publishing.</p>

      <div className="my-4 border-[1px] p-6 rounded-xl">
        <p className="text-sm font-semibold">Audience</p>

        <div className="flex items-center justify-between text-sm text-gray-600 h-[45px] border-b-[1px]">
          <p>Total units</p>

          <p className="text-primary-800 font-[700] text-[16px]">{campDetails?.campaignCost}</p>
        </div>

        <div className="flex items-center justify-between text-sm text-gray-600 h-[45px] border-b-[1px]">
          <p>Tags</p>
          <p className="space-x-2 flex items-center">
            {campDetails?.audience?.map((el) => {
              return (
                <div className="text-[12px] p-2 bg-gray-200 rounded-md" key={el}>
                  {el}
                </div>
              );
            })}
          </p>
        </div>

        <div className="mt-4">
          <p className="text-sm font-semibold">Details</p>

          <div className="flex items-center justify-between text-sm text-gray-600 h-[45px] border-b-[1px]">
            <p>Subject</p>

            <p>{campDetails?.campaign_subject}</p>
          </div>

          <div className="flex items-center justify-between text-sm text-gray-600 h-[45px] border-b-[1px]">
            <p>Objective</p>
            <p>{toTitleCase(campDetails?.campaignObjective)}</p>
          </div>

          <div className="flex items-center justify-between text-sm text-gray-600 h-[45px]">
            <p>Broadcast type</p>
            <p>Email</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
