const BACKEND_URLS = {
  SURVEY: {
    OVERVIEW: "survey-microservice/survey/overview",
    ALL_SURVEY: "survey-microservice/survey/all-surveys",

    CREATE_DETAILS: "survey-microservice/survey/create-details",
    CREATE_TARGET: "survey-microservice/survey/create-targetopt",
    CREATE_QUESTIONS: "survey-microservice/survey/create-questions",
    PUBLISH_SURVEY: "survey-microservice/survey/publish",
    FETCH_BY_SURVEY_ID: "survey-microservice/survey/single",
    FETCH_BY_PUBLISH_ID: "survey-microservice/survey/retrieve",
    GET_SUMMARY: "survey-microservice/survey/response/summary",
    GET_TOP_PERFORMING: "survey-microservice/survey/top-performing",
    GET_BRAND_SUMMARY: "survey-microservice/survey/brand/response/summary",
    DELETE_SURVEY: "survey-microservice/survey/delete",
    CLOSE_SURVEY: "survey-microservice/survey/close",
    GET_DEMOGRAPHICS: "survey-microservice/survey/analytics/demographics",
    GET_SINGLE_OVERVIEW: "survey-microservice/survey/single-overview",
    FETCH_RESPONSES: "survey-microservice/survey/response/fetch-response",
    DUPLICATE_SURVEY: "survey-microservice/survey/duplicate",
    GET_CSV: "survey-microservice/survey/produce-csv",
    SAVE_AUDIENCE: "/survey-microservice/survey/save-audience",
    FETCH_SAVED_AUDIENCE: "/survey-microservice/survey/fetch-saved-audience",
    // SAVE_AND_CONTINUE:"campaign/draft?campaignId&shouldPublish=false"
  },
  surveyRes: {
    reports: "survey-microservice/analytics",
    question: "survey-microservice/questions/",
    tracker: "survey-microservice/track/",
    respond: "survey-microservice/respond/",
    verifyContact: "survey-microservice/reward-auth/",
    preClaim: "survey-microservice/pre-reward/",
    claimReward: "survey-microservice/reward/",
    screening: "survey-microservice/verify-screening-answer",
    recentSurveys: "activities",
  },
  fieldSurveys: {
    allSurveys: "survey-microservice/field-survey/all",
    responses: "survey-microservice/field-survey/responses",
    singleResponse: "/survey-microservice/field-survey/responses",
  },
  location: {
    getCountry: "/countries/",
    getState: "/country/states",
    getCity: "/state/cities",
    getCities: "/counties",
  },
};

export default BACKEND_URLS;
