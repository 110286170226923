import { useEffect, useRef } from "react";

const useOutsideClick = (onOutsideClick) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onOutsideClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onOutsideClick]);

  return ref;
};

export default useOutsideClick;
