import React from "react";

const BlueButton = ({ text, onClick, type, disabled, className, loading }) => {
  return (
    <button
      className={`rounded-[4px] h-[40px] px-8 text-sm font-bold ${loading ? "bg-primary-300" : ""} ${
        disabled
          ? "bg-primary-300 text-white cursor-not-allowed"
          : "bg-primary-800 hover:shadow-activeShadow hover:bg-primary-600  text-white "
      } ${className}`}
      onClick={disabled ? null : onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default BlueButton;
