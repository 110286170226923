import Api from "utils/axios";
import BACKEND_URLS from "utils/backendUrls";
import { handleApiError } from "utils/handleApiError";

export const getSurveyOverview = async ({ surveyType }) => {
  try {
    const { data } = await Api.get(
      `${BACKEND_URLS.SURVEY.OVERVIEW}${surveyType !== null && surveyType !== "" ? `?surveyType=${surveyType}` : ""}`,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllSurveys = async ({ page, status, surveyType }) => {
  try {
    const { data } = await Api.get(
      `${BACKEND_URLS.SURVEY.ALL_SURVEY}?batch=${page}${
        surveyType !== null && surveyType !== "" ? `&surveyType=${surveyType}` : ""
      }${status !== null && status !== "" ? `&surveyStatus=${status}` : ""}`,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getTopSurveys = async ({ page, surveyType }) => {
  console.log({ page, surveyType });
  try {
    const { data } = await Api.get(
      `${BACKEND_URLS.SURVEY.GET_TOP_PERFORMING}?batch=${page}${
        surveyType !== null && surveyType !== "" ? `&surveyType=${surveyType}` : ""
      }`,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createSurveyDetails = async ({ surveyId, payload, surveyType }) => {
  const url =
    surveyId === undefined
      ? `${BACKEND_URLS.SURVEY.CREATE_DETAILS}${
          surveyType !== null || surveyType !== "" ? `?surveyType=${surveyType}` : "?surveyType=onlineSurvey"
        }`
      : `${BACKEND_URLS.SURVEY.CREATE_DETAILS}${surveyId !== null && surveyId !== "" ? `?surveyId=${surveyId}` : ""}${
          surveyType !== null || surveyType !== "" ? `&surveyType=${surveyType}` : "&surveyType=onlineSurvey"
        }`;
  try {
    const { data } = await Api.post(url, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createBrandedSurveyDetails = async ({ surveyId, payload, surveyType }) => {
  const firstURL = `${BACKEND_URLS.SURVEY.CREATE_DETAILS}?surveyId=${surveyId}&surveyType=${surveyType}`;
  const secondURL = `${BACKEND_URLS.SURVEY.CREATE_DETAILS}?surveyType=${surveyType}`;

  const url = surveyId !== null && surveyId !== "" ? firstURL : secondURL;

  try {
    const { data } = await Api.post(url, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createSurvey = async ({ surveyId, payload, surveyType }) => {
  const firstURL = `${BACKEND_URLS.SURVEY.CREATE_DETAILS}?surveyId=${surveyId}&surveyType=${surveyType}`;
  const secondURL = `${BACKEND_URLS.SURVEY.CREATE_DETAILS}?surveyType=${surveyType}`;

  const url = surveyId !== null && surveyId !== "" ? firstURL : secondURL;

  try {
    const { data } = await Api.post(url, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createSurveyTarget = async ({ surveyId, payload }) => {
  try {
    const { data } = await Api.post(`${BACKEND_URLS.SURVEY.CREATE_TARGET}/${surveyId}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createSurveyQuestions = async ({ surveyId, payload }) => {
  try {
    const { data } = await Api.post(`${BACKEND_URLS.SURVEY.CREATE_QUESTIONS}/${surveyId}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const publishSurvey = async ({ surveyId, payload }) => {
  try {
    const { data } = await Api.post(`${BACKEND_URLS.SURVEY.PUBLISH_SURVEY}/${surveyId}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchBySurveyId = async (surveyId) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.SURVEY.FETCH_BY_SURVEY_ID}/${surveyId}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const duplicateBySurveyId = async (surveyId) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.SURVEY.DUPLICATE_SURVEY}/${surveyId}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchByPublishId = async (publishId) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.SURVEY.FETCH_BY_PUBLISH_ID}/${publishId}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchSummary = async ({ payload }) => {
  try {
    const { data } = await Api.post(`${BACKEND_URLS.SURVEY.GET_SUMMARY}`, payload);

    console.log(data, "first");
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchBrandSummary = async (payload) => {
  try {
    const { data } = await Api.post(`${BACKEND_URLS.SURVEY.GET_BRAND_SUMMARY}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchSingleOverview = async (surveyId) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.SURVEY.GET_SINGLE_OVERVIEW}?surveyId=${surveyId}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchCSV = async (surveyId) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.SURVEY.GET_CSV}/${surveyId}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchResponse = async ({ surveyId, batch }) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.SURVEY.FETCH_RESPONSES}/${surveyId}?batch=${batch}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchDemoGraphics = async (payload) => {
  try {
    const { data } = await Api.post(`${BACKEND_URLS.SURVEY.GET_DEMOGRAPHICS}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteSurvey = async (payload) => {
  try {
    const { data } = await Api.patch(`${BACKEND_URLS.SURVEY.DELETE_SURVEY}`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const closeSurvey = async (payload) => {
  try {
    const { data } = await Api.patch(`${BACKEND_URLS.SURVEY.CLOSE_SURVEY}`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

// ==============================Field survey==========================================

export const getAllFieldSurveys = async ({ page, status }) => {
  try {
    const { data } = await Api.get(
      `${BACKEND_URLS.fieldSurveys.allSurveys}?batch=${page}${
        status !== null && status !== "" ? `&surveyStatus=${status}` : ""
      }`,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getResponses = async (id) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.fieldSurveys.responses}?surveyId=${id}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getSingleResponse = async (id) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.fieldSurveys.singleResponse}/${id}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const saveTargetedAudience = async (payload) => {
  try {
    const { data } = await Api.post(`${BACKEND_URLS.SURVEY.SAVE_AUDIENCE}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getSavedAudience = async (payload) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.SURVEY.FETCH_SAVED_AUDIENCE}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
