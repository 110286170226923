export const countryData = [
  {
    name: "Afghanistan",
    flag: "https://www.countryflags.io/af/shiny/64.png",
    number: "+93",
  },
  {
    name: "Albania",
    flag: "https://www.countryflags.io/al/shiny/64.png",
    number: "+355",
  },
  {
    name: "Algeria",
    flag: "https://www.countryflags.io/dz/shiny/64.png",
    number: "+213",
  },
  {
    name: "Andorra",
    flag: "https://www.countryflags.io/ad/shiny/64.png",
    number: "+376",
  },
  {
    name: "Angola",
    flag: "https://www.countryflags.io/ao/shiny/64.png",
    number: "+244",
  },
  {
    name: "Anguilla",
    flag: "https://www.countryflags.io/ai/shiny/64.png",
    number: "+1264",
  },
  {
    name: "Antigua and Barbuda",
    flag: "https://www.countryflags.io/ag/shiny/64.png",
    number: "+1268",
  },
  {
    name: "Argentina",
    flag: "https://www.countryflags.io/ar/shiny/64.png",
    number: "+54",
  },
  {
    name: "Armenia",
    flag: "https://www.countryflags.io/am/shiny/64.png",
    number: "+374",
  },
  {
    name: "Aruba",
    flag: "https://www.countryflags.io/aw/shiny/64.png",
    number: "+297",
  },
  {
    name: "Australia",
    flag: "https://www.countryflags.io/au/shiny/64.png",
    number: "+61",
  },
  {
    name: "Austria",
    flag: "https://www.countryflags.io/at/shiny/64.png",
    number: "+43",
  },
  {
    name: "Azerbaijan",
    flag: "https://www.countryflags.io/az/shiny/64.png",
    number: "+994",
  },
  {
    name: "Bahamas",
    flag: "https://www.countryflags.io/bs/shiny/64.png",
    number: "+1242",
  },
  {
    name: "Bahrain",
    flag: "https://www.countryflags.io/bh/shiny/64.png",
    number: "+973",
  },
  {
    name: "Bangladesh",
    flag: "https://www.countryflags.io/bd/shiny/64.png",
    number: "+880",
  },
  {
    name: "Barbados",
    flag: "https://www.countryflags.io/bb/shiny/64.png",
    number: "+1246",
  },
  {
    name: "Belarus",
    flag: "https://www.countryflags.io/by/shiny/64.png",
    number: "+375",
  },
  {
    name: "Belgium",
    flag: "https://www.countryflags.io/be/shiny/64.png",
    number: "+32",
  },
  {
    name: "Belize",
    flag: "https://www.countryflags.io/bz/shiny/64.png",
    number: "+501",
  },
  {
    name: "Benin",
    flag: "https://www.countryflags.io/bj/shiny/64.png",
    number: "+229",
  },
  {
    name: "Bermuda",
    flag: "https://www.countryflags.io/bm/shiny/64.png",
    number: "+1441",
  },
  {
    name: "Bhutan",
    flag: "https://www.countryflags.io/bt/shiny/64.png",
    number: "+975",
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "https://www.countryflags.io/ba/shiny/64.png",
    number: "+387",
  },
  {
    name: "Botswana",
    flag: "https://www.countryflags.io/bw/shiny/64.png",
    number: "+267",
  },
  {
    name: "Bouvet Island",
    flag: "https://www.countryflags.io/bv/shiny/64.png",
    number: "+55",
  },
  {
    name: "Brazil",
    flag: "https://www.countryflags.io/br/shiny/64.png",
    number: "+55",
  },
  {
    name: "British Indian Ocean Territory",
    flag: "https://www.countryflags.io/io/shiny/64.png",
    number: "+246",
  },
  {
    name: "Brunei Darussalam",
    flag: "https://www.countryflags.io/bn/shiny/64.png",
    number: "+673",
  },
  {
    name: "Bulgaria",
    flag: "https://www.countryflags.io/bg/shiny/64.png",
    number: "+359",
  },
  {
    name: "Burkina Faso",
    flag: "https://www.countryflags.io/bf/shiny/64.png",
    number: "+226",
  },
  {
    name: "Burundi",
    flag: "https://www.countryflags.io/bi/shiny/64.png",
    number: "+257",
  },
  {
    name: "Cambodia",
    flag: "https://www.countryflags.io/kh/shiny/64.png",
    number: "+855",
  },
  {
    name: "Cameroon",
    flag: "https://www.countryflags.io/cm/shiny/64.png",
    number: "+237",
  },
  {
    name: "Canada",
    flag: "https://www.countryflags.io/ca/shiny/64.png",
    number: "+1",
  },
  {
    name: "Cape Verde",
    flag: "https://www.countryflags.io/cv/shiny/64.png",
    number: "+238",
  },
  {
    name: "Cayman Islands",
    flag: "https://www.countryflags.io/ky/shiny/64.png",
    number: "+1345",
  },
  {
    name: "Central African Republic",
    flag: "https://www.countryflags.io/cf/shiny/64.png",
    number: "+236",
  },
  {
    name: "Chad",
    flag: "https://www.countryflags.io/td/shiny/64.png",
    number: "+235",
  },
  {
    name: "Chile",
    flag: "https://www.countryflags.io/cl/shiny/64.png",
    number: "+56",
  },
  {
    name: "China",
    flag: "https://www.countryflags.io/cn/shiny/64.png",
    number: "+86",
  },
  {
    name: "Christmas Island",
    flag: "https://www.countryflags.io/cx/shiny/64.png",
    number: "+61",
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "https://www.countryflags.io/cc/shiny/64.png",
    number: "+61",
  },
  {
    name: "Colombia",
    flag: "https://www.countryflags.io/co/shiny/64.png",
    number: "+57",
  },
  {
    name: "Comoros",
    flag: "https://www.countryflags.io/km/shiny/64.png",
    number: "+269",
  },
  {
    name: "Congo",
    flag: "https://www.countryflags.io/cd/shiny/64.png",
    number: "+242",
  },
  {
    name: "Cook Islands",
    flag: "https://www.countryflags.io/ck/shiny/64.png",
    number: "+682",
  },
  {
    name: "Costa Rica",
    flag: "https://www.countryflags.io/cr/shiny/64.png",
    number: "+506",
  },
  {
    name: "Croatia",
    flag: "https://www.countryflags.io/hr/shiny/64.png",
    number: "+385",
  },
  {
    name: "Cuba",
    flag: "https://www.countryflags.io/cu/shiny/64.png",
    number: "+53",
  },
  {
    name: "Cyprus",
    flag: "https://www.countryflags.io/cy/shiny/64.png",
    number: "+357",
  },
  {
    name: "Czech Republic",
    flag: "https://www.countryflags.io/cz/shiny/64.png",
    number: "+420",
  },
  {
    name: "Denmark",
    flag: "https://www.countryflags.io/dk/shiny/64.png",
    number: "+45",
  },
  {
    name: "Djibouti",
    flag: "https://www.countryflags.io/dj/shiny/64.png",
    number: "+253",
  },
  {
    name: "Dominica",
    flag: "https://www.countryflags.io/dm/shiny/64.png",
    number: "+1767",
  },
  {
    name: "Dominican Republic",
    flag: "https://www.countryflags.io/do/shiny/64.png",
    number: "+1849",
  },
  {
    name: "Ecuador",
    flag: "https://www.countryflags.io/ec/shiny/64.png",
    number: "+593",
  },
  {
    name: "Egypt",
    flag: "https://www.countryflags.io/eg/shiny/64.png",
    number: "+20",
  },
  {
    name: "El Salvador",
    flag: "https://www.countryflags.io/sv/shiny/64.png",
    number: "+503",
  },
  {
    name: "Equatorial Guinea",
    flag: "https://www.countryflags.io/gq/shiny/64.png",
    number: "+240",
  },
  {
    name: "Eritrea",
    flag: "https://www.countryflags.io/er/shiny/64.png",
    number: "+291",
  },
  {
    name: "Estonia",
    flag: "https://www.countryflags.io/ee/shiny/64.png",
    number: "+372",
  },
  {
    name: "Ethiopia",
    flag: "https://www.countryflags.io/et/shiny/64.png",
    number: "+251",
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "https://www.countryflags.io/fk/shiny/64.png",
    number: "+500",
  },
  {
    name: "Faroe Islands",
    flag: "https://www.countryflags.io/fo/shiny/64.png",
    number: "+298",
  },
  {
    name: "Fiji",
    flag: "https://www.countryflags.io/fj/shiny/64.png",
    number: "+679",
  },
  {
    name: "Finland",
    flag: "https://www.countryflags.io/fi/shiny/64.png",
    number: "+358",
  },
  {
    name: "France",
    flag: "https://www.countryflags.io/fr/shiny/64.png",
    number: "+33",
  },
  {
    name: "French Guiana",
    flag: "https://www.countryflags.io/gf/shiny/64.png",
    number: "+594",
  },
  {
    name: "French Polynesia",
    flag: "https://www.countryflags.io/pf/shiny/64.png",
    number: "+689",
  },
  {
    name: "Gabon",
    flag: "https://www.countryflags.io/ga/shiny/64.png",
    number: "+241",
  },
  {
    name: "Gambia",
    flag: "https://www.countryflags.io/gm/shiny/64.png",
    number: "+220",
  },
  {
    name: "Georgia",
    flag: "https://www.countryflags.io/ge/shiny/64.png",
    number: "+995",
  },
  {
    name: "Germany",
    flag: "https://www.countryflags.io/de/shiny/64.png",
    number: "+49",
  },
  {
    name: "Ghana",
    flag: "https://www.countryflags.io/gh/shiny/64.png",
    number: "+233",
  },
  {
    name: "Gibraltar",
    flag: "https://www.countryflags.io/gi/shiny/64.png",
    number: "+350",
  },
  {
    name: "Greece",
    flag: "https://www.countryflags.io/gr/shiny/64.png",
    number: "+30",
  },
  {
    name: "Greenland",
    flag: "https://www.countryflags.io/gl/shiny/64.png",
    number: "+299",
  },
  {
    name: "Grenada",
    flag: "https://www.countryflags.io/gd/shiny/64.png",
    number: "+1473",
  },
  {
    name: "Guadeloupe",
    flag: "https://www.countryflags.io/gp/shiny/64.png",
    number: "+590",
  },
  {
    name: "Guam",
    flag: "https://www.countryflags.io/gu/shiny/64.png",
    number: "+1671",
  },
  {
    name: "Guatemala",
    flag: "https://www.countryflags.io/gt/shiny/64.png",
    number: "+502",
  },
  {
    name: "Guernsey",
    flag: "https://www.countryflags.io/gg/shiny/64.png",
    number: "+44",
  },
  {
    name: "Guinea",
    flag: "https://www.countryflags.io/gn/shiny/64.png",
    number: "+224",
  },
  {
    name: "Guinea-Bissau",
    flag: "https://www.countryflags.io/gw/shiny/64.png",
    number: "+245",
  },
  {
    name: "Guyana",
    flag: "https://www.countryflags.io/gy/shiny/64.png",
    number: "+592",
  },
  {
    name: "Haiti",
    flag: "https://www.countryflags.io/ht/shiny/64.png",
    number: "+509",
  },
  {
    name: "Heard Island and McDonald Islands",
    flag: "https://www.countryflags.io/hm/shiny/64.png",
    number: "+672",
  },
  {
    name: "Holy See (Vatican City State)",
    flag: "https://www.countryflags.io/va/shiny/64.png",
    number: "+379",
  },
  {
    name: "Honduras",
    flag: "https://www.countryflags.io/hn/shiny/64.png",
    number: "+504",
  },
  {
    name: "Hong Kong",
    flag: "https://www.countryflags.io/hk/shiny/64.png",
    number: "+852",
  },
  {
    name: "Hungary",
    flag: "https://www.countryflags.io/hu/shiny/64.png",
    number: "+36",
  },
  {
    name: "Iceland",
    flag: "https://www.countryflags.io/is/shiny/64.png",
    number: "+354",
  },
  {
    name: "India",
    flag: "https://www.countryflags.io/in/shiny/64.png",
    number: "+91",
  },
  {
    name: "Indonesia",
    flag: "https://www.countryflags.io/id/shiny/64.png",
    number: "+62",
  },
  {
    name: "Iran",
    flag: "https://www.countryflags.io/ir/shiny/64.png",
    number: "+98",
  },
  {
    name: "Iraq",
    flag: "https://www.countryflags.io/iq/shiny/64.png",
    number: "+964",
  },
  {
    name: "Ireland",
    flag: "https://www.countryflags.io/ie/shiny/64.png",
    number: "+353",
  },
  {
    name: "Isle of Man",
    flag: "https://www.countryflags.io/im/shiny/64.png",
    number: "+44",
  },
  {
    name: "Israel",
    flag: "https://www.countryflags.io/il/shiny/64.png",
    number: "+972",
  },
  {
    name: "Italy",
    flag: "https://www.countryflags.io/it/shiny/64.png",
    number: "+39",
  },
  {
    name: "Jamaica",
    flag: "https://www.countryflags.io/jm/shiny/64.png",
    number: "+1876",
  },
  {
    name: "Japan",
    flag: "https://www.countryflags.io/jp/shiny/64.png",
    number: "+81",
  },
  {
    name: "Jersey",
    flag: "https://www.countryflags.io/je/shiny/64.png",
    number: "+44",
  },
  {
    name: "Jordan",
    flag: "https://www.countryflags.io/jo/shiny/64.png",
    number: "+962",
  },
  {
    name: "Kazakhstan",
    flag: "https://www.countryflags.io/kz/shiny/64.png",
    number: "+7",
  },
  {
    name: "Kenya",
    flag: "https://www.countryflags.io/ke/shiny/64.png",
    number: "+254",
  },
  {
    name: "Kiribati",
    flag: "https://www.countryflags.io/ki/shiny/64.png",
    number: "+686",
  },
  {
    name: "Kuwait",
    flag: "https://www.countryflags.io/kw/shiny/64.png",
    number: "+965",
  },
  {
    name: "Kyrgyzstan",
    flag: "https://www.countryflags.io/kg/shiny/64.png",
    number: "+996",
  },
  {
    name: "Lao People's Democratic Republic",
    flag: "https://www.countryflags.io/la/shiny/64.png",
    number: "+856",
  },
  {
    name: "Latvia",
    flag: "https://www.countryflags.io/lv/shiny/64.png",
    number: "+371",
  },
  {
    name: "Lebanon",
    flag: "https://www.countryflags.io/lb/shiny/64.png",
    number: "+961",
  },
  {
    name: "Lesotho",
    flag: "https://www.countryflags.io/ls/shiny/64.png",
    number: "+266",
  },
  {
    name: "Liberia",
    flag: "https://www.countryflags.io/lr/shiny/64.png",
    number: "+231",
  },
  {
    name: "Liechtenstein",
    flag: "https://www.countryflags.io/li/shiny/64.png",
    number: "+423",
  },
  {
    name: "Lithuania",
    flag: "https://www.countryflags.io/lt/shiny/64.png",
    number: "+370",
  },
  {
    name: "Luxembourg",
    flag: "https://www.countryflags.io/lu/shiny/64.png",
    number: "+352",
  },
  {
    name: "Macao",
    flag: "https://www.countryflags.io/mo/shiny/64.png",
    number: "+853",
  },
  {
    name: "Madagascar",
    flag: "https://www.countryflags.io/mg/shiny/64.png",
    number: "+261",
  },
  {
    name: "Malawi",
    flag: "https://www.countryflags.io/mw/shiny/64.png",
    number: "+265",
  },
  {
    name: "Malaysia",
    flag: "https://www.countryflags.io/my/shiny/64.png",
    number: "+60",
  },
  {
    name: "Maldives",
    flag: "https://www.countryflags.io/mv/shiny/64.png",
    number: "+960",
  },
  {
    name: "Mali",
    flag: "https://www.countryflags.io/ml/shiny/64.png",
    number: "+223",
  },
  {
    name: "Malta",
    flag: "https://www.countryflags.io/mt/shiny/64.png",
    number: "+356",
  },
  {
    name: "Marshall Islands",
    flag: "https://www.countryflags.io/mh/shiny/64.png",
    number: "+692",
  },
  {
    name: "Martinique",
    flag: "https://www.countryflags.io/mq/shiny/64.png",
    number: "+596",
  },
  {
    name: "Mauritania",
    flag: "https://www.countryflags.io/mr/shiny/64.png",
    number: "+222",
  },
  {
    name: "Mauritius",
    flag: "https://www.countryflags.io/mu/shiny/64.png",
    number: "+230",
  },
  {
    name: "Mayotte",
    flag: "https://www.countryflags.io/yt/shiny/64.png",
    number: "+262",
  },
  {
    name: "Mexico",
    flag: "https://www.countryflags.io/mx/shiny/64.png",
    number: "+52",
  },
  {
    name: "Monaco",
    flag: "https://www.countryflags.io/mc/shiny/64.png",
    number: "+377",
  },
  {
    name: "Mongolia",
    flag: "https://www.countryflags.io/mn/shiny/64.png",
    number: "+976",
  },
  {
    name: "Montenegro",
    flag: "https://www.countryflags.io/me/shiny/64.png",
    number: "+382",
  },
  {
    name: "Montserrat",
    flag: "https://www.countryflags.io/ms/shiny/64.png",
    number: "+1664",
  },
  {
    name: "Morocco",
    flag: "https://www.countryflags.io/ma/shiny/64.png",
    number: "+212",
  },
  {
    name: "Mozambique",
    flag: "https://www.countryflags.io/mz/shiny/64.png",
    number: "+258",
  },
  {
    name: "Myanmar",
    flag: "https://www.countryflags.io/mm/shiny/64.png",
    number: "+95",
  },
  {
    name: "Namibia",
    flag: "https://www.countryflags.io/na/shiny/64.png",
    number: "+264",
  },
  {
    name: "Nauru",
    flag: "https://www.countryflags.io/nr/shiny/64.png",
    number: "+674",
  },
  {
    name: "Nepal",
    flag: "https://www.countryflags.io/np/shiny/64.png",
    number: "+977",
  },
  {
    name: "Netherlands",
    flag: "https://www.countryflags.io/nl/shiny/64.png",
    number: "+31",
  },
  {
    name: "New Caledonia",
    flag: "https://www.countryflags.io/nc/shiny/64.png",
    number: "+687",
  },
  {
    name: "New Zealand",
    flag: "https://www.countryflags.io/nz/shiny/64.png",
    number: "+64",
  },
  {
    name: "Nicaragua",
    flag: "https://www.countryflags.io/ni/shiny/64.png",
    number: "+505",
  },
  {
    name: "Niger",
    flag: "https://www.countryflags.io/ne/shiny/64.png",
    number: "+227",
  },
  {
    name: "Nigeria",
    flag: "https://www.countryflags.io/ng/shiny/64.png",
    number: "+234",
  },
  {
    name: "Niue",
    flag: "https://www.countryflags.io/nu/shiny/64.png",
    number: "+683",
  },
  {
    name: "Norfolk Island",
    flag: "https://www.countryflags.io/nf/shiny/64.png",
    number: "+672",
  },
  {
    name: "Northern Mariana Islands",
    flag: "https://www.countryflags.io/mp/shiny/64.png",
    number: "+1670",
  },
  {
    name: "Norway",
    flag: "https://www.countryflags.io/no/shiny/64.png",
    number: "+47",
  },
  {
    name: "Oman",
    flag: "https://www.countryflags.io/om/shiny/64.png",
    number: "+968",
  },
  {
    name: "Pakistan",
    flag: "https://www.countryflags.io/pk/shiny/64.png",
    number: "+92",
  },
  {
    name: "Palau",
    flag: "https://www.countryflags.io/pw/shiny/64.png",
    number: "+680",
  },
  {
    name: "Panama",
    flag: "https://www.countryflags.io/pa/shiny/64.png",
    number: "+507",
  },
  {
    name: "Papua New Guinea",
    flag: "https://www.countryflags.io/pg/shiny/64.png",
    number: "+675",
  },
  {
    name: "Paraguay",
    flag: "https://www.countryflags.io/py/shiny/64.png",
    number: "+595",
  },
  {
    name: "Peru",
    flag: "https://www.countryflags.io/pe/shiny/64.png",
    number: "+51",
  },
  {
    name: "Philippines",
    flag: "https://www.countryflags.io/ph/shiny/64.png",
    number: "+63",
  },
  {
    name: "Pitcairn",
    flag: "https://www.countryflags.io/pn/shiny/64.png",
    number: "+870",
  },
  {
    name: "Poland",
    flag: "https://www.countryflags.io/pl/shiny/64.png",
    number: "+48",
  },
  {
    name: "Portugal",
    flag: "https://www.countryflags.io/pt/shiny/64.png",
    number: "+351",
  },
  {
    name: "Puerto Rico",
    flag: "https://www.countryflags.io/pr/shiny/64.png",
    number: "+1939",
  },
  {
    name: "Qatar",
    flag: "https://www.countryflags.io/qa/shiny/64.png",
    number: "+974",
  },
  {
    name: "Réunion",
    flag: "https://www.countryflags.io/re/shiny/64.png",
    number: "+262",
  },
  {
    name: "Romania",
    flag: "https://www.countryflags.io/ro/shiny/64.png",
    number: "+40",
  },
  {
    name: "Rwanda",
    flag: "https://www.countryflags.io/rw/shiny/64.png",
    number: "+250",
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "https://www.countryflags.io/kn/shiny/64.png",
    number: "+1869",
  },
  {
    name: "Saint Lucia",
    flag: "https://www.countryflags.io/lc/shiny/64.png",
    number: "+1758",
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "https://www.countryflags.io/pm/shiny/64.png",
    number: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "https://www.countryflags.io/vc/shiny/64.png",
    number: "+1784",
  },
  {
    name: "Samoa",
    flag: "https://www.countryflags.io/ws/shiny/64.png",
    number: "+685",
  },
  {
    name: "San Marino",
    flag: "https://www.countryflags.io/sm/shiny/64.png",
    number: "+378",
  },
  {
    name: "Sao Tome and Principe",
    flag: "https://www.countryflags.io/st/shiny/64.png",
    number: "+239",
  },
  {
    name: "Saudi Arabia",
    flag: "https://www.countryflags.io/sa/shiny/64.png",
    number: "+966",
  },
  {
    name: "Senegal",
    flag: "https://www.countryflags.io/sn/shiny/64.png",
    number: "+221",
  },
  {
    name: "Serbia",
    flag: "https://www.countryflags.io/rs/shiny/64.png",
    number: "+381",
  },
  {
    name: "Seychelles",
    flag: "https://www.countryflags.io/sc/shiny/64.png",
    number: "+248",
  },
  {
    name: "Sierra Leone",
    flag: "https://www.countryflags.io/sl/shiny/64.png",
    number: "+232",
  },
  {
    name: "Singapore",
    flag: "https://www.countryflags.io/sg/shiny/64.png",
    number: "+65",
  },
  {
    name: "Slovakia",
    flag: "https://www.countryflags.io/sk/shiny/64.png",
    number: "+421",
  },
  {
    name: "Slovenia",
    flag: "https://www.countryflags.io/si/shiny/64.png",
    number: "+386",
  },
  {
    name: "Solomon Islands",
    flag: "https://www.countryflags.io/sb/shiny/64.png",
    number: "+677",
  },
  {
    name: "Somalia",
    flag: "https://www.countryflags.io/so/shiny/64.png",
    number: "+252",
  },
  {
    name: "South Africa",
    flag: "https://www.countryflags.io/za/shiny/64.png",
    number: "+27",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "https://www.countryflags.io/gs/shiny/64.png",
    number: "+500",
  },
  {
    name: "Spain",
    flag: "https://www.countryflags.io/es/shiny/64.png",
    number: "+34",
  },
  {
    name: "Sri Lanka",
    flag: "https://www.countryflags.io/lk/shiny/64.png",
    number: "+94",
  },
  {
    name: "Sudan",
    flag: "https://www.countryflags.io/sd/shiny/64.png",
    number: "+249",
  },
  {
    name: "Suriname",
    flag: "https://www.countryflags.io/sr/shiny/64.png",
    number: "+597",
  },
  {
    name: "Swaziland",
    flag: "https://www.countryflags.io/sz/shiny/64.png",
    number: "+268",
  },
  {
    name: "Sweden",
    flag: "https://www.countryflags.io/se/shiny/64.png",
    number: "+46",
  },
  {
    name: "Switzerland",
    flag: "https://www.countryflags.io/ch/shiny/64.png",
    number: "+41",
  },
  {
    name: "Syrian Arab Republic",
    flag: "https://www.countryflags.io/sy/shiny/64.png",
    number: "+963",
  },
  {
    name: "Taiwan",
    flag: "https://www.countryflags.io/tw/shiny/64.png",
    number: "+886",
  },
  {
    name: "Tajikistan",
    flag: "https://www.countryflags.io/tj/shiny/64.png",
    number: "+992",
  },
  {
    name: "Thailand",
    flag: "https://www.countryflags.io/th/shiny/64.png",
    number: "+66",
  },
  {
    name: "Timor-Leste",
    flag: "https://www.countryflags.io/tl/shiny/64.png",
    number: "+670",
  },
  {
    name: "Togo",
    flag: "https://www.countryflags.io/tg/shiny/64.png",
    number: "+228",
  },
  {
    name: "Tokelau",
    flag: "https://www.countryflags.io/tk/shiny/64.png",
    number: "+690",
  },
  {
    name: "Tonga",
    flag: "https://www.countryflags.io/to/shiny/64.png",
    number: "+676",
  },
  {
    name: "Trinidad and Tobago",
    flag: "https://www.countryflags.io/tt/shiny/64.png",
    number: "+1868",
  },
  {
    name: "Tunisia",
    flag: "https://www.countryflags.io/tn/shiny/64.png",
    number: "+216",
  },
  {
    name: "Turkey",
    flag: "https://www.countryflags.io/tr/shiny/64.png",
    number: "+90",
  },
  {
    name: "Turkmenistan",
    flag: "https://www.countryflags.io/tm/shiny/64.png",
    number: "+993",
  },
  {
    name: "Turks and Caicos Islands",
    flag: "https://www.countryflags.io/tc/shiny/64.png",
    number: "+1649",
  },
  {
    name: "Tuvalu",
    flag: "https://www.countryflags.io/tv/shiny/64.png",
    number: "+688",
  },
  {
    name: "Uganda",
    flag: "https://www.countryflags.io/ug/shiny/64.png",
    number: "+256",
  },
  {
    name: "Ukraine",
    flag: "https://www.countryflags.io/ua/shiny/64.png",
    number: "+380",
  },
  {
    name: "United Arab Emirates",
    flag: "https://www.countryflags.io/ae/shiny/64.png",
    number: "+971",
  },
  {
    name: "United Kingdom",
    flag: "https://www.countryflags.io/gb/shiny/64.png",
    number: "+44",
  },
  {
    name: "United States",
    flag: "https://www.countryflags.io/us/shiny/64.png",
    number: "+1",
  },
  {
    name: "United States Minor Outlying Islands",
    flag: "https://www.countryflags.io/um/shiny/64.png",
    number: "+1581",
  },
  {
    name: "Uruguay",
    flag: "https://www.countryflags.io/uy/shiny/64.png",
    number: "+598",
  },
  {
    name: "Uzbekistan",
    flag: "https://www.countryflags.io/uz/shiny/64.png",
    number: "+998",
  },
  {
    name: "Vanuatu",
    flag: "https://www.countryflags.io/vu/shiny/64.png",
    number: "+678",
  },
  {
    name: "Viet Nam",
    flag: "https://www.countryflags.io/vn/shiny/64.png",
    number: "+84",
  },
  {
    name: "Wallis and Futuna",
    flag: "https://www.countryflags.io/wf/shiny/64.png",
    number: "+681",
  },
  {
    name: "Yemen",
    flag: "https://www.countryflags.io/ye/shiny/64.png",
    number: "+967",
  },
  {
    name: "Zambia",
    flag: "https://www.countryflags.io/zm/shiny/64.png",
    number: "+260",
  },
  {
    name: "Zimbabwe",
    flag: "https://www.countryflags.io/zw/shiny/64.png",
    number: "+263",
  },
];
