import React, { useState } from "react";
import { crmData, engagementPlans, researchData } from "../data";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getRates } from "services/rates";
import ToggleButton from "components/Button/ToogleButton";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import BlueButton from "components/Button/BlueButton";
import EmptyState from "components/EmptyState";
import emptyState from "assets/images/pricing/emptyEstimate.png";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { paymentPlans, verifyPayment } from "services/workspaceService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { useStore } from "store";

const publicKey =
  process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_FLW_KEY : process.env.REACT_APP_FLW_LIVE_KEY;

const CustomHub = () => {
  const [toggleValue, setToggleValue] = useState(false);
  const [selectResearch, setSelectResearch] = useState(null);
  const [selectCrm, setSelectCrm] = useState(null);
  const { user } = useStore();

  const rates = useQuery(["rates"], getRates);
  const conversionRate = rates?.data?.data[0].currencyRates[7].userRate;
  const handleContactSupport = () => {
    window.location.href = "mailto:support@enterscale.com";
  };

  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  const handleSelectResearch = (id) => {
    setSelectResearch(selectResearch === id ? null : id);
  };
  const handleSelectCrm = (id) => {
    setSelectCrm(selectCrm === id ? null : id);
  };

  const configs = {
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: "",
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user?.email,
      phone_number: `+234${user?.phone}`,
      name: user?.firstname + " " + user?.lastname,
    },
    customizations: {
      title: "MOOYI",
      description: "Plan Upgrade",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const initializePayment = useFlutterwave(configs);

  const { mutate: paymentVerify } = useMutation(verifyPayment, {
    onSuccess: () => {
      closePaymentModal();
      Toast.success("plan purchase successful");
      queryClient.invalidateQueries(["myPlan"]);
      queryClient.invalidateQueries(["getPlan"]);
      queryClient.invalidateQueries(["wsCredit"]);
      queryClient.invalidateQueries(["wsData"]);
    },
    onError: () => {
      Toast.error("Try again");
    },
  });

  const { mutate: payment } = useMutation(paymentPlans, {
    onSuccess: ({ data }) => {
      console.log(data);
      configs.amount = data?.plan_price;
      initializePayment({
        callback: (response) => {
          const userData = {
            tx_ref: data?.tx_ref,
            transactionId: response?.transaction_id,
          };
          paymentVerify(userData);
        },
        onClose: () => {
          // routeFn();
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit = () => {
    const payload = {
      plan_id: [selectResearch ?? null, selectCrm ?? null],
      email: user?.email,
      fullname: user?.fullname,
    };
    researchSelect?.plan === "Pro Plan" || crmSelect?.title === "Pro Plan" ? handleContactSupport() : payment(payload);
  };

  const crmSelect = engagementPlans.filter((item) => item.planId.includes(selectCrm))[0];
  const researchSelect = researchData.filter((item) => item.planId.includes(selectResearch))[0];

  const researchAmount = researchSelect?.cost ?? 0;
  const crmAmount = crmSelect?.amount ?? 0;

  const totalAmount = researchAmount + crmAmount;

  const amtConversion = (amount) => {
    return toggleValue ? `₦${(amount * conversionRate).toLocaleString()}` : `$${amount.toLocaleString()}`;
  };

  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <p>Create a customised plan tailored to your business needs.</p>
        <div className="pt-4 flex justify-center items-center space-x-3">
          <p className={`${!toggleValue && "font-bold"}`}>USD</p>
          <ToggleButton toggleValue={toggleValue} onChange={handleToggleChange} />
          <p className={`${toggleValue && "font-bold"}`}>NGN</p>
        </div>
      </div>
      <div className="py-[2rem]">
        <div className="flex items-start my-2 space-x-5">
          <div className="md:w-[80%] w-full">
            <div className="border rounded-2xl bg-white h-[500px] p-5">
              <p className="font-[600]">Research hub</p>
              <div className="space-y-5 mt-5">
                {researchData.map((el, i) => (
                  <div
                    key={i}
                    className={`border rounded-2xl p-5 space-y-2 transition-all duration-150 cursor-pointer ${
                      selectResearch === el.planId ? "border-blue-800" : ""
                    }`}
                    onClick={() => handleSelectResearch(el.planId)}
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <p className="font-[600] text-[16px]">{el.plan}</p>
                        {el.plan === "Pro Plan" ? (
                          <p className="font-[600] text-gray-500"> Contact us</p>
                        ) : (
                          <p className="font-[600]">
                            {amtConversion(el.cost)}
                            <span className="text-gray-500">/month</span>
                          </p>
                        )}
                      </div>
                      <div
                        className={`rounded-full p-1 flex items-center justify-center border w-[20px] h-[20px] ${
                          selectResearch === el.planId ? "border-blue-800" : ""
                        } `}
                      >
                        {selectResearch === el.planId ? (
                          <div className={`rounded-full border p-1 bg-blue-800`}></div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                    <hr />
                    <p className="text-gray-500">Includes</p>
                    <ul className="list-disc pl-7 text-gray-500 space-y-1">
                      {el.package.map((el, i) => (
                        <li key={i} className="text-[14px]">
                          {el}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <div className="border rounded-2xl bg-white md:h-[200px] w-full p-5 mt-5">
              <p className="font-[600]">Engagement Hub</p>
              <div className="md:space-x-5 space-y-5 md:space-y-0 mt-5 md:flex items-center">
                {crmData.map((el, i) => (
                  <div
                    className={`border rounded-2xl w-full flex justify-between p-5 cursor-pointer ${
                      selectCrm === el.planId ? "border-blue-800" : ""
                    }`}
                    key={i}
                    onClick={() => handleSelectCrm(el.planId)}
                  >
                    <div>
                      <p className="font-[600] text-[16px]">{el.plan}</p>
                      {el.plan === "Pro Plan" ? (
                        <p className="font-[600] text-gray-500"> Contact us</p>
                      ) : (
                        <p className="font-[600]">
                          {amtConversion(el.cost)}
                          <span className="text-gray-500">/month</span>
                        </p>
                      )}
                    </div>
                    <div
                      className={`rounded-full p-1 flex items-center justify-center border w-[20px] h-[20px] ${
                        selectCrm === el.planId ? "border-blue-800" : ""
                      } `}
                    >
                      {selectCrm === el.planId ? (
                        <div className={`rounded-full border p-1 bg-blue-800`}></div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`border rounded-2xl bg-white w-[30%] p-5 hidden md:inline h-[46rem] overflow-y-auto`}>
            <p className="font-[600]">Estimate</p>
            {selectResearch !== null ? (
              <div className="mt-5">
                <div className="flex items-center justify-between">
                  <p className="font-[600] text-blue-800">Research hub</p>
                  <p
                    className="text-gray-600 hover:text-blue-800 cursor-pointer"
                    onClick={() => setSelectResearch(null)}
                  >
                    <IoClose size={20} />
                  </p>
                </div>
                <div>
                  <div className="flex items-start justify-between">
                    <div>
                      <div className="space-y-2">
                        <p className="font-[600] text-[16px]">{researchSelect !== null && researchSelect.plan}</p>
                        {researchSelect?.plan === "Pro Plan" ? (
                          <p className="font-[600]"> Contact us</p>
                        ) : (
                          <p className="font-[600]">
                            {researchSelect !== null && amtConversion(researchSelect.cost)}
                            <span className="text-gray-500">/month</span>
                          </p>
                        )}
                      </div>
                      <hr className="my-2" />
                      <p className="text-gray-500">Includes</p>
                      <ul className="list-disc pl-7 text-gray-500 space-y-2">
                        {researchSelect !== null &&
                          researchSelect.package.map((el, i) => (
                            <li key={i} className="text-[14px]">
                              {el}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {selectCrm !== null ? (
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <p className="font-[600] text-blue-800">Engagement Hub</p>
                  <p className="text-gray-600 hover:text-blue-800 cursor-pointer" onClick={() => setSelectCrm(null)}>
                    <IoClose size={20} />
                  </p>
                </div>
                <div>
                  <div className="flex items-start justify-between mt-3">
                    <div>
                      <div className="space-y-2">
                        <p className="font-[600] text-[16px]">{crmSelect !== null && crmSelect.title}</p>
                        {crmSelect?.title === "Pro Plan" ? (
                          <p className="font-[600]"> Contact us</p>
                        ) : (
                          <p className="font-[600]">
                            {crmSelect !== null && amtConversion(crmSelect.amount)}
                            <span className="text-gray-500">/month</span>
                          </p>
                        )}
                      </div>
                      <hr className="my-2" />
                      <p className="text-gray-500">Includes</p>
                      <ul className="list-disc pl-7 text-gray-500 space-y-2">
                        {crmSelect !== null &&
                          crmSelect.features.map((el, i) => (
                            <li key={i} className="text-[14px]">
                              {el}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex flex-col justify-center items-center text-center mt-10">
              {selectCrm === null && selectResearch === null && (
                <div className="pt-[5rem]">
                  <EmptyState
                    title="Nothing to show yet."
                    subtitle="Your custom selection will show up here."
                    img={emptyState}
                  />
                </div>
              )}
              <div className="w-full">
                {researchSelect?.plan === "Pro Plan" || crmSelect?.title === "Pro Plan" ? (
                  <div></div>
                ) : (
                  <div>
                    <p className="font-[600] text-[36px] text-blue-800 ">
                      {amtConversion(selectCrm === null && selectResearch === null ? 0.0 : totalAmount)}
                      <span className="text-gray-500 text-[20px] font-[500]">/month</span>
                    </p>
                    <p className="text-gray-500 text-[20px] font-[500]">Billed monthly</p>
                  </div>
                )}
                <div className="w-full">
                  <Link
                    className="min-w-full"
                    to={
                      researchSelect?.plan === "Pro Plan" || crmSelect?.title === "Pro Plan"
                        ? "mailto:support@enterscale.com"
                        : `#`
                    }
                  >
                    <BlueButton
                      type="button"
                      text={
                        researchSelect?.plan === "Pro Plan" || crmSelect?.title === "Pro Plan"
                          ? "Contact us"
                          : "Buy now"
                      }
                      className="w-full"
                      onClick={handleSubmit}
                      disabled={selectCrm === null && selectResearch === null}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomHub;
