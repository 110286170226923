import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useContactStore = create(
  devtools((set, get) => ({
    contactTabs: {
      allContacts: 0,
      tags: 0,
      segment: 0,
      custom: 0,
    },
    setContactsTabs: (data) => {
      set((state) => ({ contactTabs: { ...state.contactTabs, ...data } }));
    },
  })),
);
