import React, { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowDown, IoMdCheckmark, IoMdPie } from "react-icons/io";

const SelectContactsByTag = ({ tagData, contactSize, setContactSize, populatedArray, setPopulatedArray }) => {
  const [query, setQuery] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const refer = useOnclickOutside(() => setOpenDropdown(false));

  const [searchedArray, setSearchedArray] = useState(tagData);

  useEffect(() => {
    setSearchedArray(tagData);
  }, [tagData]);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleCheck = (e, el) => {
    const isChecked = e.target.checked;
    const contactCount = +el?.contactCount;

    if (isChecked) {
      if (!populatedArray?.some((item) => item.name === el?.name) && contactCount > 0) {
        const updatedContactSize = contactSize + contactCount;
        setContactSize(updatedContactSize);
        setPopulatedArray([...populatedArray, { name: el?.name, noOfContact: contactCount }]);
      }
    } else {
      const updatedContactSize = contactSize - contactCount;
      if (updatedContactSize >= 0) {
        setContactSize(updatedContactSize);
        const filteredArray = populatedArray?.filter((item) => item.name !== el?.name);
        setPopulatedArray(filteredArray);
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value !== "") {
      const filteredData = tagData?.filter((filtered) => filtered?.name.toLowerCase().includes(value.toLowerCase()));
      setSearchedArray(filteredData.length !== 0 ? filteredData : tagData);
    } else {
      setSearchedArray(tagData);
    }
  };

  const removedFromArray = (el) => {
    const updatedContactSize = contactSize - +el?.noOfContact;
    if (updatedContactSize >= 0) {
      const filteredArray = populatedArray?.filter((item) => item.name !== el?.name);
      setPopulatedArray(filteredArray);
      setContactSize(updatedContactSize);
    }
  };

  const checkInArray = (name) => {
    return populatedArray.some((item) => item.name === name);
  };

  return (
    <div>
      <div
        className="rounded-[8px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] mt-5 max-w-[100%] flex items-center relative"
        onClick={toggleDropdown}
        ref={refer}
      >
        {populatedArray.length <= 0 ? (
          <p className="text-neu-400 text-ss"> One of the tags are displayed here.</p>
        ) : (
          <p className="text-neu-400 text-ss">{populatedArray.length} Selected</p>
        )}

        <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[22px]">
          <IoIosArrowDown />
        </div>
      </div>
      <div ref={refer} className="flex-wrap gap-[1em] py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
        {populatedArray?.map((el, i) => (
          <div
            key={i}
            className="bg-bg border-[1px] bg-gray-50 border-blue-100 flex items-center px-4 rounded-[6px] h-[30px] text-ss cursor-pointer"
          >
            {el?.name}
            <span className="ml-2 p-1 bg-gray-300 rounded-full">
              <FaTimes className="font-thin text-[9px] text-white" onClick={() => removedFromArray(el)} />
            </span>
          </div>
        ))}
      </div>
      {openDropdown && (
        <div
          ref={refer}
          className="rounded-[8px] shadow-secondary pb-4 h-[300px] overflow-y-scroll bg-white cursor-pointer"
        >
          <div className="bg-[#F5F5F5] flex min-h-[40px] items-center gap-[.7em] px-[1em] py-[5px] max-w-[100%]">
            <span className="text-[#737373]">
              <AiOutlineSearch size={20} />
            </span>

            <input
              type="text"
              className="w-full h-[100%] outline-none text-s placeholder:text-s bg-transparent"
              placeholder="Search for tags"
              value={query}
              name="query"
              onChange={handleChange}
            />
          </div>

          {searchedArray?.map((el) => (
            <div
              className={`px-4 my-1 mx-3 text-s text-primary-800-1 hover:text-white border-b-[1px] border-[#D0D5DD] py-[8px] flex items-center justify-between cursor-pointer hover:bg-primary-800 hover:rounded-[8px] group ${
                checkInArray(el?.name) ? "bg-neu-100 rounded-lg hover:!bg-white hover:text-primary-800" : ""
              }`}
              key={el._id}
            >
              <div className="flex items-center space-x-4 relative w-[100%] cursor-pointer">
                <div>
                  <input
                    type="checkbox"
                    name="recipient"
                    id="recipient"
                    onChange={(e) => handleCheck(e, el)}
                    checked={checkInArray(el?.name)}
                    className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                  />

                  <IoMdPie
                    className={`text-[24px] ${
                      checkInArray(el?.name)
                        ? "block text-primary-800 group-hover:text-primary-800"
                        : "group-hover:text-white"
                    }`}
                  />
                </div>
                <div
                  className={`${checkInArray(el?.name) ? "group-hover:text-primary-800" : "group-hover:text-white"}`}
                >
                  <p className="text-m font-[500]">{el?.name}</p>
                  <p className="text-ss font-[400]">{el?.contactCount} contacts</p>
                </div>
              </div>

              <IoMdCheckmark
                className={`text-[24px] ${
                  checkInArray(el?.name) ? "block group-hover:text-primary-800" : "hidden group-hover:text-white"
                }`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectContactsByTag;
