import React, { useCallback, useEffect, useState } from "react";
import Content from "./Content";
import Audience from "./Audience";
import Publish from "./Publish";
import GreaterThan from "assets/images/greaterThan.svg";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import PublishModal from "../../PublishModal";
import { reTargetCampaign } from "services/campaignService";
import { useNavigate, useParams } from "react-router-dom";
import PreLoader from "components/Loader/PreLoader";
import { createTestCampaign } from "services/campaignService";
import { queryClient } from "config/gateway";
import { createSmsCampaign } from "services/campaignService";
import useLoggedInUser from "hooks/useLoggedInUser";
import PluginComponent from "pages/PluginComponent";
import { trackEvent } from "config/mixpanel";
import dayjs from "dayjs";
import SaveAndExitModal from "pages/Broadcasts/common/SaveAndExitModal";
import { getCampaignDetails } from "services/campaignService";
import { saveAndExit } from "services/campaignService";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const tabs = [
  { name: "Details", value: 1 },
  { name: "Edit Content", value: 2 },
  { name: "Publish", value: 3 },
];

const EmailTabs = () => {
  const { user } = useLoggedInUser();
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const navigate = useNavigate();
  const [disableButton, setDisabledButton] = useState(true);
  const { sidebar } = useSideBarStore();

  const { id } = useParams();

  const { data, isLoading: fetchDetails } = useQuery(["fetchEmailId", id], () => getCampaignDetails(id), {
    enabled: !!id,
  });

  console.log(data);

  const [broadcastData, setBroadcastData] = useState({
    campaignName: "",
    senderName: "",
    senderEmail: "",
    campaignType: "email",
    campaignObjective: "",
    totalReceiver: 0,
    content: "",
    campaignDate: "",
    campaignTime: "",
    scheduled: false,
    recipientArray: [],
    testContact: "",
    personal: 0,
  });

  useEffect(() => {
    if (data) {
      setBroadcastData({
        campaignName: data.campaignName || "",
        senderName: data.senderId || "",
        senderEmail: data.senderEmail || "",
        campaignType: "email",
        campaignObjective: data.campaignObjective || "promotional",
        totalReceiver: data.totalReceiver || 0,
        content: data.content || "",
        campaignDate: data.campaignDate || "",
        campaignTime: data.campaignTime || "",
        scheduled: data.scheduled || false,
        recipientArray: data.recipientArray || [],
        testContact: data.testContact || "",
        personal: data.personal || 0,
        // audience: data.personalContacts?.map((el) => el) || [],
      });
    }
  }, [data]);

  // const { mutate, isLoading } = useMutation(getTemplate, {
  //   onSuccess: (data) => {
  //     // setStep(2);
  //     console.log(data, "here");
  //     setBroadcastData({
  //       ...broadcastData,
  //       content: { ...data },
  //     });
  //   },
  //   onError: (error) => {
  //     Toast.error(error);
  //   },
  // });

  const { mutate: mutateCampaign, isLoading: submitCampaign } = useMutation(createSmsCampaign, {
    onSuccess: () => {
      Toast.success("Campaign created");
      queryClient.invalidateQueries(["allEmailCampaigns"], ["onBoarding"]);
      navigate("/broadcasts/email");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: reTargetMutate, isLoading: loadingRetarget } = useMutation(reTargetCampaign, {
    onSuccess: () => {
      Toast.success("Campaign retarget");
      navigate("/broadcasts/email");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: testMutate } = useMutation(createTestCampaign, {
    onSuccess: () => {
      Toast.success("Test created");
      queryClient.invalidateQueries(["wsCredit"]);
      setBroadcastData({
        ...broadcastData,
        testContact: "",
      });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const [templateData, setTemplateData] = useState({
    testMessage: "",
    logoUrl: "",
    btnText: "",
    btnUrl: "",
    brandColor: "",
  });

  const { mutate: saveExit } = useMutation(saveAndExit, {
    onSuccess: () => {
      Toast.success(`Email saved successfully`);
      queryClient.invalidateQueries(["allEmailCampaigns"], ["onBoarding"]);
      navigate(`/broadcasts/email`);
    },
    onError: () => {
      Toast.error(`Email failed`);
    },
  });

  const handleTest = () => {
    const userData = {
      campaign_channel: broadcastData.campaignType,
      content: JSON.stringify(broadcastData.content),
      sender_name: broadcastData.senderName,
    };

    const newUserData = {
      ...userData,
      campaign_subject: broadcastData.campaignSubject || "Mooyi Camp",
      sender_mail: broadcastData.senderEmail.toString().replace(",", " "),
      tester: {
        email: broadcastData.testContact,
      },
    };
    try {
      testMutate(newUserData);
    } catch (error) {}
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return (
          <Content
            broadcastData={broadcastData}
            setBroadcastData={setBroadcastData}
            data={templateData}
            setData={setTemplateData}
            existingData={data}
            loading={fetchDetails}
          />
        );
      case 2:
        return <Audience data={broadcastData} setData={setBroadcastData} />;
      case 3:
        return <Publish handleTest={handleTest} data={broadcastData} setData={setBroadcastData} />;
      default:
    }
  };

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  // const getTemplateId = () => {
  //   const htmlString = ReactDOMServer.renderToStaticMarkup(<MyComponent data={templateData} />);

  //   mutate({
  //     template: htmlString,
  //   });

  //   console.log(htmlString);
  // };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  // console.log(broadcastData, "i am checking");

  const updatedInfo = {
    campaign_channel: broadcastData.campaignType,
    sender_name: broadcastData.senderName,
    sender_mail: broadcastData.senderEmail.toString().replace(",", " "),
    campaign_name: broadcastData.campaignName,
    campaign_subject: broadcastData.campaignName || "Mooyi Camp",
    totalReceiver: +broadcastData.personal,
    campaignObjective: broadcastData.campaignObjective,
    content: JSON.stringify(broadcastData.content),
    receiverGroup: {
      mooyiAud: 0,
      personal: +broadcastData.personal,
    },
    // campaign_data: broadcastData?.campaign_date,
    // campaign_status: broadcastData?.campaign_status,
  };

  const proceedSubmit = async () => {
    if (id) {
      const userData = {
        ...updatedInfo,
        campaign_id: id,
      };
      if (broadcastData.scheduled === true) {
        reTargetMutate({
          ...updatedInfo,
          campaign_status: "scheduled",
          campaign_date: `${broadcastData.campaignDate}T${broadcastData.campaignTime}:00`,
        });
      } else {
        // reTargetMutate(userData);
        console.log(userData);
        // mutateCampaign(userData);
      }
    } else {
      const userData = {
        ...updatedInfo,
        recipient: {
          ...(broadcastData.recipientArray.length > 0
            ? { personalContacts: broadcastData.recipientArray.map((el) => el.name) }
            : null),
        },
      };

      console.log(userData);

      if (broadcastData.scheduled === true) {
        mutateCampaign({
          ...updatedInfo,
          campaign_status: "scheduled",
          campaign_date: `${broadcastData.campaignDate}T${broadcastData.campaignTime}:00`,
        });
      } else {
        mutateCampaign(userData);
        trackEvent("publish_campaign", {
          email: user?.data?.email,
          campaignSubject: broadcastData.campaignSubject,
          date: broadcastData.campaignDate,
          isScheduledForLater: broadcastData.scheduled,
          contactSize: broadcastData.personal,
          campaignType: broadcastData.campaignType,
        });
      }
    }
  };

  const onSubmit = () => {
    if (broadcastData.scheduled === true) {
      if (broadcastData.campaignDate === "" || broadcastData.campaignTime === "") {
        Toast.success("Pick date and time");
      } else {
        proceedSubmit();
      }
    } else {
      proceedSubmit();
    }
  };

  const handleNext = () => {
    if (step === 1) {
      // getTemplateId();
    }
    if (step < 3) {
      setStep(step + 1);
    } else if (step === 3) {
      setOpen(1);
    }
  };

  const handleSave = () => {
    saveExit({ campaignId: id, payload: updatedInfo });
  };

  const checkState = useCallback(() => {
    if (step === 1) {
      if (
        broadcastData.campaignName !== "" &&
        broadcastData.senderName !== "" &&
        broadcastData.senderEmail !== "" &&
        broadcastData.recipientArray.length !== 0
      ) {
        setDisabledButton(false);
        localStorage.removeItem("segmentData");
        localStorage.removeItem("tagData");
        setOpen(2);
      } else {
        setDisabledButton(true);
      }
    } else if (step === 2) {
      if (broadcastData.recipientArray.length > 0) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    }
  }, [
    broadcastData.campaignName,
    broadcastData.recipientArray.length,
    broadcastData.senderEmail,
    broadcastData.senderName,
    step,
  ]);

  useEffect(() => {
    checkState();
  }, [checkState]);

  if (loadingRetarget) {
    return <PreLoader />;
  }

  // if (submitCampaign) {
  //   return <PreLoader />;
  // }

  // if (fetchDetails) {
  //   return <PreLoader />;
  // }

  return (
    <div className="bg-white p-6 rounded-[8px] border-[1px]">
      {editorOpen ? (
        <PluginComponent
          broadcastData={broadcastData}
          setBroadcastData={setBroadcastData}
          setStep={setStep}
          setEditorOpen={setEditorOpen}
        />
      ) : (
        <>
          <div className={` w-[100%] mx-auto`}>
            <div className="flex space-x-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%] mx-auto">
              {tabs.map((el, i) => {
                return (
                  <div
                    className="flex items-center justify-between w-[33%] cursor-pointer"
                    key={el.value}
                    onClick={() => moveToStep(el.value)}
                  >
                    <div className="flex items-center justify-center w-[100%]">
                      <p
                        className={`${
                          step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                        } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                      >
                        {el.value}
                      </p>
                      <p
                        className={`px-2 text-s cursor-pointer ${
                          step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                        } `}
                      >
                        {el.name}
                      </p>
                    </div>

                    {el.value === 3 ? null : <img src={GreaterThan} alt="GreaterThan" />}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={`w-[100%]  my-2 flex `}>
            <div className="w-[100%]">{displayStep()}</div>

            {/* <div className="border-[1px] w-[50%] p-4 h-[70vh] overflow-y-scroll">
          <div className="">
            <img
              src={templateData.logoUrl}
              alt=""
              style={{
                width: "500px",
              }}
            />

            <p
              style={{
                fontSize: "1ppx",
                marginTop: "2em",
                marginBottom: "2em",
              }}
            >
              {templateData.testMessage}
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "500px",
              }}
            >
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "500px",
                  textDecoration: "none",
                }}
                href={templateData.btnUrl}
              >
                <button
                  style={{
                    backgroundColor: templateData.brandColor,
                    color: "#fff",
                    borderRadius: "6px",
                    padding: "4px 8px",
                    fontSize: "14px",
                    margin: "auto",
                  }}
                >
                  {templateData.btnText}
                </button>
              </a>
            </div>
          </div>
        </div> */}
          </div>

          <div
            className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
              sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
            }`}
          >
            <GreyButton text="Back" onClick={() => (step !== 0 ? setStep(step - 1) : navigate(-1))} />

            <div className="flex justify-between space-x-4">
              <GreyButton text="Save & exit" onClick={() => handleModal(4)} disabled={disableButton} />

              <BlueButton
                text={`${step === 3 ? "Publish" : "Next"}`}
                css={`text-white ${step === 3 ? "bg-[#25C15E]" : "bg-primary-800"}  h-[40px] text-s`}
                disabled={disableButton}
                onClick={step === 1 ? () => setEditorOpen(true) : handleNext}
              />
            </div>
          </div>

          {/* <div className="border-t-[1px] bg-white h-[50px] w-[calc(100vw-236px)] absolute bottom-0 right-0 flex items-center justify-between px-6 z-20">
            <Link to="/broadcasts/email">
              <GreyButton text="Cancel" css="h-[35px]" />
            </Link>

            <BlueButton
              text={`${step === 3 ? "Publish" : "Next"}`}
              css="h-[35px]"
              onClick={step === 1 ? () => setEditorOpen(true) : handleNext}
              disabled={disableButton}
            />
          </div> */}

          {open === 1 ? (
            <PublishModal
              handleSubmit={onSubmit}
              setData={setBroadcastData}
              data={broadcastData}
              handleModal={handleModal}
            />
          ) : null}
        </>
      )}
      {open === 4 ? <SaveAndExitModal handleModal={() => handleModal(null)} handleSave={handleSave} /> : null}
    </div>
  );
};

export default EmailTabs;
