import React, { useEffect, useState } from "react";
import GreaterThan from "assets/images/greaterThan.svg";
import Content from "./Content";
import Preview from "./Preview";
import { IoPersonCircleSharp } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getACampaignReport } from "services/campaignService";
import Toast from "config/toast";
import { formatDate } from "lib/formatDate";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import SuccessPage from "./SuccessPage";
import PublishModal from "./PublishModal";
import { trackEvent } from "config/mixpanel";
import { reTargetCampaign } from "services/campaignService";
import useLoggedInUser from "hooks/useLoggedInUser";

const tabs = [
  { name: "Content", value: 1 },
  { name: "Retargeting Options", value: 2 },
  { name: "Publish", value: 3 },
];

const SMSRetargeting = () => {
  const [step, setStep] = useState(1);
  const { smsId } = useParams();
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const { user } = useLoggedInUser();

  const { data: campDetails, isLoading: detailsLoading } = useQuery(
    ["smsDetails", smsId],
    () => getACampaignReport(smsId),
    {
      onError: (error) => {
        Toast.error(error);
      },
    },
    { refetchOnMount: true },
  );

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const { mutate, isLoading } = useMutation(reTargetCampaign, {
    onSuccess: () => {
      Toast.success("sms sent successfully");
      handleModal(2);
      // navigate("/broadcasts/sms");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const [smsData, setSmsData] = useState({
    content: "",
    sender_name: "",
    campaign_name: "",
    campaignObjective: "",
    recipientArray: [],
    personal: 0,
    audience: "",
  });

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <Content data={campDetails} setData={setSmsData} setStep={setStep} />;
      case 2:
        return <Preview campDetails={campDetails} />;
      default:
    }
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(step + 1);
    } else if (step === 2) {
      setOpen(1);
    }
  };

  const proceedSubmit = async () => {
    const user = {
      campaign_channel: "sms",
      campaign_name: campDetails.campaignName,
      sender_name: campDetails.senderId,
      campaign_subject: campDetails?.campaignSubject,
      content: campDetails?.campaignContent,
      audience: smsData.audience,
      campaign_id: smsId,
      campaignObjective: campDetails.campaignObjective,
      totalReceiver: smsData.totalReceiver,
    };

    trackEvent("retarget_campaign", {
      email: user?.data?.email,
      campaignName: campDetails?.campaign_name,
      date: new Date(),
      isScheduledForLater: smsData.scheduled,
      campaignType: "SMS",
    });

    mutate(user);
  };

  return (
    <div className="bg-white p-4">
      <div className="flex space-x-4 mt-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%]">
        {tabs.map((el, i) => {
          return (
            <div
              className="flex items-center justify-between w-[45%]"
              key={el.value}
              onClick={() => moveToStep(el.value)}
            >
              <div className="flex items-center">
                <p
                  className={`${
                    step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                  } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                >
                  {el.value}
                </p>
                <p
                  className={`px-2 cursor-pointer text-s ${
                    step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                  } `}
                >
                  {el.name}
                </p>
              </div>

              {el.value === 2 ? null : <img src={GreaterThan} alt="GreaterThan" />}
            </div>
          );
        })}
      </div>

      <div className={`mx-auto mt-[2em] flex justify-between gap-[3em]`}>
        <div className="w-[55%]">{displayStep()}</div>

        <div className="w-[45%] flex flex-col items-center justify-center bg-[#FAFAFA] ">
          <div className="bg-white p-4 w-[100%] border-[1px]">
            <p className="text-sm">SMS Preview</p>
          </div>

          <div className="border-[2px]  border-black  w-[60%] h-[60vh] items-end rounded-[42px] p-[10px] pb-0 my-6">
            <div className="border-[2px]  border-[#D0D5DD]  w-[100%] h-[98%] items-end rounded-[32px] overflow-hidden">
              <div className="bg-[#F5F5F5] h-[90px] flex items-center justify-center flex-col space-y-1">
                <div className="bg-[#969AA5] w-[40px] h-[40px] mx-auto rounded-full flex justify-center items-center">
                  <div className=" bg-[#969AA5]  flex items-center justify-center rounded-full overflow-hidden w-[30px] h-[30px]">
                    <IoPersonCircleSharp className="text-[28px] text-white" />
                  </div>
                </div>

                <p className="text-s font-[400]">{campDetails?.senderId}</p>
              </div>

              <div className="text-center space-y-[1px] mt-2 text-ss">
                <p>Text message</p>
                <p>{formatDate(campDetails?.campaignDate)}</p>
              </div>

              <div className="bg-[#E9E9EB] m-3 p-3 rounded-[10px] min-h-[100px]">
                <p className="text-xs">{campDetails?.campaignContent}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t-[1px] bg-white h-[50px] w-[calc(100vw-236px)] absolute bottom-0 right-0 flex items-center justify-between px-6 z-[999]">
        <GreyButton text="Cancel" css="h-[35px]" onClick={() => navigate(-1)} />

        <BlueButton
          text={`${step === 3 ? "Publish" : "Next"}`}
          css="h-[35px]"
          onClick={handleNext}
          // disabled={disableButton}
        />
      </div>

      {open === 2 ? <SuccessPage /> : null}

      {open === 1 ? (
        <PublishModal handleSubmit={proceedSubmit} setData={setSmsData} data={smsData} handleModal={handleModal} />
      ) : null}
    </div>
  );
};

export default SMSRetargeting;
