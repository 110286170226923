import React from "react";

export const Segment = ({ fill1, fill2 }) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6995_2196)">
      <path
        d="M9.5018 19.1345C14.5467 19.1345 18.6364 15.0448 18.6364 9.99985C18.6364 4.95494 14.5467 0.865234 9.5018 0.865234C4.45689 0.865234 0.367188 4.95494 0.367188 9.99985C0.367188 15.0448 4.45689 19.1345 9.5018 19.1345Z"
        fill={fill1}
      />
      <path
        d="M9.4982 10.9134C11.0117 10.9134 12.2386 9.68647 12.2386 8.173C12.2386 6.65953 11.0117 5.43262 9.4982 5.43262C7.98472 5.43262 6.75781 6.65953 6.75781 8.173C6.75781 9.68647 7.98472 10.9134 9.4982 10.9134Z"
        fill={fill2}
      />
      <path
        d="M3.50781 16.8954C4.41527 14.4683 6.75536 12.7402 9.49906 12.7402C12.2427 12.7402 14.5828 14.4683 15.4903 16.8954C13.8865 18.2901 11.7914 19.1345 9.49906 19.1345C7.20675 19.1345 5.11164 18.2901 3.50781 16.8954Z"
        fill={fill2}
      />
    </g>
    <defs>
      <clipPath id="clip0_6995_2196">
        <rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
