export const campType = (x) => {
  switch (x) {
    case "sms":
      return "SMS";
    case "robocall":
      return "Robocall";
    case "email":
      return "Email";
    default:
  }
};

export const campStatus = (x) => {
  switch (x) {
    case "sent":
      return <p className="text-[#16A34A]">Sent</p>;
    case "scheduled":
      return <p className="text-[#6366F1]">Scheduled</p>;
    case "draft":
      return <p className="text-[#D97706]">Draft</p>;
    default:
  }
};

export const campStatusBg = (x) => {
  switch (x) {
    case "sent":
      return "bg-[#16A34A]";
    case "scheduled":
      return "bg-[#6366F1]";
    case "draft":
      return "bg-[#D97706]";
    default:
  }
};

const key = "amount";

export const sortByValue = (a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};
