import React from "react";

const DeleteButton = ({ text, onClick, type, disabled }) => {
  return (
    <button
      className={` text-white font-[500] rounded-[4px] h-[40px] px-6 text-s ${
        disabled
          ? "bg-[#EBEBEB] text-[#A3A3A3] cursor-not-allowed"
          : "bg-[#d31212] shadow-activeShadow hover:bg-[#EF4444]"
      }`}
      onClick={disabled ? null : onClick}
      type={type}
      disabled={disabled || false}
    >
      {text}
    </button>
  );
};

export default DeleteButton;
