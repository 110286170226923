import { handleApiError } from "utils/handleApiError";
import Api from "utils/axios";

export const getAllWorkspace = async () => {
  try {
    const { data } = await Api.get("/workspace-microservice/workspaces");
    return data?.workspace;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getWorkspacePlan = async () => {
  try {
    const { data } = await Api.get("/workspace-microservice/workspace/plan");
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createWorkspace = async (payload) => {
  try {
    const { data } = await Api.post("/workspace-microservice/new-workspace", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchWorkspace = async () => {
  try {
    const { data } = await Api.get("/workspace-microservice/workspaces/default");
    return data?.workspace;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getWorkspaceUsers = async () => {
  try {
    const { data } = await Api.get("/workspace-microservice/workspace/users");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getWorkspaceCredit = async () => {
  try {
    const { data } = await Api.get("/workspace-microservice/ws-credit");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const changeWorkspace = async (id) => {
  try {
    const { data } = await Api.get(`/workspace-microservice/workspace/change/${id}`);
    return data?.workspace;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const workspaceUsage = async () => {
  try {
    const { data } = await Api.get(`/workspace-microservice/usage/ws-credit`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const transferWorkspaceCredit = async (payload) => {
  try {
    const { data } = await Api.post("/workspace-microservice/ws-credit/transfer", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCredit = async (type) => {
  try {
    const { data } = await Api.get(`/workspace-microservice/credit/view-price?bundleType=${type}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const topUpCredit = async (id) => {
  try {
    const { data } = await Api.get(`/workspace-microservice/wallet/topup/${id}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const paymentPlans = async (payload) => {
  try {
    const { data } = await Api.post(`/plans/payment-payload`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const verifyPayment = async (payload) => {
  try {
    const { data } = await Api.post(`/plans/verify-payment`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const approveTopUp = async (payload) => {
  try {
    const { data } = await Api.post(`/workspace-microservice/wallet/credit-verify`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const viewLedger = async () => {
  try {
    const { data } = await Api.post("/workspace-microservice/ws-ledger");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const verifyInvite = async (token) => {
  try {
    const { data } = await Api.get(`/workspace-microservice/approve-ws/${token}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deactivateWorkspace = async (payload) => {
  try {
    const { data } = await Api.delete(`/workspace-microservice/workspace/deactivate`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const editWsName = async (payload) => {
  try {
    const { data } = await Api.patch(`/workspace-microservice/workspace-name`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const verifySenderId = async (id) => {
  try {
    const { data } = await Api.get(`/workspace-microservice/sender-mail/verify/${id}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
