import BlueButton from "components/Button/BlueButton";
import React from "react";
import { GrFormCheckmark } from "react-icons/gr";
import { Link } from "react-router-dom";

const PayAsYouGo = ({ conversionRate, toggleValue, setStep }) => {
  const data = [
    `${toggleValue ? `₦${(2 * conversionRate).toLocaleString()}` : "$2"} per response from individuals.`,
    "Real-time access to survey results with dynamic charts.",
    `${toggleValue ? `₦${(4 * conversionRate).toLocaleString()}` : "$4"} per response from business owners.`,
    "Top up survey responses at any time to gather more insights.",
    "Showcase customer reviews, feedback, and easily address concerns online.",
    "Access to online survey builder and audience panel.",
    "Generate QR code for easy review collection.",
  ];

  return (
    <div className="bg-white p-5 rounded-md">
      <div className="w-full flex md:flex-row flex-col items-center mx-auto gap-[2em] mt-[1em]">
        <div className="md:w-[30%] bg-white w-[100%] border-[1px] rounded-lg p-4 space-y-4">
          <div className="bg-[#EBF1FF] h-[100px] rounded-md w-[100%] p-4 flex flex-col justify-center">
            <p className="text-lg text-primary-800 font-semibold">Starter plan</p>
            <p className="text-sm text-gray-600">For anyone</p>
          </div>

          <p className="text-sm text-gray-500">Get up to 100 free emails every month </p>

          <p className="text-[37px] md:text-[40px] font-semibold py-3">Free</p>

          <BlueButton text="Try for free" type="button" className="w-full" />

          <p className="text-xs text-gray-600">
            {`Note that we charge ${
              toggleValue ? `₦${(2 * conversionRate).toLocaleString()}` : "$2"
            } per response from individuals and ${
              toggleValue ? `₦${(4 * conversionRate).toLocaleString()}` : "$4"
            } per response from Business owners. For more
          enquiry, `}
            <Link to="mailto:support@enterscale.com" className="text-primary underline underline-offset-1">
              contact us.
            </Link>{" "}
          </p>
        </div>

        <div className="md:w-[70%] w-[100%] space-y-4">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
            {data.map((el) => {
              return (
                <div key={el} className="text-sm text-gray-500 w-[90%] flex items-start space-x-4">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#EBF1FF] flex items-center justify-center mt-1">
                    {" "}
                    <GrFormCheckmark className="text-primary" />
                  </div>

                  <p> {el}</p>
                </div>
              );
            })}
          </div>

          <p className="font-secondary pt-6 text-[16px]">
            Get access to more features on our{" "}
            <span className="text-primary-800 font-[500] cursor-pointer " onClick={() => setStep(0)}>
              Premium plans
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayAsYouGo;
