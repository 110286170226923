export const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.2, // Stagger the animation by 0.2 seconds per card
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
};
