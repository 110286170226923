import GreyButton from "components/Button/GreyButton";
import duplicateOwl from "assets/images/duplicateOwl.png";
import Modal from "components/Modal";
import React from "react";
import BlueButton from "components/Button/BlueButton";
import Typography from "utils/typography";

const DuplicateSuccessModal = ({ handleModal, handleOpenDuplicate, surveyData }) => {
  return (
    <Modal title="Survey duplicated successfully!" onClose={handleModal}>
      <div className="w-[505px] ">
        <div className="flex justify-center py-2 bg-primary-100 mt-3">
          <div className="w-[208px]">
            <img src={duplicateOwl} alt="" />
          </div>
        </div>
        <div className="px-5 py-5 flex justify-center text-center">
          <Typography.SubText>
            {surveyData?.surveyName} has been duplicated successfully. Would you like to edit it now?
          </Typography.SubText>
        </div>
        <div className="flex justify-between px-5 pt-2 border-t">
          <div></div>
          <div className="flex gap-[1em] mt-[1em]">
            <GreyButton text="No, close " css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <BlueButton text="Yes, edit" onClick={handleOpenDuplicate} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateSuccessModal;
