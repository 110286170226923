import React from "react";

export const Delete = ({ fill1, fill2 }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 5.75H15V14.75C15 15.9927 13.9927 17 12.75 17H5.25C4.00736 17 3 15.9927 3 14.75V5.75Z" fill={fill1} />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 8C7.16421 8 7.5 8.33578 7.5 8.75V14C7.5 14.4142 7.16421 14.75 6.75 14.75C6.33579 14.75 6 14.4142 6 14V8.75C6 8.33578 6.33579 8 6.75 8Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.25 8C11.6642 8 12 8.33578 12 8.75V14C12 14.4142 11.6642 14.75 11.25 14.75C10.8358 14.75 10.5 14.4142 10.5 14V8.75C10.5 8.33578 10.8358 8 11.25 8Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 4.25C5.25 3.00736 6.25736 2 7.5 2H10.5C11.7427 2 12.75 3.00736 12.75 4.25H15.75C16.1642 4.25 16.5 4.58579 16.5 5C16.5 5.41421 16.1642 5.75 15.75 5.75H2.25C1.83579 5.75 1.5 5.41421 1.5 5C1.5 4.58579 1.83579 4.25 2.25 4.25H5.25ZM7.5 3.5H10.5C10.9142 3.5 11.25 3.83579 11.25 4.25H6.75C6.75 3.83579 7.08579 3.5 7.5 3.5Z"
      fill={fill2}
    />
  </svg>
);
