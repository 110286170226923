// import { useMutation } from "@tanstack/react-query";
// import Toast from "config/toast";
import React, { useState } from "react";
import Toggle from "react-toggle";
// import { costCampaign } from "services/campaignService";
// import Coin from "assets/images/coin.svg";

const Publish = ({ data, handleTest, setData }) => {
  // const [cost, setCost] = useState("");
  const [active, setActive] = useState(false);
  const [display, setDisplay] = useState(false);
  console.log(data);

  // const { mutate } = useMutation(costCampaign, {
  //   onSuccess: (data) => {
  //     setCost(data.cost);
  //   },
  //   onError: (error) => {
  //     Toast.error(error);
  //   },
  // });

  const handleChange = () => {
    setDisplay(!display);
  };

  // useEffect(() => {
  //   mutate({
  //     receiverGroup: {
  //       mooyiAud: 0,
  //       personal: data.personal,
  //     },
  //     channel: data.campaignType,
  //   });
  // }, [data.campaignType, data.personal, mutate]);

  const handleTelChange = (e) => {
    const inputValue = e.target.value;
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);

    if (inputValue.length > 6 && isValidEmail) {
      setActive(true);
    } else {
      setActive(false);
    }

    setData({
      ...data,
      testContact: inputValue,
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <p className="text-[20px] text-[#0F172A] tracking-[-0.02em] font-semibold">Preview</p>
          <p className="text-s font-[450] text-[#737373]">Confirm the details of the broadcast before publishing.</p>
        </div>

        {/* <div className="text-right">
          <p className="text-s font-[400]">Campaign credit cost</p>

          <div className="flex items-center space-x-2 float-right">
            <img src={Coin} alt="Coin" />
            <p className="bg-gradient-to-r from-[#4568DC] to-[#B06AB3] text-transparent bg-clip-text font-semibold text-[24px]">
              {cost}
            </p>
          </div>
        </div> */}
      </div>

      {/* <div className="my-[1.5em] border rounded-[8px] p-5">
        <div className="text-m flex justify-between border-b-[1px] py-4">
          <p className=" text-[#737373] font-[450]">Tota
        </div>

        <div className="text-m flex justify-between border-b-[1px] py-4">
          <p className=" text-[#737373] font-[450]">Audience</p>
          <div className="text-m flex justify-between py-4 text-right">
            <div className="flex items-center space-x-2">
              {data?.recipientArray?.map((el, i) => {
                return (
                  <p className="text-[12px] p-2 bg-gray-200 rounded-md " key={i}>
                    {el.name}
                  </p>
                );
              })}
            </div>
          </div>
        </div>

        <div className="text-m flex justify-between py-4">
          <p className=" text-[#737373] font-[450]">Reach</p>
          <p className="">{data.personal}</p>
        </div>
      </div> */}
      <div className="my-[1.5em] border rounded-[8px] p-5">
        {/* <p className="text-[20px] text-primary-800 tracking-[-0.02em] font-semibold">Setup Details</p> */}
        <p className="text-[14px] tracking-[-0.02em] font-semibold">Summary</p>

        <div className="text-m flex justify-between border-b-[1px] py-4">
          <p className=" text-[#737373] font-[450]">Total units</p>
          <p className="">{data.campaignName}</p>
        </div>

        <div className="text-m flex justify-between border-b-[1px] py-4">
          <p className=" text-[#737373] font-[450]">Audience</p>
          <div className="text-m flex justify-between py-4 text-right">
            <div className="flex items-center space-x-2">
              {data?.recipientArray?.map((el, i) => {
                return (
                  <p className="text-[12px] p-2 bg-gray-200 rounded-md " key={i}>
                    {el.name}
                  </p>
                );
              })}
            </div>
          </div>
        </div>

        <div className="text-m flex justify-between py-4">
          <p className=" text-[#737373] font-[450]">Reach</p>
          <p className="">{data.personal}</p>
        </div>
      </div>

      <div className="my-[1.5em] ">
        {/* <p className="text-[20px] text-primary-800 tracking-[-0.02em] font-semibold">Contacts</p>

        <div className="text-m flex justify-between border-b-[1px] py-4">
          <p className=" text-[#737373] font-[450]">Reach</p>

          <p className="text-primary-800 text-right">{data.personal}</p>
        </div> */}

        {/* <div className="text-m flex justify-between border-b-[1px] py-4 text-right">
          <p className=" text-[#737373] font-[450]">Segments</p>
          <div>
            {data.recipientArray?.map((el, i) => {
              return (
                <p className="text-primary-800" key={i}>
                  {el.group_name}
                </p>
              );
            })}
          </div>
        </div> */}
      </div>

      {/* <div className="my-[1.5em]">
        <p className="text-[20px] text-primary-800 tracking-[-0.02em] font-semibold">Content</p>

        <div className="text-m flex justify-between border-b-[1px] py-4">
          <p className=" text-[#737373] font-[450]">Subject</p>
          <p className="text-primary-800 ">{data.campaignSubject}</p>
        </div>

        <div className="text-m flex justify-between border-b-[1px] py-4">
          <p className=" text-[#737373] font-[450]">Sender ID</p>
          <p className="text-primary-800 ">{data.senderName.toString().replace(",", " ")}</p>
        </div>
      </div>

      <p className="text-s pb-4 text-[#737373]">
        Note : To ensure that we are compliant and considerate of people's time; please note that campaigns will be
        published between 8 a.m. and 7 p.m. Thank you for understanding.
      </p> */}

      <div className="flex space-x-4 items-center">
        <p className="text-s font-semibold">Send Test</p>
        <Toggle id="isChecked" icons={false} isChecked={display} onChange={handleChange} />
      </div>

      {display ? (
        <div>
          <div>
            <div className="flex space-x-2 items-center my-[1em]">
              <div className="flex items-center px-4 rounded-[8px] border-[#DFE5EC] border-[1px] space-x-2 w-[80%] h-[45px]">
                <input
                  type="email"
                  className="outline-none h-[100%] w-[300px] text-sm placeholder:text-sm"
                  placeholder="test@enterscale.com"
                  value={data.testContact}
                  onChange={handleTelChange}
                  name="testContact"
                />
              </div>
              <p
                className={`text-ss cursor-pointer rounded-lg px-3 h-[45px] flex items-center justify-center ${
                  active ? "text-primary-800 bg-primary-100 font-bold " : "text-[#737373] bg-gray-50 "
                }`}
                onClick={active ? handleTest : null}
              >
                Send Test
              </p>
            </div>
            <p className="text-ss">Each test campaign sent will be deducted from your credit balance</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Publish;
