import { useQuery } from "@tanstack/react-query";
import { getAllSurveys } from "services/newSurveyService";

const useOnlineSurveys = ({ page, surveyType }) => {
  const queryConfig = (status) => ({
    queryKey: [`${surveyType}${status.charAt(0).toUpperCase() + status.slice(1)}`, page],
    queryFn: () => getAllSurveys({ page: page, status, surveyType }),
  });

  const { data: activeData, isFetching: fetchActive, isLoading: loadingActive } = useQuery(queryConfig("active"));
  const { data: draftData, isFetching: fetchDraft, isLoading: loadingDraft } = useQuery(queryConfig("draft"));
  const { data: closeData, isFetching: fetchClosed, isLoading: loadingClosed } = useQuery(queryConfig("closed"));
  const {
    data: scheduledData,
    isFetching: fetchScheduled,
    isLoading: loadingScheduled,
  } = useQuery(queryConfig("scheduled"));

  return {
    newSurveyData: activeData?.data?.surveys,
    newDraftData: draftData?.data?.surveys,
    newCloseData: closeData?.data?.surveys,
    newScheduledData: scheduledData?.data?.surveys,
    fetchActive,
    loadingActive,
    fetchDraft,
    loadingDraft,
    fetchClosed,
    loadingClosed,
    fetchScheduled,
    loadingScheduled,
    totalActiveContact: activeData?.data?.filteredCount,
    totalDraftContact: draftData?.data?.filteredCount,
    totalClosedContact: closeData?.data?.filteredCount,
    totalScheduledContact: scheduledData?.data?.filteredCount,
  };
};

export default useOnlineSurveys;
