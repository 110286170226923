import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Graph = ({ data }) => {
  console.log(data);
  return (
    <ResponsiveContainer width="100%" height="80%">
      <LineChart
        width={500}
        height={250}
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line dataKey="accepted" stroke="#6B7FFF" activeDot={{ r: 8 }} />
        <Line dataKey="delivered" stroke="#5F0BDD" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Graph;
