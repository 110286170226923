import { useMutation } from "@tanstack/react-query";
import AuthButton from "components/Button/AuthButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import PreLoader from "components/Loader/PreLoader";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
import { urlRegex } from "lib/regex";
import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { updateBusiness } from "services/userService";
import * as Yup from "yup";

const OnlinePresence = ({ user, setStep }) => {
  const { mutate, isLoading: isSubmitting } = useMutation(updateBusiness, {
    onSuccess: () => {
      Toast.success("Updated successfully!!");
      queryClient.invalidateQueries(["currentUser"]);
      setStep(3);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const initialValues = {
    websiteUrl: user?.data?.business?.websiteUrl || "",
    facebook: user?.data?.socialMedia?.facebook || "",
    twitter: user?.data?.socialMedia?.twitter || "",
    instagram: user?.data?.socialMedia?.instagram || "",
    linkedIn: user?.data?.socialMedia?.linkedIn || "",
  };

  const validationSchema = Yup.object({
    websiteUrl: Yup.string().required("URL is required").matches(urlRegex, "Invalid website URL"),
  });

  const onSubmit = async (data) => {
    const userData = {
      socialMedia: {
        facebook: data.facebook,
        twitter: data.twitter,
        instagram: data.instagram,
        linkedIn: data.linkedIn,
      },
      websiteUrl: data.websiteUrl,
    };
    mutate(userData);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isSubmitting) {
    return <PreLoader text="submitting data..." />;
  }

  return (
    <div>
      <p className="text-[#292D32] font-semibold text-[24px]">Website and social media</p>

      <p className="text-[#737373] text-s font-[450]">Add your company’s online information.</p>

      <form className="mt-[2em] space-y-4" onSubmit={handleSubmit}>
        <SecondaryInput
          text="Website URL"
          placeholder="Enter Website URL"
          onChange={handleChange}
          onBlur={handleBlur}
          name="websiteUrl"
          type="text"
          value={values.websiteUrl}
          error={getError("websiteUrl")}
        />

        <div>
          <p className="text-sm">Social media handles</p>

          <div className="grid grid-cols-2 gap-[1em] mt-2">
            <div className="border-[1px] p-2 rounded-lg flex items-center space-x-2 ">
              <FaFacebookF className="text-sm" />

              <span className="h-[20px] border-l-[1px] border-slate-400"></span>

              <input
                type="text"
                value={values.facebook}
                name="facebook"
                onChange={handleChange}
                placeholder="@username"
                className="text-sm placeholder:text-sm"
              />
            </div>

            <div className="border-[1px] p-2 rounded-lg flex items-center space-x-2 ">
              <FaInstagram className="text-sm" />

              <span className="h-[20px] border-l-[1px] border-slate-400"></span>

              <input
                type="text"
                value={values.instagram}
                name="instagram"
                onChange={handleChange}
                placeholder="@username"
                className="text-sm placeholder:text-sm"
              />
            </div>

            <div className="border-[1px] p-2 rounded-lg flex items-center space-x-2 ">
              <FaXTwitter className="text-sm" />

              <span className="h-[20px] border-l-[1px] border-slate-400"></span>

              <input
                type="text"
                value={values.twitter}
                name="twitter"
                onChange={handleChange}
                placeholder="@username"
                className="text-sm placeholder:text-sm"
              />
            </div>

            <div className="border-[1px] p-2 rounded-lg flex items-center space-x-2 ">
              <FaLinkedinIn className="text-sm" />

              <span className="h-[20px] border-l-[1px] border-slate-400"></span>

              <input
                type="text"
                value={values.linkedIn}
                name="linkedIn"
                onChange={handleChange}
                placeholder="@username"
                className="text-sm placeholder:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="mt-[1em]">
          <AuthButton text="Continue" type="submit" />
        </div>

        <p
          className="text-center text-primary-800 text-sm h-[40px] cursor-pointer font-semibold"
          onClick={() => setStep(3)}
        >
          Skip
        </p>
      </form>
    </div>
  );
};

export default OnlinePresence;
