import useOutsideClick from "hooks/useOutsideClick";

const Filter = ({ children, setOpenOrgFilter, openOrgFilter }) => {
  const orgRef = useOutsideClick(() => setOpenOrgFilter(false));
  return (
    <div>
      <div
        ref={orgRef}
        className={`fixed z-10 top-[19rem] right-[4rem] w-[420px] p-4 bg-white shadow-md  transition-all drop-shadow rounded-lg ${
          openOrgFilter ? "opacity-100 translate-y-0 visible" : "opacity-0 translate-y-[-12px] invisible"
        }`}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Filter;
