import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import Mascot from "assets/images/saveProgressMascot.png";
import React from "react";

const SaveAndExitModal = ({ handleModal, handleSave, loading }) => {
  return (
    <Modal title="Save your progress" onClose={handleModal}>
      <div className="w-[400px] px-5">
        <img src={Mascot} alt="Mascot" className="w-[200px] mx-auto my-6" />

        <p className="text-sm text-center py-3">If you leave without saving, your progress would be lost. </p>
        <div className="flex items-center justify-center space-x-4">
          <GreyButton text="Don't save" onClick={handleModal} />

          <BlueButton text="Yes, save" type="button" onClick={handleSave} loading={loading} />
        </div>
      </div>
    </Modal>
  );
};

export default SaveAndExitModal;
