import React, { useEffect, useState } from "react";
import CustomiseSurvey from "./CustomiseSurvey";
import AudienceSettings from "./AudienceSettings";
import SurveyChannel from "./SurveyChannel";
import PublishStep from "./PublishStep";
import GreaterThan from "assets/images/greaterThan.svg";
import useSurveyStore from "store/insightStore";
import { nps } from "data/insightData";
import { useLocation } from "react-router-dom";
import { cSatData } from "data/csatData";
import { useQuery } from "@tanstack/react-query";
import { getInsight } from "services/insightService";

const tabs = [
  { name: "Customise survey", value: 1 },
  { name: "Audience settings", value: 2 },
  { name: "Choose survey channel", value: 3 },
  { name: "Publish", value: 4 },
];

const CreationFlow = () => {
  const [step, setStep] = useState(1);
  const { setSurveyQuestionsArray, insightCategory } = useSurveyStore();
  const location = useLocation();

  const pathArray = location.pathname.split("/");

  //check route to set surveyQuestions

  const { data: insightData } = useQuery(["getInsight-Question"], () => getInsight("nps"));

  const fetchedQuestion = insightData?.insights[0]?.insightQuestionConfig?.question;

  useEffect(() => {
    if (pathArray.includes("nps")) {
      setSurveyQuestionsArray(nps[insightCategory]);
    } else if (pathArray.includes("csat")) {
      setSurveyQuestionsArray(cSatData[insightCategory]);
    }
  }, [insightCategory, setSurveyQuestionsArray]);

  useEffect(() => {
    if (fetchedQuestion?.length > 0) {
      setSurveyQuestionsArray([...fetchedQuestion]);
    }
  }, [fetchedQuestion, setSurveyQuestionsArray]);

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <CustomiseSurvey setStep={setStep} step={step} />;
      case 10:
        return <div className="h-[70vh] overflow-y-scroll">{/* <AudienceType setStep={setStep} step={step} /> */}</div>;

      case 2:
        return <AudienceSettings setStep={setStep} step={step} />;
      case 3:
        return <SurveyChannel setStep={setStep} step={step} />;
      case 4:
        return <PublishStep setStep={setStep} step={step} />;
      default:
    }
  };

  return (
    <div className="bg-white p-6 rounded-[8px] border-[1px]">
      <div className={` w-[100%] mx-auto`}>
        <div className="flex space-x-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%] mx-auto">
          {tabs.map((el, i) => {
            return (
              <div
                className="flex items-center justify-between w-[33.3%] cursor-pointer"
                key={el.value}
                onClick={() => moveToStep(el.value)}
              >
                <div className="flex items-center">
                  <p
                    className={`${
                      step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                    } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                  >
                    {el.value}
                  </p>
                  <p
                    className={`px-2 text-s cursor-pointer ${
                      step >= el.value ? " text-primary-800 font-semibold" : "text-[#525252]"
                    } `}
                  >
                    {el.name}
                  </p>
                </div>

                {el.value === 4 ? null : <img src={GreaterThan} alt="GreaterThan" />}
              </div>
            );
          })}
        </div>
      </div>

      <div className={`w-[100%] mt-5 h-[68vh] `}>{displayStep()}</div>
    </div>
  );
};

export default CreationFlow;
