import DeleteButton from "components/Button/DeleteButton";
import GreyButton from "components/Button/GreyButton";
import closeOwl from "assets/images/CloseOwl.png";
import Modal from "components/Modal";
import React from "react";
import Typography from "utils/typography";

const CloseSurveyModal = ({ handleModal, handleClosedSurvey }) => {
  return (
    <Modal title="Close survey" onClose={handleModal}>
      <div className="w-[505px] ">
        <div className="flex justify-center py-2 bg-red-100 mt-3">
          <div className="w-[208px]">
            <img src={closeOwl} alt="" />
          </div>
        </div>
        <div className="px-5 py-5 flex justify-center">
          <Typography.SubText>Are you sure you want to close this survey?.</Typography.SubText>
        </div>
        <div className="flex justify-between px-5 pt-2 border-t">
          <div></div>
          <div className="flex gap-[1em] mt-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <DeleteButton text="Close" onClick={handleClosedSurvey} />
          </div>
        </div>
      </div>
      {/* <div className="w-[400px] px-5">
        <p>Are you sure you want to close this survey?.</p>
        <div className="flex justify-between mt-[1em]">
          <div></div>
          <div className="flex gap-[2em] mt-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <DeleteButton text="Close" onClick={handleClosedSurvey} />
          </div>
        </div>
      </div> */}
    </Modal>
  );
};

export default CloseSurveyModal;
