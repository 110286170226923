import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import SelectInput from "components/Inputs/SelectInput";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name too short")
    .max(25, "Name too long (25 characters max)")
    .required("Please fill in this field"),
  description: Yup.string().min(2, "Name too short").max(15, "Name too long (15 characters max)"),
});

const initialValues = {
  name: "",
  dataType: "",
};

const CreateCustomFieldModal = ({ handleModal, action, handleSubmitClick }) => {
  const onSubmit = async (data) => {
    handleSubmitClick(data);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const disableButton = !values.name || !values.dataType;

  return (
    <Modal title={action === "edit" ? "Edit your custom field" : "Create a new custom field"} onClose={handleModal}>
      <div className="w-[516px] px-5">
        <p className="text-m font-semibold my-2">Enter a name for your new field below.</p>
        <form action="">
          <div className="mt-5 space-y-5">
            <div className="w-full">
              <SecondaryInput
                text="Field name"
                placeholder="Enter field name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                name="name"
                error={getError("name")}
                type="text"
              />
            </div>
            <div className="w-full">
              <SelectInput
                name="dataType"
                options={[
                  { label: "Select data type", value: "" },
                  { label: "Text", value: "string" },
                  { label: "Number", value: "number" },
                  { label: "Date", value: "date" },
                ]}
                value={values.dataType}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full my-5">
            <div className="flex justify-end space-x-5 mt-[3em]">
              <div>
                <GreyButton text="cancel" onClick={handleModal} />
              </div>
              <div className="flex space-x-2">
                <BlueButton
                  text={action === "edit" ? "Save" : "Create"}
                  css=""
                  onClick={handleSubmit}
                  disabled={disableButton}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateCustomFieldModal;
