import React from "react";
import subMascot from "assets/images/subMascot.png";
import Typography from "utils/typography";

const SuccessReSubscribe = () => {
  return (
    <div className="text-center">
      <div className=" pb-4">
        <img src={subMascot} alt="" />
      </div>
      <Typography.Headers>Its great to have you back!</Typography.Headers>
      <p className="mt-4 text-gray-600">You will continue receiving emails, newsletters and updates from us.</p>
    </div>
  );
};

export default SuccessReSubscribe;
