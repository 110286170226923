import React from "react";

export const SurveyIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25 3.75C2.25 2.50736 3.25736 1.5 4.5 1.5H11.818C12.4148 1.5 12.9871 1.73705 13.409 2.15901L15.091 3.84099C15.5129 4.26295 15.75 4.83524 15.75 5.43198V14.25C15.75 15.4927 14.7427 16.5 13.5 16.5H4.5C3.25736 16.5 2.25 15.4927 2.25 14.25V3.75Z"
      fill={fill1}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 8.25C5.25 7.83578 5.58579 7.5 6 7.5H12C12.4142 7.5 12.75 7.83578 12.75 8.25C12.75 8.66422 12.4142 9 12 9H6C5.58579 9 5.25 8.66422 5.25 8.25Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 11.25C5.25 10.8358 5.58579 10.5 6 10.5H9C9.41423 10.5 9.75 10.8358 9.75 11.25C9.75 11.6642 9.41423 12 9 12H6C5.58579 12 5.25 11.6642 5.25 11.25Z"
      fill={fill2}
    />
    <path
      d="M13.2803 2.03033L15.2197 3.96967C15.5592 4.30924 15.75 4.76978 15.75 5.25H13.5C12.6716 5.25 12 4.57843 12 3.75V1.5C12.4802 1.5 12.9408 1.69076 13.2803 2.03033Z"
      fill={fill2}
    />
  </svg>
);
