import React from "react";

const DownloadAction = ({ fill1, fill2, fill3 }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" fill="#FAFAFA" />
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" stroke={fill1} stroke-width="0.8" />
    <path
      d="M20.751 14.75C20.751 14.3358 20.4153 14 20.001 14C19.5868 14 19.251 14.3358 19.251 14.75V20.8147L17.1563 18.72C16.8634 18.4271 16.3885 18.4271 16.0956 18.72C15.8028 19.0129 15.8028 19.4878 16.0956 19.7806L18.9404 22.6253C19.5261 23.2111 20.4759 23.2111 21.0617 22.6253L23.9049 19.7821C24.1978 19.4892 24.1978 19.0144 23.9049 18.7214C23.6121 18.4285 23.1372 18.4285 22.8443 18.7214L20.751 20.8147V14.75Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 21.5C14.4142 21.5 14.75 21.8358 14.75 22.25V23.75C14.75 24.1642 15.0858 24.5 15.5 24.5H24.5C24.9142 24.5 25.25 24.1642 25.25 23.75V22.25C25.25 21.8358 25.5858 21.5 26 21.5C26.4142 21.5 26.75 21.8358 26.75 22.25V23.75C26.75 24.9927 25.7427 26 24.5 26H15.5C14.2574 26 13.25 24.9927 13.25 23.75V22.25C13.25 21.8358 13.5858 21.5 14 21.5Z"
      fill={fill3}
    />
  </svg>
);

export default DownloadAction;
