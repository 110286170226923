import React from "react";
import Typography from "utils/typography";

const ChartCard = ({ title, subtitle, children }) => {
  return (
    <div className="w-full h-[360px] p-5 drop-shadow-md bg-white rounded-lg">
      <div className="flex flex-col items-center">
        <Typography.Text>{title}</Typography.Text>
        <Typography.SubText className="text-gray-500">{subtitle}</Typography.SubText>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ChartCard;
