import React, { useEffect, useRef, useState } from "react";
import PlayIcon from "assets/images/survey/PlayIcon.png";
import previousPlayback from "assets/images/survey/previousPlayback.png";
import forwardPlayback from "assets/images/survey/forwardPlayback.png";
import pauseIcon from "assets/images/survey/pauseIcon.png";
import roundedPause from "assets/images/survey/roundedPause.png";

const VideoPlayer = ({ url, current }) => {
  const [isPlaying, setIsPlaying] = useState(false); // Autoplay set to true initially
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const percentage = (currentTime / duration) * 100;

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  console.log({ currentTime: Math.floor(videoRef?.current?.currentTime), externalCurrentTime: current });

  useEffect(() => {
    const video = videoRef.current;

    const updateProgress = () => {
      if (video) {
        setCurrentTime(video.currentTime);
        setDuration(video.duration);
      }
    };

    const intervalId = setInterval(updateProgress, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = current;
    }
  }, [current]);

  // const handleProgressClick = (e) => {
  //   const progressBar = e.target;
  //   const newTime = (e.nativeEvent.offsetX / progressBar.offsetWidth) * duration;
  //   videoRef.current.currentTime = newTime;
  // };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds)}`;
  };

  const fastForward = () => {
    const video = videoRef.current;
    video.currentTime += 10;
  };
  const backward = () => {
    const video = videoRef.current;
    video.currentTime -= 10;
  };

  return (
    <div className="relative overflow-hidden">
      <video ref={videoRef} className="h-[441px] w-full object-cover" onClick={togglePlayPause}>
        <source src={url} type="video/mp4" />
      </video>
      {!isPlaying && currentTime === 0 ? (
        <div className="bg-trans-black absolute flex items-center justify-center w-full h-full left-0 top-0">
          <img src={PlayIcon} alt="" className="w-[56px] cursor-pointer" onClick={togglePlayPause} />
        </div>
      ) : !isPlaying && currentTime !== 0 ? (
        <div>
          <div className="absolute flex items-center justify-center w-full h-full left-0 top-0">
            <img src={roundedPause} alt="" className="w-[56px] cursor-pointer" onClick={togglePlayPause} />
          </div>
          <div className="bg-trans-black absolute w-full h-[63px] left-0 bottom-0">
            <div className="p-2 w-full">
              <div className="bg-gray-200 h-[5px] rounded-full w-[100%]">
                <div className="bg-primary-400 rounded-full h-[100%]" style={{ width: `${percentage}%` }}></div>
              </div>
            </div>
            <div className="flex justify-between items-center px-[2rem]">
              <div className="flex items-center space-x-5 h-full">
                <img src={previousPlayback} alt="" className="w-[24px] cursor-pointer" onClick={togglePlayPause} />
                <img src={pauseIcon} alt="" className="w-[24px] cursor-pointer" onClick={togglePlayPause} />
                <img src={forwardPlayback} alt="" className="w-[24px] cursor-pointer" onClick={togglePlayPause} />
              </div>
              <div className="flex justify-end text-white">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-trans-black absolute w-full h-[63px] left-0 bottom-0">
          <div className="p-2 w-full">
            <div className="bg-gray-200 h-[5px] rounded-full w-[100%]">
              <div className="bg-primary-400 rounded-full h-[100%]" style={{ width: `${percentage}%` }}></div>
            </div>
          </div>
          <div className="flex justify-between items-center px-[2rem]">
            <div className="flex items-center space-x-5 h-full">
              <img src={previousPlayback} alt="" className="w-[24px] cursor-pointer" onClick={backward} />
              <img src={pauseIcon} alt="" className="w-[24px] cursor-pointer" onClick={togglePlayPause} />
              <img src={forwardPlayback} alt="" className="w-[24px] cursor-pointer" onClick={fastForward} />
            </div>
            <div className="flex justify-end text-white">
              <span>{formatTime(currentTime)}</span>
              <span>{formatTime(duration)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
